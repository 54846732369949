const data = {
    namespaced: true,
    state: {
        company_list: []
    },
    mutations: {
        SET_COMPANY_LIST: (state, payload) => {
            state.company_list = payload
        },
    },
    actions: {
        getList ( {commit}, params = {} ) {     
            this._vm.$http
                .get(`get-company`, {
                    params: params, 
                })
                .then(({ data }) => {
                    commit('SET_COMPANY_LIST', data);
                })
        },
    },
}

export default data;
