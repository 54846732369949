const data = {
    namespaced: true,
    state: {
        hasBillingAccess: false,
        loadBillingPortal: false,
        billingUrl: false,
        renderedBillingPortal: false,
        reloadManageProduct: false,
        reloadInvoice: false,
    },
    mutations: {
        SET_HAS_BILLING_ACCESS: (state, payload) => {
            state.hasBillingAccess = payload
        },
        SET_LOAD_BILLING_PORTAL: (state, payload) => {
            state.loadBillingPortal = payload
        },
        SET_BILLING_URL: (state, payload) => {
            state.billingUrl = payload
        },
        SET_RENDER: (state, payload) => {
            state.renderedBillingPortal = payload
        },
        SET_RELOAD: (state, payload) => {
            state.reloadManageProduct = payload
        },
        SET_RELOAD_INVOICE: (state, payload) => {
            state.reloadInvoice = payload
        },
    },
    actions: {
        renderBillingPortal ( {commit, state}, user ) {
            commit('SET_LOAD_BILLING_PORTAL', true);

            this._vm.$http
                .get(`/billing/check-billing-access`, {
                    params: {
                        user_id: user.id,
                    },
                })
                .then(({ data }) => {
                    commit('SET_RENDER', true);
                    commit('SET_LOAD_BILLING_PORTAL', false);
                    commit('SET_HAS_BILLING_ACCESS', data.access);
                    commit('SET_BILLING_URL', data.url);
                })
        },
    },
}

export default data;
