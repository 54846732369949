<template>
    <div class="content" :class="!is_super_admin ? 'content-full' : ''">
        <breadcrumbs :links="breadcrumbs"/>

        <div class="content-wrapper">
            <h1 class="font-weight-medium">{{ $route.meta.title }}
                <span v-if="isView" class="title font-weight-thin">View</span>
                <span v-else class="title font-weight-thin">Edit</span>
            </h1>
            
            <v-layout row  class="mt-5">
                <v-col xs12 :md="is_super_admin ? '5' : '12'" :lg="is_super_admin ? '4' : '12'" class=" content-image-section">
                    <v-card class="text-left" v-if="is_super_admin">
                        <v-list>
                            <v-list-item-group color="primary">
                                <v-list-item @click="showSyncLogs()">
                                    <v-list-item-icon>
                                        <v-icon>mdi-clipboard-flow</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Sync Logs</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <sync-logs-modal :data="company" :model="'App\\Models\\Company'"/>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col xs12 md7 lg8 class="px-4 content-image">
                        <v-card class="pa-4 flex-column" min-height="632">
                            <v-tabs grow v-model="tab">
                                <v-tab>Information</v-tab>
                                <v-tab v-if="is_super_admin">Subscriptions</v-tab>
                                <v-tab v-if="is_super_admin">Invoice
                                    <v-badge 
                                        color="error" 
                                        class="ml-2"
                                        v-if="invoicesBadgeCount > 0" 
                                        :content="invoicesBadgeCount" 
                                        bordered 
                                    />
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <v-form @submit.prevent="submit()"  method="PUT" :readonly="isView">
                                        <v-container class="my-5 content-container">
                                            <v-text-field
                                                label="Id"
                                                placeholder="ID"
                                                outlined
                                                required
                                                readonly
                                                dense
                                                v-if="is_super_admin"
                                                v-model="company.id"
                                            />
                                            <v-text-field
                                                label="Central Id"
                                                placeholder="ID"
                                                outlined
                                                required
                                                readonly
                                                v-if="is_super_admin"
                                                dense
                                                v-model="company.central_id"
                                            />

                                            <div class="d-flex">
                                                <v-checkbox
                                                class="mr-10"
                                                :disabled="!roleCondition()"
                                                dense
                                                v-model="company.is_automotive"
                                                label="Automotive"
                                                color="primary"/>

                                                <v-checkbox
                                                v-show="is_super_admin"
                                                dense
                                                v-model="company.is_single_user"
                                                label="Single User"
                                                color="primary"/>
                                            </div>

                                            <v-text-field
                                                dense
                                                v-if="showDefaultPassword"
                                                class="mb-3"
                                                v-model="company.default_password"
                                                prepend-inner-icon="mdi-lock-outline"
                                                :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="password_show ? 'text' : 'password'"
                                                name="input-10-1"
                                                label="Default Password"
                                                outlined
                                                @click:append="password_show = !password_show"
                                                :rules="errors.password"
                                                hint="The password must be between 8 and 30 characters, must have atleast 1 uppercase and 1 numeric "
                                            ></v-text-field>

                                            <v-text-field
                                                :disabled="!roleCondition()"
                                                dense
                                                label="Name"
                                                placeholder="Name"
                                                outlined
                                                required
                                                v-model="company.name"
                                                :rules="errors.name"
                                            >
                                            <template #label>
                                                <span class="red--text"><strong>* </strong></span>Name
                                            </template>
                                            </v-text-field>

                                            <v-text-field
                                                dense   
                                                label="Website"
                                                placeholder="Website"
                                                outlined
                                                v-model="company.website"
                                                :rules="errors.website"
                                            >
                                            </v-text-field>

                                            <v-text-field
                                                dense
                                                label="Address"
                                                placeholder="Address"
                                                outlined
                                                v-model="company.address"
                                                :rules="errors.address"
                                            ></v-text-field>

                                            <v-text-field
                                                dense
                                                label="City"
                                                placeholder="City"
                                                outlined
                                                v-model="company.city"
                                                :rules="errors.city"
                                            ></v-text-field>

                                            <v-text-field
                                                dense
                                                label="State"
                                                placeholder="State"
                                                outlined
                                                v-model="company.state"
                                                :rules="errors.state"
                                            ></v-text-field>

                                            <v-text-field
                                                type="number"
                                                dense
                                                label="Zip"
                                                placeholder="Zip"
                                                outlined
                                                v-model="company.zip"
                                                :rules="errors.zip"
                                            ></v-text-field>
                                            
                                            <v-select
                                                v-if="is_super_admin"
                                                :disabled="!roleCondition()"
                                                v-model="company.apps"
                                                label="Apps"
                                                :items="app_list"
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                dense
                                                multiple
                                                chips
                                                @change="hasLMSApp"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            ></v-select>
                                            
                                            <v-checkbox
                                            dense
                                            :disabled="!roleCondition()"
                                            v-model="company.is_active"
                                            label="Active"
                                            color="primary"/>
                                        </v-container>
                                        <v-spacer/>
                                        <v-card-actions v-if="!isView">
                                            <v-btn
                                            v-if="roleCondition()"
                                            color="error"
                                            dark
                                            @click="remove()"
                                            class="mt-5"
                                            :loading="loading"
                                            text
                                            > 
                                            Remove 
                                            </v-btn>
                                            <v-spacer/>
                                            <v-btn
                                            color="primaryMain"
                                            dark
                                            :loading="loading"
                                            class="mt-5 primaryMainText--text"
                                            type="submit" > Submit </v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-tab-item>
                                <v-tab-item>
                                    <company-subscription :isSingleUser="isSingleUser"></company-subscription>
                                </v-tab-item>
                                <v-tab-item>
                                    <company-invoice 
                                        :items="invoices" 
                                        :loading="invoiceLoading">
                                    </company-invoice>
                                </v-tab-item>
                            </v-tabs-items>

                        </v-card>
                </v-col>
            </v-layout>   
        </div>
    </div> 
</template>

<script>
import {
  includes as _includes,
} from "lodash-es";
import Vue from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapState } from 'vuex';
import SyncLogsModal from '@/components/SyncLogsModal';
import CompanySubscription from '@/components/company_billing/CompanySubscription';
import CompanyInvoice from '@/components/company_billing/CompanyInvoice';

Vue.use(require('vue-moment'));

export default {
    computed: {
         ...mapState('apps', [
        'app_list'
        ]),
         ...mapState('user', [
            'current_user', 
            'is_super_admin', 
            'is_account_manager'
        ]),
        ...mapState('billing', [
            'reloadInvoice'
        ]),
        ...mapState('layout', [
            'passwordAutogenerate'
        ]),
        isView() {
            return this.$route.name == this.$route.meta.type +'.view'
        },
        companyId() {
            return this.$route.params.id 
        },
        showDefaultPassword() {
            return this.roleCondition() && !this.isView && !this.passwordAutogenerate
        }
    },
    data() {
        return {
            loading: false,
            tab: null,
            breadcrumbs: {
                '0': {
                    title: 'Home',
                    link: 'home',
                },
                '1': {
                    title: this.$route.meta.title,
                    link: this.$route.meta.type,
                },
                '2': {
                    title: 'Edit',
                    link: this.$route.meta.type+'.edit',
                }
            },
            password_show: false,
            company: {},
            errors: [],
            invoices: {},
            invoicesBadgeCount: '',
            invoiceLoading: {},
            lmsApp: {},
            isLMSApp: false,
            isSingleUser: false,
        };
    },
    watch: {
        reloadInvoice(val) {
            if (val) {
                this.fetchInvoices()
            }
        }
    },
    created() {
        this.fetchCompany()
        this.fetchApps()
        this.fetchInvoices()
        this.renderPage()
        this.getLMSApp()
    },
    methods: {
        getLMSApp() {
            this.$http
            .get(`/get-lms-app`)
            .then((result) => {
                this.lmsApp = result.data
                this.hasLMSApp()
            })
        },
        hasLMSApp() {
           this.isLMSApp = _includes(this.company.apps, this.lmsApp.id)
        },
        getOpenCount(value) {
            this.openCount = value
        },
        renderPage() {
            if (this.isView) {
                this.breadcrumbs[2].title = 'View'
                this.breadcrumbs[2].link = this.$route.meta.type+'.view'
            } 
        },
        pageValidation() {
            if (!this.is_super_admin && !this.is_account_manager) {
                this.$router.push({ name: this.$route.meta.type+'.view', params:{id: this.company.id } });
            }
        },
        fetchApps() {
            if (this.app_list.length == 0) {
                this.$store.dispatch('apps/getList', {exclude: true})
            }
        },
        fetchCompany() {
            this.$store.commit('global/SET_BREADCRUMBS_LOADER', true)            
                
            this.$http
                .get('company/'+ this.companyId)
                .then((result) => {
                    this.company = result.data
                    this.isSingleUser = result.data.is_single_user
                    this.pageValidation()
                    this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)            
                })
                .catch((error) => {
                    if (error.response.status == 403){
                        this.$errorNotif(error.response.data.msg)
                        this.$router.push({ name: this.$route.meta.type });
                    }
                    this.loading = false
                    this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)            
                })
        },
        fetchInvoices() {
            this.invoiceLoading = true
            this.invoices = [];
            this.$http
            .get(`billing/company-invoice/${this.companyId}`)
            .then((result) => {
                    this.invoices = result.data.invoices
                    this.invoicesBadgeCount = result.data.openCount
                    this.invoiceLoading = false
                    this.$store.commit('billing/SET_RELOAD_INVOICE', false);            
                })
                .catch((error) => {
                    this.invoiceLoading = false
                })
        },
        submit() {
            if (!this.company.zip) {
                this.company.zip = ''
            }
            this.loading = true
            this.errors = []

            this.$http
            .put(`/company/${this.company.id}`, this.company)
            .then((result) => {
                this.loading = false
                this.$store.dispatch('company/getList')
                this.$successNotif('Company information has been saved')
                this.isSingleUser = result.data.is_single_user
                window.location.reload()
            })
            .catch((error) => {
                if (error.response.status == 422){
                    this.errors = error.response.data.errors
                    this.$errorNotif('Invalid inputs, Please check the details')
                }
                this.loading = false
            })
        },
        remove() {
            let msg = {
                title: `Remove ${this.company.name}?`,
                confirmButtonText: 'Yes, Remove!',
            }

            this.$questionNotif(msg)
            .then((result) => {
                if (result) {
                    this.loading = true
                    this.$http
                    .delete(`/company/${this.company.id}`)
                    .then((data) => {
                        this.loading = false
                        this.$store.dispatch('company/getList')
                        this.$successNotif('Successfully deleted company')
                        this.$router.push({ name: this.$route.meta.type });
                    })
                }
            })
        },
        roleCondition() {
           return this.is_super_admin
        },
        showSyncLogs() {
            if (this.company) {
                this.$modal.show('sync-logs-modal')
            }
        },
    },
    components: {
        Breadcrumbs,
        SyncLogsModal,
        CompanyInvoice,
        CompanySubscription
    },
}
</script>