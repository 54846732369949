<template>
    <v-navigation-drawer
        class="nav-drawer"
        app
        v-model="drawer"
        :style="`margin-top: ${drawer_top}px`"
        :permanent="$vuetify.breakpoint.mdAndUp">


        <div>
            <div class="webinarinc-logo-group text-center">
                <img
                    class="webinarinc-logo"
                    id="company-logo"
                    v-if="companyLogo"
                    :style="'--logo-width: ' + this.companyLogoWidth + 'px'"
                    :src="companyLogo"
                >
            </div>
            <v-list flat>
            <v-list-item-group data-active="true">
                <router-link
                  :id="`central-Asidebar-${route.meta.title}`"
                  v-for="(route, index) in routes"
                  :to="{name: route.name}"
                  :key="index">
                    <v-list-item class="v-sidebar-menu">
                        <v-list-item-icon>
                            <v-icon>{{route.meta.icon}}</v-icon>
                        </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="">{{ route.meta.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>

          <v-list-group
            class="aside-dropdown"
            :value="true"
            :key="index"
            v-for="(route, index) in groupRoutes">

            <template v-slot:activator>
                <v-list-item class="v-sidebar-menu">
                  <v-list-item-icon>
                    <v-icon class="subtitle-1 billing-icon" dark>{{ route.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">{{ route.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>

            <router-link
              :key="index"
              v-for="(item, index) in route.item"
              :to="{ name: item.name }"
            >
              <v-list-item class="v-sidebar-menu"  v-if="checkBillingAccess(item)">
                <v-list-item-content>
                  <v-list-item-title class="ml-9 d-flex justify-space-between">
                    {{ item.meta.title }}

                    <v-progress-circular
                      v-if="item.meta.billing && loadBillingPortal"
                      indeterminate
                      size="15"
                      color="primaryMain"
                    ></v-progress-circular>

                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>

          </v-list-group>
        </v-list-item-group>
      </v-list>
    </div>

    <div>
      <aside-bar-footer />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import AsideBarFooter from "@/layouts/AsideBarFooter.vue";
import logoImport from "@/assets/images/revdojo_logo.png";

import {
  find as _find,
  filter as _filter,
  map as _map,
  some as _some,
} from "lodash-es";

export default {
  data() {
    return {
      drawer_top: 0,
      logo: logoImport
    };
  },
  components: {
    AsideBarFooter,
  },
  props: ["value"],
  computed: {
    ...mapState("layout", ["companyLogo", "companyLogoWidth", "labels"]),
    ...mapState("billing", [
      "loadBillingPortal",
      'hasBillingAccess',
      'renderedBillingPortal',
      'billingUrl'
    ]),
    user() {
      return this.$store.state.user.current_user
    },
    isSingleUser() {
      if(this.user) {
        return this.user.company.is_single_user
      }
      return false
    },
    isSuperAdmin() {
       return _some(this.user.roles, { name: 'super-administrator' });
    },
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    routes() {
      const user = this.user;
      const items = _find(this.$router.options.routes, {
        name: "main",
      }).children;

      return _filter(items, (item) => {
        if (item.meta.hasOwnProperty("group")) {
          return false;
        }

        if (item.meta.hasOwnProperty("showSideBar")) {
          return item.meta.showSideBar;
        }

        if (item.meta.hasOwnProperty("hide")
            && item.meta.hide == 'single-user'
            && this.isSingleUser) {
            return false
        }

        if (item.meta.hasOwnProperty("non_auto")
            && item.meta.non_auto == true
            && !user.company.is_automotive) {
            return false
        }

        if (item.meta.hasOwnProperty("allowed")) {
          let allowedList = _map(item.meta.allowed, (permission) => {
            return _some(user.roles, { name: permission });
          });
          return allowedList.includes(true);
        }

        return true;
      });
    },
    routeWithGroup() {

      const user = this.user;
      const items = _find(this.$router.options.routes, {
        name: "main",
      }).children;

      return _filter(items, (item) => {
        if (!item.meta.hasOwnProperty("group")) {
          return false;
        }

        if (item.meta.hasOwnProperty("hide")
            && item.meta.hide == 'single-user'
            && this.isSingleUser) {
            return false
        }

        if (item.meta.hasOwnProperty("allowed")) {
          let allowedList = _map(item.meta.allowed, (permission) => {
            return _some(user.roles, { name: permission });
          });
          return allowedList.includes(true);
        }
        return true;
      });
    },
    groupRoutes(){
      let routesList = this.routeWithGroup

      if (routesList.length == 0) {
        return false
      }

      let result = routesList.reduce(function (route, item) {
        route[item.meta.group] = route[item.meta.group] || [];
        route[item.meta.group]['name'] = item.meta.group
        route[item.meta.group]['icon'] = item.meta.groupIcon
        if (route[item.meta.group]['item'] == undefined){
          route[item.meta.group]['item'] = []
        }
        route[item.meta.group]['item'].push(item);
        return route;
      }, Object.create(null));

      return result
    },
    dealersLabel() {
      return this.labels!==undefined ? this.labels.dealers : '';
    }
  },
  created() {
    this.renderBillingPortal();
  },
  mounted() {
    this.updateDealersLabel(this.dealersLabel);
  },
  watch: {
    labels: function (newValue) {
      this.updateDealersLabel(newValue.dealers)
    }
  },
  methods: {
    updateDealersLabel(label) {
      if (label === undefined) {
        return;
      }
      let specificDealersRoute = this.$router.options.routes.find(route => route.name === 'main').children.find(route => route.name === 'specific_dealers');
      if (specificDealersRoute) {
        specificDealersRoute.meta.title = label.charAt(0).toUpperCase() + label.slice(1);
      }
    },
    drawerTop() {
      if (this.$vuetify.breakpoint.mdAndUp)
        this.drawer_top = this.$vuetify.application.top;
      else this.drawer_top = 0;
    },

    checkBillingAccess(item) {

      if (item.name == 'billing' && this.$store.state.user.is_super_admin) {
         return false
      }

      if(item.name == 'manage_product' && !this.isSuperAdmin) {
        return false;
      }

      if (!item.meta.billing) {
        return true
      }
   
      if (item.name == 'billing_portal' && this.billingUrl === false) {
         return false
      }

      return this.hasBillingAccess
    },

    renderBillingPortal() {
      if (this.user && !this.renderedBillingPortal) {
        this.$store.dispatch('billing/renderBillingPortal', this.user)
      }
    },
  },
};
</script>
