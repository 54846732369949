<template>
  <div>
    <div class="my-10 text-right">
      <!-- <v-btn color="primary" @click="openAddSubscription()" v-show="!isSingleUser">Add Manual Subscription</v-btn> -->
    </div>
    <div class="my-5">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="pagination.total"
        :items-per-page="pagination.per_page"
        :footer-props="{
          disablePagination: paginationDisable,
          'items-per-page-options': [5, 10, 20, 50],
        }"
      >
        <template v-slot:item.created_at="{ item }">
          <span>{{ new Date(item.created_at).toLocaleString() }}</span>
        </template>
      </v-data-table>
    </div>
    <add-subscription-modal @reload="fetch()" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AddSubscriptionModal from '@/components/company_billing/AddSubscriptionModal';

export default {
    props: [
      'isSingleUser',
    ],
    computed: {
         ...mapState('user', [
            'current_user', 
            'is_super_admin', 
            'is_account_manager'
        ]),
        companyId() {
            return this.$route.params.id 
        }
    },
    data() {
        return {
            loading: false,
            headers: [
                { text: 'Name', value: 'name'},
                { text: 'Billing User', value: 'billing_user' },      
                { text: 'Product Name', value: 'product_name' },      
                { text: 'Interval', value: 'interval' },      
                { text: 'Price', value: 'price' },      
                { text: 'Status', value: 'status' },      
                { text: 'Date Created', value: 'created_at' },
            ],
            items: [],
            search: '',
            pagination: {
                total: 1,
                per_page: 10,
                current_page: 1,
                search: '',
                sortBy: '',
                sortDesc: false,
            },
            options: {},
            paginationDisable: false,
            timeout: null,
            filters: null,
        };
    },
    created() {
        this.fetch()
    },
    methods: {
        fetch() {
            this.loading = true
            this.$http
                .get(`billing/company-subscription/${this.companyId}`)
                .then((data) => {
                    this.items = data.data
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                })
        },
        openAddSubscription() {
            this.$modal.show('add-subscription-modal')
        }
    },
    components: {
        AddSubscriptionModal
    },
}
</script>