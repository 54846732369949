<template>
  <div class="content" :class="!is_super_admin ? 'content-full' : ''">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">
        {{ dealersTitle }}
        <span v-if="isView" class="title font-weight-thin">View</span>
        <span v-else class="title font-weight-thin">Edit</span>
      </h1>

      <v-layout row class="mt-5">
        <v-col
          xs12
          :md="is_super_admin ? '5' : '12'"
          :lg="is_super_admin ? '4' : '12'"
          class="content-image-section"
        >
          <v-card class="text-left" v-if="is_super_admin">
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item @click="showSyncLogs()">
                  <v-list-item-icon>
                    <v-icon>mdi-clipboard-flow</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Sync Logs</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <sync-logs-modal
                  :data="specific_dealer"
                  :model="'App\\Models\\SpecificDealer'"
                />
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col xs12 lg12 class="px-4 content-image">
          <v-form :readonly="isView" @submit.prevent="submit()" method="PUT">
            <v-card class="pa-4 d-flex flex-column" min-height="400">
              <h2 class="title">Information</h2>
              <v-divider />

              <v-container class="my-5 content-container">
                <v-text-field
                  label="Id"
                  placeholder="ID"
                  outlined
                  readonly
                  dense
                  v-if="is_super_admin"
                  v-model="specific_dealer.id"
                />
                <v-text-field
                  label="Central Id"
                  placeholder="ID"
                  outlined
                  readonly
                  v-if="is_super_admin"
                  dense
                  v-model="specific_dealer.central_id"
                />

                <v-autocomplete
                  :disabled="!is_super_admin"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="Company"
                  required
                  bottom
                  dense
                  v-model="specific_dealer.company_id"
                  :items="company_list"
                  autocomplete="off"
                  :rules="errors.company_id"
                  @blur="outFocusCompanyField()"
                >
                </v-autocomplete>

                <v-text-field
                  label="Name"
                  placeholder="Name"
                  outlined
                  required
                  dense
                  v-model="specific_dealer.name"
                  :rules="errors.name"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Name
                  </template>
                </v-text-field>
              </v-container>
              <v-spacer />
              <v-card-actions v-if="!isView">
                <v-btn
                  color="error"
                  dark
                  @click="remove()"
                  class="mt-5"
                  :loading="loading"
                  text
                >
                  Remove
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primaryMain"
                  dark
                  :loading="loading"
                  class="mt-5 primaryMainText--text"
                  type="submit"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-layout>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import SyncLogsModal from "@/components/SyncLogsModal";

Vue.use(require("vue-moment"));

export default {
    computed: {
        ...mapState("layout", ["labels"]),
        ...mapState("user", [
            "current_user", 
            'is_super_admin', 
            'is_account_manager'
        ]),
        isView() {
            return this.$route.name == this.$route.meta.type +'.view'
        },
        company_list() {
            return this.$store.state.company.company_list;
        },
        breadcrumbs() {
            return {
                '0': {
                    title: 'Home',
                    link: 'home',
                },
                '1': {
                    title: this.dealersTitle,
                    link: this.$route.meta.type,
                },
                '2': {
                  title: this.bc2Title.charAt(0).toUpperCase() + this.bc2Title.slice(1),
                  link: this.$route.meta.type+'.'+this.bc2Title,
                }
            };
        },
        dealersTitle() {
            return this.labels !== undefined && this.labels.dealers !== undefined ? this.labels.dealers.charAt(0).toUpperCase() + this.labels.dealers.slice(1) : '';
        },
        dealerTitle() {
            return this.labels !== undefined && this.labels.dealer !== undefined ? this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1) : '';
        },
        bc2Title()  {
          return this.isView ? 'view' : 'edit';
        },
    },
    data() {
        return {
            loading: false,
            specific_dealer: {},
            company_id: '',
            errors: [],
            companies: [],
            companySetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            currentCompanyId: ''
        };
    },
    created() {
        this.$store.dispatch('company/getList', { is_automotive:1 })
        this.fetchSpecificDealer()
    },
    methods: {
        pageValidation() {
            if (this.specific_dealer.deleted_at && !this.isView){
                this.$router.push({ name: this.$route.meta.type+".view", params:{id: this.specific_dealer.id } });
            }
            
            if (!this.is_super_admin && !this.is_account_manager) {
                this.$router.push({ name: this.$route.meta.type+".view", params:{id: this.specific_dealer.id } });
            }
        },
        fetchSpecificDealer() {
            this.$store.commit('global/SET_BREADCRUMBS_LOADER', true)            
            let id = this.$route.params.id 
            this.$http
                .get('specific-dealer/'+ id)
                .then((result) => {
                    this.specific_dealer = result.data
                    this.company_id = result.data.company_id
                    this.pageValidation()
                    this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)            
                    this.currentCompanyId = this.specific_dealer.company_id
                })
                .catch((error) => {
                    if (error.response.status == 403){
                        this.$errorNotif(error.response.data.msg)
                        this.$router.push({ name: this.$route.meta.type });
                    }
                    this.loading = false
                    this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)            
                })
        },
        submit() {
            this.loading = true
            this.errors = []
            
            let form = {}
            form.company_id = this.specific_dealer.company_id
            form.name = this.specific_dealer.name
            form.id = this.specific_dealer.id

      this.$http
        .put(`/specific-dealer/${this.specific_dealer.id}`, form)
        .then((result) => {
          this.loading = false;
          this.$successNotif(this.dealerTitle+" information has been saved");
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
            this.$errorNotif("Invalid inputs, Please check the details");
          }
          this.loading = false;
        });
    },
    remove(item) {
      let msg = {
        title: "Remove " + this.specific_dealer.name + "?",
        confirmButtonText: "Yes, Remove!",
      };

      this.$questionNotif(msg).then((result) => {
        if (result) {
          this.loading = true;
          this.$http
            .delete(`/specific-dealer/${this.specific_dealer.id}`)
            .then((data) => {
              this.loading = false;
              this.$successNotif("Successfully deleted "+this.labels.dealer);
              this.$router.push({ name: this.$route.meta.type });
            })
            .catch((error) => {
              this.loading = false
              this.$errorNotif(error.response.data.msg)
            })
        }
      });
    },
    outFocusCompanyField() {
        if (this.specific_dealer.company_id == null) {
          this.specific_dealer.company_id = this.currentCompanyId
        }
    },
    showSyncLogs() {
      if (this.specific_dealer) {
        this.$modal.show("sync-logs-modal");
      }
    },
  },
  components: {
    Breadcrumbs,
    SyncLogsModal,
  },
};
</script>