<template>
    <div class="access-denied" >
        <v-card
            class="mx-auto access-denied-card "
        >   
            <div class="access-denied-content mb-10">
                <img
                    class="webinarinc-logo"
                    id="company-logo"
                    :style="'--logo-width: ' + this.companyLogoWidth + 'px'"
                    :src="companyLogo"
                    >

                <div class="mt-5 text-left">
                    <h2 class="mt-3 mb-3 text-center">{{ title }}</h2>
                    <br>
                    <p>Hi {{ userName }},</p>
                    <p v-if="isNoAppAccess || isCompanyDeactivate">You don't have permission to access the <b>{{ appName }}</b> application. </p>
                    <p v-if="isUnderReview">We'll review your registration and if we can confirm it, you'll be able to access your account within approximately 24 hours.</p>
                    <p v-if="isRejected">Your account has been <b>rejected</b> and you do not have access to our application.</p>
                    <p v-if="isInactive">
                        To reactivate your account, 
                        <b>please contact your account administrator</b> and request to reactivate your account. </p>

                    <p v-if="isDeletedUser">Your account has been <b>deleted</b>. If you would like to retrieve your deleted account.</p>
                
                    <p v-if="isCompanyDeactivate">
                        To reactivate the account, 
                        <b>please contact your account administrator</b> or our support  <a class="subtitle-2 blue--text text--accent-2" :href="'mailto:' + admin.email" target="_blank">
                        {{ admin.email }}
                    </a> and request to reactivate the account. </p>
                    <br>
                    Please send an email to 
                    <a class="subtitle-2 primaryMainInverted--text" :href="'mailto:' + admin.email" target="_blank">
                        {{ admin.email }}
                    </a>
                    if you have any concerns.
                </div>
            </div>

            <v-card-actions>
            <v-spacer />
            <v-btn
                @click="goBack()"
                color="primaryMain"
                dark
                class="mt-5 primaryMainText--text"
            >
                Go Back to Login
            </v-btn>
            <v-spacer />
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            appNameDefault: 'Central',
        }
    },
    computed: {
        ...mapState("layout", ["admin"]),
        ...mapState("layout", ["companyLogo", "companyLogoWidth"]),
        appName() {
            return this.$route.query.app_name ?? appNameDefault
        },
        error() {
            return this.$route.query.error
        },
        userName() {
            return this.$route.query.user_name
        },
        userCentralId() {
            return this.$route.query.user_central_id
        },
        companyName() {
            return this.$route.query.company_name
        },
        isNoAppAccess() {
            return this.error == 'no_app_access'
        },
        isDeletedUser() {
            return this.error == 'deleted_user'
        },
        isUnderReview() {
            return this.error == 'UNDER_REVIEW'
        },
        isInactive() {
            return this.error == 'INACTIVE'
        },
        isRejected() {
            return this.error == 'REJECTED'
        },
        isCompanyDeactivate() {
            return this.error == 'company_deactivate'
        },
        title() {
            if (this.isNoAppAccess || this.isRejected) {
                return 'Access Denied'
            }

            if (this.isUnderReview) {
                return 'Thanks for providing your information';
            }

            if (this.isInactive) {
                return 'Your account has been deactivated' ;
            }

            if (this.isDeletedUser) {
                return 'Your account has been deleted' ;
            }

            if (this.isCompanyDeactivate) {
                return `State '${this.companyName}' has been deactivated` ;
            }

            return 'Hello'
        }
    },
    mounted() {
        if (this.error == 'User did not authorize the request') {
            this.goBack();
        }

        const params = [ 
            'error_type',
            'error',
            'app_name',
            'user_name',
            'user_central_id'
        ];

        if (!params.every(key => Object.keys(this.$route.query).includes(key))) {
            this.goBack();
        }

    },
    methods: {
        goBack() {
            localStorage.removeItem('nextUrl')
            window.location.href = window.location.origin+`/okta/logout?user_central_id=${this.userCentralId}`
        }
    },
}
</script>