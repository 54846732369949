

export default {
    methods: {
        $formatProductType(value) {
            if (value == 'ENTERPRISE') {
                return 'Enterprise'
            }
            if (value == 'SINGLE_USER') {
                return 'Single User'
            }
            if (value == 'ADDS_ON') {
                return 'Add ons'
            }
            if (value == 'CONTACT_US') {
                return 'Lead Form'
            }
            if (value == 'USAGE_BASED') {
                return 'Usage-based'
            }

            return value
        },
        $formatBillingType(value) {
            if (value.length > 0) {
                value = value[0].interval
            }
            if (value == 'one_time') {
                return 'One Time'
            }
            if (value == 'month') {
                return 'Monthly'
            }
            if (value == 'year') {
                return 'Yearly'
            }
        },
        $formatUserStatus(value) {
            if (value == 'UNDER_REVIEW') {
                return 'Under Review'
            }
            if (value == 'INACTIVE') {
                return 'Inactive'
            }
            if (value == 'REJECTED') {
                return 'Rejected'
            }
        }
    }
};
