<template>
    <div class="tier-prices">
        <v-alert
            v-if="error"
            dense
            outlined
            type="error"
            class="caption text-xs mt-2"
        >
        {{ error[0] }}
        </v-alert>
        <div class="d-flex justify-space-between align-center">
            <p class="subtitle-2 mt-2 mb-4">Tiers</p>
            <div>
                <v-tooltip bottom >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            @click="addTier()"
                            color='primary' 
                            icon 
                            v-bind="attrs"
                            v-on="on">
                            <v-icon>
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Add Tier</span>
                </v-tooltip>
            </div>
        </div>

        <div v-for="(tier, index) in tiers" :key='index' >
            <label class="body-2">{{ tier.name }}</label>
            <div class="d-flex mt-2">
                <v-text-field
                    id="tier-prices-textfield-units"
                    label="No. of users"
                    placeholder="No. of users"
                    outlined
                    dense
                    :disabled="tier.type == 'last_tier'"
                    v-model="tier.no_users"
                    required
                    v-if="!tier.hide_user_field"
                >
                    <template #label>
                    <span class="red--text"><strong>* </strong></span>No. of users
                    </template>
                </v-text-field>
                <v-text-field
                    v-if="tier.is_free == false"
                    id="tier-prices-textfield-1st"
                    class="price-item ml-1"
                    label="For the first units"
                    dense
                    outlined
                    prefix="$"
                    type="number"
                    v-model="tier.price"
                    step="any"
                >
                    <template #label>
                    <span class="red--text"><strong>* </strong></span>Per user price
                    </template>
                </v-text-field>
                <v-tooltip bottom v-if="tier.type == 'first_tier'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            @click="firstTierFree()"
                            color='primary' 
                            icon 
                            v-bind="attrs"
                            v-on="on">
                            <v-icon v-if="!tier.is_free">
                                mdi-currency-usd-off
                            </v-icon>
                            <v-icon v-else>
                                mdi-currency-usd
                            </v-icon>
                        </v-btn>
                    </template>
                    <span v-if="!tier.is_free">Make the first tier FREE?</span>
                    <span v-else>Add price to the first tier</span>
                </v-tooltip>

                <v-tooltip bottom v-if="tier.type == 'next_tier'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            @click="removeTier(tier, index)"
                            color='primary' 
                            icon 
                            v-bind="attrs"
                            v-on="on">
                            <v-icon>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Remove</span>
                </v-tooltip>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import {
    find as _find,
  } from 'lodash';

  export default {
    name: "TierPrices",
    props: [
        'product',
        'error'
    ],
    data() {
      return {
        tiers: [
            {
                name: 'First Tier',
                type: 'first_tier',
                no_users: '',
                is_free: true,
                price: '',
            },
            {
                name: 'Last Tier',
                type: 'last_tier',
                no_users: 'The rest of the users',
                hide_user_field: true,
                is_free: false,
                price: '',
            }
        ]
      };
    },
    watch: {
        tiers: {
            handler: function (after, before) {
                this.$emit('tiers', this.tiers)
            },
        deep: true,
        },
        product() {
            this.initProduct()
        }
    },
    computed: {
        productTiers() {
            return this.product.tiers
        },
    },
    created() {
        this.initProduct()
    },
    methods: {
        initProduct() {
            if (!this.product || this.product.length == 0 || this.tiers.length > 2) {
                return false
            }

            let tiersCount = this.productTiers.length;
            if(tiersCount > 2) {
                this.addTier(tiersCount - 2);
            }   

            this.tiers.forEach((tierItem, index) => {

                let productTier = _find(this.productTiers, {
                    order: ++index,
                });

                if (productTier) {
                    tierItem.is_free = productTier.unit_amount == '0' || productTier.unit_amount == 0
                    tierItem.no_users = productTier.up_to ?? 'The rest of the users'
                    tierItem.price = productTier.unit_amount / 100
                }
            });
        },
        
        addTier(add = 1) {

            if (this.tiers.length == 5) { 
                this.$errorNotif("Maximum of 5 tiers only")
                return
            }

            for (let i = 0; i < add; i++) {
                let tier = {
                    name: 'Next Tier',
                    type: 'next_tier',
                    no_users: '',
                    is_free: false,
                    price: '',
                }

                this.tiers.splice(this.tiers.length - 1, 0, tier);
            }

        },
        firstTierFree() {
            let firstTier = _find(this.tiers, {
                type: 'first_tier',
            });

            firstTier.is_free = !firstTier.is_free
            firstTier.price = ''
        },
        removeTier(tier, index){
            this.tiers.splice(index, 1);
        }
    },
  };
  </script>
  