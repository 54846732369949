import { find as _find, isEmpty as _isEmpty, some as _some } from 'lodash-es'

export default {
  computed: {
    companies() {
      return this.$store.state.users.exportFilters.companies;
    },
    companyLoading() {
      return this.$store.state.users.exportFilters.companyLoading;
    },
    dealers() {
      return this.$store.state.users.exportFilters.dealers;
    },
    dealerLoading() {
      return this.$store.state.users.exportFilters.dealerLoading;
    },
    statuses() {
      return this.$store.state.users.exportFilters.statuses;
    },
    roles() {
      return this.$store.state.users.exportFilters.roles;
    },
    roleLoading() {
      return this.$store.state.users.exportFilters.roleLoading;
    },
    positions() {
      return this.$store.state.users.exportFilters.positions;
    },
    positionLoading() {
      return this.$store.state.users.exportFilters.positionLoading;
    },
    superAdmin() {
      return this.findRole('super-administrator')
    },
    accountManager() {
      return this.findRole('account-manager')
    },
    dealerManager() {
      return this.findRole('specific-dealer-manager')
    },
  },
  methods: {
    initializeFilters() {
      this.getRoles();
      this.getCompanies();
      this.getDealers();
      this.getPositions();
    },
    getRoles() {
      if (!_isEmpty(this.roles)) {
        return;
      }
      
      this.$store.commit('users/SET_ROLE_LOADING', true);
      this.$http
      .get('roles')
      .then(({ data }) => {
        this.$store.commit('users/SET_ROLE_LOADING', false);
        this.$store.dispatch('users/setRolesFilterState', data);
      })
    },
    getCompanies() {
      if (!_isEmpty(this.companies)) {
        return;
      }
      
      this.$store.commit('users/SET_COMPANY_LOADING', true);
      
      this.$http
      .get('get-company')
      .then(({ data }) => {
          this.$store.commit('users/SET_COMPANY_LOADING', false);
          this.$store.dispatch('users/setCompaniesFilterState', data);
        })
    },
    getDealers() {
      if (!_isEmpty(this.dealers)) {
        return;
      }

      this.$store.commit('users/SET_DEALER_LOADING', true);    
      this.$http
      .get('specific-dealers', {
        params: {
          filter_access: 1
        }
      })
      .then(({ data }) => {
          this.$store.commit('users/SET_DEALER_LOADING', false);    
          this.$store.dispatch('users/setDealersFilterState', data);
        })
    },
    getPositions() {
      if (!_isEmpty(this.positions)) {
        return;
      }

      this.$store.commit('users/SET_POSITION_LOADING', true);    
      this.$http
      .get('get-all-positions')
      .then(({ data }) => {
          this.$store.commit('users/SET_POSITION_LOADING', false);    
          this.$store.dispatch('users/setPositionsFilterState', data);
        })
    },
    findRole(role){
      return _some(this.$auth.user().roles, {
        name: role,
      });
    },
  }
}