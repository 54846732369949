<template>
    <div class="content">
      <div>
        <breadcrumbs :links="breadcrumbs" />

        <div class="content-wrapper">
          <h1 class="font-weight-medium">{{ $route.meta.title }}</h1>
    
          <div class="mt-5 content-card">
              <billing-portal-table/>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Breadcrumbs from "@/components/Breadcrumbs";
  import BillingPortalTable from "@/views/billing_portal/BillingPortalTable";
  import { mapState } from "vuex";
  
  export default {
    name: "IndexBillingPortal",
    components: {
      Breadcrumbs,
      BillingPortalTable
    },
    data() {
      return {
        adminBillingPortal: false,
        breadcrumbs: {
          0: {
            title: "Home",
            link: "home",
          },
          1: {
            title: this.$route.meta.title,
            link: this.$route.meta.name,
          },
        },
      };
    },
    computed: {
      ...mapState("user", [
          "is_super_admin", 
      ]),
    },
    watch: {
    },
    created() {
    },
    methods: {},
  };
  </script>
  