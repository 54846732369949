<template>
  <div class="aside-bar-footer ma-5">
      <a class="subtitle-2" :href="'tel:+' + admin.contact" > 
        <v-icon small>
          mdi-phone
        </v-icon> 
        {{ admin.contact_format }}
      </a> <br>
      <a class="subtitle-2" :href="'mailto:' + admin.email" target="_blank">
        <v-icon  small>
         mdi-email-outline
        </v-icon> 
        {{ admin.email }}
      </a>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
name: 'AsideBarFooter',
computed: {
  ...mapState("layout", [
    "admin",
  ]),
},
data() {
  return {
  }
},
created() {
},
methods: {

}
};
</script>