<template>
    <v-app-bar
        app
        fixed
        flat
        height="55"
        color="white"
        class="z-top" >
        
        <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
            class="ham-btn hidden-md-and-up"
        />

        
       
        <v-spacer/>

        <v-menu
            transition="slide-y-transition"
            bottom 
            offset-y
            max-width='350'>
            <template v-slot:activator="{ on, attrs }">
                <div>
                    <v-flex class="d-flex justify-end align-center">
                        <div class="navbar-name text-center">
                            <p> 
                                <span class="caption">
                                    Welcome,
                                </span>
                                <span class="subtitle-1 text-center font-weight-black">
                                    {{user.name}}
                                </span>
                            </p>
                        </div>
                        <v-btn
                            class="transparent"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            fab 
                            small>
                                <v-avatar color="primary" size="35">
                                    <img :src="user.cover_photo" alt="Webinarinc Profile Image">
                                </v-avatar>
                        </v-btn>
                    </v-flex>
                </div>
            </template>

            <v-list class="pa-5">
                <v-flex class="d-flex justify-center mb-5">
                    <v-avatar color="primary">
                        <img :src="user.cover_photo ? user.cover_photo : companyLogo" alt="Webinarinc Profile Image">
                    </v-avatar>
                        <p class="subtitle-1  ma-3 d-flex flex-column">
                            <span class="font-weight-black">{{ user.name }}</span>
                            <span class="caption">{{ $formatProductType(accountLabel) }}</span>
                        </p>
                </v-flex>

                <v-divider/>

                <v-list-item-group color="primary">
                    <router-link :to="{name: 'profile'}">
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title >Profile</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>

                    <v-list-item @click.prevent="logout()">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import AppMenu from '@/components/AppMenu.vue';
import logoImport from '@/assets/images/revdojo_logo.png'

export default {
    data() {
        return {
            logo: logoImport,
        };
    },
    props: ['value'],
    computed: {
        ...mapState("layout", ["companyLogo","companyLogoWidth"]),
        user() {
            return this.$store.state.user.current_user
        },
        drawer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        accountLabel() {
            return this.user.company.is_single_user ? 'SINGLE_USER' : 'ENTERPRISE';
        },
    },
    methods: {
        logout() {
            localStorage.removeItem('nextUrl')
            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
            window.location.href = window.location.origin+'/okta/logout';
        }
    },
}
</script>


<style>
.z-top {
    z-index: 9 !important;
}
</style>