import {
    some as _some,
} from 'lodash';
const data = {
    namespaced: true,
    state: {
        currentProduct: [],
        images: [],
    },
    mutations: {
        SET_CURRENT_PRODUCT: (state, payload) => {
            state.currentProduct = payload
        },
        SET_PRODUCT_IMAGES: (state, payload) => {
            state.images = payload
        },
    },
}

export default data;
