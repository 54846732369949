<template>
    <div class="d-flex justify-center manage-product-container flex-column align-center">
        <product-switch v-if="showSwitch" v-model="switchState"></product-switch>
        <div class="manage-product" v-if="products.length > 0">
            <product-card
                @reload="getProduct()"
                v-for="(product, index) in filteredProduct" :key='index'
                :product="product"
            ></product-card>
        </div>
        <div v-if="products.length == 0 && !loading" class="not-found">
            <p>No Result Found!</p>
        </div>
        <v-progress-circular
            v-if="loading"
            class="mt-16"
            indeterminate
            size="30"
            color="primaryMain"
        ></v-progress-circular>
    </div>
</template>
    
<script>
import ProductCard from "./ProductCard.vue";
import ProductSwitch from "@/components/manage_products/ProductSwitch.vue";
import { filter as _filter } from "lodash-es";

import { mapState } from "vuex";
export default {
    name: 'IndexManageProduct',
    components: {
        ProductCard,
        ProductSwitch
    },
    data () {
        return {
            products: [],
            loading: false,
            switchState: false,
        }
    },
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
        ...mapState("billing", [
            "loadBillingPortal", 
            'hasBillingAccess',
            'reloadManageProduct'
        ]),
        isSingleUser() {
            return this.user.company.is_single_user 
        },
        showSwitch() {
            if (!this.isSingleUser) {
                return false
            }
            return this.products.length != 0 && !this.loading
        },
        filteredProduct() {
            if (!this.isSingleUser) {
                return this.products
            }

            let type = 'SINGLE_USER'
            if (this.switchState) {
                type = 'ENTERPRISE'
            }
            return _filter(this.products, {
                type: type,
            });
        },
    },
    watch: {
        hasBillingAccess() {
            if (!this.loadingBillinPortal && !this.hasBillingAccess) {
                this.$errorNotif("You dont have access to Manage Product, Please contact your Company Admin with billing access", 10000)
                this.$router.push({ name: 'home'});
                this.$router.replace({'query': null});
            }
        },
        reloadManageProduct(val) {
            if(val) {
                this.getProduct()
                this.$store.commit('billing/SET_RELOAD', false);            
            }
        },
    },
    created() {
        this.getProduct()
    },
    methods: {
        getProduct() {
            this.products = []
            this.loading = true
            this.$http
            .get('billing/all-products', {
                params: {
                    user_id: this.user.id
                }
            })
            .then((result) => {
                this.products = result.data
                this.loading = false
            })
        }
    }
};
</script>
    