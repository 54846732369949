<template>
  <div>

    <v-alert v-if="notValid" type="warning" text=""
      > Email generated link is invalid or
      expired. Please contact your support. </v-alert>

    <v-form ref="form" @submit.prevent="signUp()" method="POST">
      <v-card-text class="login-card__field">
        <v-text-field v-model="form.name" outlined label="Name" :rules="errors.name" autofocus :disabled="notValid">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Name
          </template>
        </v-text-field>
        <v-text-field v-model="form.email" outlined label="Email" :rules="errors.email" :disabled="true">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Email
          </template>
        </v-text-field>

        <v-text-field :value="inviteUser.company.name" outlined label="Company" :disabled="true">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Company
          </template>
        </v-text-field>

        <v-text-field v-if="inviteUser.specific_dealer" :value="inviteUser.specific_dealer.name" outlined
          label="Specific Dealer" :disabled="true">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Specific Dealer
          </template>
        </v-text-field>

        <vue-phone-number-input v-model="phone_number" class="mb-3" no-example :only-countries="['US', 'PH']"
          color="#000000" valid-color="#004df9" @update="formatPhoneNo($event)" :disabled="notValid" />
        <p class="ma-3 mb-2 mt-5 caption red--text">
          <span v-if="isPhoneNoError || errors.phone_number">Phone Number is Invalid</span>
          <span v-else class="white--text">Phone Number</span>
        </p>

        <v-text-field v-model="form.password" :rules="errors.password" prepend-inner-icon="mdi-lock-outline"
          :disabled="notValid" :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="password_show ? 'text' : 'password'" label="Password" outlined autofocus
          @click:append="password_show = !password_show">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Password
          </template>
        </v-text-field>

        <v-text-field v-model="form.confirm_password" :rules="errors.confirm_password" prepend-inner-icon="mdi-lock-outline"
          :disabled="notValid" :append-icon="confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="confirm_password_show ? 'text' : 'password'" label="Confirm Password" outlined autofocus
          @click:append="confirm_password_show = !confirm_password_show">
          <template #label>
            <span class="red--text"><strong>* </strong></span>Confirm Password
          </template>
        </v-text-field>

        <v-checkbox v-model="form.terms" class="mt-5" color="primary" hide-details autofocus :disabled="notValid">
          <template v-slot:label>
            <div>
              I agree to
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a target="_blank" href="https://revdojo.com/terms-and-conditions/" @click.stop v-on="on">
                    Terms and Condition
                  </a>
                </template>
                Click to Read!
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
        <p class="caption red--text" v-if="isTermError">Please indicate that you have read and agree to the Terms and
          Conditions</p>
      </v-card-text>
      <v-card-actions class="login-card__action pa-0 mt-8">
        <v-btn
          class="ml-3 mb-2"
            text
            @click="goBack()"
            :loading="loading"
          >
            Go Back to login
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            class="primaryMainText--text mb-2 mr-3" color="primaryMain" type="submit" :loading="loading"
          :disabled="notValid" elevation="2"
          >
            Register
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: 'SignupContent',
  components: {
  },
  props: {
    inviteUser: {}
  },
  data() {
    return {
      form: {
        name: '',
        email: this.inviteUser.email,
        company_id: this.inviteUser.company_id,
        status: 'ACTIVE',
        specific_dealer_id: this.inviteUser.specific_dealer_id,
        role_id: this.inviteUser.role_id,
        password: '',
        confirm_password: '',
        withPassword: true,
        phone_number: '',
        phone_code: '',
        phone_country: '',
        terms: false,
        isRegister: true,
        is_password_changed: true,
      },
      password_show: '',
      confirm_password_show: '',
      isTermError: false,
      isPhoneNoError: false,
      errors: [],
      loading: false,
      phone_number: '',
    }
  },
  computed: {
    ...mapState("layout", ["type", "primaryColor"]),
    notValid() {
      return this.inviteUser.token === undefined;
    }
  },
  watch: {
    inviteUser() {
      this.form.email = this.inviteUser.email
      this.form.company_id = this.inviteUser.company_id
      this.form.specific_dealer_id = this.inviteUser.specific_dealer_id
      this.form.role_id = this.inviteUser.role_id
    },
  },
  methods: {
    signUp() {
      if (this.isPhoneNoError) {
        return false
      }

      if (this.form) {
        this.loading = true
      }

      this.$http
        .post('auth/register', this.form)
        .then((data) => {
          this.loading = false
          this.syncInvite();
          this.$successNotif("Registration successful!")
          this.$refs.form.reset()
          this.phoneNumberReset()
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors
            this.isTermError = this.errors.hasOwnProperty('terms')
            this.isPhoneNoError = (this.form.phone_number == null)

          } else if (error.response.status == 403 && error.response.data.soft_deleted) {
            this.$store.commit('login/SET_LOGIN_ERROR', error.response.data.msg)
          }
          else {
            this.$errorNotif("Something went wrong")
          }
          this.loading = false
        })
    },
    syncInvite() {
      this.$http
        .post(`/revdojo-api/v1/invite-users/register`, this.inviteUser)
        .then((result) => {
          this.$router.push({ name: 'Login' });
        })
    },
    formatPhoneNo(event) {
      this.isPhoneNoError = false

      if (event.phoneNumber == undefined) {
        return this.isPhoneNoError;
      }

      if (event.phoneNumber && !event.isValid) {
        this.isPhoneNoError = true
      }

      this.form.phone_country = event.countryCode
      this.form.phone_code = '+' + event.countryCallingCode
      this.form.phone_number = event.nationalNumber
    },
    phoneNumberReset() {
      this.form.phone_country = ''
      this.form.phone_number = ''
      this.form.phone_code = ''
      this.phone_number = ''
    },
    goBack() {
      this.$router.push({ name: 'Login'});
    }
  }
};
</script>