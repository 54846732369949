<template>
  <div>
    <div class="content-card__header">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            @click="resetFilter()"
            v-bind="attrs"
            fab
            dark
            x-small
          >
            <v-icon>mdi-filter-remove</v-icon>
          </v-btn>
        </template>
        <span>Reset Filter</span>
      </v-tooltip>
    </div>

    <v-layout class="d-flex mb-3"> </v-layout>
    <v-layout row class="mt-1">
      <v-flex xs12 md12 lg12 class="px-3">
        <v-text-field
          id="filter-button-remove"
          v-model="search"
          outlined
          dense
          label="Search"
          clearable
        ></v-text-field>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductsFilter",
  components: {},
  data() {
    return {
      timeout: null,
      loading: false,
      search: "",
      filters: {
        search: null,
        role: null,
      },
      typeItems: [
        {
          name: "Single User",
          value: "SINGLE_USER",
        },
        {
          name: "Enterprise",
          value: "ENTERPRISE",
        },
        {
          name: "Adds-On",
          value: "ADDS_ON",
        },
        {
          name: "Lead Form",
          value: "CONTACT_US",
        },
      ],
      roles: [],
      loadRole: false,
      loadCompany: false,
    };
  },
  computed: {
    ...mapState("user", ["is_super_admin", "is_account_manager"]),
  },
  watch: {
    filters: {
      handler: function (after, before) {
        this.$emit("filter", this.filters);
      },
      deep: true,
    },
    search() {
      this.renderSearch();
    },
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    renderSearch() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.filters.search = this.search;
      }, 1000);
    },
    resetFilter() {
      this.filters.search = null;
      this.filters.role = null;
      this.search = null;
    },
    fetchRoles() {
      this.loadRole = true;
      this.$http.get("roles").then((result) => {
        this.roles = result.data;
        this.loadRole = false;
      });
    },
  },
};
</script>
