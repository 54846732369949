var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.per_page,"footer-props":{ 
            disablePagination: _vm.paginationDisable,
            'items-per-page-options': [5, 10, 20, 50 ]
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(item.payment_method && item.collection_method == 'send_invoice')?{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","color":"info","icon":""},on:{"click":function($event){return _vm.viewPaymentDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-note")])],1)]}}:null],null,true)},[_c('span',[_vm._v("View Payment Details")])]),(item.collection_method == 'send_invoice' && item.status == 'open')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.manualPay(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cash")])],1)]}}],null,true)},[_c('span',[_vm._v("Manual Pay")])]):_vm._e(),(item.collection_method == 'send_invoice' && item.status == 'open')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.send(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("Send Invoice to Billing User")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","color":"info","icon":""},on:{"click":function($event){return _vm.download(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download PDF Invoice")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","color":"info","icon":""},on:{"click":function($event){return _vm.viewInvoice(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Invoice")])])],1)]}}])})],1),_c('manual-pay-modal',{attrs:{"item":_vm.currentInvoice}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }