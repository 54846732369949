var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"BillingPortalTable","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.per_page,"footer-props":{
            disablePagination: _vm.paginationDisable,
            'items-per-page-options': [5, 10, 20, 50],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.amount)+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status == 'paid')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(item.status)+" ")],1):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]}},{key:"item.pdf_link",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":item.pdf_link}},[_c('v-icon',[_vm._v("mdi-cloud-arrow-down-outline")])],1)]}},{key:"item.invoice_link",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":item.invoice_link,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-eye-outline")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }