import Vue from 'vue';
const data = {
    namespaced: true,
    state: {
        layoutloading: false,
        primaryColor: '',
        secondaryColor: '',
        defaultImage: '',
        companyLogo: '',
        companyLogoBgcolor: '',
        companyIcon: '',
        currentCompanyIcon: '',
        currentName: '',
        currentType: '',
        type: '',
        name: 'Central',
        companyLogoWidth: '150',
        notFound: '',
        favicon: '',
        tabTitle: '',
        passwordAutogenerate: false,
        admin: {},
        labels: {}
    },
    mutations: {
        SET_DEFAULT_IMAGE: (state, payload) => {
            state.defaultImage = payload
        },
        SET_ADMIN: (state, payload) => {
            state.admin = payload
        },
        SET_LAYOUT_LOADING: (state, payload) => {
            state.layoutloading = payload
        },
        SET_COLOR: (state, payload) => {
            state.primaryColor = payload.primaryColor
            state.secondaryColor = payload.secondaryColor
        },
        SET_ICON: (state, payload) => {
            state.companyIcon = payload.companyIcon
            state.favicon = payload.favicon
            state.tabTitle = payload.tabTitle
        },
        SET_LOGO: (state, payload) => {
            state.companyLogo = payload
        },
        SET_LOGO_WIDTH: (state, payload) => {
            state.companyLogoWidth = payload
        },
        SET_LOGO_BGCOLOR: (state, payload) => {
            state.companyLogoBgcolor = payload
        },
        SET_TYPE: (state, payload) => {
            state.type = payload
        },
        SET_NAME: (state, payload) => {
            state.name = payload
        },
        SET_NOT_FOUND: (state, payload) => {
            state.notFound = payload
        },
        SET_CURRENT_ICON: (state, payload) => {
            state.currentCompanyIcon = payload
        },
        SET_CURRENT_NAME: (state, payload) => {
            state.currentName = payload
        },
        SET_CURRENT_TYPE: (state, payload) => {
            state.currentType = payload
        },
        SET_PASSWORD_AUTOGENERATE: (state, payload) => {
            state.passwordAutogenerate = payload
        },
        SET_LABELS: (state, payload) => {
            state.labels = payload
        },
    },
}

export default data;
