var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tier-prices"},[(_vm.error)?_c('v-alert',{staticClass:"caption text-xs mt-2",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.error[0])+" ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('p',{staticClass:"subtitle-2 mt-2 mb-4"},[_vm._v("Tiers")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.addTier()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus-circle-outline ")])],1)]}}])},[_c('span',[_vm._v("Add Tier")])])],1)]),_vm._l((_vm.tiers),function(tier,index){return _c('div',{key:index},[_c('label',{staticClass:"body-2"},[_vm._v(_vm._s(tier.name))]),_c('div',{staticClass:"d-flex mt-2"},[(!tier.hide_user_field)?_c('v-text-field',{attrs:{"id":"tier-prices-textfield-units","label":"No. of users","placeholder":"No. of users","outlined":"","dense":"","disabled":tier.type == 'last_tier',"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("No. of users ")]},proxy:true}],null,true),model:{value:(tier.no_users),callback:function ($$v) {_vm.$set(tier, "no_users", $$v)},expression:"tier.no_users"}}):_vm._e(),(tier.is_free == false)?_c('v-text-field',{staticClass:"price-item ml-1",attrs:{"id":"tier-prices-textfield-1st","label":"For the first units","dense":"","outlined":"","prefix":"$","type":"number","step":"any"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Per user price ")]},proxy:true}],null,true),model:{value:(tier.price),callback:function ($$v) {_vm.$set(tier, "price", $$v)},expression:"tier.price"}}):_vm._e(),(tier.type == 'first_tier')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.firstTierFree()}}},'v-btn',attrs,false),on),[(!tier.is_free)?_c('v-icon',[_vm._v(" mdi-currency-usd-off ")]):_c('v-icon',[_vm._v(" mdi-currency-usd ")])],1)]}}],null,true)},[(!tier.is_free)?_c('span',[_vm._v("Make the first tier FREE?")]):_c('span',[_vm._v("Add price to the first tier")])]):_vm._e(),(tier.type == 'next_tier')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.removeTier(tier, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])]):_vm._e()],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }