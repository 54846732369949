<template>
    <div class="adds-on-section">
        <div class="my-5" v-if="(!loading && addsOnList.length > 0)">
            <v-divider v-if="scroll"></v-divider>
            <h3 class="my-3" v-if="scroll">Add ons</h3>

            <div :class="scroll ? 'adds-on-container' : ''">
                <div v-for="(item, index) in addsOnList" :key="index">
                    <p class="subtitle-2 d-flex justify-space-between mb-0">
                        <span class="d-flex align-center">
                            <span>
                                <v-checkbox
                                    v-model="selectedAddsOn"
                                    class="mt-0"
                                    x-small
                                    hide-details
                                    :value="item.id"
                                ></v-checkbox>
                            </span>
                            <span>
                                {{ item.name }}
                            </span>
                        </span>
                        <span>
                            {{ '+ $' + renderPrice(item) }}
                        </span>
                    </p>
                    <p class="caption pl-8">
                        {{ item.description }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="loading" class="d-flex justify-center my-5">
            <v-progress-circular
            indeterminate
            size="20"
            color="primaryMain"
            ></v-progress-circular>
        </div>
    </div>
  </template>
<script>
import {
    find as _find,
    map as _map,
} from 'lodash';
export default {
name: 'AddsOnSection',
data() {
    return {
        addsOnList: [],
        loading: false,
        selectedAddsOn: [],
    }
},
computed: {
    selectedTotal() {
        let total = 0
        _map(this.addsOnList, (addson) => {
            if(this.selectedAddsOn.includes(addson.id)) {
                total = total + this.renderPrice(addson)
            }
        });

        return total
    }
},
props: [
    'product',
    'scroll'
],
watch:{
    selectedAddsOn() {
        this.$emit('addsonTotal', {
            total: this.selectedTotal,
            addsonId: this.selectedAddsOn
        })
    }
},
mounted() {
    this.fetchAddsOn()
},
methods: {
    fetchAddsOn() {
        this.loading = true
        this.$http
        .get('billing/adds-on/'+ this.product.id)
        .then((result) => {
            this.addsOnList = result.data
            this.loading = false
        })
    },
    renderPrice(addson) {
        return addson.pricings[0].unit_price / 100
    },
}
};
</script>