<template>
  <modal name="restrict-create-modal" width="500" height="auto" :clickToClose="false" :adaptive="true">
    <div class="pa-5 d-flex justify-space-between align-center">
      <h2 class="title">Number of Users</h2>
    </div>
    <v-divider />

    <div class="pa-5">
      Sorry, but we can’t create a new user account at the moment due to the user license imposed by your subscription
      plan. Please consider upgrading to Tier {{ next_tier.order }} amounting to ${{ next_tier.unit_amount / 100 }} 
      <span v-if="next_tier.up_to">with a max user of {{ next_tier.up_to }}</span> by clicking the confirm button.
    </div>
    <v-card-actions class="px-5 py-3">
      <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn class="primaryMainText--text" :loading="loading" color="primaryMain" type="submit"
        @click="submit()">Confirm</v-btn>
    </v-card-actions>
  </modal>
</template>
  
<script>
import { mapState } from "vuex";
export default {
  name: 'RestrictCreateModal',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState("user", [
      'restrict_create',
      'next_tier'
    ]),
  },
  methods: {
    cancel() {
      this.$modal.hide('restrict-create-modal')
      this.$router.push({ name: this.$route.meta.type });
    },
    submit() {

      this.$http
        .post(`billing/upgrade-tier`, {
          tierId: this.next_tier.id
        })
        .then((data) => {
          const targetRouteName = this.$route.meta.type + ".create";
          if (this.$route.name !== targetRouteName) {
            this.$router.push({ name: targetRouteName });
          }
          else {
            this.$emit('confirm')
            this.$modal.hide('restrict-create-modal')
          }
        })
        .catch((error) => {
          this.$errorNotif("Something went wrong");
        });

    }
  }
};
</script>
  