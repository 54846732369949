<template>
  <div class="content">
    <breadcrumbs :links="breadcrumbs"/>


    <div class="content-wrapper">
      <h1 class="font-weight-medium">{{ dealersTitle }}</h1>

      <div class="mt-5 content-card">
        <specific-dealers-table></specific-dealers-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import SpecificDealersTable from '@/views/specific_dealers/SpecificDealersTable';
import { mapState } from "vuex";

export default {
  name: 'SpecificDealers',
  components: {
    Breadcrumbs,
    SpecificDealersTable
  },
  data () {
    return {
       
    }
  },
  computed: {
    ...mapState("layout", ["labels"]),
    breadcrumbs() {
      return {
        0: {
          title: 'Home',
          link: 'home',
        },
        1: {
          title: this.dealersTitle,
          link: this.$route.meta.name,
        }
      }
    },
    dealersTitle() {
      return this.labels !== undefined && this.labels.dealers !== undefined ? this.labels.dealers.charAt(0).toUpperCase() + this.labels.dealers.slice(1) : '';
    }
  },
  created() {
  },
  methods: {
  }
};
</script>
