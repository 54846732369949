<template>
  <modal
    name="add-subscription-modal"
    width="600"
    height="auto"
    draggable
    :clickToClose="false"
    :adaptive="true"
  >
    <div class="pa-5 d-flex justify-space-between align-center">
      <h2 class="title">Add Manual Subscription</h2>
    </div>
    <v-divider />
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      bottom
      height="5"
    ></v-progress-linear>

    <v-alert
      v-if="showError"
      dense
      outlined
      type="error"
      class="caption text-xs ma-5"
    >
      {{ error }}
    </v-alert>

    <form @submit.prevent="submit()" method="PUT">
      <v-card class="pa-4 d-flex flex-column" min-height="400">
        <v-container class="my-5 content-container">
          <v-select
            id="addSubscription-select-type"
            v-model="form.type"
            :items="typeItems"
            label="Type"
            item-text="name"
            item-value="value"
            outlined
            dense
            :disabled="loading"
            :rules="[(v) => !!v || 'Type is required']"
            @change="changeType()"
            required
            autofocus
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Type
            </template>
          </v-select>
          <v-autocomplete
            v-model="form.product"
            :items="productList"
            item-text="name"
            autocomplete="nope"
            outlined
            dense
            :disabled="loading"
            label="Products"
            return-object
            :rules="errors.product_id"
            autofocus
            :loading="productLoading"
            @change="clearError"
            required
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Product
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-model="form.price"
            :items="priceList"
            :item-text="(item) => formatPrice(item)"
            autocomplete="nope"
            outlined
            dense
            :disabled="loading && product.length == 0"
            label="Prices"
            return-object
            :rules="errors.price"
            required
            autofocus
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Prices
            </template>
          </v-autocomplete>

          <v-autocomplete
            v-if="showBillingUser"
            v-model="form.user"
            :items="billingUsersList"
            :item-text="getBillingUsersItem"
            item-value="id"
            autocomplete="nope"
            outlined
            dense
            :disabled="loading"
            label="Products"
            :rules="errors.user"
            :loading="usersLoading"
            required
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Select Billing
              Admin
            </template>
          </v-autocomplete>
        </v-container>
        <v-spacer />
        <v-card-actions>
          <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
          <v-spacer />
          <v-btn
            color="primaryMain"
            dark
            :loading="loading"
            class="mt-5 primaryMainText--text"
            type="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </modal>
</template>
  <script>
import { filter as _filter } from "lodash-es";

export default {
  computed: {
    user() {
      return this.$store.state.user.current_user;
    },
    product() {
      return this.form.product;
    },
  },
  data() {
    return {
      showError: false,
      error: "",
      loading: false,
      productLoading: false,
      usersLoading: false,
      showBillingUser: false,
      errors: [],
      form: {
        type: "",
        product: [],
        price: [],
        company_id: this.$route.params.id,
        user: "",
      },
      typeItems: [
        {
          name: "Enterprise",
          value: "ENTERPRISE",
        },
      ],
      products: [],
      productList: [],
      priceList: [],
      billingUsersList: [],
    };
  },
  watch: {
    products() {
      this.changeType();
    },
    product() {
      this.priceList = this.product.pricings;
    },
  },
  created() {
    this.fetchProduct();
    this.init();
  },
  methods: {
    init() {
      this.form.type = "ENTERPRISE";
    },

    getBillingUsersItem(item) {
      return `${item.name} - ${item.email}`;
    },

    fetchProduct() {
      this.loading = true;
      this.$http
        .get("billing/all-products")
        .then((data) => {
          this.products = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    changeType() {
      const items = _filter(this.products, {
        type: this.form.type,
      });
      this.productList = items;
      this.clearError();
    },
    formatPrice(item) {
      return item.interval + " - $" + item.unit_price / 100;
    },
    cancel() {
      this.clearError();
      this.form = {
        type: "",
        product: [],
        price: [],
        company_id: this.$route.params.id,
      };
      this.$modal.hide("add-subscription-modal");
    },
    submit() {
      this.clearError();
      this.loading = true;
      this.$http
        .post("billing/manual-subscribe", this.form)
        .then((data) => {
          this.loading = false;
          this.cancel();
          this.$emit("reload");
          this.$store.commit('billing/SET_RELOAD_INVOICE', true);            
          this.$successNotif("Successfully created a new subscription");
        })
        .catch((error) => {
          this.loading = false;
          if (
            error.response.status == 403 &&
            error.response.data.type == "no-billing-default"
          ) {
            this.showBillingUser = true;
            this.billingUsersList = error.response.data.users;
            this.showError = true;
            this.error = error.response.data.msg;
          } else if (
            error.response.status == 403
          ) {
            this.showError = true;
            this.error = error.response.data.msg;
          } else {
            this.$errorNotif("Something went wrong");
          }
          setTimeout(() => {
            state.showError = false;
          }, 10000);
        });
    },
    clearError() {
      this.showError = false;
      this.error = "";
    },
  },
};
</script>
  