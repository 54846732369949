<template>
  <div class="content product">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">
        {{ isAddsOnPage ? 'Add ons' : $route.meta.title }}
        <span v-if="isView" class="title font-weight-thin">View</span>
        <span v-else class="title font-weight-thin">Edit</span>
      </h1>

      <v-layout row class="mt-5">
        <v-flex xs12 md5 lg4 class="px-4 content-image">
          <v-card class="pa-2 mb-5" min-height="400">
            <v-tabs grow v-model="tab">
              <v-tab>Information</v-tab>
              <v-tab v-if="!isUsageBased ">Images</v-tab>
            </v-tabs>

            <v-divider />

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form
                  :readonly="isView"
                  @submit.prevent="submit()"
                  method="PUT"
                >
                  <v-container class="my-5 content-container">
                    
                    <tier-prices :error="errors.tiers" v-if="isUsageBased" @tiers="handleTiers" :product="product"></tier-prices>

                    <v-select
                      v-if="!isUsageBased"
                      id="editProductPage-select-type"
                      v-model="product.type"
                      :items="typeItems"
                      :disabled="(isRemoveBtnDisabled || isAddsOnPage)"
                      label="Type"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      @change="checkType()"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :rules="errors.type"
                    ></v-select>

                    <v-text-field
                      v-if="!isUsageBased"
                      id="editProductPage-textfield-name"
                      label="Name"
                      placeholder="Name"
                      outlined
                      required
                      dense
                      v-model="product.name"
                      :rules="errors.name"
                    />
                    <v-textarea
                      v-if="!isUsageBased"
                      id="editProductPage-textarea-description"
                      label="Description"
                      placeholder="Description"
                      outlined
                      counter="250"
                      maxlength="250"
                      no-resize
                      dense
                      rows="3"
                      v-model="product.description"
                      :rules="errors.description"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span
                        >Description
                      </template>
                    </v-textarea>

                    <v-select
                      v-if="!isContactUs && !isProductFree && !isUsageBased"
                      id="createProductPage-select-billingType"
                      :items="billingTypeItems"
                      label="Type"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      :disabled="isRemoveBtnDisabled"
                      required
                      :menu-props="{ bottom: true, offsetY: true }"
                      v-model="product.billing_type"
                      :rules="errors.billing_type"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Billing
                        Type
                      </template>
                    </v-select>

                    <v-select
                      id="editProductPage-select-client"
                      v-if="isEnterprise  && !emptyAppList && !isUsageBased"
                      :disabled="isRemoveBtnDisabled"
                      v-model="product.client_id"
                      label="Apps"
                      :items="appList"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      required
                      clearable
                      autofocus
                      :menu-props="{ bottom: true, offsetY: true }"
                      :rules="errors.client_id"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Apps
                      </template>
                       <template slot="no-data" v-if="emptyAppList">
                        <div>
                          <span class="ma-5 caption font-weight-bold">No data available</span> <br>
                          <span class="ma-5 caption">All Apps has been created an active product</span>
                        </div>
                      </template>
                    </v-select>
                    <div v-if="isEnterprise && emptyAppList" class="mb-5">
                      <span class="caption font-weight-bold error--text">No Apps available</span> <br>
                      <span class="caption error--text">All Apps has been created an active product</span>
                    </div>

                    <v-select
                      id="editProductPage-select-course"
                      v-if="isSingleUser || isProductFree"
                      :disabled="isRemoveBtnDisabled"
                      :loading="courseListLoading"
                      v-model="product.course_id"
                      label="Course"
                      :items="courseList"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      required
                      clearable
                      autofocus
                      :menu-props="{ bottom: true, offsetY: true }"
                      :rules="errors.course_id"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Course
                      </template>
                    </v-select>

                    <v-checkbox
                    class="mr-10"
                    dense
                    v-model="product.active_status"
                    label="Active"
                    color="primary"/>

                    <div class="d-flex align-center justify-space-between" v-if="isEnterprise && !isUsageBased">
                      <div>
                        <v-checkbox
                            :disabled="disableApplyUserLimit || hasUserLicenseActive"
                            v-model="product.has_user_license"
                            class="mt-0  mb-5"
                            label="Apply User Limit"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                        <p class="caption" v-if="disableApplyUserLimit">
                          User Limit's Tier details are not set. Set the Tier details
                        </p>
                      </div>
                      <v-btn
                        @click="showTierDetails"
                        color="primaryMain"
                        dark
                        small
                        class="mb-5 primaryMainText--text"
                      >
                      {{ disableApplyUserLimit ? 'Set' : 'Show' }} Tier Details 
                      </v-btn>
                    </div>


                    <v-autocomplete
                        v-if="isAddsOn"
                        :loading="productListLoading"
                        v-model="product.product_id"
                        :items="productList"
                        outlined
                        label="Product"
                        dense
                        item-text="name"
                        item-value="id"
                        bottom
                        autocomplete="off"
                    >
                    </v-autocomplete>

                    <v-autocomplete
                      id="createProductPage-select-product-action"
                      v-if="isAddsOn"
                      :loading="productActionListLoading"
                      v-model="product.system_actions"
                      label="Product Action"
                      :items="productActionList"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      clearable
                      autofocus
                      multiple
                      :menu-props="{ bottom: true, offsetY: true }"
                      :rules="errors.system_actions"
                    >
                    </v-autocomplete>
                    
                    <div v-if="!isContactUs && !isProductFree && !isUsageBased">
                      <v-divider></v-divider>
                      <p class="subtitle-2 mt-5 mb-4">Price Information</p>
                      <div class="">
                        <v-text-field
                          v-if="product.billing_type == 'RECURRING'"
                          id="editProductPage-textfield-month"
                          class="price-item"
                          label="Monthly (Base Price)"
                          dense
                          outlined
                          prefix="$"
                          :required="getBillingType == 'RECURRING'"
                          type="number"
                          autofocus
                          v-model="product.month"
                          :rules="errors.month"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span
                            >Monthly (Base Price)
                          </template>
                        </v-text-field>
  
                        <v-text-field
                          v-if="product.billing_type == 'RECURRING'"
                          id="editProductPage-textfield-year"
                          class="price-item"
                          label="Yearly (Optional)"
                          dense
                          outlined
                          prefix="$"
                          clearable
                          autofocus
                          v-model="product.year"
                          type="number"
                          :rules="errors.year"
                        >
                        </v-text-field>
                        <v-text-field
                          v-if="product.billing_type == 'ONE_TIME'"
                          id="createProductPage-textfield-month"
                          class="price-item mr-1"
                          label="One Time Payment (Base Price)"
                          dense
                          outlined
                          prefix="$"
                          type="number"
                          :required="getBillingType == 'ONE_TIME'"
                          v-model="product.one_time"
                           :rules="errors.one_time"
                        >
                          <template #label>
                            <span class="red--text"><strong>* </strong></span>One Time
                            Payment (Base Price)
                          </template>
                        </v-text-field>
                      </div>
                    </div>

                  </v-container>
                  <v-spacer />
                  <v-card-actions v-if="!isView">
                    <v-btn
                      id="editProductPage-button-remove"
                      color="error"
                      dark
                      @click="remove()"
                      class="mt-5"
                      :loading="loading"
                      text
                      :disabled="isRemoveBtnDisabled"
                    >
                      Remove
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      id="editProductPage-button-submit"
                      color="primaryMain"
                      dark
                      :loading="loading"
                      class="mt-5 primaryMainText--text"
                      type="submit"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <product-images />
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-flex>
        <v-flex xs12 md7 lg8 class="px-4" v-if="!isContactUs">
          <index-subscribed-user></index-subscribed-user>
        </v-flex>
      </v-layout>
      <user-limit-modal :load="tierkey" @reloadUsageProduct="fetchUsage()"/>
    </div>
  </div>
</template>

<script>
import { 
  find as _find
} from "lodash";
import Vue from "vue";
import { mapState } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import IndexSubscribedUser from "@/views/subscribed_users/IndexSubscribedUser";
import ProductImages from "@/views/products/ProductImages";
import TierPrices from "@/views/products/TierPrices";
import UserLimitModal from '@/views/products/UserLimitModal';

Vue.use(require("vue-moment"));

export default {
  computed: {
    ...mapState("user", [
      "current_user",
      "is_super_admin",
      "is_account_manager",
    ]),
    ...mapState("apps", ["app_list"]),
    isView() {
      return this.$route.name == this.$route.meta.type + ".view";
    },
    isEnterprise() {
      return this.product.type == "ENTERPRISE";
    },
    isSingleUser() {
      return this.product.type == "SINGLE_USER";
    },
    isContactUs() {
      return this.product.type == "CONTACT_US";
    },
    isAddsOn() {
      return this.product.type == "ADDS_ON";
    },
    isProductFree() {
      return this.product.type == "FREE";
    },
    isUsageBased() {
      return this.$route.query.type == "usage-based";
    },
    getBillingType() {
      return this.product.billing_type;
    },
    emptyAppList() {
      return this.appList.length == 0 && !this.appListLoading 
    },
    isYearDisable() {
      return this.isRemoveBtnDisabled && this.initialYear > 0
    },
    selectedProductId() {
      return this.product.product_id
    },
    disableApplyUserLimit() {
      return this.productUsageList == '' || this.productUsageList.length == 0
    }    
  },
  data() {
    return {
      tierkey: 0,
      selectedProduct: {},
      isAddsOnPage: false,
      tab: null,
      loading: false,
      productList: [],
      productListLoading: false,
      productActionList: [],
      productActionListLoading: false,
      breadcrumbs: [
        {
          title: "Home",
          link: "home",
        },
        {
          title: this.$route.meta.title,
          link: this.$route.meta.type,
        },
        {
          title: "Edit",
          link: this.$route.meta.type + ".edit",
        },
      ],
      product: {billing_type:null, active_status: null},
      typeItems: [
        {
          name: "Single User",
          value: "SINGLE_USER",
        },
        {
          name: "Enterprise",
          value: "ENTERPRISE",
        },
        {
          name: "Add ons",
          value: "ADDS_ON",
        },
        {
          name: "Lead Form",
          value: "CONTACT_US",
        },
        {
          name: "Free",
          value: "FREE",
        },
      ],
      errors: [],
      billingTypeItems: [
        {
          name: "Recurring",
          value: "RECURRING",
        },
        {
          name: "One Time Payment",
          value: "ONE_TIME",
        },
      ],
      isRemoveBtnDisabled: true,
      appListLoading: false,
      appList: [],
      courseList: [],
      courseListLoading: false,
      initialYear: '',
      hasUserLicenseActive: false,
      productUsageList: [],
      productUsageListLoading: false,
    };
  },
  watch: {
    selectedProductId() {
      this.selectedProduct = _find(this.productList, {
        id: this.product.product_id,
      });
      if (this.selectedProduct) {
        this.renderAddsOnBreadcrumbs()
      }
    }
  },
  created() {
    this.fetchProduct();
    this.renderPage();
    this.fetchApps();
    this.fetchCourse();
    this.fetchProductList();
    this.fetchProductAction();
    this.fetchUsage();
  },
  methods: {
    fetchUsage() {
      this.productUsageListLoading = true
      this.$http
      .get(`/billing/get-usage-product`)
      .then((result) => {
        this.productUsageList = result.data
        this.productUsageListLoading = false
      })
      .catch((error) => {
          this.productUsageListLoading = false
          this.$errorNotif("Something went wrong!");
      });
    },
    renderAddsOnBreadcrumbs() {
      this.selectedProduct = _find(this.productList, {
        id: this.product.product_id,
      });
      if (this.product.product_id && this.breadcrumbs.length == 3) {
        this.breadcrumbs.splice(2, 0, {
          title: this.selectedProduct.name,
          link: this.$route.meta.type + '.edit',
          params: {
            id: this.product.product_id
          }
        });

        return
      }

      this.breadcrumbs[2] = {
        title: this.selectedProduct.name,
        link: this.$route.meta.type + '.edit',
        params: {
          id: this.product.product_id
        }
      }
    },
    fetchProductAction() {
      this.productActionListLoading = true
      this.$http
      .get(`/billing/product-action`)
      .then((result) => {
          this.productActionList = result.data
          this.productActionListLoading = false
        })
        .catch((error) => {
          this.productActionListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },

    fetchProductList() {
      this.productListLoading = true
      this.$http
      .get(`/billing/all-products`, {
          params: {
              type: 'ENTERPRISE'
          }
      })
      .then((result) => {
          this.productList = result.data
          this.productListLoading = false

          if (this.isAddsOnPage) {
            this.renderAddsOnBreadcrumbs()
          }
        })
        .catch((error) => {
          this.productListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },
    fetchApps() {
      let id = this.$route.params.id;
      this.appListLoading = true
      this.$http
      .get(`/billing/app-list/`+ id)
      .then((result) => {
          this.appList = result.data
          this.appListLoading = false
        })
        .catch((error) => {
          this.appListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },

    fetchCourse() {
      let id = this.$route.params.id;
      this.courseListLoading = true
      this.$http
      .get(`/lms/courses/`, {
        params: {
          product_id: id,
        }
      })
      .then((result) => {
          this.courseList = result.data
          this.courseListLoading = false
        })
        .catch((error) => {
          this.courseListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },

    checkType() {
      if (!this.isEnterprise) {
        this.product.client_id = null;
      }
    },
    renderPage() {
      if (this.isView) {
        this.breadcrumbs[2].title = "View";
        this.breadcrumbs[2].link = this.$route.meta.type + ".view";
      }
    },
    fetchProduct() {
      this.$store.commit("global/SET_BREADCRUMBS_LOADER", true);
      let id = this.$route.params.id;
      this.$http  
      .get(`billing/get-active-subscribed-users-count/` + id)
      .then((result) => {
        this.isRemoveBtnDisabled = result.data.data > 0; 
        this.hasUserLicenseActive = result.data.userLicenseActive > 0; 
      })
      .catch((error) => {
        this.loading = false;
        this.$errorNotif("Something went wrong");
      });

      this.$http
        .get("billing/product/" + id)
        .then((result) => {

          this.product = result.data;
          this.isAddsOnPage = this.product.type == 'ADDS_ON'
          this.initialYear = this.product.year       
          this.$store.commit("global/SET_BREADCRUMBS_LOADER", false);
          this.$store.commit("product/SET_CURRENT_PRODUCT", this.product);
          this.$store.commit("product/SET_PRODUCT_IMAGES", this.product.images);
        })
        .catch((error) => {
          if (error.response.status == 403) {
            this.$errorNotif(error.response.data.msg);
            this.$router.push({ name: this.$route.meta.type });
          }
          this.loading = false;
          this.$store.commit("global/SET_BREADCRUMBS_LOADER", false);
        });
    },
    submit() {
      this.loading = true;
      this.errors = [];

      this.$http
        .put(`/billing/product/${this.product.id}`,{ ...this.product, billing_type : this.product.billing_type})
        .then((result) => {
          this.fetchProduct();
          this.loading = false;
          this.$successNotif("Product information has been saved");
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
            this.$errorNotif("Invalid inputs, Please check the details");

            if (this.errors.tiers) {
              setTimeout(() => {
                  this.errors = []
              }, 5000);
            }
          }
          this.loading = false;
        });
    },
    remove(item) {
      let msg = {
        title: "Remove " + this.product.name + "?",
        confirmButtonText: "Yes, Remove!",
      };

      this.$questionNotif(msg).then((result) => {
        if (result) {
          this.loading = true;
          this.$http
            .delete(`/billing/product/${this.product.id}`)
            .then((data) => {
              this.loading = false;
              this.$successNotif("Successfully deleted product");
              this.$router.push({ name: this.$route.meta.type });
            });
        }
      });
    },
    handleTiers(tiers) {
      this.product.tiers = tiers
    },
    showTierDetails() {
      this.tierkey++
      this.$modal.show('user-limit-modal')
    },
  },
  components: {
    Breadcrumbs,
    IndexSubscribedUser,
    ProductImages,
    TierPrices,
    UserLimitModal
  },
};
</script>