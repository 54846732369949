<template>
    <v-card class="">
        <v-container>

            <v-card-title>
                Billing Information
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4" class="py-1">Name</v-col>
                    <v-col cols="12" md="8" class="py-1 text-right font-weight-bold">{{ billingInformation.name }}</v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="5" class="py-1">Phone Number</v-col>
                    <v-col cols="12" md="7" class="py-1 text-right font-weight-bold">{{ billingInformation.phone }}</v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" md="4" class="py-1">Email</v-col>
                    <v-col cols="12" md="8" class="py-1 text-right font-weight-bold">{{ billingInformation.email }}</v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" md="4" class="py-1">Address</v-col>
                    <v-col cols="12" md="8" class="py-1 text-right font-weight-bold">{{ billingAddress }}</v-col>
                </v-row>
            </v-card-text>


        </v-container>

        <v-divider class="my-1"></v-divider>

        <v-card-actions>
            <v-btn text @click="openBillingInformationModal"> <v-icon>mdi-pencil</v-icon> Update Information</v-btn>
        </v-card-actions>



        <billing-information-modal :billingInformation="billingInformation"
            @save="refreshBillingInformation"></billing-information-modal>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import { find as _find } from "lodash";
import BillingInformationModal from '@/views/billing_portal/billing/BillingInformationModal';

export default {
    name: "BillingInformationCard",
    components: {
        BillingInformationModal
    },
    data() {
        return {
            loading: false,
            billingInformation: {
                name: '',
                phone: '',
                phone_country: '',
                phone_code: '',
                phone_number: '',
                email: '',
                address: '',
                city: '',
                state: '',
                zip: '',
            }
        };
    },
    created() {
        this.fetch();
    },
    computed: {
        billingAddress() {
            const addressParts = [
                this.billingInformation.address,
                this.billingInformation.city,
                this.billingInformation.state,
                this.billingInformation.zip
            ];

            return addressParts.filter(part => part !== null).join(", ");
        },
        billingPhone() {
            const phoneParts = [
                this.billingInformation.phone_code,
                this.billingInformation.phone_number,
            ];

            return phoneParts.filter(part => part !== null).join(" ");
        }
    },
    methods: {
        refreshBillingInformation(data) {
            this.billingInformation.name = data.name
            this.billingInformation.phone_code = data.phone_code
            this.billingInformation.phone_country = data.phone_country
            this.billingInformation.phone = this.billingPhone;
            this.billingInformation.phone_number = data.phone_number
            this.billingInformation.address = data.address
            this.billingInformation.city = data.city
            this.billingInformation.state = data.state
            this.billingInformation.zip = data.zip
        },
        openBillingInformationModal() {
            this.billingInformation = { ...this.billingInformation }
            this.$modal.show('billing-information-modal')
        },
        fetch() {
            this.loading = true;

            this.$http
                .get(`billing/billing-information/`, {
                    params: {
                        userId: this.$route.params.id || null,
                    },
                })
                .then((data) => {
                    this.loading = false;
                    this.billingInformation.name = data.data.name;
                    this.billingInformation.phone_country = data.data.phone_country;
                    this.billingInformation.phone_code = data.data.phone_code;
                    this.billingInformation.phone_number = data.data.phone_number;
                    this.billingInformation.phone = this.billingPhone;
                    this.billingInformation.email = data.data.email;
                    this.billingInformation.address = data.data.company.address;
                    this.billingInformation.city = data.data.company.city;
                    this.billingInformation.state = data.data.company.state;
                    this.billingInformation.zip = data.data.company.zip;


                })
                .catch((error) => {
                    this.loading = false;
                    this.$errorNotif("Something went wrong");
                });
        },
    },
    watch: {
        billingInformation: {
            handler(newFormData) {
                this.$emit("fetchBillingInformation", { ...newFormData });
            },
            immediate: true,
            deep: true
        }
    },
};
</script>
  