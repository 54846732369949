const data = {
    namespaced: true,
    state: {
        pageLoader: false,
        appBack: [],
        hasAppBack: false,
        breadcrumbsLoader: false,
    },
    mutations: {
        SET_PAGE_LOADER: (state, payload) => {
            state.pageLoader = payload
        },
        SET_BREADCRUMBS_LOADER: (state, payload) => {
            state.breadcrumbsLoader = payload
        },
        SET_APP_BACK: (state, payload) => {
            if (payload) {
                state.hasAppBack = true
                state.appBack = payload
            } else {
                state.hasAppBack = false
                state.appBack = []
            }
        },
    },
}

export default data;
