
import Swal from 'sweetalert2'

export default {
    methods: {
        $meFetch(){
            if (this.$auth.check()) {
                this.$http.defaults.headers.common.Authorization = `Bearer ${this.$auth.token()}`;
            }
            setTimeout(() => {
              this.$auth.fetchUser();
            }, 1000);
            this.$store.commit('user/SET_CURRENT_USER', this.$auth.user())
        },
       async $isSSORedirect(){
            const params = new URLSearchParams(window.location.search);
            let SAMLRequest = params.get('SAMLRequest');
            let RelayState = params.get('RelayState');

            if (SAMLRequest && RelayState) {
                let query = {
                  SAMLRequest: SAMLRequest,
                  RelayState: RelayState,
                }
                return query
            }

            return false;
        },

        $renderLayout(company_id, loginToggle = false) {
            this.$store.commit('layout/SET_LAYOUT_LOADING', true)    
            this.$http
            .get(`/preference/get-company-layout/${company_id}` )
            .then((result) => {
               this.$setLayout(result.data)
               if (result.data.type == 'modified' ) {
                this.$cookies.set('companyId',company_id, '20y');
               } else if (!loginToggle) {
                this.$cookies.remove('companyId');
               }
            })
            .catch((error) => {
            })
        },
        $setLayout(data){
            this.$vuetify.theme.themes.light.primary = data.primaryColor;
            this.$vuetify.theme.themes.light.secondary = data.secondaryColor;
            document.documentElement.style.setProperty('--primary', data.primaryColor);
            document.documentElement.style.setProperty('--secondary', data.secondaryColor);
            document.documentElement.style.setProperty('--logo-bgcolor', data.companyLogoBgcolor);
            document.documentElement.style.setProperty('--navbar-active',  this.$hexToRgba(data.primaryColor, 0.25));
            document.documentElement.style.setProperty('--navbar-text-active', data.primaryColor);
            
            this.$autoAdjustColor(data.primaryColor, data.secondaryColor)
            this.setTab(data.favicon, data.tabTitle)
            
            this.$store.commit('layout/SET_COLOR', data)    
            this.$store.commit('layout/SET_LOGO', data.companyLogo)    
            this.$store.commit('layout/SET_ICON',{
                'companyIcon': data.companyIcon,
                'favicon': data.favicon,
                'tabTitle': data.tabTitle,
            })
            this.$store.commit('layout/SET_LOGO_WIDTH', data.companyLogoWidth)   
            this.$store.commit('layout/SET_LOGO_BGCOLOR', data.companyLogoBgcolor)   
            this.$store.commit('layout/SET_TYPE', data.type)
            this.$store.commit('layout/SET_NAME', data.name)
            this.$store.commit('layout/SET_ADMIN', data.admin)
            this.$store.commit('layout/SET_DEFAULT_IMAGE', data.defaultImage)
            this.$store.commit('layout/SET_NOT_FOUND', data.notFound)
            this.$store.commit('layout/SET_PASSWORD_AUTOGENERATE', data.passwordAutogenerate)
            if (this.$route.name !== 'Login') {
                window.zESettings.webWidget.color.theme = data.primaryColor   
            }
            this.$store.commit('layout/SET_LAYOUT_LOADING', false)    
            this.$store.commit('layout/SET_LABELS', data.labels)    
        },

        $autoAdjustColor(primary, secondary) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(primary);
            let rgb = result ? {
                red: parseInt(result[1], 16),
                green: parseInt(result[2], 16),
                blue: parseInt(result[3], 16)
              } : null;

            if (rgb.red >= 235 && rgb.green >= 235 && rgb.blue >= 235) {
                //White Spectrum
                this.$vuetify.theme.themes.light.primary = '#000000';
                this.$vuetify.theme.themes.light.primaryMain = primary;
                this.$vuetify.theme.themes.light.primaryMainText = '#000000';
                document.documentElement.style.setProperty('--primary', '#000000');
                document.documentElement.style.setProperty('--navbar-active', this.$hexToRgba('#000000', 0.3));
                document.documentElement.style.setProperty('--navbar-text-active', '#000000');
            } else {
                this.$vuetify.theme.themes.light.primaryMain = primary;
                this.$vuetify.theme.themes.light.primaryMainText = '#ffffff';
                document.documentElement.style.setProperty('--navbar-active', this.$hexToRgba(primary, 0.25));
                document.documentElement.style.setProperty('--navbar-text-active', primary);
            }

            if (rgb.red <= 60 && rgb.green <= 60 && rgb.blue <= 60) {
                //Black Spectrum
                document.documentElement.style.setProperty('--primary-inverted', '#ffffff');
                this.$vuetify.theme.themes.light.primaryMainInverted = '#ffffff';
                document.documentElement.style.setProperty('--navbar-text-active', '#ffffff');
            } else {
                document.documentElement.style.setProperty('--primary-inverted', primary);
                this.$vuetify.theme.themes.light.primaryMainInverted = primary;
            }

        },

        $hexToRgba(hex, alpha = 1) {
            const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
            return `rgba(${r},${g},${b},${alpha})`;
        },

        setTab(favicon, name) {
            var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = favicon;

            let routeTitle = this.$route.meta.title

            let title = routeTitle ?
                routeTitle + ' | ' + name :
                name;
            document.title = title
        }
    }
};