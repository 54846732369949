<template>
    <div class="app-card my-5" >
    <v-hover class="app-card-item" v-if="app.access">
        <template v-slot:default="{ hover }">
            <div>
                <v-img
                  :max-width="width"
                  :max-height="300"
                  color="primary">
                  <v-img :src="app.thumbnail">  
                    <v-fade-transition>
                        <v-overlay
                            v-if="hover"
                            absolute >
                            <p class="text-center px-2 ma-0" width="100%">
                                <a :href="app.link" target="_blank">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                        large
                                        v-bind="attrs"
                                        color="primary"
                                        v-on="on">mdi-arrow-right-bold-circle-outline</v-icon>
                                        </template>
                                        <span>Go To {{app.name}}</span>
                                    </v-tooltip>
                                </a>
                            </p>
                        </v-overlay>
                    </v-fade-transition>
                    <div class="d-flex justify-space-between item-title caption">
                      <label>{{app.name}}</label>
                      <label class="mr-5" v-if="app.is_beta && is_super_admin">
                        Beta: {{ app.beta_tracker }}
                      </label>
                    </div>
                  </v-img>
                </v-img>
            </div>
        </template>
    </v-hover>
    </div>
</template>

<script>
import moment from 'moment'
import { mapState } from "vuex";

export default {
    data() {
        return {
            loadingRenew: false,
        }
    },
    computed: {
      user() {
        return this.$store.state.user.current_user
      },
      ...mapState("user", [
        'is_super_admin', 
      ]),
    },
    props: [
        'app',
        'width',
        'page',
    ],
    methods: {
        navigate(link) {
             this.$router.push({ name: link });
        },
    },
    components: {
    }
}
</script>