<template>
    <v-form 
        ref="form" 
        @submit.prevent="submit()" 
        method="POST" 
    > 
      <v-card-text class="login-card__field">
          <div  class="code mb-5" v-if="codeSwitch">
            <v-text-field
                type="number"
                maxlength = "6"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                v-model="form.email_key"
                outlined
                label="Email Code"
                class="text-code"
            >
              <template class=" ma-0" v-slot:append>
                  <v-btn
                    :loading="emailLoading"
                    text
                    color="primary"
                    class="ma-0"
                    @click="getEmailCode">
                    Get Code
                  </v-btn>
              </template>
            </v-text-field>
            <p class="caption ml-2">Enter the 6 digit code received by {{data.email}}.</p>
          </div>
          <div class="code mb-5" v-else>
            <v-text-field
                type="number"
                maxlength = "6"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                v-model="form.sms_key"
                outlined
                label="SMS Code"
                class="text-code"
            >
            <template class=" ma-0" v-slot:append>
                
            </template>
            </v-text-field>
            <v-btn
                :loading="smsLoading"
                text
                color="primary"
                class="code__get-code ma-0"
                @click="getSMSCode">
                Get Code
            </v-btn>
            <p class="caption ml-2">Enter the 6 digit code received by {{data.phone_number}}.</p>
          </div>

          <a class="ml-2" href="javascript:void(0)" @click="codeSwitch = !codeSwitch">
              Switch To {{codeSwitch ? 'SMS' : 'Email'}}
          </a>

          <div class="mt-10 ml-2">
            <a href="javascript:void(0)" 
              @click="changeState('security-question')">
              Authenticator Unavailable?
            </a>
          </div>
      </v-card-text>
      <v-card-actions class="login-card__action pa-0">
        <div class="forgot-group">
          <a 
              href="javascript:void(0)" 
              v-if="card_state == 'signin'" 
              @click="changeState(card_state)">
              Back to Sign In
          </a>
        </div>
        <v-btn class="submit-group primaryMainText--text" color="primaryMain" type="submit" :loading="loading"
          elevation="2"
        ><v-icon> mdi-arrow-right</v-icon></v-btn>
      </v-card-actions>
    </v-form>
</template>
<script>

export default {
  name: 'TwoFactorContent',
  props: [
    'card_state',
    'data',
  ],
  data() {
    return {
        codeSwitch: false,
        error: '',
        showError: false,
        loading: false,
        emailLoading: false,
        smsLoading: false,
        form: {
            email_key: '',
            sms_key: '',
        }
    }
  },
  methods: {
    getEmailCode() {
      if (this.data.type == 'new-device') {
        this.data['subject'] = 'Confirm New Device';
      }

      this.emailLoading = true
      this.$http
        .post(`/mfa/send-email-code`, this.data)
        .then((data) => {
          this.$successNotif('Successfully sent Email code')
          this.emailLoading = false
        })
        .catch((error) => {
          this.$errorNotif("Invalid URL Token!")
          this.$router.replace({'query': null});
          this.emailLoading = false
        })
    },
    getSMSCode() {
      this.emailLoading = true
      this.$http
        .post(`/mfa/send-sms-code`, this.data)
        .then((data) => {
          this.$successNotif('Successfully sent SMS code')
          this.emailLoading = false
        })
        .catch((error) => {
          this.$errorNotif("Invalid URL Token!")
          this.$router.replace({'query': null});
          this.emailLoading = false
        })
    },
    submit() {
        this.loading = true;
        let codeType = this.codeSwitch ? 'Email' : 'SMS';
        this.form['verify'] = this.data
        this.form['codeType'] = codeType
        this.$http
            .post(`/mfa/validate-2fa-device-code`, this.form)
            .then((data) => {
                this.loading = false
                this.$refs.form.reset()
                this.mfaLogin(data.data);
            })
            .catch((error) => {
            if (error.response.status == 422){
                this.$store.commit('login/SET_LOGIN_ERROR', error.response.data.errors )
            } else {
                this.$errorNotif("Something went wrong")
            }
            this.loading = false
            this.$refs.form.reset()
            })
    },
    async mfaLogin(value) {
        this.$auth
        .login({
          remember_token: value.remember_token,
          id: value.id,
          mfa_login: true,
          ssoQuery: await this.$isSSORedirect() 
        })
        .then(({ data }) => {
          this.$http.defaults.headers.common.Authorization = `Bearer ${data.access_token.accessToken}`;
          this.$auth.storeManager.setUser(data.user);
          this.$store.commit('user/SET_CURRENT_USER', data.user)
          this.$renderLayout(data.user.company_id)

          if (data.redirect != null) {
            this.$store.commit('global/SET_PAGE_LOADER', true)            
            window.location.assign(data.redirect);
          }

          this.loading = false
        })
        .catch((error) => {
          this.$errorNotif("Something went wrong")
          this.loading = false
          this.$refs.form.reset()
        })
    },
    changeState(state) {
      this.$emit('state', state)
    }
  }
};
</script>