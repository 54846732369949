<template>
  <v-stepper v-model="stepper">
    <v-stepper-header>
      <v-stepper-step
        step="1"
        :complete="stepper > 1"
      >
        Company Details
      </v-stepper-step>
      <v-stepper-step
        step="2"
        :complete="stepper > 2"
      >
        Upload File
      </v-stepper-step>
      <v-stepper-step
        step="3"
        :complete="stepper > 3"
      >
        Select Headers
      </v-stepper-step>
      <v-stepper-step
        step="4"
        :complete="stepper > 4"
      >
        Review
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content
        step="1"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                    v-model="filters.companies"
                    :items="companies"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    item-value="id"
                    item-text="name"
                    label="Companies"
                    :disabled="dealerManager || accountManager"
                    clearable
                ></v-select>
              </v-col>
              <v-col>
                <template
                    v-if="!isEmpty(filteredDealers)"
                >
                  <v-select
                      v-model="filters.dealers"
                      :items="filteredDealers"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      item-value="id"
                      item-text="name"
                      label="Dealers"
                      clearable
                      :disabled="dealerManager"
                      multiple
                  ></v-select>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="cancel()"
              color="primary"
              class="mr-4"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="primaryMainText--text"
              color="primaryMain"
              @click="nextStep"
            >
              Next
              <v-icon class="ml-1">mdi-arrow-right-bold-circle-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content
        step="2"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-alert type="info">
                  Please take note that the first row of the file should contain the columns (not image or banner). If the first row is an image or banner, Please use the field next to the file upload to identify the row header.
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input v-model="uploadedFile" accept=".xlsx, .xls, .csv"></v-file-input>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="row"
                  type="number"
                  single-line
                  hide-details
                  label="Row"
                  min="1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="cancel()"
              color="primary"
              class="mr-4"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              outlined
              @click="prevStep"
              class="mr-4"
            >
            <v-icon class="mr-1">mdi-arrow-left-bold-circle-outline</v-icon>
              Back
            </v-btn>
            <v-btn
              class="primaryMainText--text"
              color="primaryMain"
              @click="nextStep"
            >
              Next
              <v-icon class="ml-1">mdi-arrow-right-bold-circle-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-content
        step="3"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  :items="uploadedFileHeaders"
                  label="Name"
                  item-value="value"
                  item-text="title"
                  v-model="headers.name"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                    :items="uploadedFileHeaders"
                    label="Email"
                    item-value="value"
                    item-text="title"
                    v-model="headers.email"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    :items="uploadedFileHeaders"
                    label="Phone Country"
                    item-value="value"
                    item-text="title"
                    v-model="headers.phone_country"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                    :items="uploadedFileHeaders"
                    label="Phone Code"
                    item-value="value"
                    item-text="title"
                    v-model="headers.phone_code"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                    :items="uploadedFileHeaders"
                    label="Phone Number"
                    item-value="value"
                    item-text="title"
                    v-model="headers.phone_number"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="cancel()"
              color="primary"
              class="mr-4"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="prevStep"
              class="mr-4"
            >
            <v-icon class="mr-1">mdi-arrow-left-bold-circle-outline</v-icon>
              Back
            </v-btn>
            <v-btn
              class="primaryMainText--text"
              color="primaryMain"
              @click="nextStep"
            >
              Next
              <v-icon class="ml-1">mdi-arrow-right-bold-circle-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-content
        step="4"
      >
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    User Value
                  </th>
                  <th class="text-left">
                    Excel/CSV Column
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    Name
                  </td>
                  <td>
                    {{ headers.name }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Email
                  </td>
                  <td>
                    {{ headers.email }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Phone Country
                  </td>
                  <td>
                    {{ headers.phone_country }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Phone Code
                  </td>
                  <td>
                    {{ headers.phone_code }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Phone Number
                  </td>
                  <td>
                    {{ headers.phone_number }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="cancel()"
              color="primary"
              class="mr-4"
            >
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="prevStep"
              class="mr-4"
            >
            <v-icon class="mr-1">mdi-arrow-left-bold-circle-outline</v-icon>
              Back
            </v-btn>
            <v-btn
              class="primaryMainText--text"
              color="primaryMain"
              @click="submitForm"
            >
            <v-icon class="ml-1">mdi-arrow-right-bold-circle-outline</v-icon>
              Process
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import importExportUsers from '@/mixins/importExportUsers'
import { find as _find, isArray as _isArray, isEmpty as _isEmpty, filter as _filter } from 'lodash-es';

export default {
  name: 'ImportStepperForAdmin',
  mixins: [importExportUsers],
  data () {
    return {
      stepper: 1,
      row: 1,
      uploadedFile: [],
      filters: {
        companies: [],
        dealers: [],
        statuses: [],
        roles: [],
      },
      isEmpty: _isEmpty,
      uploadedFileName: '',
      uploadedFileHeaders: [
        {
          title: '',
          value: '',
        }
      ],
      headers: {
        name: '',
        email: '',
        phone_number: '',
        phone_code: '',
        phone_country: '',
      }
    };
  },
  computed: {
    filteredDealers() {
      if (this.filters.companies) {
        const dealers = _filter(this.dealers, { company_id: this.filters.companies});

        if (_isEmpty(dealers)) {
          return [];
        }

        return _isArray(dealers) ? dealers : [dealers];
      }

      return this.dealers;
    },
  },
  created() {
    this.prepareForm();
  },
  beforeDestroy () {
    this.resetState();
  },
  methods: {
    resetState() {
      this.filters.companies = null;
      this.filters.dealers = null;
      this.uploadedFileName = null;
      this.headers.name = null;
      this.headers.email = null;
      this.headers.phone_number = null;
      this.headers.phone_code = null;
      this.headers.phone_country = null;
      this.headers.role = null;
    },
    prepareForm() {
      if (this.superAdmin) {
        this.filters.companies = null;
        this.filters.dealers = null;

        return;
      }

      if (this.accountManager) {
        this.filters.companies = this.$auth.user().company_id;

        return;
      }

      if (this.dealerManager) {
        this.filters.companies = this.$auth.user().company_id;
        this.filters.dealers = this.$auth.user().specific_dealer_id;

        return;
      }
    },
    nextStep() {
      if (this.stepper === 2) {
        this.uploadFile();

        return;
      }

      this.stepper++;
    },
    prevStep() {
      if (this.stepper !== 1) {
        return this.stepper--;
      }
    },
    submitForm() {
      const form = {
        company_id: this.filters.companies,
        dealer_id: this.filters.dealers,
        uploaded_file: this.uploadedFileName,
        name: this.headers.name,
        email: this.headers.email,
        phone_number: this.headers.phone_number,
        phone_code: this.headers.phone_code,
        phone_country: this.headers.phone_country,
        role: this.headers.role,
      };

      this.$http.patch('import-users/process-import', form)
        .then(({ data }) => {
          this.$successNotif(data.message);

          this.resetState();

          this.$nextTick(() => {
            this.$store.dispatch('users/setImportDialogState', false);
          });
        })
        .catch(() => {
          this.$errorNotif('Failed to import');
        })
    },
    uploadFile() {
      const form = new FormData;
      form.append('file', this.uploadedFile);

      this.$http.post('import-users/upload-file', form)
        .then(({ data }) => {
          this.uploadedFileName = data.file;

          this.$nextTick(() => {
            this.getHeaders();
          });
        })
      .catch(() => {
        this.$errorNotif('Please double check the uploaded file!');
      })
    },
    getHeaders() {
      this.$http.get('import-users/get-headers', {
        params: {
          file_path: this.uploadedFileName,
          row: this.row <= 0 ? 1 : this.row,
        }
      })
        .then(({ data }) => {
          this.uploadedFileHeaders = data.headers;

          this.stepper++;
        })
    },
    cancel() {
      this.$modal.hide('import-modal')
    }
  }
}
</script>

<style scoped>

</style>