<template>
  <div class="content" :class="!is_super_admin ? 'content-full' : ''">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">
        {{ $route.meta.title }}
        <span v-if="isView" class="title font-weight-thin">View</span>
        <span v-else class="title font-weight-thin">Create</span>
      </h1>

      <v-layout row class="mt-5">
        <v-col xs12 md7 lg8 class="px-4 content-image">
          <v-card class="pa-4 flex-column" min-height="350">
            <v-tabs grow v-model="tab">
              <v-tab>Information</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form
                  @submit.prevent="submit()"
                  method="PUT"
                  :readonly="isView"
                >
                  <v-container class="my-5 content-container mb-16">
                    <v-autocomplete
                      :disabled="disableCompanyField"
                      item-text="name"
                      item-value="id"
                      outlined
                      label="Company"
                      required
                      dense
                      v-model="position.company_id"
                      :items="companies"
                      autocomplete="off"
                      :error-messages="errors.company_id"
                      @change="changeCompanyField()"
                      @blur="outFocusCompanyField()"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>
                        Company
                      </template>
                    </v-autocomplete>

                    <v-autocomplete
                      :disabled="disableDealerField"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :label="dealerLabel"
                      v-model="position.specific_dealer_id"
                      :items="specific_dealers"
                      autocomplete="off"
                      clearable
                      :loading="loadDealer"
                      :rules="errors.specific_dealer_id"
                      @change="changeDealerField()"
                  >
                      <template v-slot:append-item>
                          <div v-if="!loadedAllSpecificDealer" v-intersect="onIntersectSpecificDealer" class="pa-4 primary--text">
                              Loading more items ...
                          </div>
                      </template>
                  </v-autocomplete>

                    <v-text-field
                      dense
                      label="Name"
                      placeholder="Name"
                      outlined
                      required
                      v-model="position.friendly_name"
                      :error-messages="errors.friendly_name"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span
                        >Name
                      </template>
                    </v-text-field>

                    <div v-if="showCompanyManagerField">
                      <v-divider class="mb-5"></v-divider>
                        <p class="subtitle-2">Assign managers to positions</p>
                      </div>
                      <div v-if="!position.specific_dealer_id && this.is_super_admin && !this.is_account_manager && !this.is_dealer_manager">
                        <v-autocomplete
                          :loading="superAdminAdminListLoading"
                          v-model="position.super_admins"
                          label="Managers"
                          :items="superAdminAdminList"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          clearable
                          autofocus
                          multiple  
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="errors.company_managers"
                        >
                        </v-autocomplete>
                        </div>
                      <div v-if="!position.specific_dealer_id && this.is_account_manager && !this.is_dealer_manager && !this.is_super_admin">
                        <v-autocomplete
                          :loading="companyAdminListLoading"
                          v-model="position.company_managers"
                          label="Managers"
                          :items="companyAdminList"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          clearable
                          autofocus
                          multiple  
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="errors.company_managers"
                        >
                        </v-autocomplete>
                        </div>
                        <div v-if="position.specific_dealer_id || (this.is_dealer_manager && !this.is_account_manager && !this.is_super_admin)">
                          <v-autocomplete
                            v-if="showDealerManagerField"
                            :loading="dealerAdminListLoading"
                            v-model="position.dealer_managers"
                            label="Managers"
                            :items="dealerAdminList"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            clearable
                            autofocus
                            multiple
                            :menu-props="{ bottom: true, offsetY: true }"
                            :rules="errors.dealer_managers"
                          >
                          </v-autocomplete>
                      </div>
                  </v-container>
                  <v-spacer />
                  <br>
                  <v-card-actions v-if="!isView" class="mt-16">
                    <v-spacer />
                    <v-btn
                      color="primaryMain"
                      dark
                      :loading="loading"
                      class="mt-5 primaryMainText--text"
                      type="submit"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col
          xs12
          :md="is_super_admin ? '5' : '12'"
          :lg="is_super_admin ? '4' : '12'"
          class="content-image-section"
        >
        </v-col>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { includes as _includes } from "lodash-es";
import Vue from "vue";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapState } from "vuex";
import {
    find as _find,
    some as _some,
    filter as _filter,
} from 'lodash';
Vue.use(require("vue-moment"));

export default {
  computed: {
    ...mapState("layout", ["labels"]),
    ...mapState("apps", ["app_list"]),
    ...mapState("user", [
      "current_user",
      "is_super_admin",
      "is_account_manager",
      "is_dealer_manager"
    ]),
    isView() {
      return this.$route.name == this.$route.meta.type + ".view";
    },
    positionId() {
      return this.$route.params.id;
    },
    companies() {
      return this.$store.state.company.company_list;
    },
    disableCompanyField() {
      return !this.is_super_admin
    },
    disableDealerField() {
      return !this.is_super_admin && !this.is_account_manager
    },
    loadedAllSpecificDealer() {
      return this.specific_dealers.length >= this.specificDealerSetting.total
    },
    selectedCompany() {
        return this.position.company_id ? this.position.company_id : this.current_user.company_id;
    },
    selectedDealer() {
        return this.position.specific_dealer_id ? this.position.specific_dealer_id : this.current_user.specific_dealer_id;
    },
    showDealerManagerField() {
      return this.position.specific_dealer_id ? true : false && (this.is_super_admin || this.is_account_manager || this.is_dealer_manager)
    },
    showCompanyManagerField() {
      return this.is_super_admin || this.is_account_manager || this.is_dealer_manager;
    },
    selectedCompanyDetails() {
        if (!this.selectedCompany) {
            return false
        }
        return _find(this.companies, {
            id: this.selectedCompany,
        });
    },
    dealerLabel() {
      if (this.labels.dealer === undefined) {
        return '';
      }
      return this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1);
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      breadcrumbs: {
        0: {
          title: "Home",
          link: "home",
        },
        1: {
          title: this.$route.meta.title,
          link: this.$route.meta.type,
        },
        2: {
          title: "Create",
          link: this.$route.meta.type + ".create",
        },
      },
      position: {
        name: null,
        friendly_name: null,
        role_id: null,
        company_id: null,
        specific_dealer_id: null,
        company_managers: [],
        dealer_managers: [],
        super_admins: []
      },
      loadDealer: false,
      specific_dealers: [],
      specificDealerSetting: {
          page: 1,
          per_page: 20,
          total: ''
      },
      errors: {
        name: null,
        friendly_name: null,
        role_id: null,
        company_id: null,
      },
      companyAdminListLoading: false,
      companyAdminList: [],
      dealerAdminListLoading: false,
      dealerAdminList: [],
      superAdminAdminList: [],
      superAdminAdminListLoading: false
    };
  },
  created() {
    this.init()
  },
  watch: {
  },
  methods: {
    init() {
      this.position.company_id = this.current_user.company_id;
      this.position.specific_dealer_id = this.current_user.specific_dealer_id;
      this.fetchCompany()
      this.fetchSpecificDealer()
      this.fetchCompanyAdmin()
      this.fetchDealerAdmin()
      this.fetchSuperAdmin()
    },
    fetchCompany() {
      if (this.companies) {
        this.$store.dispatch('company/getList')
      }
    },
    // Specific Dealer Setup
    fetchSpecificDealer() {
        this.loadDealer = true
        this.$http
        .get('specific-dealer', {
            params: {
                per_page: this.specificDealerSetting.per_page,
                current_page: this.specificDealerSetting.page,
                company_id: this.selectedCompany,
            },
        })
        .then((result) => {
            this.specific_dealers = [
                ...this.specific_dealers,
                ...result.data.data
            ]

            this.specificDealerSetting.total = result.data.total
            this.loadDealer = false
            this.renderInitialDealer()
        })
    },
    renderInitialDealer() {
        if (this.selectedCompany == this.current_user.company_id) {
            this.position.specific_dealer_id = this.selectedDealer
        }
    },
    onIntersectSpecificDealer() {
        this.specificDealerSetting.page += 1
        this.fetchSpecificDealer()
    },
    // Super Admin Setup
    fetchSuperAdmin() {
      this.superAdminAdminListLoading = true
      this.$http
      .get('user/get-admins', {
        params: {
            company_id: this.selectedCompany,
            specific_dealer_id: this.selectedDealer,
            role: 'super-administrator',
        },
      })
      .then((result) => {
        this.superAdminAdminList = result.data
        this.superAdminAdminListLoading = false
      })
      .catch((error) => {
        this.companyAdminListLoading = false
      });
    },
    // Company Admin Setup
    fetchCompanyAdmin() {
      this.companyAdminListLoading = true
      this.$http
      .get('user/get-admins', {
        params: {
            company_id: this.selectedCompany,
            specific_dealer_id: this.selectedDealer,
            role: 'account-manager',
        },
      })
      .then((result) => {
        this.companyAdminList = result.data
        this.companyAdminListLoading = false
      })
      .catch((error) => {
        this.companyAdminListLoading = false
      });
    },
    // Dealer Admin Setup
    fetchDealerAdmin() {
      this.dealerAdminListLoading = true
      this.$http
      .get('user/get-admins', {
        params: {
            company_id: this.selectedCompany,
            specific_dealer_id: this.selectedDealer,
            role: 'specific-dealer-manager',
        },
      })
      .then((result) => {
          this.dealerAdminList = result.data
          this.dealerAdminListLoading = false
      })
      .catch((error) => {
        this.dealerAdminListLoading = false
      });
    },
    submit() {
      this.loading = true;
      this.errors = [];

      this.$http
        .post("/positions", this.position)
        .then((result) => {
          this.checkPositionManager();
          this.loading = false;
          this.$store.dispatch("company/getList");
          this.$successNotif("Company information has been saved");
          this.isSingleUser = result.data.is_single_user;
          this.resetField()
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
            this.position.friendly_name = ''
            this.$errorNotif("Invalid inputs, Please check the details");
          }
          this.loading = false;
        });
    },
    checkPositionManager() {
        this.$http
        .get('user/check-position-manager', {
            params: {
                user_id: this.current_user.id,
            },
        })
        .then(({data}) => {
            this.$store.commit('user/SET_CURRENT_USER_POSITION_MANAGER', data)
        })
        .catch((error) => {
        });
    },
    isSuperAdmin() {
      return this.is_super_admin;
    },
    isAccountManager() {
      return this.is_account_manager;
    },
    outFocusCompanyField() {
        if (this.position.company_id == null) {
          this.position.company_id = this.current_user.company_id;
        }
    },
    changeCompanyField() {
      this.position.specific_dealer_id = ''
      this.specific_dealers = []
      this.specificDealerSetting.page = 1
      this.specificDealerSetting.total = ''
      this.fetchSpecificDealer()
      this.changeDealerField()
    },
    changeDealerField() {
      this.position.dealer_managers = [];
      this.position.company_managers = [];
      
      this.fetchCompanyAdmin()
      this.fetchDealerAdmin()
      this.fetchSuperAdmin()
      this.resetField()
    },
    resetField() {
      this.position.friendly_name = ''
      this.position.company_managers = []
      this.position.dealer_managers = []
      this.position.super_admins = []
    }
  },
  components: {
    Breadcrumbs,
  },
};
</script>