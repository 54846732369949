<template>
  <modal
    name="sync-logs-modal"
    width="1500"
    height="700"
    :adaptive="true" >
         <div class="pa-5 align-center">
          <div class="d-flex justify-space-between ">
            <h2>Sync Logs</h2>
            <v-btn
                @click="cancel()"
                icon
                color="black"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        </div>
        <v-divider/>
        <div class="ma-5">
          <div class="text-right my-5">
            <v-btn 
              color="primaryMain" 
              class="primaryMainText--text"
              @click="resync()" 
              :loading="loadingResync"
            >
              <v-icon class="mr-2">mdi-sync</v-icon>
              Resync
            </v-btn>
          </div>
          <v-data-table
              :headers="headers"
              :items="logs"
              class="elevation-1"
              :loading="loading"
              loading-text="Loading... Please wait"
              :options.sync="options"
              :server-items-length="pagination.total"
              :items-per-page="pagination.per_page"
              :footer-props="{ disablePagination: paginationDisable }"
          >
              <template v-slot:item.exception="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a @click="copyUrl(item.exception)" href="javascript:void(0)" v-on="on">
                        Click to Copy
                      </a>
                    </template>
                    {{ item.exception }}
                  </v-tooltip>
              </template>

              <template v-slot:item.created_at="{ item }">
                <span>{{ new Date(item.created_at).toLocaleString() }}</span>
              </template>

          </v-data-table>
        </div>
  </modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'SyncLogsModal',
  data () {
    return {
      loading: false,
      loadingResync: false,
      headers: [
        { text: 'App', value: 'client' },
        { text: 'Model', value: 'model' },
        { text: 'Type', value: 'type' },
        { text: 'Response Code', value: 'response_http_code', width: 350 },
        { text: 'Exception', value: 'exception', width: 350 },
        { text: 'Date', value: 'created_at'},
      ],
      search: '',
      logs: [],
      pagination: {
        total: 1,
        per_page: 10,
        current_page: 1,
        search: '',
        sortBy: '',
        sortDesc: false,
      },
      options: {},
      paginationDisable: false,
      timeout: null, 
    }
  },
  props: [
    'model'
  ],
  computed: {
    ...mapState("user", [
      "current_user", 
      'is_super_admin', 
      'is_account_manager',
      'is_dealer_manager'
    ]),
    isProfile() {
        return this.$route.name == 'profile'
    },
    user_id() {
      let user_id = this.$route.params.id 
      if (this.isProfile) {
          user_id = this.current_user.id
      } 
      return user_id
    }
  },
  watch: {
    options: {
      handler() {
        this.changeTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetch
  },
  methods: {
    cancel() {
      this.$modal.hide('sync-logs-modal')
    },
    fetch() {

      this.loading = true;
      this.paginationDisable = true;
      this.logs = [];

      this.$http
          .get(`/sync-logs/${this.user_id }`, {
            params: {
              per_page: this.options.itemsPerPage,
              current_page: this.pagination.current_page,
              sortBy: this.pagination.sortBy,
              sortDesc: this.pagination.sortDesc,
              search: this.search,
              model: this.model,
            },
          })
          .then((data) => {
              this.loading = false;
              this.logs = data.data.data
              this.pagination.total = data.data.total;
              this.pagination.current_page = data.data.current_page;
              this.pagination.per_page = data.data.per_page
              this.paginationDisable = false;
          })
          .catch((error) => {
              this.$errorNotif("Something went wrong")
          })
    },
    changeTable() {
      this.pagination.current_page = this.options.page;

      this.pagination.sortBy =
        this.options.sortBy !== undefined ? this.options.sortBy[0] : '';
      this.pagination.sortDesc =
        this.options.sortDesc !== undefined ? this.options.sortDesc[0] : '';

      this.fetch();
    },

    copyUrl(data) {
        this.$successNotif('Copied URL')
        navigator.clipboard.writeText(data);
    },

    resync() {

      this.loadingResync = true;
      this.$http
        .get(`/resync/${this.user_id}`, {
          params: {
            model: this.model,
          },
        })
        .then((data) => {
            this.loadingResync = false;
            this.$successNotif("successfully re-synced")
        })
        .catch((error) => {
            this.loadingResync = false;
            this.$errorNotif("Something went wrong")
        })
    }
  }
};
</script>
