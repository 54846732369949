<template>
    <v-form 
        ref="form" 
        @submit.prevent="submit()"  
        method="POST" 
    >
        <v-card-text class="login-card__field">
          <div v-for="(item, index) in questions" :key="index">
            <h5 class="caption">Question {{++index}}</h5>
            <h4 class="my-2 mb-3">{{item.question}}</h4>
            <v-text-field
              v-model="item.answer"
              solo
              label="Answer"
              clearable
              dense
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="login-card__action pa-0">
          <div class="forgot-group">
            <a 
                href="javascript:void(0)" 
                @click="changeState()">
                Back to Authenticator
            </a>
          </div>
          <v-btn class="submit-group primaryMainText--text" color="primaryMain" type="submit" :loading="loading"
            elevation="2"
          ><v-icon> mdi-arrow-right</v-icon></v-btn>
        </v-card-actions>
    </v-form>
</template>
<script>

export default {
  name: 'SecurityQuestionContent',
  props: [
    'data',
    'card_state'
  ],
  data() {
    return {
        questions: [],
        loading: false,
        errors: []
    }
  },
  watch: {
    data() {
        if (this.card_state != 'reset_password') {
          this.getQuestions()
        }
    }
  },
  methods: {
    getQuestions(){
        this.$http
        .post(`/mfa/get-questions`, this.data)
        .then((data) => {
            this.questions = data.data
        })
        .catch((error) => {
            this.$errorNotif("Something went wrong")
        })
    },
    submit() {
      this.loading = true;

      let form = {
        questions: this.questions,
        data: this.data,
      }

      this.$http
        .post(`/mfa/verify-questions`, form)
        .then((data) => {
            this.loading = false
            this.$refs.form.reset()
            this.mfaLogin(data.data);
        })
        .catch((error) => {
          if (error.response.status == 422){
            this.$store.commit('login/SET_LOGIN_ERROR', error.response.data.errors )
          } else {
            this.$errorNotif("Something went wrong")
          }
            this.loading = false
        })
    },
    async mfaLogin(value) {
        this.$auth
        .login({
          remember_token: value.remember_token,
          id: value.id,
          mfa_login: true,
          ssoQuery: await this.$isSSORedirect() 
        })
        .then(({ data }) => {
          this.$http.defaults.headers.common.Authorization = `Bearer ${data.access_token.accessToken}`;
          this.$auth.storeManager.setUser(data.user);
          this.$store.commit('user/SET_CURRENT_USER', data.user)
          this.$renderLayout(data.user.company_id)

          if (data.redirect != null) {
            this.$store.commit('global/SET_PAGE_LOADER', true)            
            window.location.assign(data.redirect);
          }

          this.loading = false
          this.$infoNotif('If Authenticator is unavailable, Please Disable "Two Factor Authenticator" instead!.')
        })
        .catch((error) => {
          this.$errorNotif("Something went wrong")
          this.loading = false
          this.$refs.form.reset()
        })
    },
    changeState() {
      this.$emit('state')
    }
  }
};
</script>