<template>
    <modal name="billing-information-modal" width="500" height="auto" :adaptive="true">
        <div class="pa-5 align-center">
            <div class="d-flex justify-space-between">
                <h2>Update Billing Information</h2>
                <v-btn @click="cancel()" icon color="black">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </div>
        <v-divider />
        <div class="ma-5">

            <v-form @submit.prevent="submit" :disabled="loading">

                <v-text-field :rules="errors.name" v-model="form.name" label="Name"></v-text-field>
                <vue-phone-number-input 
                    v-model="form.phone_number" 
                    class="mb-3" no-example 
                    :only-countries="['US', 'PH']"
                    :color="$vuetify.theme.themes.light.primary" 
                    valid-color="#004df9"
                    :default-country-code="form.phone_country" 
                    @update="formatPhoneNo($event)" />
                <p class="phone-number-error caption red--text ml-4 mb-2">
                    <span v-if="isPhoneNoError || errors.phone_number">
                        Phone number is invalid, please input correct phone number
                    </span>  
                    <span v-else class="white--text">Phone Number</span>  
                </p>
                <v-text-field  class="mt-7" v-model="form.email" label="Email" :disabled="true"></v-text-field>
                <v-text-field :rules="errors.address" v-model="form.address" label="Address"></v-text-field>
                <v-text-field :rules="errors.city" v-model="form.city" label="City"></v-text-field>
                <v-text-field :rules="errors.state" v-model="form.state" label="State/Province"></v-text-field>
                <v-text-field :rules="errors.zip" v-model="form.zip" label="Zip"></v-text-field>

                <div class="d-flex justify-space-between mt-10">
                    <div>
                        <v-btn @click="cancel">Cancel</v-btn>
                    </div>
                    <div>
                        <v-btn color="primaryMain" type="submit" class="primaryMainText--text" :disabled="loading">
                            Update
                        </v-btn>
                    </div>
                </div>
            </v-form>

        </div>
    </modal>
</template>
    
<script>
export default {
    name: "BillingInformationModal",
    props: {
        billingInformation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            errors: [],
            showError: false,
            form: {
                name: '',
                phone_country: '',
                phone_code: '',
                phone_number: '',
                email: '',
                address: '',
                city: '',
                state: '',
                zip: '',
            },
            isPhoneNoError: false,
            nationalNumber: '',
        };
    },
    watch: {
        computedFormData: {
            handler(newFormData) {
                this.form = { ...newFormData };
            },
            immediate: true 
        }
    },
    computed: {
        computedFormData() {
            return {
                name: this.billingInformation.name || '',
                phone_country: this.billingInformation.phone_country || '',
                phone_code: this.billingInformation.phone_code || '',
                phone_number: this.billingInformation.phone_number || '',
                email: this.billingInformation.email,
                address: this.billingInformation.address || '',
                city: this.billingInformation.city || '',
                state: this.billingInformation.state || '',
                zip: this.billingInformation.zip || '',
            };
        }
    },
    methods: {
        submit() {
            if (this.isPhoneNoError) {
                this.$errorNotif('Invalid inputs, Please check the details')
                return false
            }
            this.loading = true;
            this.$store.commit('global/SET_PAGE_LOADER', true)  

            this.errors = []
            let data = new FormData();

            if (this.$route.params.id) {
                data.append('userId', this.$route.params.id);
            }

            data.append('name', this.form.name);
            data.append('address', this.form.address);
            data.append('city', this.form.city);
            data.append('state', this.form.state);
            data.append('zip', this.form.zip);
            if (this.form.phone_number) {
                data.append('phone_number', this.form.phone_number);
                data.append('phone_code', this.form.phone_code);
                data.append('phone_country', this.form.phone_country);
            }

            this.$http
                .post(`/billing/update-billing-information`, data)
                .then((result) => {
                    this.loading = false;
                    this.$store.commit('global/SET_PAGE_LOADER', false)  
                    this.$successNotif('Successfully updated')
                    this.$emit("save", this.form);
                    this.cancel();
                })
                .catch((error) => {
                    this.loading = false;
                    this.$store.commit('global/SET_PAGE_LOADER', false)  
                    if (error.response.status == 422) {
                        this.errors = error.response.data
                        this.$errorNotif('Invalid inputs, Please check the details')
                    }
                })
        },
        formatPhoneNo(event) {
            this.isPhoneNoError = false

            if (event.phoneNumber == undefined) {
                return this.isPhoneNoError;
            }

            if (event.phoneNumber && !event.isValid) {
                return this.isPhoneNoError = true
            }

            this.form.phone_country = event.countryCode
            this.form.phone_code = '+' + event.countryCallingCode
            this.nationalNumber = event.nationalNumber
            this.form.phone_number = this.nationalNumber
        },
        cancel() {
            this.$modal.hide("billing-information-modal");

        },
    },
};
</script>
  
<style scoped>
.error-message {
    color: red;
}

.v-text-field__details {
    display: none !important;
}
</style>