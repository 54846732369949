<template>
    <div>
        <div class="my-5">
            <v-data-table
              :headers="headers"
              :items="items"
              class="elevation-1"
              :loading="loading"
              loading-text="Loading... Please wait"
              :options.sync="options"
              :server-items-length="pagination.total"
              :items-per-page="pagination.per_page"
              :footer-props="{ 
                disablePagination: paginationDisable,
                'items-per-page-options': [5, 10, 20, 50 ]
              }"
            >
                <template v-slot:item.created_at="{ item }">
                    <span>{{ new Date(item.created_at).toLocaleString() }}</span>
                </template>
                <template v-slot:item.action="{ item }">
                    <div class="d-flex justify-end">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }" v-if="item.payment_method && item.collection_method == 'send_invoice'">
                                <v-btn
                                @click="viewPaymentDetails(item)"
                                x-small
                                color="info"
                                icon
                                class="mx-1"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-note</v-icon>
                                </v-btn>
                            </template>
                            <span>View Payment Details</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.collection_method == 'send_invoice' && item.status == 'open'">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="manualPay(item)"
                                x-small
                                color="primary"
                                icon
                                class="mx-1"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-cash</v-icon>
                                </v-btn>
                            </template>
                            <span>Manual Pay</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.collection_method == 'send_invoice' && item.status == 'open'">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="send(item)"
                                x-small
                                icon
                                class="mx-1"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-send</v-icon>
                                </v-btn>
                            </template>
                            <span>Send Invoice to Billing User</span>
                        </v-tooltip>
                         <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="download(item)"
                                x-small
                                color="info"
                                icon
                                class="mx-1"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </template>
                            <span>Download PDF Invoice</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="viewInvoice(item)"
                                x-small
                                color="info"
                                icon
                                class="mx-1"
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </template>
                            <span>View Invoice</span>
                        </v-tooltip>

                    </div>
                </template>
            </v-data-table>
        </div>
        <manual-pay-modal :item="currentInvoice"/>
    </div> 
</template>

<script>
import { mapState } from "vuex";
import ManualPayModal from '@/components/company_billing/ManualPayModal';

export default {
    computed: {
         ...mapState("user", [
            "current_user", 
            'is_super_admin', 
            'is_account_manager'
        ]),
        companyId() {
            return this.$route.params.id 
        },
        
    },
    props: [
        'items',
        'loading',
        'badgeCount'
    ],
    data() {
        return {
            headers: [
                { text: 'Invoice No.', value: 'invoice_number'},
                { text: 'Collection Method', value: 'collection_method' },      
                { text: 'status', value: 'status' },      
                { text: 'Due Date', value: 'due_date' },
                { text: 'Date Issued', value: 'date_issued' },
                { text: "", value: "action", width: "150" },
            ],
            search: '',
            pagination: {
                total: 1,
                per_page: 10,
                current_page: 1,
                search: '',
                sortBy: '',
                sortDesc: false,
            },
            options: {},
            paginationDisable: false,
            timeout: null,
            filters: null,
            currentInvoice: [],
        };
    },
    created() {
    },
    methods: {
        manualPay(item) {
            this.currentInvoice = item
            this.$modal.show('manual-pay-modal')
        },
        viewPaymentDetails(item) {
            this.currentInvoice = item
            this.$modal.show('manual-pay-modal')
        },
        send(item) {
            let msg = {
                title: "Send email to " + item.user.email + "?",
                confirmButtonText: "Yes, Send!",
            };
            this.$questionNotif(msg).then((result) => {
                if (result) {
                    this.loading = true;
                    this.$http
                        .post(`billing/send-invoice/${item.id}`)
                        .then((data) => {
                            this.$successNotif('Email sent successfully')
                            this.loading = false;
                        })
                        .catch((error) => {
                            this.loading = false;
                        });
                }
            });
        },
        download(item) {
            window.location.href = item.pdf_link;
        },
        viewInvoice(item) {
            let companyId = this.$route.params.id
            this.$router.push({ name: this.$route.meta.type+".invoice", params:{id: item.id, companyId: companyId } });
        }
    },
    components: {
        ManualPayModal
    },
}
</script>