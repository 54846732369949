<template>
  <div>
    <div class="content">
      <position-filter @filter="filter" />
    </div>
    <div class="content-card__header">
      <v-btn
      v-if="!(is_position_manager && only_position_manager)"
        color="primaryMain"
        class="primaryMainText--text"
        @click="goToCreate()"
      >
        <v-icon left> mdi-plus </v-icon>
        Add New
      </v-btn>
    </div>
    <v-data-table
      id="positionsTable"
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      :options.sync="options"
      :server-items-length="pagination.total"
      :items-per-page="pagination.per_page"
      :footer-props="{
        disablePagination: paginationDisable,
        'items-per-page-options': [5, 10, 20, 50],
      }"
    >
      <template v-slot:item.friendly_name="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a href="" v-bind="attrs" v-on="on" @click="goToEdit(item.id)">
              {{ item.friendly_name }}
            </a>
          </template>
          <span>View</span>
        </v-tooltip>
      </template>
      <template v-slot:item.company="{ item }">
        <span>{{ item.company.name  ? item.company.name  : null }}</span><br>
        <v-tooltip bottom v-if="item.company.length > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="pa-0"
              v-bind="attrs"
              v-on="on"
              text
              color="primary"
            >
              see more
            </v-btn>
          </template>
          <p class="ma-0"> 
            • {{company.name}}
          </p>
        </v-tooltip>
      </template>
      <template v-slot:item.specific_dealer="{ item }">
        <p>{{ item.specific_dealer ? item.specific_dealer.name : ''  }}</p>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <div>
            <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goToEdit(item.id)"
                    x-small
                    icon
                    color="warning"
                    class="mx-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </span>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="remove(item)"
                  x-small
                  color="error"
                  icon
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Remove</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PositionFilter from './PositionFilter.vue';
import { mapState } from 'vuex';
import { find as _find } from 'lodash';

export default {
  name: 'ProductsTable',
  components: {
    PositionFilter,
  },
  data() {
    return {
      loading: false,
      items: [],
      search: '',
      pagination: {
        total: 1,
        per_page: 10,
        current_page: 1,
        search: '',
        sortBy: '',
        sortDesc: false,
      },
      options: {},
      paginationDisable: false,
      timeout: null,
      filters: null,
    };
  },
  computed: {
    ...mapState("layout", ["labels"]),
    ...mapState('user', [
      'current_user',
      'is_super_admin',
      'is_account_manager',
      'is_position_manager',
      'only_position_manager'
    ]),
    dealerLabel() {
      if (this.labels.dealer === undefined) {
        return '';
      }
      return this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1);
    },
    headers() {
      return [
        { text: 'Name', value: 'friendly_name' },
        { text: 'Company', value: 'company' },
        { text: this.dealerLabel, value: 'specific_dealer' },
        { text: '', value: 'action', width: '180' },
      ]
    },
  },
  watch: {
    options: {
      handler() {
        this.changeTable();
      },
      deep: true,
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.paginationDisable = true;
      this.items = [];

      this.$http
        .get(`positions`, {
          params: {
            per_page: this.options.itemsPerPage,
            current_page: this.pagination.current_page,
            sortBy: this.pagination.sortBy,
            sortDesc: this.pagination.sortDesc,
            filter: this.filters,
          },
        })
        .then((data) => {
          this.loading = false;
          this.items = data.data.data;
          this.pagination.total = data.data.total;
          this.pagination.current_page = data.data.current_page;
          this.pagination.per_page = data.data.per_page;
          this.paginationDisable = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$errorNotif('Something went wrong');
        });
    },
    changeTable() {
      this.pagination.current_page = this.options.page;
      this.pagination.per_page = this.options.itemsPerPage;

      this.pagination.sortBy =
        this.options.sortBy !== undefined ? this.options.sortBy[0] : '';
      this.pagination.sortDesc =
        this.options.sortDesc !== undefined ? this.options.sortDesc[0] : '';

      this.fetch();
    },
    goToCreate() {
      this.$router.push({ name: this.$route.meta.type + '.create' });
    },
    goToEdit(id) {
      this.$router.push({
        name: this.$route.meta.type + '.edit',
        params: { id: id },
      });
    },
    remove(item) {
      let msg = {
        title: 'Remove ' + item.name + '?',
        confirmButtonText: 'Yes, Remove!',
      };

      this.$questionNotif(msg).then((result) => {
        if (result) {
          this.loading = true;
          this.$http.delete(`/positions/${item.id}`).then((data) => {
            this.loading = false;
            this.fetch();
            this.$successNotif('Successfully deleted position');
          });
        }
      });
    },
    filter(filters) {
      this.filters = filters;
      this.fetch();
    },
  },
};
</script>
