import Vue from 'vue'
import App from './App.vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import VueAuth from '@d0whc3r/vue-auth-plugin';
import Vuetify from 'vuetify';
import VueAxios from 'vue-axios'
import VueSession from 'vue-session';
import VueCookies from 'vue-cookies'
import axios from 'axios'
import router from './router'
import store from './store'
import mixin from './mixins/_mixin.js';
import VModal from 'vue-js-modal'
import Zendesk from '@dansmaculotte/vue-zendesk'
import VueQRCodeComponent from 'vue-qr-generator'

import 'font-awesome/css/font-awesome.min.css'
import './assets/css/style.styl';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import '@mdi/font/css/materialdesignicons.css' 

const instance = axios.create({
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

Vue.use(Zendesk, {
  key: 'b410c210-e499-4ebc-97a4-4e4d979f61f3',
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      color: {
        theme: '#000'
      }
    }
  }
})

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

const auth = {
  tokenStore: ['localStorage'],
  loginData: {
    url: '/auth/login',
    method: 'POST',
    redirect: null,
    headerToken: 'Authorization',
    fetchUser: true,
    customToken: (response) => response.data.access_token.accessToken,
  },
  fetchData: {
    url: '/auth/me',
    method: 'POST',
    interval: 10,
    enabled: true,
    customToken: (response) => response.data.access_token.accessToken,
  },
  refreshData: {
    url: '/auth/refresh',
    method: 'POST',
    interval: 30,
    enabled: true,
    customToken: (response) => response.data.access_token.accessToken,
  },
  logoutData: {
    url: '/auth/logout',
    method: 'POST',
    redirect: '/login',
    customToken: (response) => response.data.access_token.accessToken,
  },
};

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.component('qr-code', VueQRCodeComponent)

Vue.use(require('vue-moment'));
Vue.use(VueSession)
Vue.use(VueCookies)
Vue.use(VueAxios, instance);
Vue.use(VueAuth, auth);
Vue.use(Vuetify);
Vue.use(VModal)

Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#000',
          secondary: '#000',
          primaryMain: '#000',
          primaryMainText: '#000',
          primaryMainInverted: '#000',
        }
      }
    },
    icons: {
      iconfont: 'mdi'
    },
  }),
  router,
  store,
  render: h => h(App)
}).$mount('#app')
