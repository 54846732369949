<template>
    <v-form 
        ref="form" 
        @submit.prevent="forgotPassword()"  
        method="POST" 
    > 
        <v-alert
          dense
          outlined
          type="info"
          class="caption text-xs mx-8 my-0"
          >
          Please enter your email address to receive the password reset link
        </v-alert>
        <v-card-text class="login-card__field">
          <v-text-field
            autofocus
            v-model="form.email"
            outlined
            label="Email"
            prepend-inner-icon="mdi-email-outline"
            :rules="errors.email"
          ></v-text-field>

          <p class="error-not-exist caption red--text" v-if="errorNotExist">
            No user was found with this e-mail address. Please contact our support team at
            <a class="font-weight-bold" :href="'tel:+' + admin.contact">
                {{ admin.contact_format }}
            </a> or 
            <a class="font-weight-bold" :href="'mailto:' + admin.email">
                {{ admin.email }}
            </a>
          </p>
        </v-card-text>
        <v-card-actions class="login-card__action pa-0">
          <v-btn
          class="ml-3 mb-2"
            text
            @click="goBack()"
            :loading="loading"
          >
            Go Back to login
        </v-btn>
          <v-spacer/>
          <v-btn
            color="primaryMain"
            class="primaryMainText--text mb-3 mr-3"
            type="submit"
            :loading="loading"
          >
            Send Reset Link
        </v-btn>
        </v-card-actions>
    </v-form>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: 'ForgotPasswordContent',
  computed: {
    ...mapState("layout", [
      "admin",
    ]),
  },
  props: [
    'card_state'
  ],
  data() {
    return {
        form: {
            email: ''
        },
        loading: false,
        errors: [],
        errorNotExist: false
    }
  },
  methods: {
    forgotPassword() {
      this.errors = []
      this.errorNotExist = false
      this.loading = true
      this.$http  
        .post('auth/forgot-password', this.form)
        .then((data) => {
          this.$successNotif('Reset Password Email has been sent')
          this.loading = false
        })
        .catch((error) => {
          if (error.response.status == 422){

            if (error.response.data.errors == "email_not_exist") {
              this.errorNotExist = true
            } else {
              this.errors = error.response.data.errors
            }
          } else {
            this.$errorNotif("Something went wrong")
          }
          this.loading = false
        })
      
    },
    changeState() {
      this.$emit('state', this.card_state)
    },
    goBack() {
      this.$router.push({ name: 'Login'});
    }
  }
};
</script>