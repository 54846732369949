<template>
    <div class="my-3 pa-4">
      <v-alert
        dense
        class="caption mb-8"
        outlined
        v-if="showError"
        type="error"
      >
      {{ errorMsg }}
      </v-alert>
      <div class="text-center">
        <p class="caption">Recommended:  png, jpg, 2MB max per image size, 8 total images </p>
      </div>
      <div class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    :loading="loading"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="openFile()" 
                     >
                <v-icon dark small> mdi-upload</v-icon>
                    <input 
                        id="product-image-upload"
                        class="file-type" 
                        ref="productImageFileType" 
                        type="file" 
                        accept="image/*"
                        @change="uploadFile" 
                        multiple >
                </v-btn>
            </template>
            <span>upload</span>
          </v-tooltip>
      </div>
      
      <div class="my-5">
        <v-lazy
          class="list-group-item"
          :options="{
          threshold: .5
          }"
          min-height="200"
          transition="fade-transition"
        >
          <v-container class="product-gallery d-flex flex-wrap justify-center">
            <draggable :disabled="!enabledDrag" :list="images" class="gallery-list-group" handle=".gallery-handle">
              <v-hover class="gallery-handle" v-for="(media, index) in images" :key="index" >
                  <template v-slot:default="{ hover}">
                      <div>
                          <v-img
                          :aspect-ratio="16/9"
                          width="150"
                          class="ma-3"
                          :src="media.gallery_icon"
                          :lazy-src="media.gallery_icon" >
                              <v-fade-transition>
                                  <v-overlay
                                      v-if="hover"
                                      absolute
                                      class="gallery-img-hover" >

                                      <div class="gallery-close">
                                          <a href="javascript:void(0)" @click="destroy(media.id)">
                                              <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">
                                                  <v-icon 
                                                  v-bind="attrs"
                                                  v-on="on"
                                                  small class="red--text text--accent-3">mdi-close</v-icon>
                                                  </template>
                                                  <span>Remove</span>
                                              </v-tooltip>
                                          </a>
                                      </div>

                                      <div class="gallery-view">
                                         <a href="javascript:void(0)" @click="view(media.image_path)">
                                              <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">
                                                  <v-icon 
                                                      small
                                                      v-bind="attrs"
                                                      v-on="on" >mdi-magnify-expand</v-icon>
                                                  </template>
                                                  <span>View</span>
                                              </v-tooltip>
                                          </a>
                                      </div>
                                  </v-overlay>
                              </v-fade-transition>
                          </v-img>
                      </div>
                  </template>
              </v-hover>
              </draggable>
              <div class="text-center">
                  <p class="my-10 caption text-uppercase " v-if="images.length < 1">No Image</p>
              </div>
          </v-container>
        </v-lazy>
      </div>
      <gallery-viewer :path="viewerPath"></gallery-viewer>
    </div>
</template>


<script>
import draggable from 'vuedraggable'
import { mapState } from "vuex";
import { find as _find } from "lodash";
import GalleryViewer from "@/components/galleries/GalleryViewer";

export default {
  name: "ProductImges",
  components: {
    GalleryViewer,
    draggable
  },
  data() {
    return {
      showError: false,
      model: 'App\\Models\\Product',
      loading: false,
      errorMsg: '',
      limitErrorMsg: 'Product images maximum count must be 8 images only',
      viewerPath: '',
      enabledDrag: true
    };
  },
  computed: {
    ...mapState("product", [
      "currentProduct",
      "images"
    ]),
    productId() {
      return this.$route.params.id 
    },
    maxImages() {
      return this.images.length == 8
    }
  },
  watch: {
        images: {
          handler() {
              this.reorder()
          },
          deep: true,
        },
    },
  methods: {
    openFile() {
      if (this.maxImages) {
        this.errorMsg = this.limitErrorMsg
          this.showError = true
          setTimeout(() => {
            this.showError = false
        }, 5000);
        return false
      }
      $('#product-image-upload').trigger('click');
    },
    uploadFile(e) {
      let images = e.target.files;
      this.loading = true
      var form = new FormData();
      Array.from(images).forEach((value, index) => {
          form.append('image['+index+']', value)
      });

      form.append('id', this.productId);
      form.append('type', 'gallery_images');
      form.append('folder', 'galleries');
      form.append('model', this.model);
      form.append('max', 8);
      
      this.$http
          .post('/media', form)
          .then((result) => {
              this.$store.commit("product/SET_PRODUCT_IMAGES", result.data.images);
              this.loading = false
              
              if(result.data.showLimitError) {
                  this.errorMsg = this.limitErrorMsg
                  this.showError = true
                  setTimeout(() => {
                    this.showError = false
                }, 5000);
              }
          })
          .catch((error) => {
              this.errorMsg = "Only jpeg,png,gif images are allowed. Maximum allowed size is 2MB"
              this.showError = true
              setTimeout(() => {
                this.showError = false
            }, 5000);
              this.loading = false
          })
    },
    destroy(id) {
      let msg = {
          title: "Remove image?",
          confirmButtonText: "Yes, Remove!",
      }
      this.$questionNotif(msg)
      .then((result) => {
          if (result) {
          this.loading = true

          this.$http
          .delete(`/media/${id}`, {
            params: {
              modelId: this.productId,
              model: this.model,
            },
          })
          .then((result) => {
              this.$store.commit("product/SET_PRODUCT_IMAGES", result.data);
              this.loading = false
              this.$successNotif('Successfully deleted an Image')
          })
          .catch((error) => {});
        }
      })
    },
    view(path) {
      this.viewerPath = path
      this.$modal.show('image-viewer-modal');
    },
    reorder() {
      this.enabled = false
      this.loading = true
      let form = {
        'list': this.images,
        'model': this.model,
        'modelId': this.productId,
      }
      this.$http
          .put('media/reorder', form)
          .then((result) => {
              this.loading = false
              this.enabled = true
          })
          .catch((error) => {
              this.enabled = true
              this.loading = false
              this.$errorNotif("Something went wrong")
          })
    },
  },
};
</script>
