<template>
    <div class="mb-10">
      <v-layout class="d-flex justify-end mb-3">
        <div></div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" @click="resetFilter()"
              v-bind="attrs" fab dark x-small>
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </template>
          <span>Reset Filter</span>
        </v-tooltip>
      </v-layout>
      <v-layout row justify-center class="mt-1">
        <v-flex xs12 md12 lg12 class="px-3">
          <v-text-field
            v-model="search"
            outlined
            dense
            label="Search"
            clearable
        ></v-text-field>
        </v-flex>
      </v-layout>
    </div>
    </template>
    
    <script>
    import { mapState } from "vuex";
    
    export default {
      name: 'BillingPortalFilter',
      components: {
      },
      data () {
        return {
            timeout: null,
            loading: false,
            search: '',
            filters: {
              search: null,
            },
        }
      },
      computed: {
         ...mapState("user", [
              'is_super_admin', 
              'is_account_manager', 
          ]),
      },
      watch: {
        filters: {
          handler: function (after, before) {
            this.$emit('filter', this.filters)
          },
        deep: true,
        },
        search() {
          this.renderSearch()
        }
      },
      created() {
      },
      methods: {
        renderSearch() {
          clearTimeout(this.timeout);
    
          this.timeout = setTimeout(() => {
            this.filters.search = this.search
          }, 1000);
        },
        resetFilter() {
            this.filters.search = null
            this.search = null
        }
      }
    };
    </script>
    