<template>
  <modal
    name="import-modal"
    width="1200"
    height="auto"
    :clickToClose="false"
    :adaptive="true" >
        <div class="pa-5 align-center">
        <div class="d-flex justify-space-between ">
          <h2 class="title primary--text">Import User</h2>
          <v-btn
            icon
            small
            color="black"
            @click="cancel()"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      </div>
      <v-divider/>
      <div>
        <import-stepper></import-stepper>
      </div>
  </modal>
</template>

<script>
import {
  find as _find
} from 'lodash-es'
import ImportStepper from '@/components/import-export-users/ImportStepper'
import importExportUsers from '@/mixins/importExportUsers'

export default {
  name: 'ImportDialog',
  components: { ImportStepper },
  mixins: [importExportUsers],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    cancel() {
      this.$modal.hide('import-modal')
    },
  }
}
</script>

<style scoped>

</style>