<template>
    <div class="content">
        <breadcrumbs :links="breadcrumbs"/>

        <div class="content-wrapper">
            <h1 class="font-weight-medium">{{ $route.meta.title }}
                <span class="title font-weight-thin">Create</span>
            </h1>

            <v-layout row justify-center class="mt-5">
                <v-flex xs12 md5 lg4 class="px-4 content-image-section">
                    <v-card class="mb-5 pa-5 content-image-section__card">
                        <v-alert
                            dense
                            class="caption text-left"
                            outlined
                            v-if="showError"
                            type="error"
                        >
                        {{ errorMsg }}
                        </v-alert>
                        <v-img
                            contain
                            class="content-image"
                            :src="image"
                            :lazy-src="image"
                            max-width="500"
                            max-height="300"
                            >
                            <template v-slot:placeholder>
                                <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <div class="img-option plain-btn-group">
                            <v-btn
                                block
                                elevation="2"
                                depressed
                                color="primaryMain"
                                dark
                                class="mt-5 primaryMainText--text"
                                @click="openFile()" > Upload Avatar </v-btn>
                            <input class="file-type" ref="filetype" type="file" @change="previewImage">
                        </div>
                    </v-card>
                </v-flex>
                <v-flex xs12 md7 lg8 class="px-4 content-image" >
                    <form method="POST">
                        <v-card class="pa-4 d-flex flex-column" min-height="632">
                            <h2 class="title">Information</h2>
                            <v-divider/>
                            <v-container class="my-5">
                                <v-text-field
                                    label="Name"
                                    placeholder="Name"
                                    outlined
                                    required
                                    dense
                                    v-model="form.name"
                                    :error-messages="errors.name"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Name
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    label="Email"
                                    placeholder="Email"
                                    outlined
                                    required
                                    dense
                                    v-model="form.email"
                                    :error-messages="errors.email"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Email
                                    </template>
                                </v-text-field>
                                <vue-phone-number-input
                                    v-model="form.phone_number"
                                    class="mb-3"
                                    no-example
                                    :only-countries="['US', 'PH']"
                                    :color="$vuetify.theme.themes.light.primary"
                                    valid-color="#004df9"
                                    :default-country-code="form.phone_country"
                                    @update="formatPhoneNo($event)"
                                />
                                 <p class="phone-number-error caption red--text ml-4 mb-2">
                                    <span v-if="isPhoneNoError || errors.phone_number">Phone number is invalid, please input correct phone number</span>
                                    <span v-else class="white--text">Phone Number</span>
                                </p>
                                <v-select
                                    v-model="form.status"
                                    :items="statusItems"
                                    label="Status"
                                    item-text="name"
                                    item-value="value"
                                    outlined
                                    dense
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :rules="errors.status"
                                ></v-select>
                                <v-autocomplete
                                    :disabled="disableCompanyField"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    label="Company"
                                    required
                                    dense
                                    v-model="form.company_id"
                                    :items="company_list"
                                    autocomplete="off"
                                    :rules="errors.company_id"
                                    @change="changeCompanyField()"
                                    @blur="outFocusCompanyField()"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Company
                                    </template>
                                    <template v-slot:item="data">
                                        <div class="dropdown-subvalue">
                                            <span>
                                            {{ data.item.name }}
                                            </span>
                                            <span v-if="data.item.is_automotive">
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="subvalue" v-bind="attrs"
                                                        v-on="on">mdi-car-convertible</v-icon>

                                                    </template>
                                                    <span>Automotive Company</span>
                                                </v-tooltip>
                                            </span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                                <span class="d-flex" v-if="hideDealerField">
                                    <v-autocomplete
                                        :disabled="disableDealerField"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        :label="dealerTitle"
                                        v-model="form.specific_dealer_id"
                                        :items="specific_dealers"
                                        autocomplete="off"
                                        clearable
                                        autofocus
                                        :loading="loadDealer"
                                        @change="changeDealerField()"
                                        :rules="errors.specific_dealer_id"
                                        :no-data-text="loadDealer ? 'Loading' : 'No data available'"
                                    >
                                        <template v-slot:append-item>
                                            <div v-if="!loadedAllSpecificDealer" v-intersect="onIntersectSpecificDealer" class="pa-4 primary--text">
                                                Loading more items ...
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom v-if="!disableDealerField">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="showCreateDealerModal()"
                                                color='primary'
                                                icon
                                                v-bind="attrs"
                                                v-on="on">
                                                <v-icon>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add {{ dealerTitle }}</span>
                                    </v-tooltip>
                                </span>

                                <span class="d-flex">
                                    <v-autocomplete
                                        :required="is_position_manager && this.only_position_manager"
                                        :disabled="disablePositionField"
                                        item-text="friendly_name"
                                        item-value="id"
                                        outlined
                                        dense
                                        label="Position"
                                        v-model="form.position_id"
                                        :items="positions"
                                        autocomplete="off"
                                        clearable
                                        :loading="loadPosition"
                                        :rules="errors.position_id"
                                        :search-input.sync="positionSearch"
                                        :no-data-text="loadPosition ? 'Loading' : 'No data available'"
                                    >
                                        <template v-slot:append-item>
                                            <div v-if="!loadedAllPosition" v-intersect="onIntersectPosition" class="pa-4 primary--text">
                                                Loading more items ...
                                            </div>
                                        </template>
                                        <template v-slot:item="data">
                                        <div class="dropdown-subvalue">
                                            <span>
                                            {{ data.item.friendly_name }}
                                            </span>
                                            <span v-if="data.item.specific_dealer">
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="subvalue" v-bind="attrs"
                                                        v-on="on">mdi-handshake</v-icon>

                                                    </template>
                                                    <span>{{ data.item.specific_dealer.name }}</span>
                                                </v-tooltip>
                                            </span>
                                        </div>
                                    </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom v-if="!(is_position_manager && only_position_manager)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="showCreatePositionModal()"
                                                color='primary'
                                                icon
                                                v-bind="attrs"
                                                v-on="on">
                                                <v-icon>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add Position</span>
                                    </v-tooltip>
                                </span>

                                <span v-if="isOnlyLearner" class="d-flex">
                                  <v-autocomplete
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      label="Team Leader"
                                      v-model="form.team_leader_id"
                                      :items="managers"
                                      autocomplete="off"
                                      clearable
                                      :loading="loadManager"
                                      :rules="errors.team_leader_id"
                                      :no-data-text="loadManager ? 'Loading' : 'No data available'"
                                  >
                                  </v-autocomplete>
                                </span>

                                <span class="d-flex">
                                    <v-autocomplete
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        dense
                                        label="Assign to Group"
                                        v-model="form.groups"
                                        :items="groups"
                                        autocomplete="off"
                                        clearable
                                        :loading="loadGroup"
                                        :rules="errors.groups"
                                        :search-input.sync="groupSearch"
                                        :no-data-text="loadGroup ? 'Loading' : 'No data available'"
                                    >
                                        <template v-slot:append-item>
                                            <div v-if="!loadedAllGroup" v-intersect="onIntersectGroup" class="pa-4 primary--text">
                                                Loading more items ...
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="showCreateGroupModal()"
                                                color='primary'
                                                icon
                                                v-bind="attrs"
                                                v-on="on">
                                                <v-icon>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add Group</span>
                                    </v-tooltip>
                                </span>

                                <h4 class="subtitle-2">Roles</h4>
                                <div class="role-loader" v-show="loadRole">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </div>
                                <div v-show="!loadRole">
                                    <v-checkbox
                                        v-for="(role, index) in roles"
                                        :key="index"
                                        :label="role.friendly_name"
                                        color="primary"
                                        v-model="form.roles"
                                        :value="role.id"
                                        hide-details
                                        v-show="role.value"
                                    >
                                        <template #label>
                                            <v-tooltip bottom :disabled="!role.description">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-on="on" v-bind="attrs">{{ role.friendly_name }}</span>
                                                </template>
                                                <span>{{ role.description }}</span>
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-container>
                            <v-spacer/>
                            <v-container>
                              <h4 class="subtitle-2">Notification Settings</h4>
                              <v-checkbox
                                  :label="emailOptedOut.label"
                                  v-model="form.email_opted_out"
                                  :value="emailOptedOut.value"
                                  color="primary"
                                  hide-details
                              >
                                  <template #label>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on, attrs }">
                                              <span v-on="on" v-bind="attrs">{{ emailOptedOut.label }}</span>
                                          </template>
                                          <span>{{ emailOptedOut.label }}</span>
                                      </v-tooltip>
                                  </template>
                              </v-checkbox>
                            </v-container>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                color="primaryMain"
                                dark
                                @click="submit()"
                                class="mt-5 primaryMainText--text"
                                :loading="loading"
                                > Submit </v-btn>
                            </v-card-actions>
                        </v-card>
                    </form>
                </v-flex>
            </v-layout>
        </div>
        <restrict-create-modal @confirm="submit()"/>
        <restore-message-modal :user="deletedUser" @createUser="submit()"/>
        <create-dealer-modal :companyId="this.selectedCompany" @refreshDealer="dealerFieldRefresh()"></create-dealer-modal>
        <create-position-modal :companyId="this.selectedCompany" :dealerId="this.selectedDealer" @refreshPosition="positionFieldRefresh()"></create-position-modal>
        <create-group-modal :companyId="this.selectedCompany" :dealerId="this.selectedDealer" @refreshGroup="groupFieldRefresh()"></create-group-modal>
    </div>
</template>

<script>
import Vue from 'vue'
import RestrictCreateModal from '@/components/users/RestrictCreateModal';
import Breadcrumbs from '@/components/Breadcrumbs';
import RestoreMessageModal from '@/components/users/RestoreMessageModal';
import CreateDealerModal from '@/components/users/CreateDealerModal';
import CreatePositionModal from '@/components/users/CreatePositionModal';
import CreateGroupModal from '@/components/users/CreateGroupModal';
import {
    find as _find,
    some as _some,
    filter as _filter,
    includes as _includes,
} from 'lodash';
import imageImport from '@/assets/images/empty-img.png'
import { mapState } from "vuex";

Vue.use(require('vue-moment'));

export default {
    computed: {
        ...mapState("layout", [
            "defaultImage","companyIcon","labels"
        ]),
        ...mapState("user", [
            "current_user",
            'is_super_admin',
            'is_account_manager',
            'is_dealer_manager',
            'only_position_manager',
            'is_position_manager',
            'managing_positions',
            'restrict_create'
        ]),
        company_list() {
            return this.$store.state.company.company_list;
        },
        isNonAuto() {
            return !this.current_user.company.is_automotive
        },
        selectedCompany() {
            return this.form.company_id ? this.form.company_id : this.current_user.company_id;
        },
        selectedDealer() {
            return this.form.specific_dealer_id ? this.form.specific_dealer_id : this.current_user.specific_dealer_id;
        },
        selectedCompanyDetails() {
            return _find(this.company_list, {
                id: this.selectedCompany,
            });
        },
        loadedAllSpecificDealer() {
            return this.specific_dealers.length >= this.specificDealerSetting.total
        },
        loadedAllPosition() {
            return this.positions.length >= this.positionSetting.total
        },
        loadedAllGroup() {
            return this.groups.length >= this.groupsSettings.total
        },
        selectedRole() {
            return this.form.roles
        },
        isSuperAdminSelected() {
            return _includes(this.form.roles, this.superAdmin.id);
        },
        isOnlyLearner() {
            if(!this.form.roles.length || this.form.roles.length > 1){
              return false
            }

            const learnerRole = this.roles.find((role) => role.name === "salesperson")

            return this.form.roles[0] === learnerRole.id
        },
        hideDealerField() {
            if (this.selectedCompanyDetails == undefined) {
                return !this.hideSpecificDealer
            }

            return !this.hideSpecificDealer && this.selectedCompanyDetails.is_automotive
        },
        disableCompanyField() {
            return !this.is_super_admin
        },
        disableDealerField() {
            return !this.is_super_admin && !this.is_account_manager
        },
        disablePositionField() {
            return !this.is_super_admin && !this.is_account_manager && !this.is_dealer_manager
        },

        renderRoleCompany() {
            return [this.roles, this.selectedCompanyDetails]
        },

        //Roles
        superAdmin() {
            return this.findRole('super-administrator')
        },
        accountManager() {
            return this.findRole('account-manager')
        },
        secretShopper() {
            return this.findRole('secret-shopper')
        },
        dealerManager() {
            return this.findRole('specific-dealer-manager')
        },
        salesperson() {
            return this.findRole('salesperson')
        },
        csm() {
            return this.findRole('csm')
        },
        dealerTitle() {
            return this.labels !== undefined && this.labels.dealer !== undefined ? this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1) : '';
        }
    },
    data() {
        return {
            trueCarCentralId: '16deade7-d7d9-49bb-a3d1-ff3eba8cc153',
            errors: [],
            loading: false,
            loadRole: false,
            loadPosition: false,
            loadGroup: false,
            loadManager: false,
            loadDealer: false,
            breadcrumbs: {
                '0': {
                    title: 'Home',
                    link: 'home',
                },
                '1': {
                    title: this.$route.meta.title,
                    link: this.$route.meta.type,
                },
                '2': {
                    title: 'Create',
                    link: this.$route.meta.type+'.create',
                }
            },
            positionSearch: '',
            groupSearch: '',
            renderPosition: false,
            renderManagers: false,
            image: imageImport,
            form: {
                image: '',
                name: '',
                email: '',
                position_id: null,
                company_id: '',
                specific_dealer_id: null,
                phone_number: '',
                phone_code: '',
                phone_country: '',
                status: '',
                roles: [],
                email_opted_out: 0,
                team_leader_id: null,
                groups: []
            },
            isPhoneNoError: true,
            nationalNumber: '',
            roles: [],
            positions: [],
            managers: [],
            groups: [],
            specific_dealers: [],
            specificDealerSetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            positionSetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            managersSettings: {
                page: 1,
                per_page: 20,
                total: ''
            },
            groupsSettings: {
                page: 1,
                per_page: 20,
                total: ''
            },
            hideSpecificDealer: false,
            hidePosition: false,
            statusItems: [
                {
                    name: 'Active',
                    value: 'ACTIVE',
                },
                {
                    name: 'Under Review',
                    value: 'UNDER_REVIEW',
                },
                {
                    name: 'Inactive',
                    value: 'INACTIVE',
                },
                {
                    name: 'Rejected',
                    value: 'REJECTED',
                }
            ],
            deletedUser: [],
            showError: false,
            emailOptedOut: {
              label: 'Opt-out from email notification',
              value: 1
            },
        };
    },
    watch: {
        renderRoleCompany() {
            if (this.roles.length > 0 && this.selectedCompanyDetails) {
                this.rolesCondition()
            }
        },
        positionSearch() {
            this.positionFieldRefresh();
        },
        groupSearch() {
            if (this.groupSearch) {
                this.groupFieldRefresh();
            }
        },
        companyIcon(value) {
            this.image = value
        },
        selectedCompany() {
            this.specificDealerSetting.page = 1;
            this.specific_dealers = []
            this.fetchSpecificDealer()
            this.checkAutomotiveCompany()
            this.positions = [];
        },
        roles() {
            this.renderInitialRole()
        },
        selectedRole() {
            let isHide = this.isSuperAdminSelected
            this.hideSpecificDealer = isHide
            this.hidePosition = isHide

            if (this.form.roles.length == 0) {
                this.renderInitialRole()
            }
        },
        selectedDealer() {
            this.fetchManagers()

            this.form.team_leader_id = null
        },
        isOnlyLearner(){
          this.form.team_leader_id = null
        },
        managing_positions(){
            this.renderInitialPosition()
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.loadPosition = true;
            this.loadGroup = true;
            this.checkPositionManager()
            this.$store.dispatch('company/getList')
            this.fetchRoles()
            this.fetchManagers()
            this.fetchGroups()
            this.renderInitialCompany()
            this.fetchSpecificDealer()
            this.renderInitialStatus()
            this.image = this.companyIcon

            if (this.isNonAuto) {
                this.hideSpecificDealer = true
            }

        },
        checkPositionManager() {
            this.$http
            .get('user/check-position-manager', {
                params: {
                    user_id: this.current_user.id,
                },
            })
            .then(({data}) => {
                this.$store.commit('user/SET_CURRENT_USER_POSITION_MANAGER', data)
                this.renderPosition = true
                this.fetchPosition()
                this.renderInitialPosition()
            })
            .catch((error) => {
            });
        },

        renderInitialPosition() {
            if (this.managing_positions.length == 1) {
                this.form.position_id = this.managing_positions[0].id
            }
        },
        renderInitialRole() {
            this.form.roles.push(this.salesperson.id)
        },
        checkAutomotiveCompany() {
            if (this.isSuperAdminSelected) {
                this.hideSpecificDealer = true
                this.form.specific_dealer_id = null
                return
            }

            if (!this.selectedCompanyDetails.is_automotive) {
                this.hideSpecificDealer = true
                this.form.specific_dealer_id = null

            } else {
                this.hideSpecificDealer = false
            }
        },
        rolesCondition() {
            if (this.is_super_admin) {
                this.superAdmin.value = true
            } else {
                this.superAdmin.value = false
            }

            if (this.secretShopper !== undefined) {
                this.secretShopper.value = this.is_super_admin ? true : false
            }

            this.accountManager.value = this.is_account_manager || this.is_super_admin  ? true : false

            //only show csm if truecar
            if (this.csm !== undefined) {
                this.csm.value = this.selectedCompanyDetails.central_id != this.trueCarCentralId ? false : true
            }

            //hide dealer manager for non automotive
            this.dealerManager.value = Boolean(this.selectedCompanyDetails.is_automotive) ? true : false

            //hide dealer manager if dealer field is empty
            this.dealerManager.value = this.form.specific_dealer_id == null || this.form.specific_dealer_id == '' ? false : true

            if (this.form.specific_dealer_id == null || this.form.specific_dealer_id == '') {
                this.dealerManager.value = false
                this.removeManagerOnRole()
            } else {
                this.dealerManager.value = true
            }
            this.loadRole = false
        },
        findRole(role){
            return _find(this.roles, {
                name: role,
            });
        },
        fetchRoles() {
            this.$store.commit('global/SET_BREADCRUMBS_LOADER', true)
            this.loadRole = true
            this.$http
            .get('roles')
            .then((result) => {
                this.roles = result.data
                this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)
            })
        },

        // company Setup
        renderInitialCompany() {
            this.form.company_id = this.selectedCompany
        },

        // Specific Dealer Setup
        fetchSpecificDealer() {
            this.loadDealer = true
            this.$http
            .get('specific-dealer', {
                params: {
                    per_page: this.specificDealerSetting.per_page,
                    current_page: this.specificDealerSetting.page,
                    company_id: this.selectedCompany,
                },
            })
            .then((result) => {
                this.specific_dealers = [
                    ...this.specific_dealers,
                    ...result.data.data
                ]

                this.specificDealerSetting.total = result.data.total
                this.loadDealer = false
                this.renderInitialDealer()
            })
        },
        renderInitialDealer() {
            if (this.selectedCompany == this.current_user.company_id) {
                this.form.specific_dealer_id = this.selectedDealer
            }
            this.rolesCondition()
        },

        onIntersectSpecificDealer() {
            this.specificDealerSetting.page += 1
            this.fetchSpecificDealer()
        },

        dealerFieldRefresh() {
            this.specificDealerSetting.page = 1
            this.specificDealerSetting.total = ''
            this.fetchSpecificDealer()
        },


        //Position setup
        fetchPosition() {
            let filter = {
                search: this.positionSearch
            }
            this.loadPosition = true
            this.$http
            .get('positions', {
                params: {
                    per_page: this.positionSetting.per_page,
                    current_page: this.positionSetting.page,
                    company_id: this.selectedCompany,
                    specific_dealer_id: this.selectedDealer,
                    company_only: false,
                    only_position_manager: this.only_position_manager,
                    managing_positions: this.only_position_manager ? this.managing_positions : [],
                    filter: filter
                },
            })
            .then((result) => {
                this.positions = [
                    ...this.positions,
                    ...result.data.data
                ]
                this.loadPosition = false
                this.positionSetting.total = result.data.total
            })
        },
        fetchGroups() {
            let filter = {
                search: this.groupSearch
            }
            this.loadGroup = true
            this.$http
            .get('groups', {
                params: {
                    per_page: this.groupsSettings.per_page,
                    current_page: this.groupsSettings.page,
                    company_id: this.selectedCompany,
                    specific_dealer_id: this.selectedDealer,
                    filter: filter
                },
            })
            .then((result) => {
                this.groups = [
                    ...this.groups,
                    ...result.data.data
                ]
                this.loadGroup = false
                this.groupsSettings.total = result.data.total
            })
        },
        fetchManagers() {
            this.loadManager = true
            this.$http
            .get('user/get-admins', {
              params: {
                  company_id: this.selectedCompany,
                  specific_dealer_id: this.selectedDealer,
                  role: this.selectedDealer ? 'specific-dealer-manager' : 'account-manager',
              },
            })
            .then((result) => {
                this.managers = result.data
                this.loadManager = false
            })
        },

        onIntersectPosition() {
            this.positionSetting.page += 1
            this.fetchPosition()
        },
        onIntersectGroup() {
            this.groupsSettings.page += 1
            this.fetchGroups()
        },
        positionFieldRefresh() {
            this.positionSetting.page = 1
            this.positionSetting.total = ''
            this.positionSearch = ''
            if (this.renderPosition) {
                this.fetchPosition()
            }
        },

        groupFieldRefresh() {
            this.groupsSettings.page = 1
            this.groupsSettings.total = ''
            this.fetchGroups()
        },

        renderInitialStatus() {
            let defaultStatus = _find(this.statusItems, {
                value: 'ACTIVE',
            });
            this.form.status = defaultStatus
        },

        formatPhoneNo(event) {
            this.isPhoneNoError = false

            if (event.phoneNumber == undefined) {
                return this.isPhoneNoError;
            }

            if (event.phoneNumber && !event.isValid) {
                return this.isPhoneNoError = true
            }

            this.form.phone_country = event.countryCode
            this.form.phone_code = '+' + event.countryCallingCode
            this.nationalNumber = event.nationalNumber
            this.form.phone_number = this.nationalNumber
        },

        openFile() {
           $('.file-type').trigger('click');
        },
        previewImage(e) {
            const file = e.target.files[0];
            this.form.image = e.target.files[0]
            this.image = URL.createObjectURL(file);
        },
        submit() {
            if (this.is_position_manager && this.only_position_manager && !this.form.position_id) {
                this.$errorNotif('Position field is required')
                return false
            }

            if (this.isPhoneNoError) {
                this.$errorNotif('Invalid inputs, Please check the details')
                return false
            }
            this.loading = true


            if (!this.hideDealerField) {
                this.removeManagerOnRole()
                this.form.specific_dealer_id = null
            }


            this.errors = []
            let data = new FormData();

            data.append('image', this.form.image);
            data.append('name', this.form.name);
            data.append('email', this.form.email);
            data.append('position_id', this.form.position_id);
            data.append('company_id', this.form.company_id);
            data.append('status', this.form.status);
            data.append('roles', this.form.roles);
            data.append('specific_dealer_id', this.form.specific_dealer_id);
            data.append('email_opted_out',this.form.email_opted_out ? this.form.email_opted_out : 0)
            data.append('team_leader_id',this.form.team_leader_id)
            data.append('groups',JSON.stringify(this.form.groups))

            if (this.form.phone_number) {
                data.append('phone_number', this.form.phone_number);
                data.append('phone_code', this.form.phone_code);
                data.append('phone_country', this.form.phone_country);
            }

            this.$http
            .post(`/user`, data)
            .then((result) => {
                this.loading = false

                if (result.data.status == 'restrict_create') {
                    this.$modal.show('restrict-create-modal')
                    this.$store.commit('user/SET_RESTRICT_CREATE', result.data);
                    return;
                }

                this.$successNotif('Successfully created a user')
                this.resetForm()

            })
            .catch((error) => {
                if (error.response.status == 422){
                    this.errors = error.response.data.errors
                    this.$errorNotif('Invalid inputs, Please check the details')
                }

                if ((error.response.status == 422  && error.response.data.errors.image) || error.response.status == 413){
                    this.errorMsg = "Only jpeg,png,gif images are allowed. Maximum allowed size is 2MB"
                    this.showError = true
                    setTimeout(() => {
                        this.showError = false
                    }, 5000);
                }

                if (error.response.status == 403 && error.response.data.existing_deleted_user){
                    this.deletedUser = error.response.data.deleted_user
                    this.$modal.show('restore-message-modal')
                }

                this.loading = false
            })
        },
        changeCompanyField() {
            this.form.specific_dealer_id = ''
            this.form.position_id = ''
            this.specific_dealers = []
            this.positions = []
            this.positionFieldRefresh()
            this.dealerFieldRefresh()
            this.rolesCondition()

            if (!this.selectedCompanyDetails.is_automotive) {
                this.removeManagerOnRole()
                this.hideSpecificDealer = false
            }
        },
        changeDealerField() {
            this.rolesCondition()
            this.removeManagerOnRole()
            this.positions = []
            this.form.position_id = ''
            this.positionFieldRefresh()
        },
        resetForm() {
            this.form.image = ''
            this.form.name = ''
            this.form.email = ''
            this.form.position_id = ''
            this.form.team_leader_id = null

            this.form.phone_number = ''
            this.form.phone_code = ''
            this.form.phone_country = ''
            this.form.roles = []
            this.image = this.defaultImage

            if (!this.disableDealerField) {
                this.form.specific_dealer_id = null
            }
        },
        showCreateDealerModal() {
            this.$modal.show('create-dealer-modal')
        },
        showCreatePositionModal() {
            this.$modal.show('create-position-modal')
        },
        showCreateGroupModal() {
            this.$modal.show('create-group-modal')
        },
        outFocusCompanyField() {
            if (this.form.company_id == null) {
                this.form.company_id = this.selectedCompany
            }
        },
        removeManagerOnRole(){
            if (_includes(this.form.roles, this.dealerManager.id)) {
                this.form.roles = this.form.roles.filter(role => {
                    return role != this.dealerManager.id;
                })
            }
        },
    },
    components: {
        Breadcrumbs,
        RestoreMessageModal,
        CreateDealerModal,
        CreatePositionModal,
        RestrictCreateModal,
        CreateGroupModal
    },
}
</script>
