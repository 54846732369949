<template>
    <div>

        <v-data-table id="BillingPortalTable" :headers="headers" :items="items" class="elevation-1" :loading="loading"
            loading-text="Loading... Please wait" :options.sync="options" :server-items-length="pagination.total"
            :items-per-page="pagination.per_page" :footer-props="{
                disablePagination: paginationDisable,
                'items-per-page-options': [5, 10, 20, 50],
            }">

            <template v-slot:item.created_at="{ item }">
                <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>

            <template #item.amount="{ item }">
                {{ item.currency}} {{ item.amount }}
            </template>

            <template #item.status="{ item }">
                <v-chip 
                    v-if="item.status == 'paid'" 
                    class="ma-2" 
                    color="green" 
                    text-color="white"
                    >
                    <v-icon class="mr-1" small>mdi-check</v-icon> {{ item.status }}
                </v-chip>

                <v-chip 
                    v-else class="ma-2" 
                    color="red" 
                    text-color="white"
                    >
                    <v-icon class="mr-1" small>mdi-close</v-icon> {{ item.status }}
                </v-chip>
            </template>

            <template #item.pdf_link="{ item }">
                <a :href="item.pdf_link"><v-icon>mdi-cloud-arrow-down-outline</v-icon></a>
            </template>

            <template #item.invoice_link="{ item }">
                <a :href="item.invoice_link" target="_blank"><v-icon>mdi-file-eye-outline</v-icon></a>
            </template>

        </v-data-table>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import { find as _find } from "lodash";

export default {
    name: "BillingPortalTable",
    components: {
    },
    data() {
        return {
            loading: false,
            headers: [
                { text: "Invoice", value: "invoice_number", width: '40%' },
                { text: "Amount", value: "amount" },
                { text: "Date", value: "created_at" },
                { text: "Status", value: "status" },
                { text: "", value: "pdf_link" },
                { text: "", value: "invoice_link" },
            ],
            items: [],
            search: "",
            pagination: {
                total: 1,
                per_page: 5,
                current_page: 1,
                search: "",
                sortBy: "",
                sortDesc: false,
            },
            options: {},
            paginationDisable: false,
            timeout: null,
            filters: null,
        };
    },
    computed: {
        ...mapState("user", [
            "current_user",
            "is_super_admin",
            "is_account_manager",
        ]),
    },
    watch: {
        options: {
            handler() {
                this.changeTable();
            },
            deep: true,
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            this.paginationDisable = true;
            this.items = [];



            this.$http
                .get(`billing/invoice/`, {
                    params: {
                        per_page: this.options.itemsPerPage,
                        current_page: this.pagination.current_page,
                        sortBy: this.pagination.sortBy,
                        sortDesc: this.pagination.sortDesc,
                        userId: this.$route.params.id || null,
                        filter: this.filters,
                        redirectBack: this.$route.path,
                    },
                })
                .then((data) => {
                    this.loading = false;
                    this.items = data.data.data;
                    this.pagination.total = data.data.total;
                    this.pagination.current_page = data.data.current_page;
                    this.pagination.per_page = data.data.per_page;
                    this.paginationDisable = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$errorNotif("Something went wrong");
                });
        },
        changeTable() {
            this.pagination.current_page = this.options.page;
            this.pagination.per_page = this.options.itemsPerPage;

            this.pagination.sortBy =
                this.options.sortBy !== undefined ? this.options.sortBy[0] : "";
            this.pagination.sortDesc =
                this.options.sortDesc !== undefined ? this.options.sortDesc[0] : "";

            this.fetch();
        },
        redirecToPortal(item) {
            window.open(item.billing_redirect);
        },
        filter(filters) {
            this.filters = filters;
            this.fetch();
        },
    },
};
</script>
  