<template>
  <div class="content content-full product">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">
        {{ isAddsOnPage ? 'Add ons' : $route.meta.title }}
        <span class="title font-weight-thin">Create</span>
      </h1>

      <v-layout row class="mt-5">
        <v-flex xs12 lg12 class="px-4 content-image">
          <form @submit.prevent="submit()" method="PUT">
            <v-card class="pa-4 d-flex flex-column" min-height="400">
              <h2 class="title">Information</h2>
              <v-divider />

              <v-container class="my-5 content-container">

                <tier-prices :error="errors.tiers" v-if="isUsageBased" @tiers="handleTiers"></tier-prices>

                <v-select
                  v-if="!isUsageBased"
                  ref="select"
                  :disabled="isAddsOnPage"
                  id="createProductPage-select-type"
                  v-model="form.type"
                  :items="typeItems"
                  label="Type"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  required
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="errors.type"
                  item-disabled="disabled"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Type
                  </template>
                </v-select>
                <v-text-field
                  v-if="!isUsageBased"
                  id="createProductPage-textfield-name"
                  label="Name"
                  placeholder="Name"
                  outlined
                  dense
                  required
                  v-model="form.name"
                  :rules="errors.name"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Name
                  </template>
                </v-text-field>
                <v-textarea
                  v-if="!isUsageBased"
                  id="createProductPage-textfield-description"
                  label="Description"
                  placeholder="Description"
                  outlined
                  no-resize
                  counter="250"
                  maxlength="250"
                  dense
                  rows="3"
                  v-model="form.description"
                  :rules="errors.description"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span
                    >Description
                  </template>
                </v-textarea>
            

                <v-select
                  v-if="!isContactUs && !isUsageBased"
                  id="createProductPage-select-billingType"
                  v-model="form.billing_type"
                  :items="billingTypeItems"
                  label="Type"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  required
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="errors.billing_type"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Billing
                    Type
                  </template>
                </v-select>

                <v-select
                id="createProductPage-select-client"
                  :loading="appListLoading"
                  v-if="isEnterprise && !emptyAppList"
                  v-model="form.client_id"
                  label="Apps"
                  :items="appList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  required
                  clearable
                  autofocus
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="errors.apps"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Apps
                  </template>
                  <template slot="no-data" v-if="emptyAppList">
                    <div>
                      <span class="ma-5 caption font-weight-bold">No data available</span> <br>
                      <span class="ma-5 caption">All Apps has been created an active product</span>
                    </div>
                  </template>
                </v-select>
                <div v-if="isEnterprise && emptyAppList" class="mb-5">
                  <span class="caption font-weight-bold error--text">No Apps available</span> <br>
                  <span class="caption error--text">All Apps has been created an active product</span>
                </div>


                <v-select
                  id="createProductPage-select-course"
                  v-if="isSingleUser"
                  :loading="courseListLoading"
                  v-model="form.course_id"
                  label="Course"
                  :items="courseList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  required
                  clearable
                  autofocus
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="errors.course_id"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Course
                  </template>
                </v-select>

                <v-checkbox
                  v-model="form.active_status"
                  class="mt-0  mb-5"
                  label="Active"
                  color="primary"
                  hide-details
              ></v-checkbox>

              <div class="d-flex align-center justify-space-between" v-if="isEnterprise">
                <div>
                  <v-checkbox
                      :disabled="disableApplyUserLimit"
                      v-model="form.has_user_license"
                      class="mt-0  mb-5"
                      label="Apply User Limit"
                      color="primary"
                      hide-details
                  ></v-checkbox>
                  <p class="caption" v-if="disableApplyUserLimit">
                    User Limit's Tier details are not set. Set the Tier details
                  </p>
                </div>
                <v-btn
                  @click="showTierDetails"
                  color="primaryMain"
                  dark
                  small
                  class="mb-5 primaryMainText--text"
                >
                {{ disableApplyUserLimit ? 'Set' : 'Show' }} Tier Details 
                </v-btn>
              </div>

                <v-autocomplete
                    v-if="isAddsOn"
                    :disabled="isAddsOnPage"
                    :loading="productListLoading"
                    v-model="form.product_id"
                    :items="productList"
                    outlined
                    label="Product"
                    dense
                    item-text="name"
                    item-value="id"
                    bottom
                    autocomplete="off"
                >
                </v-autocomplete>

                <v-autocomplete
                  id="createProductPage-select-product-action"
                  v-if="isAddsOn"
                  :loading="productActionListLoading"
                  v-model="form.system_actions"
                  label="Product Action"
                  :items="productActionList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  autofocus
                  multiple
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="errors.system_actions"
                >
                </v-autocomplete>

                <div v-if="!isContactUs && !isUsageBased">
                  <v-divider></v-divider>
                  <p class="subtitle-2 mt-8 mb-4">Price Information</p>
                  <div class="product-price-group">
                    <v-text-field
                      v-if="getBillingType == 'RECURRING'"
                      id="createProductPage-textfield-month"
                      class="price-item mr-1"
                      label="Monthly (Base Price)"
                      dense
                      outlined
                      prefix="$"
                      :required="getBillingType == 'RECURRING'"
                      type="number"
                      step="any"
                      v-model="form.month"
                      :rules="errors.month"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Monthly
                        (Base Price)
                      </template>
                    </v-text-field>
                    <v-text-field
                      v-if="getBillingType == 'RECURRING'"
                      id="createProductPage-textfield-year"
                      class="price-item ml-1"
                      label="Yearly (Optional)"
                      dense
                      outlined
                      prefix="$"
                      type="number"
                      step="any"
                      v-model="form.year"
                    >
                    </v-text-field>
                    <v-text-field
                      v-if="getBillingType == 'ONE_TIME'"
                      id="createProductPage-textfield-month"
                      class="price-item mr-1"
                      label="One Time Payment (Base Price)"
                      dense
                      outlined
                      prefix="$"
                      :required="getBillingType == 'ONE_TIME'"
                      type="number"
                      v-model="form.one_time"
                      :rules="errors.one_time"
                      step="any"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>One Time
                        Payment (Base Price)
                      </template>
                    </v-text-field>
                  </div>
                </div>
                
              </v-container>
              <v-spacer />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  id="createProductPage-button-submit"
                  color="primaryMain"
                  dark
                  :loading="loading"
                  class="mt-5 primaryMainText--text"
                  type="submit"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </form>
        </v-flex>
        <user-limit-modal :load="tierkey" @reloadUsageProduct="fetchUsage()"/>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { 
  remove as _remove,
  find as _find,
  isEmpty as _isEmpty
} from "lodash";
import Vue from "vue";
import Breadcrumbs from "@/components/Breadcrumbs";
import TierPrices from "@/views/products/TierPrices";
import { mapState } from "vuex";
import UserLimitModal from '@/views/products/UserLimitModal';

Vue.use(require("vue-moment"));

export default {
  computed: {
    ...mapState("user", ["is_super_admin", "current_user"]),
    isEnterprise() {
      return this.form.type == "ENTERPRISE";
    },
    isSingleUser() {
      return this.form.type == "SINGLE_USER";
    },
    isContactUs() {
      return this.form.type == "CONTACT_US";
    },
    isUsageBased() {
      return this.$route.query.type == "usage-based";
    },
    isAddsOn() {
      return this.form.type == "ADDS_ON";
    },
    getBillingType() {
      return this.form.billing_type;
    },
    emptyAppList() {
      return this.appList.length == 0 && !this.appListLoading 
    },
    selectedProduct() {
      if (this.isAddsOnPage) {
        return _find(this.productList, {
            id: this.parentProductId,
        });
      }
      return false;
    },
    hasEnterpriseProducts() {
      return !_isEmpty(_find(this.productList, {
            type: 'ENTERPRISE',
        }));
    },
    usageBasedItem() {
      return _find(this.typeItems, {
          value: 'USAGE_BASED',
      })
    },
    disableApplyUserLimit() {
      return this.productUsageList == '' || this.productUsageList.length == 0
    }    
  },
  data() {
    return {
      tierkey: 0,
      isAddsOnPage: false,
      loading: false,
      courseList: [],
      courseListLoading: false,
      productList: [],
      productListLoading: false,
      productActionList: [],
      productActionListLoading: false,
      productUsageList: [],
      productUsageListLoading: false,
      appList: [],
      appListLoading: false,
      parentProductId: '',
      breadcrumbs: [
        {
          title: "Home",
          link: "home",
        },
        {
          title: this.$route.meta.title,
          link: this.$route.meta.type,
        },
        {
          title: "Create",
          link: this.$route.meta.type + ".create",
        },
      ],
      form: { 
        billing_type: "RECURRING", 
        one_time: null,
        active_status: true,
        usage_limit: true,
      },
      billingTypeItems: [
        {
          name: "Recurring",
          value: "RECURRING",
        },
        {
          name: "One Time Payment",
          value: "ONE_TIME",
        },
      ],
      typeItems: [
        {
          name: "Single User",
          value: "SINGLE_USER",
        },
        {
          name: "Enterprise",
          value: "ENTERPRISE",
        },
        {
          name: "Add ons",
          value: "ADDS_ON",
        },
        {
          name: "Lead Form",
          value: "CONTACT_US",
        },
      ],
      usageLimitItems: [
        {
          name: "Limit Users",
          value: "User Licenses",
        },
        {
          name: "Limit Dealers",
          value: "limit_dealers",
        },
        {
          name: "Limit Positions",
          value: "limit_positions",
        },
      ],
      
      errors: [],
    };
  },
  watch: {
    selectedProduct() {
      if(this.selectedProduct) {
        this.breadcrumbs.splice(2, 0, {
          title: this.selectedProduct.name,
          link: this.$route.meta.type + '.edit',
          params: {
            id: this.parentProductId
          }
        });
      }
    }
  },
  created() {
    this.checkIfAddsOn();
    this.fetchApps();
    this.fetchCourse();
    this.fetchUsage();
    this.fetchProduct();
    this.fetchProductAction();
    this.form.billing_type = "RECURRING";
  },
  methods: {
    checkIfAddsOn() {
      let query = this.$route.query
      const addsOnParams = ['type','parent_product_id'];
      let hasParams = addsOnParams.every(key => Object.keys(query).includes(key)); 
      if (hasParams && query.type == 'adds-on') {
        this.isAddsOnPage = true
        this.form.type = 'ADDS_ON'
        this.form.product_id = this.parentProductId
        this.parentProductId = parseInt(query.parent_product_id)
      }
    },
    fetchProductAction() {
      this.productActionListLoading = true
      this.$http
      .get(`/billing/product-action`)
      .then((result) => {
          this.productActionList = result.data
          this.productActionListLoading = false
        })
        .catch((error) => {
          this.productActionListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },

    fetchUsage() {
      this.productUsageListLoading = true
      this.$http
      .get(`/billing/get-usage-product`)
      .then((result) => {
        this.productUsageList = result.data
        this.productUsageListLoading = false
      })
      .catch((error) => {
          this.productUsageListLoading = false
          this.$errorNotif("Something went wrong!");
      });
    },

    fetchProduct() {
      this.productListLoading = true
      this.$http
      .get(`/billing/all-products`, {
          params: {
              type: 'ENTERPRISE'
          }
      })
      .then((result) => {
          this.productList = result.data

          this.form.product_id = this.parentProductId
          this.productListLoading = false
        })
        .catch((error) => {
          this.productListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },
    fetchApps() {
      this.appListLoading = true
      this.$http
      .get(`/billing/app-list`)
      .then((result) => {
          this.appList = result.data
          this.appListLoading = false
        })
        .catch((error) => {
          this.appListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },

    fetchCourse() {
      this.courseListLoading = true
      this.$http
      .get(`/lms/courses`)
      .then((result) => {
          this.courseList = result.data
          this.courseListLoading = false
        })
        .catch((error) => {
          this.courseListLoading = false
          this.$errorNotif("Something went wrong!");
        });
    },

    handleTiers(tiers) {
      this.form.tiers = tiers
    },

    submit() {

      if (this.isAddsOnPage && this.productActionListLoading && this.productListLoading) {
        return false
      }


      this.loading = true;
      this.errors = [];
      
      this.$http
        .post(`/billing/product`, this.form)
        .then((result) => {
          this.loading = false;
          this.$successNotif("Successfully created a product");
          this.$router.push({
            name: this.$route.meta.type + ".edit",
            params: { id: result.data.id },
          });
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
            this.$errorNotif("Invalid inputs, Please check the details");
            if (this.errors.tiers) {
              setTimeout(() => {
                  this.errors = []
              }, 5000);
            }
          }
          this.loading = false;
        });  
    },
    showTierDetails() {
      this.tierkey++
      this.$modal.show('user-limit-modal')
    },
  },
  components: {
    Breadcrumbs,
    TierPrices,
    UserLimitModal
  },
};
</script>