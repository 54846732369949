<template>
  <div class="content">
    <breadcrumbs :links="breadcrumbs"/>


    <div class="content-wrapper">
      <h1 class="font-weight-medium">{{ $route.meta.title }}</h1>

        <div class="mt-5 content-card">
            <div>
                <div class="d-flex security__switch">
                    <v-switch
                        v-model="user.mfa.mfa_enable"
                        class="ma-0"
                        inset
                        color="primary"
                        hide-details
                        @change="update()"
                    ></v-switch>
                    <h3 class="title primary--text">
                        Two Factor Authenticator
                        <v-btn
                            class="ml-"
                            icon
                            color="gray"
                            x-small
                            outlined
                            >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                    v-bind="attrs"
                                    v-on="on"
                                    >  
                                    <v-icon color='gray' small>mdi-information-variant</v-icon>
                                    </span>
                                </template>
                                <span v-html="info2FA"></span>
                            </v-tooltip>
                        </v-btn>
                    </h3>
                </div>

                <div class="my-15">
                    <v-row class="security__row">
                        <v-col class="item" cols="12" sm="5">
                            <h4>Email Authenticator</h4>
                        </v-col>
                        <v-col class="item" cols="12" sm="5">
                            <p class="ma-0 body-2">{{user.email}}</p>
                        </v-col>
                        <v-col class="item" cols="12" sm="2">
                            <v-tooltip bottom >
                                <template v-slot:activator="{ on }">
                                    <v-icon v-show="user.email_verified_at"
                                        class="ml-1"
                                        color="primary" 
                                        v-on="on"
                                    >
                                    mdi-check-circle-outline
                                    </v-icon>
                                    <v-btn
                                        v-show="user.email_verified_at == null"
                                        color="red"
                                        dark
                                        text
                                        icon
                                        v-on="on"
                                        @click="verifyEmail()"
                                    >
                                        <v-icon dark>
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span v-show="user.email_verified_at">Verified</span>
                                <span v-show="user.email_verified_at == null">Verify Email</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <v-divider class="my-2" color="F9B417"/>

                    <v-row class="security__row">
                        <v-col class="item" cols="12" sm="5">
                            <h4>SMS Authenticator</h4>
                        </v-col>
                        <v-col class="item" cols="12" sm="5">
                                <p class="ma-0 body-2" 
                                    :class="!this.phoneNumber ? 
                                    'red--text' : ''">
                                    {{this.phoneNumber ? this.phoneNumber : 'Invalid Phone Number'}}
                                </p>
                        </v-col>
                        <v-col class="item" cols="12" sm="2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-show="user.sms_verified_at"
                                        color="primary" 
                                        class="ml-1"
                                        v-on="on"
                                    >
                                    mdi-check-circle-outline
                                    </v-icon>
                                    <v-btn
                                        v-show="user.sms_verified_at == null"
                                        color="red"
                                        dark
                                        text
                                        icon
                                        v-on="on"
                                        @click="verifySMS()"
                                    >
                                        <v-icon dark>
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span v-show="user.sms_verified_at">Verified</span>
                                <span v-show="user.sms_verified_at == null">Verify SMS</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <v-divider class="my-2" color="F9B417"/>

                    <v-row class="security__row">
                        <v-col class="item" cols="12" sm="5">
                            <h4>Security Questions</h4>
                        </v-col>
                        <v-col class="item" cols="12" sm="5">
                            <v-btn
                                v-show="securityEnable"
                                color="blue"
                                dark
                                outlined
                                x-small
                                @click="showEditSecurityQuestion()"
                            >
                            Edit Security Questions
                            </v-btn>
                        </v-col>
                        <v-col class="item" cols="12" sm="2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-show="securityEnable"
                                        color="primary" 
                                        class="ml-1"
                                        v-on="on"
                                    >
                                    mdi-check-circle-outline
                                    </v-icon>
                                    <v-btn
                                        v-show="!securityEnable"
                                        color="red"
                                        dark
                                        text
                                        icon
                                        v-on="on"
                                        @click="showSecurityQuestion()"
                                    >
                                        <v-icon dark>
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span v-show="securityEnable" ref="enableSecQues">Enabled</span>
                                <span v-show="!securityEnable">Set Security Questions</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </div>
            </div>


            <two-factor-verify-modal/>
            <email-verify-modal/>
            <sms-verify-modal/>
            <edit-security-question-modal :security_questions="securityQuestions"/>
            <security-question-modal @renderSecurityQuestions="renderSecurityQuestions"/>
        </div>
        
        <devices-table/>
    </div>

  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import TwoFactorVerifyModal from '@/components/security/TwoFactorVerifyModal';
import EmailVerifyModal from '@/components/security/EmailVerifyModal';
import SmsVerifyModal from '@/components/security/SmsVerifyModal';
import SecurityQuestionModal from '@/components/security/SecurityQuestionModal';
import EditSecurityQuestionModal from '@/components/security/EditSecurityQuestionModal';
import DevicesTable from '@/components/security/DevicesTable';

export default {
    name: 'Security',
    components: {
        Breadcrumbs,
        TwoFactorVerifyModal,
        EmailVerifyModal,
        SmsVerifyModal,
        SecurityQuestionModal,
        EditSecurityQuestionModal,
        DevicesTable,
    },
    data () {
        return {
        breadcrumbs: {
                0 : {
                title: 'Home',
                link: 'home',
                },
                1 : {
                title: 'Profile' ,
                link: 'profile',
                },
                2 : {
                title: this.$route.meta.title ,
                link: this.$route.meta.name,
                }
            }, 
            securityQuestions: [],
            info2FA: `Two Factor Authentication, or 2FA, is an extra layer of protection used to ensure the security of your account <br> 
                    beyond just an email and password whenever you login. <br><br>  
                    <b>To enable two factor authenticator please activate Email Authenticator, SMS Authenticator, and Security Questions</b>`
        }
    },
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
        securityEnable() {
            return this.securityQuestions.length == 3
        },
        phoneNumber() {
            if(this.user.phone_code && this.user.phone_number) {
                return this.user.phone_code + this.user.phone_number
            }
            return false;
        }
    },
    created() {
        this.updateUser()
        this.initSecurityQuestion()
    },
    methods: {
        async updateUser()  {
            await this.$http
                .get('user/'+ this.user.id)
                .then(({data}) => {
                    this.$store.commit('user/SET_CURRENT_USER_PHONE_CODE', data.phone_code)
                    this.$store.commit('user/SET_CURRENT_USER_PHONE_NUMBER', data.phone_number)
                    this.$store.commit('user/SET_CURRENT_USER_EMAIL', data.email)
                })                 
        },
        verifyEmail() {
            this.$modal.show('email-verify-modal')
        },
        verifySMS() {
            if (!this.phoneNumber) {
                return this.$errorNotif("Please input valid phone number on profile section")
            }

            this.$modal.show('sms-verify-modal')
        },
        showSecurityQuestion() {
            this.$modal.show('security-question-modal')
        },
        showEditSecurityQuestion() {
            this.$modal.show('edit-security-question-modal')
        },
        renderSecurityQuestions(val) {
            this.securityQuestions = val
            this.$refs.enableSecQues.hidden = true
        },
        initSecurityQuestion() {
            this.$http
                .get(`/security-question`, {
                    params: {
                        user_id: this.user.id,
                    },
                })
                .then((data) => {
                    this.securityQuestions = data.data
                })
                .catch((error) => {
                    this.$errorNotif("Something went wrong")
                })
        },
        update() {
            this.$http
                .put(`/mfa/update`)
                .then((data) => {
                    this.$meFetch()
                    this.$successNotif('Security information has been saved')
                    this.$store.commit('user/SET_CURRENT_USER', data.data)
                    this.loading = false
                })
                .catch((error) => {
                    if (error.response.status == 422){
                        this.errors = error.response.data.errors
                    } else if(error.response.status == 403){
                        this.$errorNotif("Please activate all Authenticators")
                        this.user.mfa.mfa_enable = false
                    } else {
                        this.$errorNotif("Something went wrong")
                    }
                    this.loading = false
                })
        }
    }

};
</script>
