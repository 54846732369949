<template>
    <div class="content">
        <breadcrumbs :links="breadcrumbs"/>

        <div class="content-wrapper">
            <h1 class="font-weight-medium">{{ $route.meta.title }}
                <span class="title font-weight-thin">Create</span>
            </h1>

            <v-layout row  class="mt-5">
                <v-flex xs12 lg12 class="px-4 content-image" >
                    <form @submit.prevent="submit()"  method="PUT">
                        <v-card class="pa-4 d-flex flex-column" min-height="632">
                            <h2 class="title">Information</h2>
                            <v-divider/>
                            
                            <div class="d-flex company-container">
                                <v-container class="my-5 content-container ">
                                    <div class="d-flex company-container">
                                        <v-checkbox
                                        class="mr-10"
                                        dense
                                        v-model="form.is_automotive"
                                        label="Automotive"
                                        color="primary"/>
    
                                        <v-checkbox
                                        v-show="is_super_admin"
                                        dense
                                        v-model="form.is_single_user"
                                        label="Single User"
                                        color="primary"/>
                                    </div>
    
                                    <v-text-field
                                        v-if="!passwordAutogenerate"
                                        dense
                                        class="mb-3"
                                        v-model="form.default_password"
                                        prepend-inner-icon="mdi-lock-outline"
                                        :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="password_show ? 'text' : 'password'"
                                        name="input-10-1"
                                        label="Default Password"
                                        outlined
                                        @click:append="password_show = !password_show"
                                        :rules="errors.password"
                                        hint="The password must be between 8 and 30 characters, must have atleast 1 uppercase and 1 numeric "
                                    ></v-text-field>
    
                                    <v-text-field
                                        label="Name"
                                        placeholder="Name"
                                        outlined
                                        required
                                        dense
                                        v-model="form.name"
                                        :rules="errors.name"
                                    >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Name
                                    </template>
                                    </v-text-field>
    
                                    <v-text-field
                                        dense
                                        label="Website"
                                        placeholder="Website"
                                        outlined
                                        v-model="form.website"
                                        :rules="errors.website"
                                    >
                                    </v-text-field>
    
                                    <v-text-field
                                        label="Address"
                                        placeholder="Address"
                                        outlined
                                        dense
                                        v-model="form.address"
                                        :rules="errors.address"
                                    ></v-text-field>
    
                                    <v-text-field
                                        label="City"
                                        placeholder="City"
                                        outlined
                                        dense
                                        v-model="form.city"
                                        :rules="errors.city"
                                    ></v-text-field>
    
                                    <v-text-field
                                        label="State"
                                        placeholder="State"
                                        outlined
                                        dense
                                        v-model="form.state"
                                        :rules="errors.state"
                                    ></v-text-field>
    
                                    <v-text-field
                                        type="number"
                                        label="Zip"
                                        placeholder="Zip"
                                        outlined
                                        dense
                                        v-model="form.zip"
                                        :rules="errors.zip"
                                    ></v-text-field>
                                    <v-select
                                        v-model="form.apps"
                                        label="Apps"
                                        :items="app_list"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        multiple
                                        chips
                                        clearable
                                        autofocus
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        :rules="errors.apps"
                                        @change="hasLMSApp"
                                    >
                                    </v-select>
    
                                    <v-checkbox
                                    dense
                                    v-model="form.is_active"
                                    label="Active"
                                    color="primary"/>

    
                                </v-container>
                                <v-container class="my-6 content-container">
                                    <div class="mb-5">
                                        <span class="font-weight-bold"><strong class="red--text">* </strong> Create User</span>
                                        <span class="ml-1 font-weight-light subtitle-2">Initial Company Admin</span>
                                    </div>
                                    <v-text-field
                                        label="Name"
                                        placeholder="Name"
                                        outlined
                                        dense
                                        autofocus
                                        v-model="form.userName"
                                        :rules="errors.userName"
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        label="Email"
                                        placeholder="Email"
                                        outlined
                                        dense
                                        autofocus
                                        v-model="form.userEmail"
                                        :rules="errors.userEmail"
                                    >
                                    </v-text-field>
                                    <v-checkbox
                                        class="mr-10"
                                        dense
                                        v-model="form.userSendWelcome"
                                        label="Send User Welcome Email"
                                        color="primary"/>
                                </v-container>
                            </div>
                            <v-spacer/>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                color="primaryMain"
                                dark
                                :loading='loading'
                                class="mt-5 primaryMainText--text"
                                type="submit" > Submit </v-btn>
                            </v-card-actions>
                        </v-card>
                    </form>
                </v-flex>
            </v-layout>   
        </div>
        <restore-message-modal :user="deletedUser" @createUser="submit()"/>
    </div> 
</template>

<script>
import {
  includes as _includes,
} from "lodash-es";
import Vue from 'vue'
import { mapState } from "vuex";
import Breadcrumbs from '@/components/Breadcrumbs';
import RestoreMessageModal from '@/components/users/RestoreMessageModal';
Vue.use(require('vue-moment'));

export default {
    computed: {
        ...mapState("apps", [
        "app_list"
        ]),
         ...mapState("user", [
          'is_super_admin', 
      ]),
      ...mapState("layout", [
          'passwordAutogenerate', 
      ]),
    },
    data() {
        return {
            loading: false,
            breadcrumbs: {
                '0': {
                    title: 'Home',
                    link: 'home',
                },
                '1': {
                    title: this.$route.meta.title,
                    link: this.$route.meta.type,
                },
                '2': {
                    title: 'Create',
                    link: this.$route.meta.type+'.create',
                }
            },
            form: {
                is_automotive: false,
                is_single_user: false,
                default_password: '',
                name: '',
                website: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                is_active: true,
                lms_service: true,
                secretshop_service: true,
                has_on_demand: false,
                userName: '',
                userEmail: '',
                userSendWelcome: false,
                apps: [],
            },
            hideRestore: false,
            deletedUser: [],
            lmsApp: {},
            isLMSApp: false,
            password_show: false,
            errors: [],
            image: require('../../assets/images/bgcar.png'),
        };
    },
    created() {
        this.fetchApps()
        this.getLMSApp()
    },
    methods: {
        getLMSApp() {
            this.$http
            .get(`/get-lms-app`)
            .then((result) => {
                this.lmsApp = result.data
            })
        },
        hasLMSApp() {
           this.isLMSApp = _includes(this.form.apps, this.lmsApp.id)
        },
        fetchApps() {
            if (this.app_list.length == 0) {
                this.$store.dispatch('apps/getList', {exclude: true})
            }
        },
        submit() {
            this.loading = true
            this.errors = []
            
            this.$http
            .post(`/company`, this.form)
            .then((result) => {
                this.loading = false
                this.$store.dispatch('company/getList')
                this.$successNotif('Successfully created a company')
                this.resetForm()
            })
            .catch((error) => {
                if (error.response.status == 422){
                    this.errors = error.response.data.errors
                    this.$errorNotif('Invalid inputs, Please check the details')
                }
                if (error.response.status == 403 && error.response.data.existing_deleted_user){
                    this.deletedUser = error.response.data.deleted_user
                    this.$modal.show('restore-message-modal')
                }
                this.loading = false
            })
        },
        resetForm() {
            this.form.is_automotive = false;
            this.form.is_single_user = false;
            this.form.default_password = '';
            this.form.name = '';
            this.form.website = '';
            this.form.address = '';
            this.form.city = '';
            this.form.state = '';
            this.form.zip = '';
            this.form.is_active = true;
            this.form.lms_service = true;
            this.form.secretshop_service = true;
            this.form.userName = '';
            this.form.userEmail = '';
            this.form.userSendWelcome = false;
            this.form.apps = [];
        }
    },
    components: {
        Breadcrumbs,
        RestoreMessageModal
    },
}
</script>