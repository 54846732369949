import Swal from 'sweetalert2'

export default {
  methods: {
    $successNotif(message){
      const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: message == null ? 'Data has been saved' : message  
        })
      },

      $infoNotifToast(message){
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'info',
            title: message == null ? 'Data has been saved' : message  
          })
        },

      $errorNotif(message, timer = 3000){
          const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: timer,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            
            Toast.fire({
              icon: 'error',
              title: message == null ? 'Something went wrong!' : message  
            })
      },

      $questionNotif(message){
          return Swal.fire({
              title: message == null ? 'Are you sure? ' : message.title,
              text: message == null ? '' : message.text,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: this.$store.state.layout.primaryColor,
              cancelButtonColor: '#353534',
              confirmButtonText: message == null ? 'Yes!' : message.confirmButtonText
              }).then((result) => {
                  return result.value
              })
      },

      $warningNotif(message, title = 'Oops...'){
          return Swal.fire({
              icon: 'error',
              title: title,
              confirmButtonColor: this.$store.state.layout.primaryColor,
              html: message,
              allowOutsideClick: false
            }).then((result) => {
              return result.value
            })
      },

      $infoNotif(message){
        Swal.fire({
            icon: 'info',
            title: 'Hello!',
            confirmButtonColor: this.$store.state.layout.primaryColor,
            text: message,
          })
    },

  },
};
