<template>
    <modal 
      class="addson-modal"
      name="addson-modal" 
      width="600"  
      height="auto"  
      :clickToClose="true"
      :adaptive="true" >
  
      <div class="ma-5" >
          <div class="d-flex justify-space-between ">
              <h2>Add ons</h2>
              <v-btn
                  @click="cancel()"
                  icon
                  color="black"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </div>
          <p>{{item.name}}</p>
      </div>
      <v-divider></v-divider>
        <div class="mx-5 my-8">
            <adds-on-section :product="item" @addsonTotal="addAddsonTotal" :scroll="false"></adds-on-section>
        </div>
        <v-divider/>
        <div class="d-flex justify-end px-10 py-3 align-center">
            <span class="subtitle-2 mx-5">
                Total:
            </span>  
            <span>
                $ {{ price }}
            </span>
        </div>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="checkout()" :loading="loading">
                Checkout
            </v-btn>
        </v-card-actions>
    </modal>
  </template>
  <script>
import AddsOnSection from "@/components/manage_products/AddsOnSection.vue";
  
  export default {
      computed: {
          user() {
              return this.$store.state.user.current_user
          },
      },
      props: [
          'item'
      ],
      watch: {
       
      },
      data() {
          return {
              loading: false,
              price: 0
          };
      },
      methods: {
          cancel() {
              this.$modal.hide('addson-modal')
          },
          addAddsonTotal(item) {
            this.price = item.total
            this.addsOnItems = item.addsonId
          },
          checkout() {
            this.loading = true
            let form = {
                userId: this.user.id,
                addson: this.addsOnItems
            }
            
            this.$http
            .post('billing/adds-on/subscribe', form)
            .then((result) => {
                    this.loading = false
                    this.$meFetch()
                    this.$store.commit('billing/SET_RELOAD', true);            
                    this.$store.dispatch('billing/renderBillingPortal', this.user)
                })
                .catch((error) => {
                });
          }
      },
      components: {
        AddsOnSection
      }
  }
  </script>
  