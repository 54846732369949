<template>
    <modal name="export-users-modal" width="700" height="auto" :clickToClose="false" :adaptive="true">
        <div class="pa-5 d-flex justify-space-between align-center">
            <h2 class="title">Send as email</h2>
        </div>
        <v-divider />
        <v-alert v-if="showError" dense outlined type="error" class="caption text-xs ma-5">
            <p class="mb-0" v-for="(error, index) in errors" :key=index>
                ● {{ error[0] }}
            </p>
        </v-alert>
        <v-form ref="form" @submit.prevent="submit()" method="POST">
            <v-card-text class="login-card__field px-5 py-3">

                <v-layout row class="mt-1">
                    <v-flex xs12 md12 lg12 class="px-3">
                        Enter email addresses, separated by commas.
                        <v-text-field label="Emails" placeholder="example@domain.com, another@domain.com" outlined dense
                            required autofocus v-model="emails" :rules="errorsEmail">
                            <template #label>
                                <span class="red--text"><strong>* </strong></span>Emails
                            </template>
                        </v-text-field>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions class="px-5 py-3">
                <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="primaryMainText--text" :loading="loading" color="primaryMain" type="submit">Send</v-btn>
            </v-card-actions>
        </v-form>
    </modal>
</template>
  
<script>
import {
    isEmpty as _isEmpty,
    filter as _filter,
    forEach as _forEach,
    flatten as _flatten,
    map as _map,
} from 'lodash-es';
import { mapState } from "vuex";

export default {
    name: 'ExportUsersModal',
    props: ['filters'],
    data() {
        return {
            showError: false,
            errors: [],
            loading: false,
            emails: '',
            loaders: {
                companies: false,
                dealers: false,
                roles: false,
                positions: false,
            }
        }
    },
    computed: {
        ...mapState("user", [
            'is_super_admin',
            'is_account_manager',
            'is_dealer_manager',
            'is_position_manager',
            'managing_positions',
            'only_position_manager',
        ]),
        filtersAllNull() {
            return Object.values(this.filters).every(value => {
                if (value.length == 0) {
                    return true;
                }
                return false;
            });
        },
        errorsEmail() {
            return Object.keys(this.errors)
                .filter(key => key.startsWith('emails'))
                .reduce((result, key) => {
                    return this.errors[key];
                }, []);
        }
    },
    methods: {
        cancel() {
            this.resetState();
            this.$modal.hide('export-users-modal')
        },
        submit() {
            this.loading = true
            this.errors = []
            this.$http
                .post(`/user/export`, {
                    search: this.filters.search,
                    trashed: this.filters.trashed,
                    companies: this.filters.companies,
                    dealers: this.filters.dealers,
                    statuses: this.filters.statuses,
                    roles: this.filters.roles,
                    positions: this.filters.positions,
                    leader_id: this.filters.leader_id,
                    emails: this.emails.split(",").filter(email => email.trim() !== '')
                })
                .then((result) => {
                    if (result.data.status !== undefined && result.data.status === false) {
                        this.$errorNotif('No data available, Please make a different filter section.')
                        this.loading = false
                        return;
                    }

                    this.loading = false
                    this.$successNotif("Your request is in progress, we will be sending the report to your email as soon as we're done generating it.")
                })
                .catch((error) => {
                    if (error.response.status == 422) {
                        this.errors = error.response.data
                        this.$errorNotif('Invalid inputs, Please check the details')
                    }
                    this.loading = false
                })
        },
        exportUser() {
            if (this.filtersAllNull) {
                this.$errorNotif("No Filters selected, Please select filters")
                return false
            }

            this.loading = true
            this.$http.post('export-users', {
                companies: this.filters.companies,
                dealers: this.filters.dealers,
                statuses: this.filters.statuses,
                roles: this.filters.roles,
            })
                .then(({ data }) => {
                    this.loading = false
                    if (data.success) {
                        this.$successNotif(data.message);
                    } else {
                        this.$errorNotif(data.message);
                    }
                    this.resetState();
                })
                .catch((error) => {
                    this.$errorNotif("Something went wrong")
                    this.loading = false
                })

        },
        checkIfFiltersNull() {

        },
        resetState() {

            this.emails = '';

            this.$nextTick(() => {
                this.showDialog = false;
            })
        },
    }
};
</script>
  
<style scoped>
.error-message {
    color: red;
    font-size: 0.75rem;
    word-break: break-word;
}
</style>