import {
    some as _some,
} from 'lodash';
const data = {
    namespaced: true,
    state: {
        current_user: [],
        is_super_admin: false,
        is_account_manager: false,
        is_dealer_manager: false,
        is_position_manager: false,
        managing_positions: [],
        only_position_manager: false,
        restrict_create: false,
        next_tier: {},
    },
    mutations: {
        SET_CURRENT_USER: (state, payload) => {
            state.current_user = payload
            if (state.current_user) {
                state.is_super_admin = _some(state.current_user.roles, {name:'super-administrator'})
                state.is_account_manager = _some(state.current_user.roles, {name:'account-manager'})
                state.is_dealer_manager = _some(state.current_user.roles, {name:'specific-dealer-manager'})
            }
        },
        SET_CURRENT_USER_PHONE_CODE: (state, payload) => {
            state.current_user.phone_code = payload
        },
        SET_CURRENT_USER_PHONE_NUMBER: (state, payload) => {
            state.current_user.phone_number = payload
           
        },
        SET_CURRENT_USER_EMAIL: (state, payload) => {
            state.current_user.email = payload
        },
        SET_CURRENT_USER_POSITION_MANAGER: (state, payload) => {
            state.is_position_manager = payload.isPositionManager
            state.managing_positions = payload.managingPositions

            if (state.is_account_manager) {
                 return state.only_position_manager = payload.companyAdmins > 0
            }

            if (state.is_dealer_manager) {
                return state.only_position_manager = payload.dealerAdmins > 0
            }
       },
        SET_RESTRICT_CREATE: (state, payload) => {
            state.restrict_create = payload.status
            state.next_tier = payload.nextTier
        },
    },
}

export default data;
