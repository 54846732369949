var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"font-weight-medium mt-10 mb-5"},[_vm._v("Device Management")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.devices,"items-per-page":10,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('p',_vm._g({},on),[_vm._v(" "+_vm._s(item.uuid.substring(0, 16) + " ...")+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.uuid)+" ")])]}},{key:"item.browser",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.browser + ' ( v' + item.browser_version + ' )')+" ")]}},{key:"item.platform",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.platform + ' ( v' + item.platform_version + ' )')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"MMMM DD, YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.removeDevice(item.id)}}},[_vm._v(" remove ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }