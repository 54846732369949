<template>
  <div>
    <div>
      <users-filter @filter="filter" />
    </div>
    <div class="content-card__header d-flex justify-space-between">
      <!-- <div>
        <template v-if="superAdmin">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on"
                  v-bind="attrs"  dark @click="openExportDialog()" class="mr-2">
                <v-icon left> mdi-download </v-icon>
                  Export
                </v-btn>
            </template>
            <span>Export Users</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-on="on"
                  v-bind="attrs" @click="openImportDialog()">
                  <v-icon left>
                    mdi-upload
                  </v-icon>
                  Import
                </v-btn>
            </template>
            <span>Import Users</span>
          </v-tooltip>
        </template>
      </div> -->
      <div></div>
      <div>
        <v-btn color="primaryMain" @click="handleClickExport()" class="mr-2 primaryMainText--text">
          <v-icon left>
            mdi-export
          </v-icon>
          Export
        </v-btn>
        <v-btn color="primaryMain" @click="goToCreate()" class="mr-2 primaryMainText--text">
          <v-icon left>
            mdi-plus
          </v-icon>
          Add New
        </v-btn>
      </div>
    </div>
    <v-data-table :headers="headers" :items="items" class="elevation-1" :loading="loading"
      loading-text="Loading... Please wait" :options.sync="options" :server-items-length="pagination.total"
      :items-per-page="pagination.per_page" :footer-props="{
        disablePagination: paginationDisable,
        'items-per-page-options': [5, 10, 20, 50]
      }">

      <template v-slot:item.name="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <a href="javascript:void(0)" v-bind="attrs" v-on="on"
              @click="roleCondition(item) ? goToEdit(item.id) : goToView(item.id)">
              {{ item.name }}
            </a>
          </template>
          <span>View</span>
        </v-tooltip>
      </template>

      <template v-slot:item.roles="{ item }">
        <span>{{ item.roles.length > 0 ? item.roles[0].friendly_name : null }}</span><br>
        <v-tooltip bottom v-if="item.roles.length > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="pa-0" v-bind="attrs" v-on="on" text color="primary">
              see more
            </v-btn>
          </template>
          <p class="ma-0" v-for="(role, index) in item.roles" :key="index">
            • {{ role.friendly_name }}
          </p>
        </v-tooltip>
      </template>

      <template v-slot:item.products="{ item }">
        <div class="d-flex" @click="viewProducts(item.name, item.products)">
          <span class="mx-1" v-for="(product, index) in item.products" :key="index">
            <v-tooltip bottom v-if="product.access">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar color="white" size="25" v-bind="attrs" v-on="on">
                  <img :src="product.cover_photo">
                </v-avatar>
              </template>
              <span>{{ product.name }}</span>
            </v-tooltip>
          </span>
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip @click="changeStatus(item)" small class="ma-2" :color="formatStatus(item.status).type" text-color="white">
          {{ formatStatus(item.status).name }}
        </v-chip>
      </template>

      <template v-slot:item.created_at="{ item }">
        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
      </template>

      <template v-slot:item.last_login_at="{ item }">
        <span>{{ (item.last_login_at !== null) ? new Date(item.last_login_at).toLocaleString() : '' }}</span>
      </template>

      <template v-slot:item.deleted_at="{ item }">
        <span>{{ new Date(item.deleted_at).toLocaleString() }}</span>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <div v-if="is_super_admin">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="resync(item)" x-small icon color="purple" class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              <span>Re-Sync</span>
            </v-tooltip>
          </div>
          <div v-if="showTrashed">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="restore(item)" x-small icon color="success" class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </template>
              <span>Restore</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="permanentDelete(item)" x-small icon color="error" class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>Permanent Remove</span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="changeStatus(item)" x-small icon color="success" class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </template>
              <span>Approve/Reject</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="resend(item)" x-small icon class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </template>
              <span>Re-send Invitation</span>
            </v-tooltip>

            <span>
              <v-tooltip bottom v-if="roleCondition(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="goToEdit(item.id)" x-small icon color="warning" class="mx-1" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="goToView(item.id)" x-small icon color="info" class="mx-1" v-bind="attrs" v-on="on">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </span>

            <v-tooltip bottom v-if="roleCondition(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="remove(item)" x-small color="error" icon class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Remove</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
    <products-modal :items='selectedProducts' />
    <status-modal :item='selectedItem' />
    <restrict-create-modal />
    <export-dialog></export-dialog>
    <import-dialog></import-dialog>
    <export-users-modal :filters="filters"></export-users-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import ProductsModal from '@/components/users/ProductsModal';
import StatusModal from '@/components/users/StatusModal';
import RestrictCreateModal from '@/components/users/RestrictCreateModal';
import UsersFilter from './UsersFilter.vue';
import ExportDialog from '@/components/import-export-users/ExportDialog'
import ImportDialog from '@/components/import-export-users/ImportDialog'
import { mapState } from "vuex";
import {
  some as _some,
  find as _find,
} from 'lodash';
import ExportUsersModal from '@/components/users/ExportUsersModal';

export default {
  name: 'UsersTable',
  components: {
    ImportDialog,
    ExportDialog,
    ProductsModal,
    StatusModal,
    UsersFilter,
    RestrictCreateModal,
    ExportUsersModal,
  },
  data() {
    return {
      loading: false,
      items: [],
      filters: null,
      pagination: {
        total: 1,
        per_page: 10,
        current_page: 1,
        search: '',
        sortBy: '',
        sortDesc: false,
      },
      options: {},
      paginationDisable: false,
      timeout: null,
      selectedProducts: [],
      selectedItem: [],
      initialized: false,
      addUserLoading: [],
    }
  },
  computed: {
    ...mapState("layout", ["labels"]),
    ...mapState("user", [
      "current_user",
      'is_super_admin',
      'is_account_manager',
      'is_position_manager',
      'only_position_manager',
      'restrict_create'
    ]),
    showTrashed() {
      return this.filters ? this.filters.trashed : false
    },
    superAdmin() {
      return this.findRole('super-administrator')
    },
    accountManager() {
      return this.findRole('account-manager')
    },
    dealerManager() {
      return this.findRole('specific-dealer-manager')
    },
    roles() {
      return this.$auth.user().roles;
    },
    headers() {
      return [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Number', value: 'phone_number' },
        { text: 'Roles', value: 'roles', sortable: false, width: '100' },
        { text: 'Position', value: 'position', sortable: false },
        { text: 'Team Leader', value: 'team_leader', sortable: false },
        { text: 'Apps', value: 'products', sortable: false },
        { text: 'Company', value: 'company', sortable: false },
        { text: this.dealerTitle, value: 'specific_dealer', sortable: false, align: '' },
        { text: 'Status', value: 'status' },
        { text: 'Last Login', value: 'last_login_at' },
        { text: 'Date Created', value: 'created_at' },
        { text: 'Date Removed', value: 'deleted_at', align: ' d-none' },
        { text: '', value: 'action', width: '190' },
      ]
    },
    dealerTitle() {
      return this.labels !== undefined && this.labels.dealer !== undefined ? this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1) : '';
    }
  },
  watch: {
    options: {
      handler() {
        this.changeTable();
      },
      deep: true,
    },
  },
  created() {
    localStorage.userTab = 0;
    this.hideDealerCol()
  },
  methods: {
    hideDealerCol() {
      let dealerHeader = _find(this.headers, {
        value: "specific_dealer",
      });

      return dealerHeader.align = this.current_user.company.is_automotive ? '' : ' d-none'
    },
    async fetch() {
      this.loading = true;
      this.paginationDisable = true;
      this.items = [];
      await this.$http
        .get(`user`, {
          params: {
            per_page: this.options.itemsPerPage,
            current_page: this.pagination.current_page,
            sortBy: this.pagination.sortBy,
            sortDesc: this.pagination.sortDesc,
            filter: this.filters,
          },
        })
        .then((data) => {
          this.loading = false
          this.items = data.data.data
          this.pagination.total = data.data.total;
          this.pagination.current_page = data.data.current_page;
          this.pagination.per_page = data.data.per_page
          this.paginationDisable = false;
        })
        .catch((error) => {
          this.loading = false
          this.$errorNotif("Something went wrong")
        })
    },
    changeTable() {
      this.pagination.current_page = this.options.page;
      this.pagination.per_page = this.options.itemsPerPage;

      this.pagination.sortBy =
        this.options.sortBy !== undefined ? this.options.sortBy[0] : '';
      this.pagination.sortDesc =
        this.options.sortDesc !== undefined ? this.options.sortDesc[0] : '';


      if (this.initialized || !this.is_position_manager) {
        this.fetch();
      }
    },
    formatStatus(item) {
      if (item === '' || item === undefined) {
        return { type: null, name: null };
      }

      let status = {
        ACTIVE: {
          name: 'Active',
          type: 'success',
        },
        UNDER_REVIEW: {
          name: 'Under Review',
          type: 'warning',
        },
        INACTIVE: {
          name: 'Inactive',
          type: 'info',
        },
        REJECTED: {
          name: 'Rejected',
          type: 'error',
        },
      }
      if (!status[item]) {
        return { type: null, name: null };
      }

      return status[item]
    },
    viewProducts(name, items) {
      this.selectedProducts = {
        apps: items,
        name: name,
      }
      this.$modal.show('products-modal')
    },
    resend(item) {
      let msg = {
        title: "Resend Invitation?",
        confirmButtonText: "Yes, Resend!",
      }

      this.$questionNotif(msg)
        .then((result) => {
          if (result) {
            this.loading = true
            this.$http
              .put(`/user/resend-invite/${item.id}`)
              .then((data) => {
                this.loading = false
                this.$successNotif('Successfully sent invitation')
              })
          }
        })
    },
    changeStatus(item) {
      if (item.deleted_at) {
        this.$errorNotif("You can not edit deleted user.")
        return
      }
      if (!this.roleCondition(item)) {
        this.$errorNotif("You have no access to edit a Super Admin Account")
        return false
      }

      this.selectedItem = item
      this.$modal.show('status-modal')
    },
    remove(item) {
      let msg = {
        title: "Remove " + item.name + "?",
        confirmButtonText: "Yes, Remove!",
      }

      this.$questionNotif(msg)
        .then((result) => {
          if (result) {
            this.loading = true
            this.$http
              .delete(`/user/${item.id}`)
              .then((data) => {
                this.loading = false
                this.$successNotif('Successfully deleted user')
                this.fetch()
              })
              .catch((error) => {
                this.loading = false
                if (error.response.status == 422) {
                  this.$errorNotif(error.response.data.errors)
                }
              })

          }
        })
    },
    restore(item) {
      let msg = {
        title: "Restore " + item.name + "?",
        confirmButtonText: "Yes, Restore!",
      }

      this.$questionNotif(msg)
        .then((result) => {
          if (result) {
            this.loading = true
            this.$http
              .put(`/user/restore/${item.id}`)
              .then((data) => {
                this.loading = false
                this.$successNotif('Successfully restored user')
                this.fetch()
              })
              .catch((error) => {
                this.loading = false
                if (error.response.status == 422) {
                  this.$errorNotif(error.response.data.errors, 4000)
                }
                else {
                  this.$errorNotif("Something went wrong")
                }
              })
          }
        })
    },
    permanentDelete(item) {
      let msg = {
        title: "Permanent Remove " + item.name + "?",
        text: "User will be Permanent Remove and you won't be able to revert this!",
        confirmButtonText: "Yes, Remove!",
      }

      this.$questionNotif(msg)
        .then((result) => {
          if (result) {
            this.loading = true
            this.$http
              .put(`/user/permanent-remove/${item.id}`)
              .then((data) => {
                this.loading = false
                this.fetch()
                Vue.prototype.$echo
                  .channel(`user-permanent-deleted-${data.data}`)
                  .listen('UserPermanentDeleted', (e) => {
                    this.loading = false
                    this.fetch()
                    this.$successNotif('Successfully permanent removed the user')
                  });
              })
          }
        })
    },

    goToCreate() {
      this.checkUserlimit();
    },
    goToEdit(id) {
      this.$router.push({ name: this.$route.meta.type + ".edit", params: { id: id } });
    },
    goToView(id) {
      this.$router.push({ name: this.$route.meta.type + ".view", params: { id: id } });
    },

    async filter(filters) {
      this.initialized = true
      this.options.page = 1

      this.filters = filters
      await this.fetch()

      const items = _find(this.headers, { value: 'deleted_at' });
      this.showTrashed
        ? items.align = ''
        : items.align = ' d-none'
    },
    roleCondition(item) {
      if (item.deleted_at) {
        return false;
      }

      if (this.is_super_admin) {
        return true
      }

      return !_some(item.roles, { name: 'super-administrator' })
    },
    resync(item) {
      this.loading = true
      this.$http
        .get(`/resync/${item.id}`, {
          params: {
            model: 'App\\Models\\User',
          },
        })
        .then((data) => {
          this.loading = false;
          this.$successNotif("successfully re-synced")
        })
        .catch((error) => {
          this.loading = false;
          this.$errorNotif("Something went wrong")
        })
    },
    openExportDialog() {
      this.$modal.show('export-modal')
    },
    openImportDialog() {
      this.$modal.show('import-modal')
    },
    findRole(role) {
      return _find(this.roles, {
        name: role,
      });
    },
    async checkUserlimit() {
      this.$http
        .get(`billing/check-user-limit/${this.current_user.company_id}`)
        .then(({ data }) => {
          if (data.status == 'restrict_create') {
            this.$modal.show('restrict-create-modal')
            this.$store.commit('user/SET_RESTRICT_CREATE', data);
            return
          }

          this.$router.push({ name: this.$route.meta.type + ".create" });

        })
        .catch((error) => {
          this.loading = false
          this.$errorNotif("Something went wrong")
        })
    },
    handleClickExport() {
      this.$modal.show('export-users-modal')
    },
  }
};
</script>
