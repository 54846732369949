const data = {
    namespaced: true,
    state: {
        app_list: []
    },
    mutations: {
        SET_APP_LIST: (state, payload) => {
            state.app_list = payload
        },
    },
    actions: {
        getList ( {commit}, payload ) {   
            this._vm.$http
                .get(`apps`, {
                    params: {
                      'exclude': payload ? payload.exclude : false
                    },
                })
                .then(({ data }) => {
                    commit('SET_APP_LIST', data);
                })
        },
    },
}

export default data;
