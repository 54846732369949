<template>
    <div>
      <access-denied-card v-if="isAccessDenied"/>
    </div>
  </template>
  <script>
  import { mapState } from "vuex";
  import AccessDeniedCard from '../components/cards/AccessDeniedCard.vue';
  
  export default {
    name: 'ShopLogin',
    computed: {
      query() {
        return this.$route.query
      }
    },
    components: {
        AccessDeniedCard
    },
    data() {
      return {
          isShopLogin: false,
          isAccessDenied: false
      }
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        const forgotPassword = ['forgot-token', 'id'];
        if (forgotPassword.every(key => Object.keys(this.query).includes(key))) {
          this.initForgotPassword();
          return
        } 
        
        if (this.$route.query.error_type) {
          this.isAccessDenied = true;
          return
        }

        if (this.query['amp;forgot-token']) {
          let str;
          str = this.$route.fullPath.replace("amp%3Bforgot-token", "forgot-token"); 
          window.location.href = window.location.origin + str
          return 
        }
        
        if (this.$route.query.error_type) {
          this.isAccessDenied = true;
          return
        }

        this.checkAuth();

      },
      initForgotPassword() {

        if (this.$auth.user()) {
          this.$router.push({ name: 'profile', query: {'change_password' : true}});
          return;
        } 
        this.$router.push({ name: 'Login', query: this.$route.query});
      },
      checkAuth() {
        if (this.$cookies.get('default_auth_token')) {
          this.$cookies.set('shoplogin', this.query, '20y');
          window.location.href = window.location.origin+'/okta/logout';
          this.$cookies.remove('default_auth_token');
          localStorage.removeItem('nextUrl')
          this.$cookies.remove('default_auth_user');
          return false;
        }
        this.checkShopLogin()
      },
      checkShopLogin() {
        const shopLogin = ['shop_login', 'email', 'access_token', 'id'];
        
        if (shopLogin.every(key => Object.keys(this.query).includes(key))) {
              this.initShopLogin()
        } else {
            this.invalidToken()
        }
      },
      initShopLogin() {
        this.isShopLogin = true
        this.$store.commit('global/SET_PAGE_LOADER', true)   
      
        this.$auth
          .login(this.query)
          .then(({ data }) => {
            this.$cookies.remove('shoplogin');
            this.$cookies.set('current_central_token', data.access_token.token.id, '20y');
            const accessToken = `Bearer ${data.access_token.accessToken}`;
            this.$http.defaults.headers.common.Authorization = accessToken;
            this.$auth.storeManager.setUser(data.user);
            this.$store.commit('user/SET_CURRENT_USER', data.user)
            this.$renderLayout(data.user.company_id)
            this.$meFetch()

            if (data.redirect != null) {
              this.$store.commit('global/SET_PAGE_LOADER', true)
              window.location.assign(data.redirect);
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
                this.invalidToken()
            } 
          })
      },
      invalidToken() {
        this.$errorNotif("Token is Invalid")
        this.$router.replace({'query': null});
        this.$store.commit('global/SET_PAGE_LOADER', false);  
        this.$router.push({ name: "Login"});
      },
    }
  };
  </script>
  