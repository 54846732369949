<template>
  <modal
    name="security-question-modal"
    width="600"
    height="auto"
    :clickToClose="false"
    :adaptive="true" >
         <div class="pa-5 d-flex justify-space-between align-center">
            <h2 class="title ">Set Security Questions</h2>
            <v-btn
                class="ml-"
                icon
                color="gray"
                x-small
                outlined
                >
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >  
                        <v-icon color='gray' small>mdi-information-variant</v-icon>
                        </span>
                    </template>

                    <div>
                        <span class="caption font-weight-bold">Security Questions are the last option to authenticate your account if email and sms authenticators are unavailable</span><br>
                        <span class="caption">1. Select questions and answer the selected questions</span><br>
                        <span class="caption">2. Make sure to remember your exact answers (case insensitive)</span><br>
                        <span class="caption">3. Click <b>Submit</b> to set your security questions</span><br>
                    </div>
                </v-tooltip>
            </v-btn>
        </div>
        <v-divider/>
        <v-alert
            v-if="showError"
            dense
            outlined
            type="error"
            class="caption text-xs ma-5"
            >
            <p class="mb-0" v-for="(error, index) in errors" :key=index>
                ● {{error}}
            </p>
        </v-alert>
        <v-form
            ref="form"
            @submit.prevent="submit()"
            method="POST"
        >
            <v-card-text class="login-card__field px-5 py-8">
                <div>
                    <h3 class="primary--text mb-3">Question 1</h3>
                    <v-select
                        v-model="form.question[0]"
                        :items="questions"
                        outlined
                        dense
                        label="Question"
                    ></v-select>
                    <v-text-field
                        v-model="form.answer[0]"
                        outlined
                        dense
                        label="Answer"
                    />
                </div>
                 <div>
                    <h3 class="primary--text mb-3">Question 2</h3>
                    <v-autocomplete
                        v-model="form.question[1]"
                        :items="questions"
                        outlined
                        dense
                        label="Question"
                    ></v-autocomplete>
                    <v-text-field
                        v-model="form.answer[1]"
                        outlined
                        dense
                        label="Answer"
                    />
                </div>
                 <div>
                    <h3 class="primary--text mb-3">Question 3</h3>
                    <v-autocomplete
                        v-model="form.question[2]"
                        :items="questions"
                        outlined
                        dense
                        label="Question"
                    ></v-autocomplete>
                    <v-text-field
                        v-model="form.answer[2]"
                        outlined
                        dense
                        label="Answer"
                    />
                </div>
            </v-card-text>
            <v-card-actions class="px-5 py-3">
                <v-btn
                    text
                    color="primary"
                    @click="cancel()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    class="primaryMainText--text"
                    :loading="loading"
                    color="primaryMain"
                    type="submit"
                    >Submit</v-btn>
            </v-card-actions>
        </v-form>
  </modal>
</template>
<script>

export default {
    data() {
        return {
            showError: false,
            errors: '',
            loading: false,
            items: [
                { text: "What is your mother's maiden name?", disabled: false },
                { text: "In what city were you born?", disabled: false },
                { text: "What is the name of your first school?", disabled: false },
                { text:  "What high school did you attend?", disabled: false },
                { text: "In what city or town was your first job?", disabled: false },
                { text: "What is the name of your favorite pet?", disabled: false },
                { text: "What was the make of your first car?", disabled: false },
                { text: "What was your favorite food as a child?", disabled: false },
                { text: "What was your childhood nickname?", disabled: false },
            ],
            form: {
                question: [],
                answer: [],
            },
        };
    },
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
        questions() {
            Object.entries(this.items).forEach(
                value => value[1].disabled = false);

             Object.entries(this.items).forEach(
                value => this.form.question.forEach(
                    value1 =>
                    value[1].text == value1 ?
                    value[1].disabled = !value[1].disabled :
                    ''
                )
            );

            return this.items
        }
    },
    methods: {
        cancel() {
            this.$modal.hide('security-question-modal')
        },
        submit() {
            this.$http
            .post(`/security-question`, this.form)
            .then((data) => {
                this.$emit('renderSecurityQuestions', data.data)
                this.$modal.hide('security-question-modal')
                this.$successNotif('Security Question has been set')
            })
            .catch((error) => {
                if (error.response.status == 422){
                    this.showError = true
                    let errors = error.response.data.errors

                    if(!errors.hasOwnProperty('answer')) {
                        this.errors = ['Duplicate answers are invalid','Answers fields are required']
                    }
                    this.errors = errors.answer.concat(errors.question)
                    this.errors = this.errors.filter(Boolean);
                }
            })
        },
    },
}
</script>
