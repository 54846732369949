var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content"},[_c('position-filter',{on:{"filter":_vm.filter}})],1),_c('div',{staticClass:"content-card__header"},[(!(_vm.is_position_manager && _vm.only_position_manager))?_c('v-btn',{staticClass:"primaryMainText--text",attrs:{"color":"primaryMain"},on:{"click":function($event){return _vm.goToCreate()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add New ")],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"positionsTable","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.per_page,"footer-props":{
      disablePagination: _vm.paginationDisable,
      'items-per-page-options': [5, 10, 20, 50],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.friendly_name",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":""},on:{"click":function($event){return _vm.goToEdit(item.id)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.friendly_name)+" ")])]}}],null,true)},[_c('span',[_vm._v("View")])])]}},{key:"item.company",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.company.name ? item.company.name : null))]),_c('br'),(item.company.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"small":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" see more ")])]}}],null,true)},[_c('p',{staticClass:"ma-0"},[_vm._v(" • "+_vm._s(_vm.company.name)+" ")])]):_vm._e()]}},{key:"item.specific_dealer",fn:function(ref){
    var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.specific_dealer ? item.specific_dealer.name : ''))])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',[_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"warning"},on:{"click":function($event){return _vm.goToEdit(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","color":"error","icon":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }