const data = {
  namespaced: true,
  state: {
    exportFilters: {
      companies: [],
      companyLoading: false,
      dealers: [],
      dealerLoading: false,
      roles: [],
      roleLoading: false,
      positions: [],
      positionLoading: false,
      statuses: [
        {
          value: 'ACTIVE',
          name: 'Active',
        },
        {
          value: 'REJECTED',
          name: 'Rejected',
        },
        {
          value: 'UNDER_REVIEW',
          name: 'Under Review',
        },
        {
          value: 'INACTIVE',
          name: 'Inactive',
        }
      ],
    }
  },
  mutations: {
    SET_COMPANIES_FILTER_STATE: (state, payload) => {
      state.exportFilters.companies = payload;
    },
    SET_DEALERS_FILTER_STATE: (state, payload) => {
      state.exportFilters.dealers = payload;
    },
    SET_ROLES_FILTER_STATE: (state, payload) => {
      state.exportFilters.roles = payload;
    },
    SET_STATUSES_FILTER_STATE: (state, payload) => {
      state.exportFilters.statuses = payload;
    },
    SET_COMPANY_LOADING: (state, payload) => {
      state.exportFilters.companyLoading = payload;
    },
    SET_DEALER_LOADING: (state, payload) => {
      state.exportFilters.dealerLoading = payload;
    },
    SET_ROLE_LOADING: (state, payload) => {
      state.exportFilters.roleLoading = payload;
    },
    SET_POSITION_FILTER_STATE: (state, payload) => {
      state.exportFilters.positions = payload;
    },
    SET_POSITION_LOADING: (state, payload) => {
      state.exportFilters.positionLoading = payload;
    },
  },
  actions: {
    setImportDialogState({ commit }, payload) {
      commit('SET_IMPORT_DIALOG_STATE', payload)
    },
    setCompaniesFilterState({ commit }, payload) {
      commit('SET_COMPANIES_FILTER_STATE', payload);
    },
    setDealersFilterState({ commit }, payload) {
      commit('SET_DEALERS_FILTER_STATE', payload);
    },
    setRolesFilterState({ commit }, payload) {
      commit('SET_ROLES_FILTER_STATE', payload);
    },
    setStatusesFilterState({ commit }, payload) {
      commit('SET_STATUSES_FILTER_STATE', payload);
    },
    setPositionsFilterState({ commit }, payload) {
      commit('SET_POSITION_FILTER_STATE', payload);
    }
  }
};

export default data;