<template>
  <modal
    name="change-password-modal"
    width="500"
    height="auto"
    :clickToClose="false"
    :adaptive="true" >
         <div class="pa-5 d-flex justify-space-between align-center">
            <h2 class="title">Change Password</h2>
        </div>
        <v-divider/>
        <v-alert
            v-if="showError"
            dense
            outlined
            type="error"
            class="caption text-xs ma-5"
            >
            <p class="mb-0" v-for="(error, index) in errors" :key=index>
                ● {{error[0]}}
            </p>
        </v-alert>
       <v-form 
        ref="form" 
        @submit.prevent="submit()"  
        method="POST"
        >
        <v-card-text class="login-card__field px-5 py-8">
          <v-text-field
            class="mb-3"
            v-model="form.password"
            prepend-inner-icon="mdi-lock-outline"
            :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="password_show ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            outlined
            @click:append="password_show = !password_show"
            :rules="errors.password"
            hint="The password must be between 8 and 30 characters, must have atleast 1 uppercase and 1 numeric "
          ></v-text-field>
          <v-text-field
            v-model="form.confirm_password"
            prepend-inner-icon="mdi-lock-outline"
            :append-icon="confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="confirm_password_show ? 'text' : 'password'"
            name="input-10-1"
            label="Confim Password"
            outlined
            hint="The confirm password must be identical to the password "
            :rules="errors.confirm_password"
            @click:append="confirm_password_show = !confirm_password_show"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="px-5 py-3">
          <v-btn
              text
              color="primary"
              @click="cancel()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="primaryMainText--text"
              :loading="loading"
              color="primaryMain"
              type="submit"
              >Submit</v-btn>
      </v-card-actions>
      </v-form>
  </modal>
</template>

<script>
export default {
  name: 'ChangePasswordModal',
  data () {
    return {
      showError: false,
      errors: [],
      password_show: false,
      confirm_password_show: false,
      loading: false,
      form: {
        password: '',
        confirm_password: '',
        user_id: '',
      }
    }
  },
  props: [
    'user_id'
  ],
  methods: {
    cancel() {
      this.$router.replace({'query': null});
      this.$modal.hide('change-password-modal')
    },
    submit() {
      this.loading = true
      this.form.user_id = this.user_id
      this.$http
        .post(`/auth/change-password`, this.form)
        .then((data) => {
          this.loading = false
          this.$refs.form.reset()
          this.$router.replace({'query': null});
          this.$successNotif('Successfully changed password')
          window.location.reload()
        })
        .catch((error) => {
          if (error.response.status == 422){
            this.errors = error.response.data.errors
            this.showError = true;
              
              setTimeout(()=>{
              this.showError = false;
              },5000)

          } else {
            this.$errorNotif("Something went wrong")
          }

          this.loading = false
        })
    }
  }
};
</script>
