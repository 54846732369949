<template>
  <div class="content" :class="!is_super_admin ? 'content-full' : ''">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">
        {{ $route.meta.title }}
        <span class="title font-weight-thin">Invoice</span>
      </h1>
      <v-layout row class="mt-5">
        <v-flex>
          <v-container style="max-width: 860px">
            <v-row class="justify-end" v-if="!isMobileSubs">
              <v-col cols="12" sm="2" md="2" lg="2" class="text-right;"  v-if="invoice.collection_method == 'send_invoice' && invoice.status == 'open'">
                <v-btn
                  style="width: 100%"
                  :loading="loading"
                  color="primaryMain"
                  class="mx-1 pa-5"
                  @click="send()"
                  normal
                  outlined
                >
                  Send Invoice
                </v-btn>
              </v-col>
              <v-col cols="12" sm="2" md="2" lg="2" class="text-right;">
                <v-btn
                  style="width: 100%"
                  class="mx-1 pa-5"
                  :loading="loading"
                  color="primaryMain"
                  normal
                  outlined
                  @click="download()"
                >
                  Download Invoice
                </v-btn>
              </v-col>
              <v-col cols="12" sm="2" md="2" lg="2" class="text-right;" v-if="invoice.collection_method == 'send_invoice' && invoice.status == 'open'">
                <v-btn
                  style="width: 100%"
                  class="primaryMainText--text pa-5 text-center"
                  :loading="loading"
                  @click="manualPay()"
                  color="primaryMain"
                  normal
                  target="_blank"
                >
                  Manual Payment
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-flex>
      </v-layout>
      <v-layout row class="mt-5">
        <v-flex>
          <v-container class="grey lighten-5 pa-5" style="max-width: 860px">
            <v-row>
              <v-col cols="12" sm="4">
                <v-img
                  contain
                  max-height="150"
                  max-width="250"
                  src="https://webinarinc-central.s3.us-west-1.amazonaws.com/public/company_logo/revdojo_logo.png"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="4">
                <p class="text-lg-right">Webinar Inc. LLC <br />5032671267</p>
              </v-col>
              <v-col cols="12" sm="4">
                <p class="text-sm-right">
                  160 W. Camino Real, Unit #510 <br />
                  Boca Raton, Florida <br />
                  33432 <br />
                  United States
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4"
                ><h4>Billed To</h4>
                {{ userCompanyName }}</v-col
              >
              <v-col cols="12" sm="4"
                ><h4>Date of Issue</h4>
                {{ formatDate(stripeData.period_start) }} <br /><br />
                <span v-if="!isMobileSubs">
                  <h4>Due Date</h4>
                  {{ formatDate(stripeData.due_date) }}
                </span>
                <span v-if="hasSubscription && invoice.subscription.trial_ends_at && invoice.subscription.stripe_status == 'active'">
                  <h4>End of Free Trial</h4>
                  {{ formatSubsDate(invoice.subscription.trial_ends_at) }}
                </span>
                <span v-if="hasSubscription && invoice.subscription.stripe_status == 'canceled'">
                  <h4>Subscription has been canceled</h4>
                  {{ formatSubsDate(invoice.subscription.ends_at) }}
                </span>
              </v-col>
              <v-col cols="12" sm="4" class="text-sm-right"
                ><h4>Invoice Number</h4>
                {{ stripeData.number }}
              </v-col>
              <v-col class="text-lg-right"
                ><h4>Amount Due (USD)</h4>
                <h2>{{ formatNumber(stripeData.total) }}</h2>
              </v-col>
            </v-row>
            <v-row
              ><v-col
                ><v-divider
                  style="border-color: black !important"
                ></v-divider></v-col
            ></v-row>

            <v-row class="pl-4 mt-12">
              <v-col><h4>Description</h4> </v-col>
              <v-col class="text-right"><h4>Rate</h4></v-col>
              <v-col class="text-right"><h4>Qty</h4></v-col>
              <v-col class="text-right"><h4>Line Total</h4> </v-col>
            </v-row>
            <v-row
              class="pl-4"
              v-for="(lineItem, index) in stripeData.lines.data"
              :key="index"
            >
              <v-col>{{ lineItem.plan.name }} </v-col>
              <v-col class="text-right">{{
                formatNumber(lineItem.amount)
              }}</v-col>
              <v-col class="text-right">{{ lineItem.quantity }}</v-col>
              <v-col class="text-right"
                >{{ formatNumber(lineItem.amount * lineItem.quantity) }}
              </v-col>
            </v-row>
            <v-divider class="mt-12"></v-divider>
            <v-row class="pl-4 mt-5" style="height: 320px">
              <v-col md="3" offset-md="6" class="text-right"
                >Subtotal<br />
                Tax<br />
                Total<br />
                Amount Paid<br />
                <h3>Amount Due (USD)</h3></v-col
              >

              <v-col class="text-right">
                {{ formatNumber(stripeData.subtotal) }}<br />
                {{ formatNumber(stripeData.tax_amount) }}<br />
                {{ formatNumber(stripeData.total) }}<br />
                {{ formatNumber(stripeData.amount_paid) }}<br />
                <h3>{{ formatNumber(stripeData.amount_due) }}</h3></v-col
              >
            </v-row>
          </v-container>
        </v-flex>
      </v-layout>
    </div>
    <manual-pay-modal :item="invoice" @reload="fetch()" />
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import ManualPayModal from '@/components/company_billing/ManualPayModal';
export default {
  components: {
    Breadcrumbs,
    ManualPayModal
  },
  computed: {
    ...mapState("apps", ["app_list"]),
    ...mapState("user", ["is_super_admin"]),
    isSendInvoice() {
      return this.invoice.collection_method == 'send_invoice' && this.invoice.status == 'open'
    },
    isMobileSubs() {
      if (this.invoice.subscription == undefined) {
        return true
      }
      return this.invoice.subscription.is_mobile
    },
    hasSubscription() {
      return this.invoice.subscription !== undefined
    }
  },
  data() {
    return {
      loading: false,
      userCompanyName: null,
      invoice: {
        collection_method: null,
        status: null
      },
      stripeData: {
        period_start: null,
        due_date: null,
        pdf_link: null,
        lines: { data: {} },
      },
      breadcrumbs: {
        0: {
          title: "Home",
          link: "home",
        },
        1: {
          title: "Company",
          link: "companies",
        },
        2: {
          title: "Edit",
          link: `companies.edit`,
          params: { id: this.$route.params.companyId },
        },
        3: {
          title: "Invoice",
        },
      },
      errors: [],
      image: require("../../assets/images/bgcar.png"),
      currentInvoice: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    manualPay() {
      this.currentInvoice = this.invoice;
      this.$modal.show("manual-pay-modal");
    },
    fetch() {
      this.$store.commit("global/SET_BREADCRUMBS_LOADER", true);
      this.$http
        .get(`billing/get-user-invoice/${this.$route.params.id}`)
        .then((data) => {
          this.stripeData = data.data.stripeData;
          this.userCompanyName = data.data.userCompanyName;
          this.email = data.data.email;
          this.invoice = data.data.invoice;
          this.$store.commit("global/SET_BREADCRUMBS_LOADER", false);
        })
        .catch((error) => {
          this.$store.commit("global/SET_BREADCRUMBS_LOADER", false);
        });
    },
    formatNumber(number) {
      if (
        number == "" ||
        number == 0 ||
        number == undefined ||
        number == null
      ) {
        return "$0.00";
      }
      number = parseFloat(number);
      let options = { 
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      };

      return "$" + Number(number / 100).toLocaleString("en", options);
    },
    formatDate(date) {
      if (date == "" || date == 0 || date == undefined || date == null) {
        return "";
      }
      return moment(date * 1000).format("MMMM DD, YYYY");
    },
    download() {
      window.location.href = this.stripeData.invoice_pdf;
    },
    send() {
      let msg = {
        title: "Send email to " + this.email + "?",
        confirmButtonText: "Yes, Send!",
      };
      this.$questionNotif(msg).then((result) => {
        if (result) {
          this.loading = true;
          this.$http
            .post(`billing/send-invoice/${this.$route.params.id}`)
            .then((data) => {
              this.$successNotif("Email sent successfully");
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    formatSubsDate(date) {
      const trialEndsAt = new Date(date)
      return trialEndsAt.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }
  },
};
</script>