<template>
  <div class="not-found">
    <div class="not-found__group">
        <img class="img" :src="notFound" alt="Not Found" v-if="notFound">
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'NotFound',
  computed: {
    ...mapState("layout", [
      "notFound",
    ]),
  },
  watch: {
    notFound() {
     if (this.notFound) {
      this.$store.commit('global/SET_PAGE_LOADER', false)            
     } 
    }
  },  
  created() {
    this.$store.commit('global/SET_PAGE_LOADER', true)            
  },
};
</script>
