<template>
    <v-menu
        class="site-menu"
        transition="slide-y-transition"
        bottom 
        offset-y>
        <template v-slot:activator="{ on, attrs }">
            <div>
                <v-flex class="d-flex justify-end ml-2">
                    <v-btn
                        class="transparent"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon>
                            <img class="site-menu-icon" :src="appMenuLogo" alt="Apps Menu">
                    </v-btn>
                </v-flex>
            </div>
        </template>

        <v-list class="site-menu-container pa-5">
            <h4 class="overline font-weight-black">Apps Menu</h4>
            <v-divider class="pb-3"/>
            
            <v-list-item-group v-if="!loading" color="primary">
                <div v-for="(client, index) in accessClients" :key='index' >
                    <a :href="client.link" target="_blank" v-if="client.access">
                        <v-list-item>
                            <v-list-item-icon>
                                <img class="site-menu__icon" :src="client.cover_photo" alt="App Menu" max-width="20">
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title >{{client.name}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </a>
                </div>
            </v-list-item-group>

            <p class="caption text-center" v-if="loading">Loading... Please wait</p>
            <p class="caption no-access-msg" v-if="!hasAccessClients && !loading">No data available</p>
        </v-list>
    </v-menu>
</template>

<script>
import appMenuLogo from '@/assets/images/site-menu.png'

export default {
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
        hasAccessClients() {
            if (this.accessClients.length == 0) {
                return false
            }

            let hasAccess = this.accessClients.map(
                client => client.access == true
            );
            return hasAccess.includes(true)    
        }
    },
    data() {
        return {
            loading: false,
            accessClients: [],
            appMenuLogo: appMenuLogo
        };
    },
    mounted() {
        this.renderSiteApp();
    },
    methods: {
        renderSiteApp() {
            this.loading = true
            this.$http
                .get(`user/app/menu/${this.user.id}`)
                .then((data) => {
                    this.accessClients = data.data
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                })
        },
    }
}
</script>
