<template>
    <div class="content">
        <div v-if="!loadBillingPortal && (this.is_super_admin || this.is_account_manager)">
            <breadcrumbs :links="breadcrumbs" />

            <div class="content-wrapper" v-show="!initLoading">
                <h1 class="font-weight-medium">{{ $route.meta.title }}</h1>

                <div v-if="userLimit">
                    <active-users-card :isDisabled="disableUserLimit" :isLoading="loading" :userLimit="userLimit" @fetchActiveUsers="getActiveUsers"></active-users-card>
                    <v-divider class="my-10"></v-divider>
                </div>

                <v-row class="grid-list-md">

                    <v-col class="px-5" cols="12" md="6" v-for="(app, index) in apps" :key="index">
                        <apps-card :app="app" @fetchApps="getApps" @fetchActiveUsers="getActiveUsers"></apps-card>
                    </v-col>

                </v-row>

                <v-divider class="my-10"></v-divider>

                <v-row>
                    <v-col class="px-5" cols="12" md="7">
                        <payment-method-card :billingInformation="billingInformation"></payment-method-card>
                    </v-col>
                    <v-col class="px-5" cols="12" md="5">
                        <billing-information-card
                            @fetchBillingInformation="updateBillingInformation"></billing-information-card>
                    </v-col>
                </v-row>

                <v-divider class="my-10"></v-divider>

                <invoice-table></invoice-table>

            </div>
        </div>
        <div v-else class="d-flex justify-center">
            <v-progress-circular class="mt-16" indeterminate size="30" color="primaryMain"></v-progress-circular>
        </div>
    </div>
</template>
  
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import ActiveUsersCard from "@/views/billing_portal/billing/ActiveUsersCard";
import AppsCard from "@/views/billing_portal/billing/AppsCard";
import PaymentMethodCard from "@/views/billing_portal/billing/PaymentMethodCard";
import BillingInformationCard from "@/views/billing_portal/billing/BillingInformationCard";
import InvoiceTable from "@/views/billing_portal/billing/InvoiceTable";
import { mapState } from "vuex";

export default {
    name: "IndexSingleBillingPortal",
    components: {
        Breadcrumbs,
        ActiveUsersCard,
        AppsCard,
        PaymentMethodCard,
        BillingInformationCard,
        InvoiceTable,
    },
    data() {
        return {
            adminBillingPortal: false,
            breadcrumbs: {
                0: {
                    title: "Home",
                    link: "home",
                },
                1: {
                    title: this.$route.meta.title,
                    link: this.$route.meta.name,
                },
            },
            initLoading: true,
            loading: false,
            apps: [],
            userLimit: null,
            billingInformation: {}
        };
    },
    computed: {
        ...mapState("billing", [
            "loadBillingPortal",
            'hasBillingAccess',
            'billingUrl',
            'renderedBillingPortal'
        ]),
        ...mapState("user", [
            "is_super_admin",
            "is_account_manager",
        ]),
        disableUserLimit() {
            return this.apps.filter(app => app.ends_at == null).length == 0;
        }
    },
    watch: {
        renderedBillingPortal() {

            if (!this.renderedBillingPortal) {
                return false
            }

            if (!this.hasBillingAccess || this.billingUrl === false) {
                this.$errorNotif("You dont have access to Billing Portal, Please contact your Company Admin with billing access", 10000)
                this.$router.push({ name: 'home' });
                this.$router.replace({ 'query': null });
                return false
            }

            if (!(this.is_super_admin || this.is_account_manager)) {
                window.location.href = this.billingUrl;
            }
        }
    },
    async created() {
        if (!(this.is_super_admin || this.is_account_manager) && this.hasBillingAccess) {
            window.location.href = this.billingUrl;
        }
        this.$store.commit('global/SET_PAGE_LOADER', true)
        await this.getActiveUsers()
        await this.getApps()
        this.initLoading = false
        this.$store.commit('global/SET_PAGE_LOADER', false)
    },
    methods: {
        updateBillingInformation(data) {
            this.billingInformation = data;
        },
        async getApps() {
            this.$store.commit('global/SET_PAGE_LOADER', true)

            await this.$http
                .get(`/billing/get-apps/`, {
                    params: {
                        userId: this.$route.params.id || null,
                    },
                })
                .then((data) => {
                    this.$store.commit('global/SET_PAGE_LOADER', false)
                    this.apps = data.data;
                })
                .catch((error) => {
                    this.$store.commit('global/SET_PAGE_LOADER', false)
                    this.$errorNotif("Something went wrong");
                });
        },
        async getActiveUsers() {
            this.loading = true;
            
            await this.$http
                .get(`/billing/get-active-users/`, {
                    params: {
                        userId: this.$route.params.id || null,
                    },
                })
                .then((data) => {
                    this.loading = false;
                    this.userLimit = data.data;
                })
                .catch((error) => {
                    this.loading = false;
                });
        }
    },
};
</script>
  
<style>
.price {
    font-size: 3rem;
    white-space: nowrap;
}

.unit {
    white-space: nowrap;
}
</style>