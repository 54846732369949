<template>
    <div
      class="cd-panel cd-panel--from-right js-cd-panel-main"
      :class="show ? 'cd-panel--is-visible' : ''"
    >
      <header class="cd-panel__header">
        <h4>{{ title }}</h4>
        <a
          href="#"
          @click.stop.prevent="close"
          class="cd-panel__close js-cd-close"
          >Close</a
        >
      </header>
      <div class="cd-panel__container">
        <div class="cd-panel__content">
        <v-img
            class="white--text align-end"
            height="200px"
            :src="imageDefault" 
            >
        </v-img>
        <div>
            <div class="d-flex justify-space-between">
                <div>
                    <h3>{{ product.name }}</h3>
                    <p class="product-type mb-0">
                        {{$formatProductType(product.type)}}
                    </p>
                    
                </div>
                <div class="text-right">
                    <p class="primary--text title my-0 mb-2">{{ price }}</p>
                    <div>
                        <v-select
                        v-if='selectPrice.interval != "one_time"'
                        v-model="selectPrice"
                        class="product-interval"
                        :items="pricings"
                        solo
                        :menu-props="{ bottom: true, offsetY: true }"
                        dense
                        return-object
                        item-text="interval"
                        label="Interval"
                    ></v-select>
                    <label class="caption" v-else>One Time Payment</label>
                    </div>
                </div>
            </div>
            <p>
                {{ product.description }}
            </p>

            <adds-on-section :product="product" @addsonTotal="addAddsonTotal" :scroll="true"></adds-on-section>
        </div>
        
        <v-divider></v-divider>

        <div class="py-5">
            <h4 class="mb-5 font-weight-light">Customer Information</h4>
            <v-text-field
              v-model="user.name"
              outlined
              readonly
              dense
              label="Name"
              autofocus
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Name
              </template>
            </v-text-field>
            <v-text-field
              v-model="user.email"
              outlined
              readonly
              dense
              label="Email"
              autofocus
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Email
              </template>
            </v-text-field>
            <v-text-field
              v-model="user.company.name"
              outlined
              readonly
              dense
              label="Company"
              autofocus
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Company
              </template>
            </v-text-field>

            <v-layout class="mt-1" v-if="hasPaymentMethod">
                <v-flex xs12 md6 lg6>
                    <v-text-field
                        v-model="user.pm_type"
                        outlined
                        readonly
                        dense
                        label="Card Type"
                        autofocus
                        >
                        <template #label>
                            <span class="red--text"><strong>* </strong></span>Card Type
                        </template>
                    </v-text-field>
                </v-flex>
                <v-flex xs12 md6 lg6 class="pl-3" >
                    <v-text-field
                        v-model="user.pm_last_four"
                        outlined
                        readonly
                        dense
                        label="Card Last Four Digit"
                        autofocus
                        >
                        <template #label>
                            <span class="red--text"><strong>* </strong></span>Card Last Four Digit
                        </template>
                    </v-text-field>
                </v-flex>
            </v-layout>
            <div class="text-right mb-5" v-if="hasPaymentMethod">
                <v-btn small color="primary" text @click="toggleCardPayment()">
                    <span v-if="showCardInput">
                        Use Current Card
                    </span> 
                    <span v-else>
                        Change Card Details
                    </span> 
                </v-btn>
            </div>

            <div v-show="showCardInput" :id="'checkoutPage-stripe-cardElement-'+product.id"></div>
            <span class="error--text caption" v-if="stripeError">{{stripeError}}</span>
            <div class="mt-10 text-right">
                <v-btn color="primary" @click="checkout()" :loading="loading">Checkout</v-btn>
            </div>
        </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import { loadStripe } from "@stripe/stripe-js";
import {
    find as _find,
} from 'lodash';
import imageDefault from '@/assets/images/revdojo_default.jpg'
import AddsOnSection from "@/components/manage_products/AddsOnSection.vue";


export default {
    name: 'CheckoutSlide',
    components: {
        AddsOnSection
    },
    props: {
      product: Object,
      title: String,
      show: Boolean,
    },
    data () {
        return {
            imageDefault: imageDefault,
            selectPrice: [],
            price: '',
            form: {
                id: '',
                name: '',
            },
            showCardInput: false,
            cardElement: {},
            stripeError: false,
            loading: false,
            addsOnItems: [],
        }
    },
    computed: {
        pricings() {
            return this.product.pricings
        },
        user() {
            return this.$store.state.user.current_user
        },
        hasPaymentMethod() {
            if (this.selectPrice.interval == 'one_time') {
                return false;
            }
            return this.user.pm_type && this.user.pm_last_four 
        },
       
    },
    watch: {
        selectPrice() {
            this.price = '$' + (this.selectPrice.unit_price / 100)
        },
        show(val) {
            if (val) {
                this.checkCurrentPayment()
            }
            if (this.product) {
                this.renderPrice()
            }
        },
    },
    mounted() {
        this.renderPrice()
        this.renderCard()
    },  
    methods: {
        addAddsonTotal(item) {
            this.price = '$' + (item.total + this.selectPrice.unit_price / 100)
            this.addsOnItems = item.addsonId
        },
        toggleCardPayment() {
            this.stripeError = false
            this.showCardInput = !this.showCardInput
            if (this.showCardInput) {
                this.renderCard()
            }
        },  
        async renderCard() {
            this.stripe = await loadStripe(this.product.pkToken);
            const elements = this.stripe.elements();
            this.cardElement = elements.create("card", {
                classes: {
                base: "product-credit-card px-3 py-3",
                },
            });
            this.cardElement.mount(`#checkoutPage-stripe-cardElement-${this.product.id}`);
        },
        renderPrice() {
            this.selectPrice  = _find(this.product.pricings, (price) => {
                return price.interval == 'month' || price.interval == 'one_time';
            });
        },
        checkCurrentPayment() {
            if (!this.hasPaymentMethod) {
                this.toggleCardPayment()
            }
        },
        close() {
            this.showCardInput = false
            this.$emit('close');
        },
        async checkout(){
            this.loading = true
            let paymentMethodId = ''
            if (this.showCardInput) {
                const { paymentMethod, error } = await this.stripe.createPaymentMethod(
                    "card",
                    this.cardElement,
                    {
                        billing_details: {
                            name: this.user.name,
                            email: this.user.email,
                        },
                    }
                );
                if (error) {
                    this.stripeError = error.message;
                    this.loading = false;
                    return false
                }
                
                paymentMethodId = paymentMethod.id
            }
            
            let form = {
                price: this.selectPrice,
                email: this.user.email,
                payment_method_id: paymentMethodId,
                central: true,
                addson: this.addsOnItems
            }
            
            this.$http
                .post('billing/subscribe', form)
                .then((result) => {
                    this.$meFetch()
                    this.loading = false
                    this.$store.commit('billing/SET_RELOAD', true);            
                    this.$store.dispatch('billing/renderBillingPortal', this.user)
                    this.$store.commit('user/SET_CURRENT_USER', result.data)
                    this.close()
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.status == 403 && error.response.data.type == 'card_error')  {
                        this.stripeError = error.response.data.msg
                    }
                });
        },
    },
};
</script>