var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('breadcrumbs',{attrs:{"links":_vm.breadcrumbs}}),_c('div',{staticClass:"content-wrapper"},[_c('h1',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('div',{staticClass:"mt-5 content-card"},[_c('div',[_c('div',{staticClass:"d-flex security__switch"},[_c('v-switch',{staticClass:"ma-0",attrs:{"inset":"","color":"primary","hide-details":""},on:{"change":function($event){return _vm.update()}},model:{value:(_vm.user.mfa.mfa_enable),callback:function ($$v) {_vm.$set(_vm.user.mfa, "mfa_enable", $$v)},expression:"user.mfa.mfa_enable"}}),_c('h3',{staticClass:"title primary--text"},[_vm._v(" Two Factor Authenticator "),_c('v-btn',{staticClass:"ml-",attrs:{"icon":"","color":"gray","x-small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"gray","small":""}},[_vm._v("mdi-information-variant")])],1)]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.info2FA)}})])],1)],1)],1),_c('div',{staticClass:"my-15"},[_c('v-row',{staticClass:"security__row"},[_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"5"}},[_c('h4',[_vm._v("Email Authenticator")])]),_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"5"}},[_c('p',{staticClass:"ma-0 body-2"},[_vm._v(_vm._s(_vm.user.email))])]),_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.email_verified_at),expression:"user.email_verified_at"}],staticClass:"ml-1",attrs:{"color":"primary"}},on),[_vm._v(" mdi-check-circle-outline ")]),_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.email_verified_at == null),expression:"user.email_verified_at == null"}],attrs:{"color":"red","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.verifyEmail()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close-circle-outline ")])],1)]}}])},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.email_verified_at),expression:"user.email_verified_at"}]},[_vm._v("Verified")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.email_verified_at == null),expression:"user.email_verified_at == null"}]},[_vm._v("Verify Email")])])],1)],1),_c('v-divider',{staticClass:"my-2",attrs:{"color":"F9B417"}}),_c('v-row',{staticClass:"security__row"},[_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"5"}},[_c('h4',[_vm._v("SMS Authenticator")])]),_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"5"}},[_c('p',{staticClass:"ma-0 body-2",class:!this.phoneNumber ? 
                                  'red--text' : ''},[_vm._v(" "+_vm._s(this.phoneNumber ? this.phoneNumber : 'Invalid Phone Number')+" ")])]),_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.sms_verified_at),expression:"user.sms_verified_at"}],staticClass:"ml-1",attrs:{"color":"primary"}},on),[_vm._v(" mdi-check-circle-outline ")]),_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.sms_verified_at == null),expression:"user.sms_verified_at == null"}],attrs:{"color":"red","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.verifySMS()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close-circle-outline ")])],1)]}}])},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.sms_verified_at),expression:"user.sms_verified_at"}]},[_vm._v("Verified")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.sms_verified_at == null),expression:"user.sms_verified_at == null"}]},[_vm._v("Verify SMS")])])],1)],1),_c('v-divider',{staticClass:"my-2",attrs:{"color":"F9B417"}}),_c('v-row',{staticClass:"security__row"},[_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"5"}},[_c('h4',[_vm._v("Security Questions")])]),_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"5"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.securityEnable),expression:"securityEnable"}],attrs:{"color":"blue","dark":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.showEditSecurityQuestion()}}},[_vm._v(" Edit Security Questions ")])],1),_c('v-col',{staticClass:"item",attrs:{"cols":"12","sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.securityEnable),expression:"securityEnable"}],staticClass:"ml-1",attrs:{"color":"primary"}},on),[_vm._v(" mdi-check-circle-outline ")]),_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.securityEnable),expression:"!securityEnable"}],attrs:{"color":"red","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.showSecurityQuestion()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close-circle-outline ")])],1)]}}])},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.securityEnable),expression:"securityEnable"}],ref:"enableSecQues"},[_vm._v("Enabled")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.securityEnable),expression:"!securityEnable"}]},[_vm._v("Set Security Questions")])])],1)],1)],1)]),_c('two-factor-verify-modal'),_c('email-verify-modal'),_c('sms-verify-modal'),_c('edit-security-question-modal',{attrs:{"security_questions":_vm.securityQuestions}}),_c('security-question-modal',{on:{"renderSecurityQuestions":_vm.renderSecurityQuestions}})],1),_c('devices-table')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }