var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"name":"activity-logs-modal","width":"1500","height":"auto","adaptive":true}},[_c('div',{staticClass:"pa-5 align-center"},[_c('div',{staticClass:"d-flex justify-space-between "},[_c('h2',[_vm._v("Activity Logs")]),_c('v-btn',{attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-divider'),_c('div',{staticClass:"ma-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.logs,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.per_page,"footer-props":{ disablePagination: _vm.paginationDisable }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.batch_id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('p',_vm._g({},on),[_vm._v(" "+_vm._s(item.batch_id.substring(0, 16) + " ...")+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.batch_id)+" ")])]}},{key:"item.change_field",fn:function(ref){
var item = ref.item;
return _vm._l((item.change_field),function(field,index){return _c('p',{key:index,staticClass:"ma-0"},[_vm._v(" "+_vm._s(field)+" ")])})}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"MMMM DD, YYYY"))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }