<template>
    <v-card class="fill-height">
        <v-container>
            <v-row>
                <v-col cols="12" lg="6">
                    <v-card-title>
                        <span class="mr-2">{{ app.product.name }}</span>
                        <v-chip class=" text-capitalize" size="x-small">
                            {{ addIntervalSuffix(app.pricing.interval) }}
                        </v-chip>
                    </v-card-title>
                    <div v-if="app.stripe_status != 'canceled' && isSubscription">
                        <v-card-text v-if="app.ends_at == null">Your plan will be renewed on {{ app.renewed_at
                        }}.</v-card-text>
                        <v-card-text v-else>Your plan will end at {{ app.formatted_ends_at }}.</v-card-text>
                    </div>
                </v-col>
                <v-col cols="12" lg="6">
                    <div class="text-right pa-2">
                        <span class="price font-weight-bold">${{ app.pricing.unit_price / 100 }}</span>&nbsp;
                        <span class="unit" v-if="isSubscription">per {{ app.pricing.interval }}</span>
                        <span class="unit" v-else>One Time Payment</span>
                    </div>
                </v-col>


            </v-row>

        </v-container>

        <div class="height">
            <div class="position-bottom" v-if="isSubscription">
                <v-divider></v-divider>
                <v-card-actions class="height">
                    <v-btn v-if="app.stripe_status == 'canceled'" v-show="!is_super_admin" text @click="handleReSubscribe()"
                        color="primaryMain">Re-subscribe plan</v-btn>
                    <v-btn v-else-if="app.ends_at == null" text @click="handleCancel()" color="primaryMain">
                        Cancel plan
                    </v-btn>
                    <v-btn v-else text @click="handleRenew()" color="primaryMain">Renew plan</v-btn>
                </v-card-actions>
            </div>
        </div>


    </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "AppsCard",
    components: {

    },
    props: {
        app: {
            type: Object,
            required: true
        }
    },
    data() {
        return {

        };
    },
    computed: {
        ...mapState("user", [
            "current_user",
            'is_super_admin',
            'is_account_manager',
            'is_dealer_manager'
        ]),
        isSubscription() {
            return this.app.name != 'one_time';
        }
    },
    created() {

    },
    methods: {
        addIntervalSuffix(interval) {
            if (interval == 'one_time') {
                return 'Lifetime access';
            }

            return (interval === 'month' || interval === 'year') ? interval + 'ly' : interval;
        },
        handleRenew() {
            let msg = {
                title: `Renew ${this.app.product.name} Plan?`,
                confirmButtonText: `Yes, Renew ${this.app.product.name} Plan!`,
            }

            this.$questionNotif(msg)
                .then((result) => {
                    if (result) {
                        this.$store.commit('global/SET_PAGE_LOADER', true)
                        this.$http
                            .post(`/billing/renew-app`, {
                                userId: this.$route.params.id,
                                appId: this.app.id
                            })
                            .then((data) => {
                                this.$store.commit('global/SET_PAGE_LOADER', false)
                                this.$emit('fetchApps');
                                this.$successNotif(`Successfully renewed ${this.app.product.name} plan`)
                            })
                            .catch((error) => {
                                this.$store.commit('global/SET_PAGE_LOADER', false)
                                if (error.response.status == 422) {
                                    this.$errorNotif(error.response.data.errors)
                                }
                            })

                    }
                })
        },
        handleCancel() {
            let msg = {
                title: `Cancel ${this.app.product.name} Plan?`,
                confirmButtonText: `Yes, Cancel ${this.app.product.name} Plan!`,
            }

            this.$questionNotif(msg)
                .then((result) => {
                    if (result) {
                        this.$store.commit('global/SET_PAGE_LOADER', true)
                        this.$http
                            .post(`/billing/cancel-app`, {
                                userId: this.$route.params.id,
                                appId: this.app.id
                            })
                            .then((data) => {
                                this.$store.commit('global/SET_PAGE_LOADER', false)
                                this.$emit('fetchApps');
                                this.$successNotif(`Successfully cancelled ${this.app.product.name} plan`)
                            })
                            .catch((error) => {
                                this.$store.commit('global/SET_PAGE_LOADER', false)
                                if (error.response.status == 422) {
                                    this.$errorNotif(error.response.data.errors)
                                }
                            })

                    }
                })
        },
        handleReSubscribe() {
            let msg = {
                title: `Re-subscribe ${this.app.product.name} Plan?`,
                confirmButtonText: `Yes, Re-subscribe ${this.app.product.name} Plan!`,
            }

            this.$questionNotif(msg)
                .then((result) => {
                    if (result) {

                        this.$store.commit('global/SET_PAGE_LOADER', true)

                        let form = {
                            app: this.app,
                            email: this.current_user.email,
                            central: true,
                        }

                        this.$http
                            .post('billing/subscribe', form)
                            .then((result) => {
                                this.$store.commit('global/SET_PAGE_LOADER', false)
                                this.$emit('fetchActiveUsers');
                                this.$emit('fetchApps');
                                this.$successNotif(`Successfully re-subscribed ${this.app.product.name} plan`)
                            })
                            .catch((error) => {
                                this.$store.commit('global/SET_PAGE_LOADER', false)
                                if (error.response.status == 422) {
                                    this.$errorNotif(error.response.data.errors)
                                }
                            });


                    }
                })

        }
    },
};
</script>

<style>
.position-bottom {
    bottom: 0;
    width: 100%;
    position: absolute;
    background: #fff;
}

.height {
    height: 3rem;
}
</style>