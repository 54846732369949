<template>
  <div>
    <v-alert
        v-if="!user.is_password_changed"
        text
        type="warning"
        >
        Your current password is still set to the initial password. 
        Please change your password <b class="cursor-pointer" @click="changePassword()">here</b>
    </v-alert>
    <div class="ma-16 mt-10 mb-16 headline">
      <h3>
        Welcome,
        <span class="font-weight-light">{{ user.name }}!</span>
      </h3>
    </div>
    <div class='home'>
      <app-card 
          class="item"
          v-for="(app, index) in accessClients" :key="index"
          :app="app"
          @reload="renderSiteApp()"
          page="home"
          :width="400">
      </app-card>
    </div>
  </div>
</template>
<script>
import AppCard from '@/components/cards/AppCard';

export default {
  components: {
      AppCard
  },
  name: 'Home',
  computed: {
      user() {
        return this.$store.state.user.current_user
      },
  },
  data() {
    return {
      loading: false,
      accessClients: '',
    }
  },
  created() {
    this.renderSiteApp()
    this.$meFetch()
  },
  methods: {
    changePassword() {
      this.$router.push({ name: 'profile', query: {'change_password' : true}});
    },
    renderSiteApp() {
      this.loading = true
      this.$store.commit('global/SET_PAGE_LOADER', true)            
      this.$http
          .get(`user/app/menu/${this.user.id}`)
          .then((data) => {
              this.accessClients = data.data;
              this.loading = false
              this.$store.commit('global/SET_PAGE_LOADER', false)            
          })
          .catch((error) => {
              this.loading = false
          })
      },
  }
};
</script>