<template>
  <div>
    <div class="content">
      <!-- <product-filter @filter="filter" /> -->
    </div>
    <div class="content-card__header">
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      :options.sync="options"
      :server-items-length="pagination.total"
      :items-per-page="pagination.per_page"
      :footer-props="{
        disablePagination: paginationDisable,
        'items-per-page-options': [5, 10, 20, 50],
      }"
    >
      <template v-slot:item.created_at="{ item }">
        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
      </template>
      <template v-slot:item.deleted_at="{ item }">
        <span>{{ new Date(item.deleted_at).toLocaleString() }}</span>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="d-flex">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="redirecToPortal(item)"
                  x-small
                  color="primaryMain"
                  class="mx-1 primaryMainText--text"
                  v-bind="attrs"
                  v-on="on"
                >
                Billing Portal
                </v-btn>
              </template>
              <span>Go to {{ item.name }} Billing Portal</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { find as _find } from "lodash";

export default {
  name: "SubscribedUserTable",
  components: {
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Company", value: "company.name" },
        { text: "Card Type", value: "pm_type" },
        { text: "Last Four Digit", value: "pm_last_four" },
        { text: "", value: "action", width: "180" },
      ],
      items: [],
      search: "",
      pagination: {
        total: 1,
        per_page: 10,
        current_page: 1,
        search: "",
        sortBy: "",
        sortDesc: false,
      },
      options: {},
      paginationDisable: false,
      timeout: null,
      filters: null,
    };
  },
  watch: {
    options: {
      handler() {
        this.changeTable();
      },
      deep: true,
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.paginationDisable = true;
      this.items = [];

      this.$http
        .get(`billing/get-subscibed-users`, {
          params: {
            per_page: this.options.itemsPerPage,
            current_page: this.pagination.current_page,
            sortBy: this.pagination.sortBy,
            sortDesc: this.pagination.sortDesc,
            product_id: this.$route.params.id,
            filter: this.filters,
            redirectBack: this.$route.path,
          },
        })
        .then((data) => {
          this.loading = false;
          this.items = data.data;
          this.pagination.total = data.data.total;
          this.pagination.current_page = data.data.current_page;
          this.pagination.per_page = data.data.per_page;
          this.paginationDisable = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$errorNotif("Something went wrong");
        });
    },
    changeTable() {
      this.pagination.current_page = this.options.page;
      this.pagination.per_page = this.options.itemsPerPage;

      this.pagination.sortBy =
        this.options.sortBy !== undefined ? this.options.sortBy[0] : "";
      this.pagination.sortDesc =
        this.options.sortDesc !== undefined ? this.options.sortDesc[0] : "";

      this.fetch();
    }, 
    redirecToPortal(item) {
      this.$router.push({ name: 'billing', params: { id: item.id } });
    },
  },
};
</script>
