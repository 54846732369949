<template>
  <v-app id="app">
    <!-- <app-back></app-back> -->
    <qr-widget></qr-widget>
    <router-view :key="$route.fullPath"></router-view>
     <v-overlay :value="pageLoader">
      <v-progress-circular
        indeterminate
        size="64"
        color="primaryMain"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { mapState } from "vuex";
import { isEmpty as _isEmpty } from 'lodash';
import QrWidget from '@/components/Qrcode/QrWidget';
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

export default {
  name: 'App',
  components: {
    QrWidget,
  },
  data () {
    return {
      pusherKey: ''
    }
  }, 
  computed: {
    ...mapState("global", ["pageLoader"]),
    ...mapState("layout", ["admin","labels"]),
    user() {
       return this.$store.state.user.current_user
    },
  },
  async created() {
    this.$auth.refresh();
    await this.$meFetch();

    this.logoutIfCookieAuthExpired();
    this.layout()
    this.checkAppBack()
    this.checkAuth()
    this.fetchCompany();
    this.checkShopLogin();
    this.setupPusher()
    this.updateRoutes()
  },
  watch: {
    admin() {
      this.checkUserNotExist()
    },
    user() {
      this.checkPositionManager()
    }
  },
  methods: {
    checkPositionManager() {
      this.$http
      .get('user/check-position-manager', {
        params: {
            user_id: this.user.id,
        },
      })
      .then(({data}) => {
        this.$store.commit('user/SET_CURRENT_USER_POSITION_MANAGER', data)
      })
      .catch((error) => {
      });
    },
    setupPusher() {
      this.$http
        .get('get-env/')
        .then(({data}) => {
            Vue.prototype.$echo = new Echo({
            broadcaster: 'pusher',
            key: data.pusher_key,
            cluster: data.pusher_cluster,
            forceTLS: true,
            authEndpoint: '/broadcasting/auth',
            encrypted: true,
            auth: {
              headers: {
                Authorization: `Bearer ${this.$auth.token()}`,
              },
            },
          });
          
          this.centralLogoutListener()
        })
    },
    centralLogoutListener() {
      Vue.prototype.$echo
        .channel('sso-logout')
        .listen('SSOLogout', (e) => {
          let centralId = this.$auth.user() ? this.$auth.user().central_id : this.$route.query.user_central_id 
          if (centralId == e.user_central_id) {
              localStorage.removeItem('nextUrl')
             this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
             window.location.href = window.location.origin+'/okta/logout';
          }
        });
    },  
    checkShopLogin() {
      let shopLogin = this.$cookies.get('shoplogin')
      if (shopLogin) {
        this.$router.push({ name: 'shop.login', query: shopLogin});
      }
    },  
  
    fetchCompany() {
      if (this.user) {
        this.$store.dispatch('company/getList')
      }
    },

    checkAppBack() {
      let cookieAppOrigin = this.$cookies.get('app_origin')
      let queryAppOrigin = this.$route.query.app_origin

      if (queryAppOrigin || cookieAppOrigin) {
        let value = queryAppOrigin ?? cookieAppOrigin
        this.$http
        .get('user/app-back/'+ value)
        .then((result) => {
          this.$cookies.set('app_origin', result.data.id, '20y');
          this.$store.commit('global/SET_APP_BACK', result.data)            
          
          let query = Object.assign({}, this.$route.query);
          delete query.app_origin;
          this.$router.replace({ query });
        })
      }
    },
    checkUserNotExist() {
      let queryApp = this.$route.query.app
      let queryRedirect = this.$route.query.redirect
      let msg = false
      let title = false
      if (queryApp && queryRedirect == 'user_not_exist') {
        msg = `You don't have access to ${queryApp} or you have an inactive account.<br>Please contact admin to setup your account.
            <a class="font-weight-bold" href="mailto:${this.admin.email}">
                ${this.admin.email}
            </a>`;

        title = `User does not exist on ${queryApp} `
      }

      if (queryApp && queryRedirect == 'user_no_access') {
        msg = `You don't have access to ${queryApp}. <br> Please contact admin to setup your account.
            <a class="font-weight-bold" href="mailto:${this.admin.email}">
                ${this.admin.email}
            </a>`;
        title = `Unable to access ${queryApp}`
      }

      if (msg) {
        this.$warningNotif(msg, title)
          .then((result) => {
            if (result) {
              this.$router.replace({ });
              location.reload()
            }
          })
      }
    },
    checkAuth() {
      if (this.$route.name == 'Login' || this.$route.name == 'shop.login' ) { 
        return false
      }

      this.$http
      .get('/checkAuth')
      .catch((error) => {
        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
        window.location.href = window.location.origin+'/okta/logout';
      });
    },
    layout() {
      let companyId 
      let loginToggle = false

      if (this.user) {
        companyId = this.user.company_id
      } else {
        companyId = this.$cookies.get('companyId')
      }

      if (this.$route.query.company_id) {
        companyId = this.$route.query.company_id
        loginToggle = true
      }

      this.$renderLayout(companyId, loginToggle)
    },
    logoutIfCookieAuthExpired() {
     if (!_isEmpty(this.getCookie('default_auth_token'))) {
        return false;
      }

      if (this.$route.name == 'Login' || this.$route.name == 'shop.login' ) { 
        return false
      }
      
      this.$auth.logout();
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length > 1) {
        return parts.pop().split(';').shift();
      }
    },
    updateRoutes() {
      const mainRoutes = this.$router.options.routes.find(route => route.name === 'main').children;
      const specificDealerRoute = mainRoutes.find(route=>route.name === 'specific_dealers');

      specificDealerRoute.meta.title = this.labels.dealers

    }
  },
};
</script>
