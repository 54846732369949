<template>
  <v-app>
    <nav>
      <aside-bar v-model="drawer"/>
      <nav-bar v-model="drawer"/>
    </nav>
    <v-main class="main-layout">
        <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import AsideBar from '@/layouts/AsideBar.vue';
import NavBar from '@/layouts/NavBar.vue';

export default {
  name: 'Main',
  components: {
    NavBar,
    AsideBar
  },
  data() {
    return {
      drawer: null,
    }
  },
  created() {
  },
  methods: {
  
  }
};
</script>