<template>
    <modal name="edit-invite-users-modal" width="500" height="auto" :adaptive="true">
        <div class="pa-5 align-center">
            <div class="d-flex justify-space-between">
                <h2>Invite User</h2>
                <v-btn @click="cancel()" icon color="black">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </div>
        <v-divider />
        <div class="ma-5">

            <v-form @submit.prevent="handleClickSave">
                <v-row>
                    <v-col :cols="colRow(invite)">
                        <v-text-field v-model="invite.email" label="Email" required disabled></v-text-field>
                    </v-col>
                    <v-col :cols="colRow(invite)">
                        <v-select v-model="invite.role_id" :items="filteredRoles" label="Role" required
                            item-text="friendly_name" item-value="id"></v-select>
                    </v-col>

                    <v-col :cols="colRow(invite)" v-if="colRow(invite) === 3">
                        <v-autocomplete item-text="name" item-value="id" label="Company" v-model="invite.company_id"
                            :items="companyList" autocomplete="off" @change="handleChangeCompany(invite)" hide-details=true>
                        </v-autocomplete>

                        <div v-if="showError && errorData && errorData[`invite.${i}.company_id`]"
                            class="error-message mt-1">
                            {{ errorData[`invite.${i}.company_id`][0] }}
                        </div>
                    </v-col>

                    <v-col :cols="colRow(invite)" v-if="colRow(invite) === 3 || colRow(invite) === 4">
                        <v-autocomplete item-text="name" item-value="id" label="Store" v-model="invite.specific_dealer_id"
                            :items="invite.specific_dealer_list" autocomplete="off" :loading="loadDealer" hide-details=true
                            :no-data-text="loadDealer ? 'Loading' : 'No data available'">
                            <template v-slot:append-item>
                                <div v-if="!loadedAllSpecificDealer(invite)" v-intersect="onIntersectSpecificDealer(invite)"
                                    class="pa-4 primary--text">
                                    Loading more items ...
                                </div>
                            </template>
                        </v-autocomplete>

                        <div v-if="showError && errorData && errorData[`invite.${i}.specific_dealer_id`]"
                            class="error-message mt-1">
                            {{ errorData[`invite.${i}.specific_dealer_id`][0] }}
                        </div>
                    </v-col>

                </v-row>


                <div class="d-flex justify-space-between mt-10">
                    <div>
                        <v-btn @click="cancel">Cancel</v-btn>
                    </div>
                    <div>
                        <v-btn color="primaryMain" type="submit" class="primaryMainText--text">
                            Save
                        </v-btn>
                    </div>
                </div>
            </v-form>


        </div>
    </modal>
</template>
  
<script>
import { mapState } from "vuex";
import {
    find as _find,
    some as _some,
    filter as _filter,
    includes as _includes,
} from 'lodash';

export default {
    name: "EditInviteUsersModal",
    props: {
        inviteUser: {
            type: Object,
            default: function () {
                return {
                    email: '',
                    role_id: '',
                    company_id: this.current_user.company_id,
                    specific_dealer_id: 0,
                    specific_dealer_list: [],
                    specific_dealer_setting: {
                        page: 1,
                        per_page: 20,
                        total: ''
                    }
                };
            }
        }
    },
    data() {
        return {
            trueCarCentralId: '16deade7-d7d9-49bb-a3d1-ff3eba8cc153',
            loading: false,
            headers: [
                { text: "App", value: "client" },
                { text: "Model", value: "model" },
                { text: "Type", value: "type" },
                { text: "Response Code", value: "response_http_code", width: 350 },
                { text: "Exception", value: "exception", width: 350 },
                { text: "Date", value: "created_at" },
            ],
            roles: [],
            invite: {
                email: '',
                role_id: '',
                company_id: 0,
                specific_dealer_id: 0,
                specific_dealer_list: [],
                specific_dealer_setting: {
                    page: 1,
                    per_page: 20,
                    total: ''
                }
            },
            specificDealers: [],
            specificDealerSetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            errorData: null,
            showError: false,
            intersectLoading: false,
            loadDealer: false,
        };
    },
    watch: {
        inviteUser: {
            async handler() {

                this.invite = {
                    ...this.inviteUser,
                    specific_dealer_list: [],
                    specific_dealer_setting: {
                        page: 1,
                        per_page: 20,
                        total: ''
                    }
                }

                await this.fetchSpecificDealer(this.inviteUser.company_id);

                this.invite.specific_dealer_id = this.inviteUser.specific_dealer_id;
            },
        }
    },
    async mounted() {
        await this.fetch();

        this.rolesCondition();
    },
    computed: {
        ...mapState("user", [
            "current_user",
            'is_super_admin',
            'is_account_manager',
            'is_dealer_manager',
        ]),
        //Roles
        superAdmin() {
            return this.findRole('super-administrator')
        },
        accountManager() {
            return this.findRole('account-manager')
        },
        secretShopper() {
            return this.findRole('secret-shopper')
        },
        dealerManager() {
            return this.findRole('specific-dealer-manager')
        },
        salesperson() {
            return this.findRole('salesperson')
        },
        csm() {
            return this.findRole('csm')
        },
        filteredRoles() {
            return this.roles.filter(role => role.value);
        },
        companyList() {
            return this.$store.state.company.company_list;
        },
    },
    methods: {
        cancel() {
            this.$modal.hide("edit-invite-users-modal");
        },
        async fetch() {
            this.loading = true;
            this.paginationDisable = true;
            this.logs = [];
            await this.$http
                .get(`/roles`)
                .then((data) => {
                    this.loading = false;
                    this.roles = data.data;
                })
                .catch((error) => {
                    this.$errorNotif("Something went wrong");
                });
        },
        copyUrl(data) {
            this.$successNotif("Copied URL");
            navigator.clipboard.writeText(data);
        },
        handleClickSave() {
            this.loadingResync = false;
            this.$http
                .put(`/revdojo-api/v1/invite-users`, this.invite)
                .then((data) => {
                    this.loadingResync = false;
                    this.$successNotif("Successfully saved");
                    this.$emit("save");
                    this.$modal.hide("edit-invite-users-modal");

                    this.invite = {
                        email: '',
                        role_id: ''
                    };
                })
                .catch((error) => {
                    this.loadingResync = false;
                    this.$errorNotif("Something went wrong");
                });

        },
        rolesCondition() {
            if (this.is_super_admin) {
                this.superAdmin.value = true
                this.secretShopper.value = true
            } else {
                this.superAdmin.value = false
                this.secretShopper.value = false
            }

            this.is_account_manager || this.is_super_admin
                ? this.accountManager.value = true
                : this.accountManager.value = false

            //only show csm if truecar
            this.current_user.company.central_id != this.trueCarCentralId
                ? this.csm.value = false
                : this.csm.value = true

            //hide dealer manager for non automotive
            Boolean(this.current_user.company.is_automotive)
                ? this.dealerManager.value = true
                : this.dealerManager.value = false

        },
        findRole(role) {
            const foundRole = _find(this.roles, {
                name: role,
            });
            return foundRole || {};
        },
        colRow(user) {
            if (this.is_super_admin) {
                return 3;
            }
            return user && user.role_id === this.dealerManager.id && !this.is_dealer_manager ? 4 : 6;
        },
        async fetchSpecificDealer(companyId = null) {
            this.loadDealer = true;
            await this.$http
                .get('specific-dealer', {
                    params: {
                        per_page: this.invite.specific_dealer_setting.per_page,
                        current_page: this.invite.specific_dealer_setting.page,
                        company_id: companyId || this.current_user.company_id,
                    },
                })
                .then((result) => {
                    this.invite.specific_dealer_list = [
                        ...this.invite.specific_dealer_list,
                        ...result.data.data
                    ]
                    this.invite.specific_dealer_setting.total = result.data.total
                    this.loadDealer = false
                    this.intersectLoading = false;

                    if (this.specificDealers.length == 0) {
                        this.specificDealers = result.data.data
                    }
                })
        },
        onIntersectSpecificDealer(user) {
            if (this.intersectLoading) {
                return;
            }

            this.intersectLoading = true;
            user.specific_dealer_setting.page += 1
            this.fetchSpecificDealer(user.company_id)
        },
        handleChangeRole(user) {
            if (user && user.role_id === this.dealerManager.id && !this.is_dealer_manager) {
                user.specific_dealer_id = null;
            }
        },
        handleChangeCompany(invite) {
            invite.specific_dealer_id = null;
            invite.specific_dealer_list = []
            invite.specific_dealer_setting = {
                page: 1,
                per_page: 20,
                total: ''
            }
            this.fetchSpecificDealer(invite.company_id)
        },
        loadedAllSpecificDealer(user) {
            return user.specific_dealer_list.length >= user.specific_dealer_setting.total
        },
    },
};
</script>
  