<template>
    <div class="content content-full">
        <breadcrumbs :links="breadcrumbs"/>

        <div class="content-wrapper">
            <h1 class="font-weight-medium">{{ dealersTitle }}
                <span class="title font-weight-thin">Create</span>
            </h1>

            <v-layout row  class="mt-5">
                <v-flex xs12 lg12 class="px-4 content-image" >
                    <form @submit.prevent="submit()"  method="PUT">
                        <v-card class="pa-4 d-flex flex-column" min-height="400">
                            <h2 class="title">Information</h2>
                            <v-divider/>

                            <v-container class="my-5 content-container">

                                <v-autocomplete
                                    :disabled="!is_super_admin"
                                    v-model="form.company_id"
                                    :items="company_list"
                                    outlined
                                    label="Company"
                                    :rules="errors.company_id"
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    required
                                    bottom
                                    autocomplete="off"
                                    @blur="outFocusCompanyField()"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Company
                                    </template>
                                </v-autocomplete>

                                <v-text-field
                                    label="Name"
                                    placeholder="Name"
                                    outlined
                                    dense
                                    required
                                    v-model="form.name"
                                    :rules="errors.name"
                                >
                                <template #label>
                                    <span class="red--text"><strong>* </strong></span>Name
                                </template>
                                </v-text-field>

                            </v-container>
                            <v-spacer/>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                color="primaryMain"
                                dark
                                :loading="loading"
                                class="mt-5 primaryMainText--text"
                                type="submit" > Submit </v-btn>
                            </v-card-actions>
                        </v-card>
                    </form>
                </v-flex>
            </v-layout>   
        </div>
    </div> 
</template>

<script>
import Vue from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapState } from "vuex";

Vue.use(require('vue-moment'));

export default { 
    computed: {
        ...mapState("layout", ["labels"]),
        ...mapState("user", [
          'is_super_admin', 
          'current_user', 
        ]),
        company_list() {
            return this.$store.state.company.company_list;
        },
        loadedAllCompany() {
            return this.companies.length >= this.companySetting.total
        },
        breadcrumbs() {
            return {
                '0': {
                    title: 'Home',
                    link: 'home',
                },
                '1': {
                    title: this.dealersTitle,
                    link: this.$route.meta.type,
                },
                '2': {
                    title: 'Create',
                    link: this.$route.meta.type+'.create',
                }
            };
        },
        dealersTitle() {
            return this.labels !== undefined && this.labels.dealers !== undefined ? this.labels.dealers.charAt(0).toUpperCase() + this.labels.dealers.slice(1) : '';
        }
            
    },
    data() {
        return {
            loading: false,
            form: {
                name: '',
                company_id: ''
            },
            companies: [],
            companySetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            loadCompany: false,
            errors: [],
        };
    },
    created() {
        this.$store.dispatch('company/getList', { is_automotive:1 } )
        this.renderInitialCompany()
    },
    methods: {
        renderInitialCompany() {
            this.form.company_id = this.current_user.company_id
        },
        submit() {
            this.loading = true
            this.errors = []

            this.$http
            .post(`/specific-dealer`, this.form)
            .then((result) => {
                this.loading = false
                this.$successNotif('Successfully created a '+this.labels.dealer)
                this.resetForm();
            })
            .catch((error) => {
                if (error.response.status == 422){
                    this.errors = error.response.data.errors
                    this.$errorNotif('Invalid inputs, Please check the details')
                }
                this.loading = false
            })
        },
        resetForm() {
            this.form.name = null;
        },
        outFocusCompanyField() {
           if (this.form.company_id == null) {
                this.renderInitialCompany()
            }
        }
    },
    components: {
        Breadcrumbs,
    },
}
</script>