<template>
    <v-card class="pa-4 mb-5">
        <v-tabs grow v-model="tab">
          <v-tab v-if="hasAddsOnSection">Add ons</v-tab>
          <v-tab>Subscribed Users</v-tab>
        </v-tabs>
        <v-divider />

        <v-tabs-items v-model="tab">
          <v-tab-item v-if="hasAddsOnSection">
            <adds-on-table></adds-on-table>
          </v-tab-item>
          <v-tab-item>
            <subscribed-user-table></subscribed-user-table>
          </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import SubscribedUserTable from "@/views/subscribed_users/SubscribedUserTable";
import AddsOnTable from "@/views/adds_on/AddsOnTable";

export default {
  name: "IndexSubscibedUser",
  components: {
    SubscribedUserTable,
    AddsOnTable
  },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    ...mapState("product", [
      "currentProduct",
    ]),

    hasAddsOnSection() {
      return this.currentProduct.type != 'ADDS_ON' && this.currentProduct.type != 'USAGE_BASED'
    }
  },
  created() {},
  methods: {},
};
</script>
