import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import {
  find as _find,
  filter as _filter,
  map as _map,
  some as _some,
} from 'lodash-es';
import Login from '@/layouts/Login.vue'
import ShopLogin from '@/layouts/ShopLogin.vue'
import NotFound from '@/layouts/NotFound.vue';
import Empty from '@/layouts/Empty.vue';
import MainLayout from '@/layouts/Main.vue';
import Home from '@/views/Home.vue';
import Profile from '@/views/Profile.vue';
import Security from '@/views/Security.vue';
import Preference from '@/views/Preference.vue';
import InvitationLink from '@/views/InvitationLink.vue';

import IndexUsers from '@/views/users/IndexUsers.vue';
import CreateUsers from '@/views/users/CreateUsers.vue';
import EditUsers from '@/views/users/EditUsers.vue';

import IndexCompanies from '@/views/companies/IndexCompanies.vue';
import CreateCompanies from '@/views/companies/CreateCompanies.vue';
import EditCompanies from '@/views/companies/EditCompanies.vue';
import ShowInvoice from '@/views/companies/ShowInvoice.vue';

import IndexSpecificDealers from '@/views/specific_dealers/IndexSpecificDealers.vue';
import CreateSpecificDealers from '@/views/specific_dealers/CreateSpecificDealers.vue';
import EditSpecificDealers from '@/views/specific_dealers/EditSpecificDealers.vue';

import IndexProducts from '@/views/products/IndexProducts.vue';
import EditProducts from '@/views/products/EditProduct.vue';
import CreateProduct from '@/views/products/CreateProduct.vue';

import IndexPositions from '@/views/positions/IndexPositions.vue';
import EditPosition from '@/views/positions/EditPosition.vue';
import CreatePosition from '@/views/positions/CreatePosition.vue';

import IndexManageProduct from '@/views/manage_products/IndexManageProduct.vue';
import IndexBillingPortal from '@/views/billing_portal/IndexBillingPortal.vue';
import IndexSingleBillingPortal from '@/views/billing_portal/IndexSingleBillingPortal.vue';



Vue.use(VueRouter)

const routes = [
  {
    path: '/pre-login',
    name: 'shop.login',
    component: ShopLogin,
    meta: {
      title: 'Shop Login'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false,
      title: 'Login'
    },
  },
  {
    path: '/',
    component: MainLayout,
    name: 'main',
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          title: 'Home',
          icon: 'mdi-home',
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
          title: 'Profile',
          icon: 'mdi-account',
          group: 'Settings',
          groupIcon: 'mdi-cog'
        }
      },
      {
        path: '/preference',
        name: 'preference',
        component: Preference,
        meta: {
          title: 'Preference',
          icon: 'mdi-format-color-fill',
          allowed: ['super-administrator','account-manager'],
          group: 'Settings',
          groupIcon: 'mdi-cog',
          hide: 'single-user',
        }
      },
      {
        path: '/users',
        component: Empty,
        name: 'users',
        meta: {
          title: 'Users',
          icon: 'mdi-account-multiple',
          hide: 'single-user',
          allowed: ['super-administrator','account-manager','specific-dealer-manager']
        },
        children: [
            {
                path: '',
                name: 'users',
                component: IndexUsers,
                meta: {
                    title: 'Users',
                    type: 'users',
                    hide: 'single-user',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
                path: 'create',
                name: 'users.create',
                component: CreateUsers,
                meta: {
                    title: 'Users',
                    type: 'users',
                    hide: 'single-user',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
                path: ':id',
                name: 'users.edit',
                props: true,
                component: EditUsers,
                meta: {
                    title: 'Users',
                    type: 'users',
                    hide: 'single-user',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
              path: ':id/view',
              name: 'users.view',
              props: true,
              component: EditUsers,
              meta: {
                  title: 'Users',
                  type: 'users',
                  hide: 'single-user',
                  allowed: ['super-administrator','account-manager','specific-dealer-manager']
              },
          },
        ]
      },
      {
        path: '/companies',
        name: 'companies',
        component: Empty,
        meta: {
          title: 'Companies',
          icon: 'mdi-city',
          hide: 'single-user',
          allowed: ['super-administrator','account-manager','specific-dealer-manager']
        },
        children: [
            {
                path: '',
                name: 'companies',
                component: IndexCompanies,
                meta: {
                    title: 'Companies',
                    type: 'companies',
                    hide: 'single-user',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
                path: 'create',
                name: 'companies.create',
                component: CreateCompanies,
                meta: {
                    title: 'Companies',
                    type: 'companies',
                    hide: 'single-user',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
                path: ':id/edit',
                name: 'companies.edit',
                props: true,
                component: EditCompanies,
                meta: {
                    title: 'Companies',
                    type: 'companies',
                    hide: 'single-user',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
              path: ':id/view',
              name: 'companies.view',
              props: true,
              component: EditCompanies,
              meta: {
                  title: 'Companies',
                  type: 'companies',
                  hide: 'single-user',
                  allowed: ['super-administrator','account-manager','specific-dealer-manager']
              },
            },
            {
              path: ':companyId/invoice/:id',
              name: 'companies.invoice',
              component: ShowInvoice,
              meta: {
                  title: 'Invoice',
                  type: 'invoice',
                  allowed: ['super-administrator','account-manager','specific-dealer-manager']
              },
            }
        ]
      },
      {
        path: '/specific',
        name: 'specific_dealers',
        component: Empty,
        meta: {
          title: 'Stores',
          icon: 'mdi-handshake',
          hide: 'single-user',
          non_auto: true,
          allowed: ['super-administrator','account-manager','specific-dealer-manager']
        },
        children: [
            {
                path: ':value',
                name: 'specific_dealers.dynamic',
                component: IndexSpecificDealers,
                meta: {
                    title: 'Stores',
                    type: 'specific_dealers',
                    hide: 'single-user',
                    non_auto: true,
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
                path: ':value/create',
                name: 'specific_dealers.create',
                component: CreateSpecificDealers,
                meta: {
                    title: 'Stores',
                    type: 'specific_dealers',
                    hide: 'single-user',
                    non_auto: true,
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
                path: ':value/:id',
                name: 'specific_dealers.edit',
                props: true,
                component: EditSpecificDealers,
                meta: {
                    title: 'Stores',
                    type: 'specific_dealers',
                    hide: 'single-user',
                    non_auto: true,
                    allowed: ['super-administrator','account-manager','specific-dealer-manager']
                },
            },
            {
              path: ':value/:id/view',
              name: 'specific_dealers.view',
              props: true,
              component: EditSpecificDealers,
              meta: {
                  title: 'Stores',
                  type: 'specific_dealers',
                  hide: 'single-user',
                  non_auto: true,
                  allowed: ['super-administrator','account-manager','specific-dealer-manager']
              },
            },
        ]
      },
      {
        path: '/positions',
        name: 'positions',
        component: Empty,
        meta: {
          title: 'Positions',
          icon: 'mdi-account-tie',
          allowed: ['super-administrator','account-manager','specific-dealer-manager'],
        },
        children: [
            {
                path: '',
                name: 'positions',
                component: IndexPositions,
                meta: {
                    title: 'Positions',
                    type: 'positions',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager'],
                },
            },
            {
                path: 'create',
                name: 'positions.create',
                component: CreatePosition,
                meta: {
                    title: 'Positions',
                    type: 'positions',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager'],
                },
            },
            {
                path: ':id',
                name: 'positions.edit',
                props: true,
                component: EditPosition,
                meta: {
                    title: 'Positions',
                    type: 'positions',
                    allowed: ['super-administrator','account-manager','specific-dealer-manager'],
                },
            },
        ]
      },
      {
        path: '/invitation-link',
        name: 'invitation_link',
        component: InvitationLink,
        meta: {
          title: 'Invitation Link',
          icon: 'mdi-link',
          hide: 'single-user',
          allowed: ['super-administrator','account-manager']
        }
      },
    ]
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  linkActiveClass: "active",
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/' : process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const user = router.app.$auth.user();

  if (!user && to.query.shared == 'true' && to.name !== 'Login') {
    router.push({
      path: '/login',
      query: {
        nextUrl: to.name,
        company_id: to.query.company_id,
        shared: to.query.shared,
      }
    })
  }

  if (to.path == '/' ) {
    return next({name: 'home'})
  }

  if (to.path != '/users/' ) {
    localStorage.userTab = 0;
  }

  if (user === null) {
    return next();
  }

  const { roles } = user;
  const userRoles = _map(roles, (role) => role.name);
  if (
    to.matched.some((record) => 'allowed' in record.meta) &&
    !_some(userRoles, (role) => to.meta.allowed.includes(role))
  ) {

    next({
      name: 'home',
    });
  }

  let isSingleUser = user.company.is_single_user;

  if (to.meta.hide == 'single-user' && isSingleUser) {
    next({
      name: 'home',
    });
  }

  if (to.meta.non_auto == true && !user.company.is_automotive) {
    next({
      name: 'home',
    });
  }

  return next();
});

router.afterEach((to, from) => {
  const DEFAULT_TITLE = store.state.layout.tabTitle;
  Vue.nextTick(() => {
      let title = to.meta.title ?
        to.meta.title + ' | ' + DEFAULT_TITLE :
        DEFAULT_TITLE;
      document.title = title

      var link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
      link.href = store.state.layout.favicon;
  });
});

Vue.router = router;
export default router
