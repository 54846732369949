<template>
  <modal 
    class="products-modal"
    name="products-modal" 
    width="800"  
    height="auto"  
    :clickToClose="true"
    :adaptive="true" >

    <div class="ma-5" >
        <div class="d-flex justify-space-between ">
            <h2>Apps</h2>
            <v-btn
                @click="cancel()"
                icon
                color="black"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <p>{{items.name}}</p>
    </div>
    <div class="products-modal__content">
        <app-card 
            class="item"
            v-for="(app, index) in items.apps" :key="index"
            :app="app" 
            :width="300">
        </app-card>
    </div>
  </modal>
</template>
<script>
import AppCard from '@/components/cards/AppCard';

export default {
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
    },
    props: [
        'items'
    ],
    data() {
        return {
        };
    },
    methods: {
        cancel() {
            this.$modal.hide('products-modal')
        },
    },
    components: {
        AppCard
    }
}
</script>
