<template>
<div>
    <h1 class="font-weight-medium mt-10 mb-5">Device Management</h1>
    <v-data-table
        :headers="headers"
        :items="devices"
        :items-per-page="10"
        class="elevation-1"
        :loading="loading"
    >
        <template v-slot:item.uuid="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                <p v-on="on">
                    {{item.uuid.substring(0, 16) + " ..." }}
                </p>
                </template>
                {{ item.uuid }}
            </v-tooltip>
        </template>

        <template v-slot:item.browser="{ item }">
            {{item.browser + ' ( v' + item.browser_version + ' )'}}
        </template>

        <template v-slot:item.platform="{ item }">
            {{item.platform + ' ( v' + item.platform_version + ' )'}}
        </template>
        
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment("MMMM DD, YYYY") }}
        </template>

        <template v-slot:item.action="{ item }">
            <v-btn text @click="removeDevice(item.id)" x-small color="red">
                remove
            </v-btn>
        </template>
    </v-data-table>
</div>
</template>

<script>

export default {
  name: 'DevicesTable',
  components: {
  },
  data () {
    return {
        loading: false,
        headers: [
          { text: 'Unique Identifier', value: 'uuid', width: 200 },
          { text: 'Browser', value: 'browser' },      
          { text: 'Platform', value: 'platform' },      
        //   { text: 'Location', value: 'location' },
        //   { text: 'Public IP Address', value: 'ip' },
          { text: 'Created Date', value: 'created_at' },
          { text: 'Action', value: 'action' },
        ],
        devices: []
    }
  },
  computed: {
    user() {
       return this.$store.state.user.current_user
    },
  },
  created() {
      this.getDevices()
  },
  methods: {
      getDevices() {
        this.loading = true;
        this.$http
            .get(`/devices`)
            .then((data) => {
                this.loading = false;
                this.devices = data.data
            })
            .catch((error) => {
                this.$errorNotif("Something went wrong")
            })
      },
      removeDevice(id) {
        this.loading = true;
        this.$http
            .delete(`/devices/${id}`)
            .then((data) => {
                this.loading = false;
                this.getDevices();
            })
            .catch((error) => {
                this.$errorNotif("Something went wrong")
            })
      },
      transformDevice(item) {
        let deviceName = item.browser + 
            ' ( v' + item.browser_version + ' ) ' +
            item.platform + item.platform_version

        return deviceName;
      }
  }
};
</script>
