<template>
    <modal
      name="create-group-modal"
      width="500"
      height="auto"
      :clickToClose="false"
      :adaptive="true" >
           <div class="pa-5 d-flex justify-space-between align-center">
              <h2 class="title">Create Group</h2>
          </div>
          <v-divider/>
          <v-alert
              v-if="showError"
              dense
              outlined
              type="error"
              class="caption text-xs ma-5"
              >
              <p class="mb-0" v-for="(error, index) in errors" :key=index>
                  ● {{error[0]}}
              </p>
          </v-alert>
         <v-form 
          ref="form" 
          @submit.prevent="submit()"  
          method="POST"
          >
          <v-card-text class="login-card__field px-5 py-8">
            <v-text-field
                label="Name"
                placeholder="Name"
                outlined
                dense
                required
                autofocus
                v-model="form.name"
                :rules="errors.name"
            >
            <template #label>
                <span class="red--text"><strong>* </strong></span>Name
            </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions class="px-5 py-3">
            <v-btn
                text
                color="primary"
                @click="cancel()">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="primaryMainText--text"
                :loading="loading"
                color="primaryMain"
                type="submit"
                >Submit</v-btn>
        </v-card-actions>
        </v-form>
    </modal>
  </template>
  
  <script>
  export default {
    name: 'CreateGroupModal',
    data () {
      return {
        showError: false,
        errors: [],
        loading: false,
        form: {
          company_id: '',
          name: '',
        }
      }
    },
    props: [
      'companyId',
      'dealerId'
    ],
    methods: {
      cancel() {
        this.$modal.hide('create-group-modal')
      },
      submit() {
        this.loading = true
        this.errors = []
        this.form.company_id = this.companyId
        this.form.specific_dealer_id = this.dealerId
        this.$http
        .post("/groups", this.form)
        .then((result) => {
            this.loading = false
            this.$successNotif('Successfully created a group')
            this.form.name = '';
            this.$emit('refreshGroup', true)
        })
        .catch((error) => {
          if (error.response.status == 422){
                this.errors = error.response.data.errors
                this.$errorNotif('Invalid inputs, Please check the details')
            }
            this.loading = false
        });
      }
    }
  };
  </script>
  