<template>
  <modal name="image-viewer-modal" 
    :min-width="200"
    :min-height="200"
    :reset="true"
    class="image-viewer-modal"
    width="100%"
    height="100%"
  >

  <p class="text-right pa-5 ma-0">
        <a href="javascript:void(0)" @click="$modal.hide('image-viewer-modal')">
              <v-icon dark>mdi-close</v-icon>
        </a>
  </p>
  <div class="image-viewer-container">
    <img :src="path">
  </div>
  </modal>
</template>
<script>
export default {
  data() {
        return {
        };
    },
    props: {
        path: String
    },
    methods: {
        close() {
            this.$modal.close('image-viewer-modal');
        }
    },
}
</script>