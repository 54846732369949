<template>
  <modal 
    name="2fa-verify-modal" 
    width="400"  
    height="auto"  
    draggable
    :clickToClose="false"
    :adaptive="true" >
        <h2 class="title pa-5">2FA Activation</h2>
        <v-divider/>

         <v-alert
            v-if="showError"
            dense
            outlined
            type="error"
            class="caption text-xs ma-5"
            >
            {{error}}
        </v-alert>
        <v-form 
            ref="form" 
            @submit.prevent="update()"  
            method="PUT" 
        > 
            <v-card-text class="login-card__field px-5 py-8">
                <div class="code">
                    <v-text-field
                        type="number"
                        maxlength = "6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        v-model="form.email_key"
                        outlined
                        label="Email Code"
                        class="text-code"
                    >
                    <template class=" ma-0" v-slot:append>
                        
                    </template>
                    </v-text-field>
                    <v-btn
                        :loading="emailLoading"
                        text
                        color="primary"
                        class="code__get-code ma-0"
                        @click="getEmailCode">
                        Get Code
                    </v-btn>
                    <p class="caption ml-2">Enter the 6 digit code received by {{user.email}}.</p>
                </div>
                <div class="code mt-10">
                    <v-text-field
                        type="number"
                        maxlength = "6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        v-model="form.sms_key"
                        outlined
                        label="SMS Code"
                        class="text-code"
                    >
                    <template class=" ma-0" v-slot:append>
                        
                    </template>
                    </v-text-field>
                    <v-btn
                        :loading="smsLoading"
                        text
                        color="primary"
                        class="code__get-code ma-0"
                        @click="getSMSCode">
                        Get Code
                    </v-btn>
                    <p class="caption ml-2">Enter the 6 digit code received by {{user.phone_number}}.</p>
                </div>
            </v-card-text>
            <v-card-actions class="px-5 py-3">
                <v-btn 
                    text 
                    color="primary"
                    @click="cancel()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                    class="primaryMainText--text"
                    :loading="loading"
                    color="primaryMain"
                    type="submit"
                    >Submit</v-btn>
            </v-card-actions>
        </v-form>
  </modal>
</template>
<script>

export default {
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
    },
    data() {
        return {
            showError: false,
            error: '',
            loading: false,
            emailLoading: false,
            smsLoading: false,
            form: {
                email_key: '',
                sms_key: '',
                mfa_enable: true,
            }
        };
    },
    methods: {
        getEmailCode() {
            this.emailLoading = true
            
            this.$http
            .post(`/mfa/send-email-code`)
            .then((data) => {
                this.$successNotif('Successfully sent email code')
                this.emailLoading = false
            })
            .catch((error) => {
                this.emailLoading = false
            })
        },
        getSMSCode() {
            this.smsLoading = true
            
            this.$http
            .post(`/mfa/send-sms-code`)
            .then((data) => {
                this.$successNotif('Successfully sent email code')
                this.smsLoading = false
            })
            .catch((error) => {
                this.smsLoading = false
            })
        },
        cancel() {
            this.user.mfa.mfa_enable = false
            this.$modal.hide('2fa-verify-modal')
        },
        update() {
            this.loading = true
            this.$http
            .put(`/mfa/validate-code`, this.form)
            .then((data) => {
                this.$meFetch()
                this.$successNotif('Two Factor Activated')
                this.$store.commit('user/SET_CURRENT_USER', data.data)
                this.loading = false
                this.$modal.hide('2fa-verify-modal')
            })
            .catch((error) => {
                this.loading = false
                if (error.response.status == 422){
                    this.showError = true
                    this.error = error.response.data.errors
                } else {
                    this.$errorNotif("Something went wrong")
                }

                this.$refs.form.reset()
                setTimeout(() => {
                    state.showError = false
                }, 10000);
            })
        }
    },
}
</script>
