<template>
<div class="login-card-container">
  <v-card class="login-card" elevation="2">
      <v-card-title class="login-card__title">
        <div class="logo-group">
          <img class="logo" :src="companyLogo ? companyLogo : logo" alt="webinarinc central logo">
        </div>
      </v-card-title>

       <v-alert
            v-if="showLoginError"
            dense
            outlined
            type="error"
            class="caption text-xs mx-8 my-0"
            >
            {{loginError}}
        </v-alert>

        <login-content
          v-show="state == 'signin'"
          @state="changeState"
        />
        <register-content
          v-show="state == 'signup'"
        />
        <signup-content
          v-show="state == 'invite'"
          @state="changeState"
          :inviteUser="inviteUser"
        />
        <forgot-password-content
          v-show="state == 'forgot_password'"
          :card_state="cardState"
          @state="changeState"
        />
         <reset-password-content
          v-show="state == 'reset_password'"
          :card_state="cardState"
          :data="verify"
          @state="changeState"
        />
         <two-factor-content
          v-show="state == 'registered-device' ||  state == 'new-device'"
          :card_state="cardState"
          :data="verify"
          @state="changeState"
        />
         <security-question-content
          v-show="state == 'security-question'"
          @state="verifyTokenUrl"
          :data="verify"
          :card_state="state"
        />
  </v-card>
</div>

</template>
<script>
import { mapState } from "vuex";
import LoginContent from './LoginContent.vue';
import RegisterContent from './RegisterContent.vue';
import ForgotPasswordContent from './ForgotPasswordContent.vue';
import ResetPasswordContent from './ResetPasswordContent.vue';
import TwoFactorContent from './TwoFactorContent.vue';
import SecurityQuestionContent from './SecurityQuestionContent.vue';
import logoImport from '@/assets/images/kain-logo.png';
import SignupContent from '@/components/invite_users/SignupContent.vue';

export default {
  name: 'LoginCard',
  components: {
    LoginContent,
    RegisterContent,
    ForgotPasswordContent,
    ResetPasswordContent,
    TwoFactorContent,
    SecurityQuestionContent,
    SignupContent
  },
  data() {
    return {
      logo: logoImport,
      state: 'forgot_password',
      verify: {},
      inviteUser: {
        email: '',
        company: {
          name: ''
        }
      },
    }
  },
  computed: {
    ...mapState("layout", ["companyLogo","layoutloading","admin"]),
    ...mapState("login", [
      "loginError",
      'showLoginError',
      'cardState',
    ]),
    title: function () {
      if (this.state == 'signup' || this.state == 'invite') {
        return 'Sign Up'
      } else if (this.state == 'forgot_password') {
        return 'Forgot Password'
      } else if (this.state == 'reset_password') {
        return 'Reset Password'
      } else if (this.state == 'activation') {
        return 'Activation'
      } else if (this.state == 'registered-device' || this.state == 'new-device') {
        return '2FA'
      } else if (this.state == 'security-question') {
        return 'Security Questions'
      }

      return 'Sign In'
    },
  },
  watch: {
    cardState(val) {
      this.state = val
    },
  },
  created() {
    this.verifyTokenUrl()
  },

  methods: {
    verifyTokenUrl(){
      let query = this.$route.query
      const mfaParams = ['id', 'type', 'mfa-token'];
      const forgotParams = ['id', 'forgot-token'];
      const inviteParams = ['company_id', 'invite'];
      const inviteUserParams = ['centralId', 'token'];

      if (mfaParams.every(key => Object.keys(query).includes(key))) {
        this.initMfa(query)
      }

      if (forgotParams.every(key => Object.keys(query).includes(key))) {
        this.initForgotPassword(query)
      }

      if (inviteParams.every(key => Object.keys(query).includes(key))) {
        this.initInvite(query)
      }

      if (inviteUserParams.every(key => Object.keys(query).includes(key))) {
        this.state = 'invite';
        this.verifyInvite(query);
      }
    },
    initMfa(query) {
      this.loading = true;
      this.$store.commit('global/SET_PAGE_LOADER', true);

      if (Object.values(query).includes("")) {
        this.invalidToken()
        return false
      }

      this.$http
        .post(`mfa/validate-token`, query)
        .then((data) => {
          if(data.data.verify) {
            this.verify = query;
            this.verify.email = data.data.email
            this.verify.phone_number = data.data.phone_number
            this.state = query.type
          }
          this.$store.commit('global/SET_PAGE_LOADER', false);
        })
        .catch((error) => {
          this.invalidToken()
          return false
        })

    },
    initForgotPassword(query){
      this.loading = true;
      this.$store.commit('global/SET_PAGE_LOADER', true);

      if (Object.values(query).includes("")) {
        this.invalidToken()
        return false
      }

      this.$http
        .post(`auth/verify-forgot-password`, query)
        .then((data) => {
          if(data) {
            this.verify = query;
            this.state = 'reset_password'
          }
          this.$store.commit('global/SET_PAGE_LOADER', false);
        })
        .catch((error) => {
          this.$errorNotif("Reset Password link is invalid. Please re-send the password reset link to your email.", 5000)
          this.state = 'forgot_password'
          this.$store.commit('global/SET_PAGE_LOADER', false);
          return false
        })
    },
    initInvite(query){
      this.state = 'signup'
      this.$store.commit('login/SET_CARD_STATE', this.state);
      this.$emit('switch', this.state)
    },
    invalidToken() {
      this.$errorNotif("Token is Invalid")
      this.$router.replace({'query': null});
      this.$store.commit('global/SET_PAGE_LOADER', false);
    },
    changeState(val) {
      this.state = val
    },
    verifyInvite(query){
      this.$store.commit('global/SET_PAGE_LOADER', true);

      this.$http
        .post(`/revdojo-api/v1/invite-users/verify`, query)
        .then((data) => {
          if(data) {
            this.inviteUser = data.data;
          }
          this.$store.commit('global/SET_PAGE_LOADER', false);
        })
        .catch((error) => {
          this.$store.commit('global/SET_PAGE_LOADER', false);
          return
        })
    },
  }
};
</script>
