<template>
  <modal
    name="export-modal"
    width="700"
    height="auto"
    :clickToClose="false"
    :adaptive="true" >
        <div class="pa-5 align-center">
        <div class="d-flex justify-space-between ">
          <h2 class="title primary--text">Export User</h2>
      </div>
      </div>
      <v-divider/>
      <div class="ma-5">
         <v-layout row class="mt-5">
          <template v-if="is_super_admin">
            <v-flex xs12 md6 lg6 class="px-3">
              <v-select
                  v-model="filters.companies"
                  :items="companies"
                  outlined
                  :loading="companyLoading"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  item-value="id"
                  item-text="name"
                  label="Companies"
                  clearable
                  multiple
              ></v-select>
            </v-flex>
          </template>
          <template
            v-if="is_super_admin || is_account_manager"
          >
            <v-flex xs12 md6 lg6 class="px-3">
              <v-select
                  v-model="filters.dealers"
                  :items="filteredDealers"
                  outlined
                  :loading="dealerLoading"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  item-value="id"
                  item-text="name"
                  label="Dealers"
                  clearable
                  multiple
              ></v-select>
            </v-flex>
          </template>
        </v-layout>
        <v-layout row class="mt-5">
          <v-flex xs12 md6 lg6 class="px-3">
            <v-select
                v-model="filters.roles"
                :items="roles"
                outlined
                :loading="roleLoading"
                :menu-props="{ bottom: true, offsetY: true }"
                dense
                item-value="id"
                item-text="friendly_name"
                label="Role"
                clearable
                multiple
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg6 class="px-3">
            <v-select
                v-model="filters.statuses"
                :items="statuses"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                dense
                item-value="value"
                item-text="name"
                label="Status"
                clearable
                multiple
            ></v-select>
          </v-flex>
        </v-layout>
      </div>
      <v-card-actions class="px-5 py-3">
          <v-btn
            text
            color="primary"
            @click="cancel()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="primaryMainText--text"
            :loading="loading"
            @click="exportUser"
            color="primaryMain"
            type="submit"
            >
            <v-icon class>
              mdi-download
            </v-icon>Export Users
          </v-btn>
      </v-card-actions>
  </modal>
</template>

<script>
import {
  isEmpty as _isEmpty,
  filter as _filter,
  forEach as _forEach,
  flatten as _flatten,
  map as _map,
} from 'lodash-es';
import importExportUsers from '@/mixins/importExportUsers'
import { mapState } from "vuex";


export default {
  name: 'ExportDialog',
  mixins: [importExportUsers],
  data () {
    return {
      loading: false,
      filters: {
        companies: [],
        dealers: [],
        statuses: [],
        roles: [],
      },
      loaders: {
        companies: false,
        dealers: false,
        roles: false,
      }
    }
  },
  computed: {
    ...mapState("user", [
        'is_super_admin', 
        'is_account_manager'
    ]),
    filtersAllNull() {
      return Object.values(this.filters).every(value => {
        if (value.length == 0) {
          return true;
        }
        return false;
      });
    },
    filteredDealers() {
      if (!_isEmpty(this.filters.companies)) {
        let dealers = [];

        _forEach(this.filters.companies, (companyId) => {
          dealers.push(_flatten(_filter(this.dealers, { company_id: companyId})));
        });

        dealers = _flatten(dealers);

        return dealers;
      }

      return this.dealers;
    },
  },
  mounted() {
    this.initializeFilters();
  },
  methods: {
    exportUser() {
      if (this.filtersAllNull) {
        this.$errorNotif("No Filters selected, Please select filters")
        return false
      }

      this.loading = true
      this.$http.post('export-users', {
        companies: this.filters.companies,
        dealers: this.filters.dealers,
        statuses: this.filters.statuses,
        roles: this.filters.roles,
      })
      .then(({ data }) => {
        this.loading = false
        if (data.success) {
          this.$successNotif(data.message);
        } else {
          this.$errorNotif(data.message);
        }
        this.resetState();
      })
      .catch((error) => {
        this.$errorNotif("Something went wrong")
        this.loading = false
      })
    
    },
    checkIfFiltersNull() {
     
    },
    resetState() {
      this.filters = {
        companies: [],
        dealers: [],
        statuses: [],
        roles: [],
      };

      this.$nextTick(() => {
        this.showDialog = false;
      })
    },
    cancel() {
      this.$modal.hide('export-modal')
    },
  }
}
</script>