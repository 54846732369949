var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('invite-users-filter',{on:{"filter":_vm.filter}})],1),_c('div',{staticClass:"content-card__header d-flex justify-space-between"},[_c('div'),_c('div',[_c('v-btn',{staticClass:"mr-2 primaryMainText--text",attrs:{"color":"primaryMain"},on:{"click":function($event){return _vm.goToCreate()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Invite ")],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.per_page,"footer-props":{
      disablePagination: _vm.paginationDisable,
      'items-per-page-options': [5, 10, 20, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"item.roles",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.roles.length > 0 ? item.roles[0].friendly_name : null))]),_c('br'),(item.roles.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"small":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" see more ")])]}}],null,true)},_vm._l((item.roles),function(role,index){return _c('p',{key:index,staticClass:"ma-0"},[_vm._v(" • "+_vm._s(role.friendly_name)+" ")])}),0):_vm._e()]}},{key:"item.company",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.company.name))])]}},{key:"item.specificDealer",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.specificDealer.name))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status))])]}},{key:"item.invited_by",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.invited_by.name))])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(item.status == 'Pending')?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.copyUrl(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy Link")])]),(_vm.is_super_admin)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"purple"},on:{"click":function($event){return _vm.resync(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,true)},[_c('span',[_vm._v("Re-Sync")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.resend(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("Re-send Invitation")])]),_c('span',[(_vm.roleCondition(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"warning"},on:{"click":function($event){return _vm.goToEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"info"},on:{"click":function($event){return _vm.goToView(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])],1),(_vm.roleCondition(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","color":"error","icon":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])]):_vm._e()],1):_vm._e()]}}])}),_c('restrict-create-modal'),_c('invite-users-modal',{on:{"save":_vm.fetch}}),_c('edit-invite-users-modal',{attrs:{"inviteUser":_vm.selectedInvite},on:{"save":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }