<template>
  <div class="content">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">{{ $route.meta.title }}</h1>

      <div class="mt-5 content-card">
        <positions-table></positions-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import PositionsTable from "@/views/positions/PositionsTable";

export default {
  name: "Products",
  components: {
    Breadcrumbs,
    PositionsTable,
  },
  data() {
    return {
      breadcrumbs: {
        0: {
          title: "Home",
          link: "home",
        },
        1: {
          title: this.$route.meta.title,
          link: this.$route.meta.name,
        },
      },
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
