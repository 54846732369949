<template>
    <div class="login-switch mt-16">
      <div
          class="login-switch__toggle"
          style="--switch-width: 19rem"
          :class=[this.state_class]>
          <span @click.self="onClick">SINGLE USER</span>
          <span @click.self="onClick">ENTERPRISE</span>
          <div
              class="login-switch__draggable primaryMain primaryMainText--text"
              @mousedown.prevent="dragStart"
              :style="style">
              {{text}}
          </div>
      </div>
    </div>
  </template>
  <script>
  
  export default {
  name: 'ProductSwitch',
  
  props: {
      value: {
        type: Boolean,
        default: false
      }
  },
  data() {
      return {
        width: 100,
        state: false,
        pressed: 0,
        position: 0,
      }
  },
  mounted() {
      this.toggle(this.value)
  },
  computed: {
      style() {
        return {
          transform: `translateX(${this.pos_percentage})`
       }
      },
      pos_percentage() {
        return `${this.position / this.width * 100}%`
      },
      state_class() {
        if (this.state) {
          return 'active'
        }
        return false
      },
      text() {
          let text
          this.position == 0 
            ? text = 'SINGLE USER'
            : text = 'ENTERPRISE'
          
          return text
      }
  },
  watch: {
      position() {
        this.state = this.position >= 50
      },
      value(val) {
         this.toggle(this.value)
      }
  },
  methods: {
      onClick() {
        this.toggle(!this.state)
        this.emit()
      },
      toggle(state) {
        this.state = state
        this.position = !state
          ? 0
          : 100
      },
      dragging(e) {
        const pos = e.clientX - this.$el.offsetLeft 
        const percent = pos / this.width * 100
        this.position = percent <= 0
          ? 0
          : percent >= 100
            ? 100
            : percent
      },
      dragStart(e) {
        this.startTimer()
        window.addEventListener('mousemove', this.dragging)
        window.addEventListener('mouseup', this.dragStop)
      },
      dragStop() {
        window.removeEventListener('mousemove', this.dragging)
        window.removeEventListener('mouseup', this.dragStop)
        this.resolvePosition()
        clearInterval(this.$options.interval)
        if (this.pressed < 30) {
          this.toggle(!this.state)
        }
        this.pressed = 0
        this.emit()
      },
      startTimer() {
        this.$options.interval = setInterval(() => {
          this.pressed++
        }, 1)
      },
      resolvePosition() {
        this.position = this.state
          ? 100
          : 0
      },
      emit() {
        this.$emit('input', this.state);
      }
    }
  };
  </script>