<template>
    <v-card class="fill-height">
        <v-container>

            <v-card-title>
                Payment Method
            </v-card-title>


            <div class="pa-3">
                <v-container class="rounded border pa-5" v-if="hasPaymentMethod">
                    <v-row>
                        <v-col cols="1">
                            <img style="width:2rem" :src="cardLogoUrl" alt="mastercard">

                        </v-col>
                        <v-col cols="8" lg="9">
                            <v-card-text class="pa-0 pl-3">
                                {{ capitalize(user.pm_type) }} ending in {{ user.pm_last_four }} <br>
                                <span>
                                    <v-icon>
                                        mdi-email-outline
                                    </v-icon>
                                    {{ user.email }}
                                </span>
                            </v-card-text>
                        </v-col>
                        <v-col cols="3" lg="2">
                            <v-btn @click="toggleCardPayment()">Edit</v-btn>
                        </v-col>
                    </v-row>

                </v-container>

                <div class="mt-5" v-show="showCardInput">
                    <div :id="'checkoutPage-stripe-cardElement'"></div>
                    <span class="error--text caption" v-if="stripeError">{{ stripeError }}</span>
                </div>

            </div>

        </v-container>

        <div v-show="showCardInput">
            <v-divider class="my-1"></v-divider>

            <v-card-actions>
                <v-btn text @click="update()"> Save Payment Method</v-btn>
            </v-card-actions>
        </div>


    </v-card>
</template>


<script>
import { loadStripe } from "@stripe/stripe-js";
import { mapState } from "vuex";
import { find as _find } from "lodash";

export default {
    name: "BillingInformationCard",
    components: {
    },
    props: {
        billingInformation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            stripeError: false,
            showCardInput: false,
            cardElement: {},
            user: {
                pm_type: '',
                pm_last_four: ''
            },
            pkToken: '',
        };
    },
    created() {
        this.fetch();
    },
    async mounted() {

        await this.getStripeToken()
        await this.renderCard()

        this.checkCurrentPayment()
    },
    computed: {
        hasPaymentMethod() {
            return this.user.pm_type && this.user.pm_last_four
        },
        cardLogoUrl() {
            switch (this.user.pm_type) {
                case 'visa':
                    return 'https://webinarinc-central.s3.us-west-1.amazonaws.com/public/cover_photo/16652b26-8f81-4599-b9b2-761b91fc1248.png';
                case 'mastercard':
                    return 'https://webinarinc-central.s3.us-west-1.amazonaws.com/public/cover_photo/b39261a3-c82f-4273-9bd9-6e71d9c4efb9.png';
                case 'amex':
                    return 'https://webinarinc-central.s3.us-west-1.amazonaws.com/public/cover_photo/6947d154-0feb-4186-99d1-5d0fdb1e0bc0.png';
                default:
                    return '';
            }
        }
    },
    methods: {
        capitalize(text) {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        },
        checkCurrentPayment() {
            if (!this.hasPaymentMethod) {
                this.toggleCardPayment()
            }

            if (this.hasPaymentMethod) {
                this.showCardInput = false;
            }
        },
        async getStripeToken() {
            await this.$http
                .get('/billing/get-stripe-token')
                .then(({ data }) => {
                    this.pkToken = data;
                })
        },
        toggleCardPayment() {
            this.stripeError = false
            this.showCardInput = !this.showCardInput
            if (this.showCardInput) {
                this.renderCard()
            }
        },
        async renderCard() {
            this.stripe = await loadStripe(this.pkToken);
            const elements = this.stripe.elements();
            this.cardElement = elements.create("card", {
                classes: {
                    base: "product-credit-card px-3 py-3",
                },
            });
            this.cardElement.mount(`#checkoutPage-stripe-cardElement`);
        },
        fetch() {

            this.$http
                .get(`billing/get-user`, {
                    params: {
                        userId: this.$route.params.id || null,
                    },
                })
                .then((data) => {
                    this.user = data.data;
                })
                .catch((error) => {
                    this.$errorNotif("Something went wrong");
                });
        },
        async update() {
            this.$store.commit('global/SET_PAGE_LOADER', true)  
            let paymentMethodId = ''
            if (this.showCardInput) {
                const { paymentMethod, error } = await this.stripe.createPaymentMethod(
                    "card",
                    this.cardElement,
                    {
                        billing_details: {
                            name: this.billingInformation.name,
                            email: this.billingInformation.email,
                            address: {
                                line1: this.billingInformation.address,
                                city: this.billingInformation.city,
                                state: this.billingInformation.state,
                                postal_code: this.billingInformation.zip
                            }
                        },
                    }
                );
                if (error) {
                    this.stripeError = error.message;
                    this.$store.commit('global/SET_PAGE_LOADER', false)  
                    return false
                }

                paymentMethodId = paymentMethod.id
            }
            let form = {
                payment_method_id: paymentMethodId,
                userId: this.$route.params.id || null,
            }

            this.$http
                .post('/billing/update-payment-method', form)
                .then((data) => {
                    this.$store.commit('global/SET_PAGE_LOADER', false)  
                    this.user = data.data;
                    this.toggleCardPayment();
                    this.$successNotif("Successfully updated.");
                })
                .catch((error) => {
                    this.$store.commit('global/SET_PAGE_LOADER', false)  ;
                    if (error.response.status == 403 && error.response.data.type == 'card_error') {
                        this.stripeError = error.response.data.msg
                    }
                });
        }
    },
};
</script>

<style scoped>
.border {
    border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>