<template>
    <div>
      <div class="content">
        <billing-portal-filter @filter="filter"></billing-portal-filter>
      </div>
      <v-data-table
        id="BillingPortalTable"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="pagination.total"
        :items-per-page="pagination.per_page"
        :footer-props="{
          disablePagination: paginationDisable,
          'items-per-page-options': [5, 10, 20, 50],
        }"
      >
        <template v-slot:item.created_at="{ item }">
          <span>{{ new Date(item.created_at).toLocaleString() }}</span>
        </template>
        <template v-slot:item.deleted_at="{ item }">
          <span>{{ new Date(item.deleted_at).toLocaleString() }}</span>
        </template>
        <template v-slot:item.is_mobile="{ item }">
            <v-icon v-show="item.is_mobile"
                color="primary" 
                class="ml-1"
            >
            mdi-check-circle-outline
            </v-icon>
        </template>
  
        <template v-slot:item.action="{ item }">
          <div class="d-flex" v-if="!item.is_mobile">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="redirectToPortal(item)"
                  x-small
                  color="primaryMain"
                  class="mx-1 primaryMainText--text"
                  v-bind="attrs"
                  v-on="on"
                >
                View Billing
                </v-btn>
              </template>
              <span>Go to {{ item.name }} Billing Portal</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { find as _find } from "lodash";
  import BillingPortalFilter from './BillingPortalFilter.vue';
  
  export default {
    name: "BillingPortalTable",
    components: {
      BillingPortalFilter
    },
    data() {
      return {
        loading: false,
        headers: [
          { text: "Company", value: "company_name" },
          { text: "Billing User Name", value: "name" },
          { text: "Billing User Email", value: "email" },
          { text: "Card Type", value: "pm_type" },
          { text: "Card Last 4 Digit", value: "pm_last_four" },
          { text: "Mobile Subscription", value: "is_mobile" },
          { text: "", value: "action", width: "180" },
        ],
        items: [],
        search: "",
        pagination: {
          total: 1,
          per_page: 10,
          current_page: 1,
          search: "",
          sortBy: "",
          sortDesc: false,
        },
        options: {},
        paginationDisable: false,
        timeout: null,
        filters: null,
      };
    },
    computed: {
      ...mapState("user", [
        "current_user",
        "is_super_admin",
        "is_account_manager",
      ]),
    },
    watch: {
      options: {
        handler() {
          this.changeTable();
        },
        deep: true,
      },
    },
    created() {
      this.fetch();
    },
    methods: {
      fetch() {
        this.loading = true;
        this.paginationDisable = true;
        this.items = [];

        this.$http
          .get(`billing/billing-portal-list`, {
            params: {
              per_page: this.options.itemsPerPage,
              current_page: this.pagination.current_page,
              sortBy: this.pagination.sortBy,
              sortDesc: this.pagination.sortDesc,
              filter: this.filters,
              redirectBack: this.$route.path,
            },
          })
          .then((data) => {
            this.loading = false;
            this.items = data.data.data;
            this.pagination.total = data.data.total;
            this.pagination.current_page = data.data.current_page;
            this.pagination.per_page = data.data.per_page;
            this.paginationDisable = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$errorNotif("Something went wrong");
          });
      },
      changeTable() {
        this.pagination.current_page = this.options.page;
        this.pagination.per_page = this.options.itemsPerPage;
  
        this.pagination.sortBy =
          this.options.sortBy !== undefined ? this.options.sortBy[0] : "";
        this.pagination.sortDesc =
          this.options.sortDesc !== undefined ? this.options.sortDesc[0] : "";
  
        this.fetch();
      },
      redirectToPortal(item) {
        this.$router.push({ name: 'billing', params: { id: item.id } });
      },
      filter(filters) {
        this.filters = filters;
        this.fetch();
      },
    },
  };
  </script>
  