<template>
    <div class="content content-full">
        <breadcrumbs :links="breadcrumbs"/>

        <div class="content-wrapper">
            <h1 class="font-weight-medium">{{ $route.meta.title }}
                <span class="title font-weight-thin">Generate Invitation Link</span>
            </h1>

            <v-layout row  class="mt-5">
                <v-flex xs12 lg12 class="px-4 content-image" >
                    <form @submit.prevent="submit()"  method="PUT">
                        <v-card class="pa-4 d-flex flex-column" min-height="400">
                          <v-container class="my-5 content-container">
                                <v-autocomplete
                                    :disabled="!is_super_admin"
                                    v-model="form.company_id"
                                    :items="company_list"
                                    outlined
                                    label="Company"
                                    :rules="errors.company_id"
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    required
                                    bottom
                                    @change="renderLink()"
                                    autocomplete="off"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Company
                                    </template>
                                </v-autocomplete>

                                <div>
                                  <div class="caption text-right">
                                      <a href="javascript:void(0)" @click="copyUrl">
                                          Copy Link
                                      </a>
                                  </div>
                                  <v-text-field
                                      v-model="form.url"
                                      append-icon="mdi-content-copy"
                                      label="Invitation Link"
                                      type="text"
                                      dense
                                      outlined
                                      @click:append="copyUrl"
                                  ></v-text-field>
                              </div>
                              <div>
                                <!-- <v-btn :loading="loading" @click="generateLink()" dense block color="primaryMain" class="primaryMainText--text">
                                  Generate Link
                                </v-btn> -->
                              </div>
                          </v-container>
                        </v-card>
                    </form>
                </v-flex>
            </v-layout>   
        </div>
    </div> 
</template>

<script>
import Vue from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapState } from "vuex";
import {
    find as _find,
} from 'lodash';
Vue.use(require('vue-moment'));

export default {
    computed: {
        ...mapState("user", [
          'is_super_admin', 
          'current_user', 
      ]),
      company_list() {
          return this.$store.state.company.company_list;
      },
    },
    data() {
        return {
            loading: false,
            breadcrumbs: {
                '0': {
                    title: 'Home',
                    link: 'home',
                },
                '1': {
                    title: this.$route.meta.title,
                    link: this.$route.meta.type,
                },
            },
            form: {
                url: '',
                company_id: ''
            },
            errors: [],
        };
    },
    watch: {
        company_list(val) {
            this.renderInitialCompany()
        }
    },
    created() {
        this.loading = true
        this.$store.commit('global/SET_BREADCRUMBS_LOADER', true)            
    },
    mounted() {
        if (this.company_list.length > 0) {
            this.renderInitialCompany()
        }
    },
    methods: {
        renderInitialCompany() {
            this.form.company_id = this.current_user.company_id
            this.renderLink()
            this.loading = false
            this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)            
        },
        renderLink() {
            let company = this.findCompany(this.form.company_id)
            this.form.url = null
            this.formatLink(company.central_id)
        },
        findCompany(id){
            return _find(this.company_list, {
                id: id,
            });
        },
        formatLink(company_id) {
            let url = window.location.href
            let urlObject = new URL(url);
            urlObject.searchParams.append('company_id', company_id);
            urlObject.searchParams.append('invite', true);

            this.form.url = urlObject.origin +'/login' + urlObject.search
        },
        copyUrl() {
            this.$successNotif('Copied URL')
            navigator.clipboard.writeText(this.form.url);
        },
    },
    components: {
        Breadcrumbs,
    },
}
</script>