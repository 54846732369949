<template>
    <div>
        <router-view></router-view>
    </div> 
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("layout", ["labels"]),
    },
    created() {
        if (this.$route.name == 'specific_dealers') {
            this.$router.push(`/specific/${this.labels.dealers}`);
            return
        }
    },
}
</script>
