<template>
  <modal name="invite-users-modal" width="500" height="auto" scrollable>
    <div class="pa-5 align-center">
      <div class="d-flex justify-space-between">
        <h2>Invite Users</h2>
        <v-btn @click="cancel()" icon color="black">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <v-divider />
    <div class="ma-5">

      <v-form @submit.prevent="handleClickSave">

        <div v-for="(user, i) in inviteUsers" :key="i">
          <div class="d-flex align-start">
            <v-row>
              <v-col :cols="colRow(user)">
                <v-text-field v-model="user.email" label="Email" required :disabled="loading" hide-details=true>
                </v-text-field>
                <div v-if="showError && errorData && errorData[`invite.${i}.email`]" class="error-message mt-1">
                  {{ errorData[`invite.${i}.email`][0] }}
                </div>
              </v-col>
              <v-col :cols="colRow(user)">
                <v-select v-model="user.role_id" label="Role" required :items="filteredRoles" item-text="friendly_name"
                  hide-details=true :disabled="loading" item-value="id" @change="handleChangeRole(user)"></v-select>
                <div v-if="showError && errorData && errorData[`invite.${i}.role_id`]" class="error-message mt-1">
                  {{ errorData[`invite.${i}.role_id`][0] }}
                </div>
              </v-col>

              <v-col :cols="colRow(user)" v-if="colRow(user) === 3">
                <v-autocomplete item-text="name" item-value="id" label="Company" v-model="user.company_id"
                  :items="companyList" autocomplete="off" @change="handleChangeCompany(i, user)" hide-details=true>
                </v-autocomplete>

                <div v-if="showError && errorData && errorData[`invite.${i}.company_id`]" class="error-message mt-1">
                  {{ errorData[`invite.${i}.company_id`][0] }}
                </div>
              </v-col>

              <v-col :cols="colRow(user)" v-if="colRow(user) === 3 || colRow(user) === 4">
                <v-autocomplete item-text="name" item-value="id" label="Store" v-model="user.specific_dealer_id"
                  :items="user.specific_dealer_list" autocomplete="off" :loading="loadDealer" hide-details=true
                  :no-data-text="loadDealer ? 'Loading' : 'No data available'">
                  <template v-slot:append-item>
                    <div v-if="!loadedAllSpecificDealer(user)" v-intersect="onIntersectSpecificDealer(i, user)"
                      class="pa-4 primary--text">
                      Loading more items ...
                    </div>
                  </template>
                </v-autocomplete>

                <div v-if="showError && errorData && errorData[`invite.${i}.specific_dealer_id`]"
                  class="error-message mt-1">
                  {{ errorData[`invite.${i}.specific_dealer_id`][0] }}
                </div>
              </v-col>

            </v-row>

            <v-btn @click="handleClickRemove(i)" color="error" class="ml-3 mt-5" dark small icon
              v-if="inviteUsers.length > 1" :disabled="loading">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>

          </div>


        </div>
        <v-btn color="primaryMain" class="primaryMainText--text mt-5" type="button" block @click="handleClickAdd"
          :disabled="loading">
          Add Another User
        </v-btn>

        <div class="d-flex justify-space-between mt-10">
          <div>
            <v-btn @click="cancel">Cancel</v-btn>
          </div>
          <div>
            <v-btn color="primaryMain" type="submit" class="primaryMainText--text">
              <v-icon class="mr-2" :disabled="loading">mdi-send</v-icon>
              Send Invitation
            </v-btn>
          </div>
        </div>
      </v-form>

    </div>
  </modal>
</template>
  
<script>
import { mapState } from "vuex";
import {
  find as _find,
  some as _some,
  filter as _filter,
  includes as _includes,
} from 'lodash';

export default {
  name: "InviteUsersModal",
  data() {
    return {
      loading: false,
      headers: [
        { text: "App", value: "client" },
        { text: "Model", value: "model" },
        { text: "Type", value: "type" },
        { text: "Response Code", value: "response_http_code", width: 350 },
        { text: "Exception", value: "exception", width: 350 },
        { text: "Date", value: "created_at" },
      ],
      inviteUsers: [],
      roles: [],
      specificDealers: [],
      errorData: null,
      showError: false,
      intersectLoading: false,
      loadDealer: false,
    };
  },
  props: ["model"],
  async mounted() {
    await this.fetch();

    this.rolesCondition();
    this.inviteUsers = [{
      email: '',
      role_id: this.salesperson.id,
      company_id: this.current_user.company_id,
      specific_dealer_id: 0,
      specific_dealer_list: [],
      specific_dealer_setting: {
        page: 1,
        per_page: 20,
        total: ''
      }
    }]



    this.fetchSpecificDealer();
  },
  computed: {
    ...mapState("user", [
      "current_user",
      'is_super_admin',
      'is_account_manager',
      'is_dealer_manager',
    ]),
    //Roles
    superAdmin() {
      return this.findRole('super-administrator')
    },
    accountManager() {
      return this.findRole('account-manager')
    },
    secretShopper() {
      return this.findRole('secret-shopper')
    },
    dealerManager() {
      return this.findRole('specific-dealer-manager')
    },
    salesperson() {
      return this.findRole('salesperson')
    },
    csm() {
      return this.findRole('csm')
    },
    filteredRoles() {
      return this.roles.filter(role => role.value);
    },
    companyList() {
      return this.$store.state.company.company_list;
    },
  },
  methods: {
    cancel() {
      this.$modal.hide("invite-users-modal");
      this.inviteUsers = [{
        email: '',
        role_id: this.salesperson.id,
        company_id: this.current_user.company_id,
        specific_dealer_id: 0,
        specific_dealer_list: [],
        specific_dealer_setting: {
          page: 1,
          per_page: 20,
          total: ''
        }
      }];
      this.errorData = null
    },
    async fetch() {
      this.loading = true;
      this.paginationDisable = true;
      this.logs = [];
      await this.$http
        .get(`/roles`)
        .then((data) => {
          this.loading = false;
          this.roles = data.data;
        })
        .catch((error) => {
          this.$errorNotif("Something went wrong");
        });
    },
    handleClickSave() {
      this.loading = true;
      this.errorData = null

      this.$http
        .post(`/revdojo-api/v1/invite-users`, {
          invite: this.inviteUsers
        })
        .then((data) => {
          this.loading = false;
          this.$successNotif("Successfully saved");
          this.$emit("save");

          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.showError = true;
            this.errorData = error.response.data;
            return;
          }


          this.$errorNotif("Something went wrong");
        });

    },
    handleClickAdd() {
      this.inviteUsers.push({
        email: '',
        role_id: this.salesperson.id,
        company_id: this.current_user.company_id,
        specific_dealer_id: 0,
        specific_dealer_list: this.specificDealers,
        specific_dealer_setting: {
          page: 1,
          per_page: 20,
          total: ''
        }
      });
    },
    handleClickRemove(index) {
      this.inviteUsers.splice(index, 1);
    },
    rolesCondition() {
      if (this.is_super_admin) {
        this.superAdmin.value = true
        this.secretShopper.value = true
      } else {
        this.superAdmin.value = false
        this.secretShopper.value = false
      }

      this.is_account_manager || this.is_super_admin
        ? this.accountManager.value = true
        : this.accountManager.value = false

      this.csm.value = false;

      //hide dealer manager for non automotive
      Boolean(this.current_user.company.is_automotive)
        ? this.dealerManager.value = true
        : this.dealerManager.value = false

    },
    findRole(role) {
      const foundRole = _find(this.roles, {
        name: role,
      });
      return foundRole || {};
    },
    colRow(user) {
      if (this.is_super_admin) {
        return 3;
      }
      return user && user.role_id === this.dealerManager.id && !this.is_dealer_manager ? 4 : 6;
    },
    fetchSpecificDealer(index = 0, companyId = null) {
      this.loadDealer = true;
      this.$http
        .get('specific-dealer', {
          params: {
            per_page: this.inviteUsers[index].specific_dealer_setting.per_page,
            current_page: this.inviteUsers[index].specific_dealer_setting.page,
            company_id: companyId || this.current_user.company_id,
          },
        })
        .then((result) => {
          this.inviteUsers[index].specific_dealer_list = [
            ...this.inviteUsers[index].specific_dealer_list,
            ...result.data.data
          ]
          this.inviteUsers[index].specific_dealer_setting.total = result.data.total
          this.loadDealer = false
          this.intersectLoading = false;

          if (this.specificDealers.length == 0) {
            this.specificDealers = result.data.data
          }
        })
    },
    onIntersectSpecificDealer(index, user) {
      if (this.intersectLoading) {
        return;
      }

      this.intersectLoading = true;
      user.specific_dealer_setting.page += 1
      this.fetchSpecificDealer(index, user.company_id)
    },
    handleChangeRole(user) {
      if (user && user.role_id === this.dealerManager.id && !this.is_dealer_manager) {
        user.specific_dealer_id = 0;
      }
    },
    handleChangeCompany(index, invite) {
      invite.specific_dealer_id = 0;
      invite.specific_dealer_list = []
      invite.specific_dealer_setting = {
        page: 1,
        per_page: 20,
        total: ''
      }
      this.fetchSpecificDealer(index, invite.company_id)
    },
    loadedAllSpecificDealer(user) {
      return user.specific_dealer_list.length >= user.specific_dealer_setting.total
    },
  },
  watch: {
    inviteUsers: {
      deep: true,
      handler() {
        this.showError = false;
      }
    }
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 0.75rem;
  word-break: break-word;
}
</style>