<template>
    <div>
      <div class="content">
      </div>
      <div class="content-card__header">
        <v-btn
          color="primaryMain"
          class="primaryMainText--text"
          @click="goToCreate()"
        >
          <v-icon left> mdi-plus </v-icon>
          Add New
        </v-btn>
      </div>
      <v-data-table
        id="productsTable"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="pagination.total"
        :items-per-page="pagination.per_page"
        :footer-props="{
          disablePagination: paginationDisable,
          'items-per-page-options': [5, 10, 20, 50],
        }"
      >
        <template v-slot:item.name="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a href="" v-bind="attrs" v-on="on" @click="goToEdit(item.id)">
                {{ item.name }}
              </a>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
        <template v-slot:item.description="{ item }">
          <p>{{ item.description.substring(0, 100)}} <span>{{ item.description.length > 100 ? '...' : ''  }}</span></p>
        </template>
        <template v-slot:item.adds_on_based="{ item }">
          <p>{{ item.adds_on_based.name }}</p>
        </template>
        <template v-slot:item.billing_type="{ item }">
          <p>{{ $formatBillingType(item.pricings) }}</p>
        </template>
        <template v-slot:item.type="{ item }">
          <p>{{ $formatProductType(item.type) }}</p>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{ new Date(item.created_at).toLocaleString() }}</span>
        </template>
        <template v-slot:item.deleted_at="{ item }">
          <span>{{ new Date(item.deleted_at).toLocaleString() }}</span>
        </template>
  
        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <div>
              <span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="goToEdit(item.id)"
                      x-small
                      icon
                      color="warning"
                      class="mx-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </span>
  
              <v-tooltip bottom v-if="item.subscription_items.length == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="remove(item)"
                    x-small
                    color="error"
                    icon
                    class="mx-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { find as _find } from "lodash";
  
  export default {
    name: "AddsOnTable",
    data() {
      return {
        loading: false,
        headers: [
          { text: "Name", value: "name" },
          { text: "Description", value: "description" },
          { text: "Product", value: "adds_on_based" },
          { text: "Billing Type", value: "billing_type" },
          { text: "Type", value: "type" },
          { text: "", value: "action", width: "180" },
        ],
        items: [],
        search: "",
        pagination: {
          total: 1,
          per_page: 10,
          current_page: 1,
          search: "",
          sortBy: "",
          sortDesc: false,
        },
        options: {},
        paginationDisable: false,
        timeout: null,
        filters: null,
      };
    },
    computed: {
      ...mapState("user", [
        "current_user",
        "is_super_admin",
        "is_account_manager",
      ]),
      AddsOnValue() {
        return 'ADDS_ON'
      },
      productId() {
        return this.$route.params.id 
      }
    },
    watch: {
      options: {
        handler() {
          this.changeTable();
        },
        deep: true,
      },
    },
    created() {
      this.fetch();
    },
    methods: {
      fetch() {
        this.loading = true;
        this.paginationDisable = true;
        this.items = [];
        
        this.filters = {
            'type': this.AddsOnValue,
            'product_id': this.productId
        }
        this.$http
          .get(`billing/product`, {
            params: {
              per_page: this.options.itemsPerPage,
              current_page: this.pagination.current_page,
              sortBy: this.pagination.sortBy,
              sortDesc: this.pagination.sortDesc,
              filter: this.filters,
            },
          })
          .then((data) => {
            this.loading = false;
            this.items = data.data.data;
            this.pagination.total = data.data.total;
            this.pagination.current_page = data.data.current_page;
            this.pagination.per_page = data.data.per_page;
            this.paginationDisable = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$errorNotif("Something went wrong");
          });
      },
      changeTable() {
        this.pagination.current_page = this.options.page;
        this.pagination.per_page = this.options.itemsPerPage;
  
        this.pagination.sortBy =
          this.options.sortBy !== undefined ? this.options.sortBy[0] : "";
        this.pagination.sortDesc =
          this.options.sortDesc !== undefined ? this.options.sortDesc[0] : "";
  
        this.fetch();
      },
      goToCreate() {
        this.$router.push({ name: this.$route.meta.type + ".create", 
          query: { 
            type: 'adds-on', 
            parent_product_id: this.productId,
          } });
      },
      goToEdit(id) {
        this.$router.push({
          name: this.$route.meta.type + ".edit",
          params: { id: id },
        });
      },
      remove(item) {
        let msg = {
          title: "Remove " + item.name + "?",
          confirmButtonText: "Yes, Remove!",
        };
  
        this.$questionNotif(msg).then((result) => {
          if (result) {
            this.loading = true;
            this.$http.delete(`/billing/product/${item.id}`).then((data) => {
              this.loading = false;
              this.fetch();
              this.$successNotif("Successfully deleted product");
            });
          }
        });
      },
      filter(filters) {
        this.filters = filters;
        this.fetch();
      },
    },
  };
  </script>
  