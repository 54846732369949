<template>
  <div class="content" :class="!is_super_admin ? 'content-full' : ''">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">
        {{ $route.meta.title }}
        <span v-if="isView" class="title font-weight-thin">View</span>
        <span v-else class="title font-weight-thin">Edit</span>
      </h1>

      <v-layout row class="mt-5">
        <v-col
          xs12
          :md="is_super_admin ? '5' : '12'"
          :lg="is_super_admin ? '4' : '12'"
          class="content-image-section"
        >
          <v-card class="text-left" v-if="is_super_admin">
            <v-list>
              <v-list-item-group color="primary">
                <v-list-item @click="showSyncLogs()">
                  <v-list-item-icon>
                    <v-icon>mdi-clipboard-flow</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Sync Logs</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <sync-logs-modal
                  :data="position"
                  :model="'App\\Models\\Position'"
                />
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col xs12 md7 lg8 class="px-4 content-image">
          <v-card class="pa-4 flex-column" :min-height="superAdminAccess ? 500 : 300">
            <v-tabs grow v-model="tab">
              <v-tab>Information</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form
                  @submit.prevent="submit()"
                  method="PUT"
                  :readonly="isView"
                >
                  <v-container class="my-5 content-container">
                    <v-text-field
                      label="Id"
                      placeholder="ID"
                      outlined
                      required
                      readonly
                      dense
                      v-if="superAdminAccess"
                      v-model="position.id"
                    />
                    <v-text-field
                      label="Central Id"
                      placeholder="ID"
                      outlined
                      required
                      readonly
                      v-if="superAdminAccess"
                      dense
                      v-model="position.central_id"
                    />

                    <v-autocomplete
                      :disabled="!superAdminAccess"
                      item-text="name"
                      item-value="id"
                      outlined
                      label="Company"
                      required
                      dense
                      v-model="position.company_id"
                      :items="companies"
                      autocomplete="off"
                      :rules="errors.company_id"
                      @blur="outFocusCompanyField()"
                      @change="changeCompanyField()"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Company
                      </template>
                    </v-autocomplete>

                    <v-autocomplete
                        :disabled="disableDealerField"
                        item-text="name"
                        item-value="id"
                        outlined
                        :label="dealerLabel"
                        dense
                        v-model="position.specific_dealer_id"
                        :items="specific_dealers"
                        autocomplete="off"
                        clearable
                        :loading="loadDealer"
                        :rules="errors.specific_dealer_id"
                        @change="changeDealerField()"
                    >
                        <template v-slot:append-item>
                            <div v-if="!loadedAllSpecificDealer" v-intersect="onIntersectSpecificDealer" class="pa-4 primary--text">
                                Loading more items ...
                            </div>
                        </template>
                    </v-autocomplete>

                    <v-text-field
                      dense
                      label="Name"
                      placeholder="Name"
                      outlined
                      required
                      v-model="position.friendly_name"
                      :rules="errors.friendly_name"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span
                        >Name
                      </template>
                    </v-text-field>

                    <div v-if="showDealerManagerField">
                      <v-divider class="mb-5"></v-divider>
                        <p class="subtitle-2">Assign managers to positions</p>
                      </div>
                      <div v-if="!position.specific_dealer_id && this.is_super_admin && !this.is_account_manager && !this.is_dealer_manager">
                        <v-autocomplete
                          :loading="superAdminAdminListLoading"
                          v-model="position.super_admins"
                          label="Managers"
                          :items="superAdminAdminList"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          clearable
                          autofocus
                          multiple  
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="errors.company_managers"
                        >
                        </v-autocomplete>
                        </div>
                        <v-autocomplete
                          v-if="!position.specific_dealer_id && this.is_account_manager && !this.is_dealer_manager && !this.is_super_admin"
                          :loading="companyAdminListLoading"
                          v-model="position.company_managers"
                          label="Managers"
                          :items="companyAdminList"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          clearable
                          autofocus
                          multiple  
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="errors.company_managers"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                          v-if="position.specific_dealer_id || (this.is_dealer_manager && !this.is_account_manager && !this.is_super_admin)"
                          :loading="dealerAdminListLoading"
                          v-model="position.dealer_managers"
                          label="Managers"
                          :items="dealerAdminList"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          clearable
                          autofocus
                          multiple
                          :menu-props="{ bottom: true, offsetY: true }"
                          :rules="errors.dealer_managers"
                        >
                        </v-autocomplete>
                  </v-container>
                  <v-spacer />
                  <v-card-actions v-if="!isView">
                    <v-btn
                      color="error"
                      dark
                      @click="remove()"
                      class="mt-5"
                      :loading="loading"
                      text
                    >
                      Remove
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primaryMain"
                      dark
                      :loading="loading"
                      class="mt-5 primaryMainText--text"
                      type="submit"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { includes as _includes } from "lodash-es";
import Vue from "vue";
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapState } from "vuex";
import SyncLogsModal from "@/components/SyncLogsModal";
import {
    find as _find,
    some as _some,
    filter as _filter,
} from 'lodash';
Vue.use(require("vue-moment"));

export default {
  computed: {
    ...mapState("layout", ["labels"]),
    ...mapState("apps", ["app_list"]),
    ...mapState("user", [
      "current_user",
      "is_super_admin",
      "is_account_manager",
      "is_dealer_manager"
    ]),
    isView() {
      return this.$route.name == this.$route.meta.type + ".view";
    },
    positionId() {
      return this.$route.params.id;
    },
    companies() {
      return this.$store.state.company.company_list;
    },
    superAdminAccess() {
      return this.is_super_admin
    },
    disableDealerField() {
        return !this.is_super_admin && !this.is_account_manager
    },
    selectedCompany() {
        return this.position.company_id ? this.position.company_id : this.current_user.company_id;
    },
    selectedCompanyDetails() {
        if (!this.selectedCompany) {
            return false
        }
        return _find(this.companies, {
            id: this.selectedCompany,
        });
    },
    loadedAllSpecificDealer() {
        return this.specific_dealers.length >= this.specificDealerSetting.total
    },
    showDealerManagerField() {
      return this.position.specific_dealer_id ? true : false && (this.is_super_admin || this.is_account_manager || this.is_dealer_manager)
    },
    showCompanyManagerField() {
      return this.is_super_admin || this.is_account_manager || this.is_dealer_manager;
    },
    selectedDealer() {
        return this.position.specific_dealer_id ? this.position.specific_dealer_id : this.current_user.specific_dealer_id;
    },
    dealerLabel() {
      if (this.labels.dealer === undefined) {
        return '';
      }
      return this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1);
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      breadcrumbs: {
        0: {
          title: "Home",
          link: "home",
        },
        1: {
          title: this.$route.meta.title,
          link: this.$route.meta.type,
        },
        2: {
          title: "Edit",
          link: this.$route.meta.type + ".edit",
        },
      },
      position: {},
      errors: [],
      currentCompanyId: '',
      loadDealer: false,
      specific_dealers: [],
      specificDealerSetting: {
          page: 1,
          per_page: 20,
          total: ''
      },
      companyAdminListLoading: false,
      companyAdminList: [],
      dealerAdminListLoading: false,
      dealerAdminList: [],
      superAdminAdminList: [],
      superAdminAdminListLoading: false
    };
  },
  watch: {
    selectedDealer() {
      this.fetchDealerAdmin()
    },
  },
  created() {
    this.fetchPosition();
    this.fetchCompanyAdmin()
    this.fetchDealerAdmin()
    this.fetchSuperAdmin()
  },
  methods: {
    async fetchPosition() {
      this.$store.commit("global/SET_BREADCRUMBS_LOADER", true);

      try {
        const result = await this.$http.get("positions/" + this.positionId);
        this.position = result.data;
        this.currentCompanyId = this.position.company_id
        this.fetchSpecificDealer()
      } catch (error) {
        if (error.response.status == 403) {
          this.$errorNotif(error.response.data.msg);
          this.$router.push({ name: this.$route.meta.type });
        }
        this.loading = false;
      } finally {
        this.$store.commit("global/SET_BREADCRUMBS_LOADER", false);
      }
    },
    submit() {
      this.loading = true;
      this.errors = [];

      this.$http
        .put(`/positions/${this.position.id}`, this.position)
        .then((result) => {
          this.loading = false;
          this.$store.dispatch("company/getList");
          this.$successNotif("Company information has been saved");
          this.isSingleUser = result.data.is_single_user;
          this.checkPositionManager();
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
            this.$errorNotif("Invalid inputs, Please check the details");
          }
          this.loading = false;
        });
    },
    checkPositionManager() {
        this.$http
        .get('user/check-position-manager', {
            params: {
                user_id: this.current_user.id,
            },
        })
        .then(({data}) => {
            this.$store.commit('user/SET_CURRENT_USER_POSITION_MANAGER', data)
        })
        .catch((error) => {
        });
    },
    remove() {
      let msg = {
        title: `Remove ${this.position.friendly_name}?`,
        confirmButtonText: "Yes, Remove!",
      };

      this.$questionNotif(msg).then((result) => {
        if (result) {
          this.loading = true;
          this.$http.delete(`/positions/${this.position.id}`).then((data) => {
            this.loading = false;
            this.$store.dispatch("positions/getList");
            this.$successNotif("Successfully deleted position");
            this.$router.push({ name: this.$route.meta.type });
          });
        }
      });
    },
    showSyncLogs() {
      if (this.position) {
        this.$modal.show("sync-logs-modal");
      }
    },
    outFocusCompanyField() {
      if (this.position.company_id == null) {
        this.position.company_id = this.currentCompanyId;
      }
    },
    // Specific Dealer Setup
    fetchSpecificDealer() {
        this.loadDealer = true
        this.$http
        .get('specific-dealer', {
            params: {
                per_page: this.specificDealerSetting.per_page,
                current_page: this.specificDealerSetting.page,
                company_id: this.position.company_id,
                initial: this.position.specific_dealer_id,
            },
        })
        .then((result) => {
            this.specific_dealers = [
                ...this.specific_dealers,
                ...result.data.data
            ]
            this.specificDealerSetting.total = result.data.total
            this.loadDealer = false
        })
    },
  
    onIntersectSpecificDealer() {
        this.specificDealerSetting.page += 1
        this.fetchSpecificDealer()
    },

    changeCompanyField() {
        this.position.specific_dealer_id = ''
        this.specific_dealers = []
        this.specificDealerSetting.page = 1
        this.specificDealerSetting.total = ''
        this.position.company_managers = []
        this.resetField()
        this.fetchSpecificDealer()
    },
    // Super Admin Setup
    fetchSuperAdmin() {
      this.superAdminAdminListLoading = true
      this.$http
      .get('user/get-admins', {
        params: {
            company_id: this.selectedCompany,
            specific_dealer_id: this.selectedDealer,
            role: 'super-administrator',
        },
      })
      .then((result) => {
        this.superAdminAdminList = result.data
        this.superAdminAdminListLoading = false
      })
      .catch((error) => {
        this.companyAdminListLoading = false
      });
    },
        // Company Admin Setup
    fetchCompanyAdmin() {
      this.companyAdminListLoading = true
      this.$http
      .get('user/get-admins', {
        params: {
            company_id: this.selectedCompany,
            specific_dealer_id: this.selectedDealer,
            role: 'account-manager',
        },
      })
      .then((result) => {
        this.companyAdminList = result.data
        this.companyAdminListLoading = false
      })
      .catch((error) => {
        this.companyAdminListLoading = false
      });
    },
    // Dealer Admin Setup
    fetchDealerAdmin() {
      this.dealerAdminListLoading = true
      this.$http
      .get('user/get-admins', {
        params: {
            company_id: this.selectedCompany,
            specific_dealer_id: this.selectedDealer,
            role: 'specific-dealer-manager',
        },
      })
      .then((result) => {
          this.dealerAdminList = result.data
          this.dealerAdminListLoading = false
      })
      .catch((error) => {
        this.dealerAdminListLoading = false
      });
    },
    changeDealerField() {
      this.position.dealer_managers = [];
      this.position.company_managers = [];
      this.position.super_admins = [];

      this.resetField()
    },
    resetField() {
      this.position.friendly_name = ''
      this.position.dealer_managers = []
      this.fetchCompanyAdmin()
      this.fetchDealerAdmin()
      this.fetchSuperAdmin()
    }
  },
  components: {
    Breadcrumbs,
    SyncLogsModal,
  },
};
</script>