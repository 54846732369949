<template>
    <v-form
        ref="form"
        @submit.prevent="signIn()"
        method="POST"
    >
        <v-card-text class="login-card__field my-5">
            <v-text-field
                v-model="email"
                outlined
                label="Email"
                prepend-inner-icon="mdi-email-outline"
                autocomplete="new"
            ></v-text-field>
            <v-text-field
                v-model="password"
                prepend-inner-icon="mdi-lock-outline"
                :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="password_show ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                outlined
                @click:append="password_show = !password_show"
                autocomplete="new-password"
            ></v-text-field>
            <v-checkbox
                v-model="remember"
                class="mt-0"
                label="Remember Me"
                color="primary"
                hide-details
            ></v-checkbox>
        </v-card-text>
        <v-card-actions class="login-card__action pa-0">
          <div class="forgot-group">
            <a href="javascript:void(0)" @click="changeState('forgot_password')">Forgot Password?</a>
          </div>
          <v-btn class="submit-group primaryMainText--text" color="primaryMain" type="submit" :loading="loading"
            elevation="2"
          ><v-icon> mdi-arrow-right</v-icon></v-btn>
        </v-card-actions>
    </v-form>
</template>
<script>
import { mapState } from "vuex";
import webinarincIconBlack from '@/assets/images/webinarinc-icon-black.png'

export default {
  name: 'LoginContent',
  data() {
    return {
        hasWhiteLabel: false,
        email: '',
        password: '',
        remember: false,
        loading: false,
        password_show: '',
        icon: webinarincIconBlack,
        switchLabel: true,
        switchShake: false

    }
  },
  computed: {
    ...mapState("layout", [
      'layoutloading',
      "type",
      "companyIcon",
      "name",
      "currentName",
      "currentCompanyIcon",
      "currentType",
    ]),
    companyId() {
      let companyId = this.user ? this.user.company_id : this.$cookies.get('companyId')
      
      if (this.$route.query.company_id) {
        companyId = this.$route.query.company_id
      }
      return companyId
    },
    whiteLabelName() {
      return this.type == 'modified' ? this.name : this.currentName + ' Company Login'
    },
    whiteLabelIcon() {
      if (this.currentCompanyIcon) {
        return this.type == 'modified' ? this.icon : this.currentCompanyIcon
      }
      return this.icon;
    },
    whiteLabelClose() {
      return this.type !== 'modified';
    },
  },
  watch: {
    type(val) {
     this.checkHasWhiteLabel()
    },
    switchLabel() {
      this.changeLayout()
    },
  },
  mounted() {
    this.checkHasWhiteLabel()
    this.checkRememberUser()
  },
  methods: {
    async signIn() {
      let loginParams = {
        whiteLabeltype: this.type,
        companyId: this.companyId,
        email: this.email,
        password: this.password,
        remember: this.remember,
        ssoQuery: await this.$isSSORedirect()
      };

      this.loading = true
      this.$auth
        .login(loginParams)
        .then(({ data }) => {
          this.rememberUser(this.remember)
          this.$cookies.set('current_central_token', data.access_token.token.id, '20y');
          this.$cookies.set('default_auth_token', data.access_token.accessToken, '20y');
          const accessToken = `Bearer ${data.access_token.accessToken}`;
          this.$http.defaults.headers.common.Authorization = accessToken;
          this.$auth.storeManager.setUser(data.user);
          this.$store.commit('user/SET_CURRENT_USER', data.user)
          this.$renderLayout(data.user.company_id)


          if (!this.switchLabel) {
            this.closeWhiteLabel()
          }

          if (data.redirect != null) {
            this.$store.commit('global/SET_PAGE_LOADER', true)
            window.location.assign(data.redirect);
          }

          this.loading = false
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store.commit('login/SET_LOGIN_ERROR', 'Incorrect email or password.' )
          } else if (error.response.status === 401) {
            this.$store.commit('login/SET_LOGIN_ERROR', error.response.data.error )

            this.checkSwitchError(error.response.data)
          } else if (
            error.response.status === 403 &&
            error.response.data.mfa_guard
          ) {
            this.$router.push({
              name: 'Login',
              query:  error.response.data.query
            });
          } else {
            this.$errorNotif("Something went wrong")
          }

          this.loading = false
          this.email = ''
          this.password = ''
          if (this.companyId) {
            this.$renderLayout(this.companyId)
          }
        })
    },
    rememberUser(rememberMe) {
      if (rememberMe) {
        localStorage.setItem('email', this.email);
        localStorage.setItem('password', this.password);
        localStorage.setItem('remember_me', rememberMe);
      } else {
        localStorage.setItem('email', '');
        localStorage.setItem('password', '');
        localStorage.setItem('remember_me', '');
      }
    },
    checkRememberUser() {
      // Check if the authentication token is stored in local storage
      // The token is stored in local storage, pre-fill the login form
      const email = localStorage.getItem('email');
      const password = localStorage.getItem('password');
      const remember_me = localStorage.getItem('remember_me');

      if (email) {        
        this.email = email;
        this.password = password;
        this.remember = remember_me;
      }
    },
    changeLayout() {
      if (this.type == 'modified') {
        this.$store.commit('layout/SET_CURRENT_ICON', this.companyIcon)   
        this.$store.commit('layout/SET_CURRENT_NAME', this.name)   
        this.$store.commit('layout/SET_CURRENT_TYPE', this.type)   
        this.$renderLayout(null, true)
      }

      if (this.type == 'default') {
        this.$renderLayout(this.$cookies.get('companyId'), true)
      }
    },
    changeState(state) {
      this.$emit('state', state)
    },
    checkHasWhiteLabel() {
      if (this.type == 'modified' || this.currentType == 'modified') {
        return this.hasWhiteLabel = true
      } 
      this.hasWhiteLabel = false
    },
    closeWhiteLabel() {
      this.$cookies.remove('companyId');
      this.$renderLayout(null)
      this.hasWhiteLabel = false
    },
    checkSwitchError(data) {
      if (typeof data.switchError != undefined) {
        this.switchShake = true
        setTimeout(() => {
          this.switchShake = false
        }, 3000);
      }
    }

  }
};
</script>
