<template>
    <v-form 
        ref="form" 
        @submit.prevent="signUp()"  
        method="POST" 
    >
        <v-card-text class="login-card__field">
            <v-text-field
              v-model="form.name"
              outlined
              label="Name"
              :rules="errors.name"
              autofocus
            >
              <template #label>
                  <span class="red--text"><strong>* </strong></span>Name
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.email"
              outlined
              label="Email"
              :rules="errors.email"
              autofocus
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Email
              </template>
            </v-text-field>
            <v-text-field
                v-model="form.password"
                :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="password_show ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                outlined
                :rules="errors.password"
                @click:append="password_show = !password_show"
                autocomplete="new-password"
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Password
              </template>
            </v-text-field>
            <vue-phone-number-input 
              v-model="phone_number"
              class="mb-3"
              no-example
              :only-countries="['US', 'PH']"
              color="#000000" 
              valid-color="#004df9"
              @update="formatPhoneNo($event)"
            />
            <p class="ma-3 mb-2 mt-5 caption red--text">
              <span v-if="isPhoneNoError || errors.phone_number">Phone Number is Invalid</span>  
              <span v-else class="white--text">Phone Number</span>  
            </p>
            <v-autocomplete
              disabled
              v-model="form.company_id"
              :items="company_list"
              item-text="name"
              item-value="id"
              autocomplete="nope"
              outlined
              label="Company"
              :rules="errors.company_id"
              autofocus
              :loading="companyLoading"
              v-if="!hideCompany"
              class="register-company-select"
            >
              <template #label>
                <span class="red--text"><strong>* </strong></span>Company
              </template>
            </v-autocomplete>
            <v-checkbox
              v-model="form.terms"
              class="mt-5"
              color="primary"
              hide-details
              autofocus
            >
            <template v-slot:label >
              <div>
                I agree to
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      target="_blank"
                      href="https://revdojo.com/terms-and-conditions/"
                      @click.stop
                      v-on="on"
                    >
                      Terms and Condition
                    </a>
                  </template>
                  Click to Read!
                </v-tooltip>
              </div>
            </template>
            </v-checkbox>
            <p class="caption red--text" v-if="isTermError">Please indicate that you have read and agree to the Terms and Conditions</p>
            <br>
            <span>already have an account? <a @click="goToLogin()"> Click here to Login </a> </span>
        </v-card-text>

        <v-card-actions class="login-card__action pa-0">
          <v-spacer/>
          <v-btn
            color="primaryMain"
            class="primaryMainText--text mb-3"
            type="submit"
            :loading="loading"
          >
            Register
        </v-btn>
        <v-spacer/>
        </v-card-actions>
    </v-form>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: 'RegisterContent',
  components: {
  },
  data() {
    return {
        form: {
            name: '',
            email: '',
            company_id: '',
            phone_number: '',
            phone_code: '',
            phone_country: '',
            password: '',
            terms: false,
            isRegister: true,
          },
        password_show: '',
        isTermError: false,
        isPhoneNoError: false,
        errors: [],
        loading: false,
        phone_number: '',
        company_list: [],
        companyLoading:false,
        hideCompany:false,
    }
  },
  computed: {
    ...mapState("layout", ["type","primaryColor"]),
    companyIdCookie() {
      return this.$cookies.get('companyId')
    },
    companyIdParam() {
      return this.$route.query.company_id
    }
  },
  watch: {
    type(val) {
      this.renderInitialCompany();
    }
  },
  mounted() {
      this.renderInitialCompany();
  },
  methods: {
    renderInitialCompany() {
      this.hideCompany = false

      if (this.companyIdParam) {
        this.form.company_id = this.companyIdParam 
      }

      if (this.form.company_id) {
        this.companyLoading = true
        this.$http
          .get(`/get-company-show/${this.form.company_id}` )
          .then((result) => {
            this.company_list = result.data
            this.form.company_id = result.data[0].id
            this.companyLoading = false
          })
          .catch((error) => {
            this.companyLoading = false
          })
      } else {
        this.hideCompany = true
      }

    },
    signUp() {
      if (this.isPhoneNoError) {
        return false
      }
      this.loading = true
      this.$http
        .post('auth/register', this.form)
        .then((data) => {
          this.loading = false
          this.$refs.form.reset()
          this.phoneNumberReset()
          this.$router.push({ name: 'Login'});
        })
        .catch((error) => {
          if (error.response.status == 422){
            this.errors = error.response.data.errors
            this.isTermError = this.errors.hasOwnProperty('terms')
            this.isPhoneNoError = (this.form.phone_number == null)  

          } else if (error.response.status == 403 && error.response.data.soft_deleted) {
              this.$store.commit('login/SET_LOGIN_ERROR', error.response.data.msg )
          } 
          else {
            this.$errorNotif("Something went wrong")
          }
          this.loading = false
        })
    },
    formatPhoneNo(event) {
      this.isPhoneNoError = false

      if (event.phoneNumber == undefined) {
        return this.isPhoneNoError;
      }

      if (event.phoneNumber && !event.isValid) {
        this.isPhoneNoError = true
      }

      this.form.phone_country = event.countryCode
      this.form.phone_code = '+' + event.countryCallingCode
      this.form.phone_number = event.nationalNumber
    },
    phoneNumberReset(){
      this.form.phone_country = ''
      this.form.phone_number = ''
      this.form.phone_code = ''
      this.phone_number = ''
    },
    goToLogin() {
      this.$router.replace({'query': null});
    }
  }
};
</script>