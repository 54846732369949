<template>
  <modal 
    name="manual-pay-modal" 
    width="600"  
    height="auto"  
    draggable
    :clickToClose="false"
    :adaptive="true" >
      <div class="pa-5 d-flex justify-space-between align-center">
          <h2 class="title ">{{ item.status == 'open' ? 'Manual Payment' : 'Payment Details'}}</h2>
      </div>
      <v-divider/>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        bottom
        height="5"
        ></v-progress-linear>

         <v-alert
            v-if="showError"
            dense
            outlined
            type="error"
            class="caption text-xs ma-3 mt-5 mb-1"
            >
            {{error}}
        </v-alert>
        <form @submit.prevent="sendOTP()"  
            method="PUT" 
            v-if="!showVerifyOTP">
    <v-card class="pa-4 d-flex flex-column">

      <v-container class="my-5 content-container">
              <v-select v-if="item.status == 'open'"
                v-model="form.type"
                :items="typeItems"
                label="Payment Type"
                item-text="name"
                item-value="value"
                outlined
                dense
                required
                :disabled="loading"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Payment Type is required']"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Payment Type
                </template>
              </v-select>
              <v-select v-else
                :items="typeItems"
                :value="item.payment_method"
                label="Payment Type"
                item-text="name"
                item-value="value"
                outlined
                dense
                required
                :disabled="loading"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Payment Type is required']"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Payment Type
                </template>
              </v-select>
              <v-textarea
                v-if="item.status == 'open'"
                label="Payment Notes"
                placeholder="Payment Notes"
                outlined
                no-resize
                counter="250"
                maxlength="250"
                dense
                required
                rows="3"
                :disabled="loading"
                v-model="form.notes"
                :rules="errors.notes"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span
                  >Payment Notes
                </template>
              </v-textarea>
              <v-textarea
                v-else
                :value="item.payment_notes"
                label="Payment Notes"
                placeholder="Payment Notes"
                outlined
                no-resize
                counter="250"
                maxlength="250"
                dense
                required
                rows="3"
                :disabled="loading"
                :rules="errors.notes"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span
                  >Payment Notes
                </template>
              </v-textarea>
              <v-checkbox
              v-if="item.status == 'open'"
              class="mr-10"
              dense
              v-model="form.sendUser"
              label="Send email to user"
              color="primary"/>
          </v-container>
          <v-card-actions class="px-5 py-3">
            <v-btn 
                text 
                color="primary"
                @click="cancel()">{{ item.status == 'open' ? 'Cancel' : 'Close'}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn 
            v-if="item.status == 'open'"
                class="primaryMainText--text"
                :loading="loading"
                color="primaryMain"
                type="submit"
                >Submit</v-btn>
          </v-card-actions>
        </v-card>
      </form>
  
   <v-form @submit.prevent="verifyOTP()" method="PUT" v-if="showVerifyOTP">
    <v-card-text class="login-card__field px-5 py-8">
      <h3 class="mt-5 text-center">
        Please enter the One-Time Password to verify your transaction.
      </h3>
      <p class="text-center">
        A One-Time Password has been sent to {{ user.phone_code + user.phone_number }}
      </p>
      <div class="mt-5">
        <v-otp-input
          ref="otpInput"
          :length="otpLength"
          v-model="form.sms_key"
          type="number"
          @finish="verifyOTP()"
        ></v-otp-input>
      </div>
      <p class="text-center">
        Did not receive code?
        <v-btn
          class="primaryMainText--text"
          :loading="loading"
          color="primaryMain"
          x-small
          @click="resendOTP()"
          :disabled="countDown > 0"
          >RESEND</v-btn
        >
        (in {{ countDown }} sec)
      </p>
    </v-card-text>
    <v-card-actions class="px-5 py-3">
      <v-btn text color="primary" @click="goToPaymentForm()">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="primaryMainText--text"
        :loading="loading"
        color="primaryMain"
        type="submit"
        :disabled="form.sms_key.length != 6"
        >Verify OTP</v-btn
      >
    </v-card-actions>
  </v-form>
  </modal>
</template>
<script>
import {
  filter as _filter,
} from "lodash-es";

export default {
  computed: {
      user() {
          return this.$store.state.user.current_user
      },
      isVerifyOTPDisable() {
        if (!this.form.sms_key) {
          return false
        }
        
        return this.form.sms_key.length != 6
      }
  },
  props: [
      'item'
  ],
  data() {
      return {
          showError: false,
          error: '',
          loading: false,
          errors: [],
          form: {
              type: '',
              notes: '',
              invoiceId: '',
              sendUser: false,
              sms_key: '',
              status: ''
          },
          typeItems: [
              {
                  name: "Check",
                  value: "check",
              },
              {
                  name: "Cash",
                  value: "cash",
              },
              {
                  name: "Bank Transfer",
                  value: "bank_transfer",
              },
              {
                  name: "Others",
                  value: "other",
              },
          ],
          showVerifyOTP: false,
          passwordShow: false,
          countDown: 1,
          otpLength: 6,
      };
  },
  created() {

  },
  methods: {
      cancel() {
        if (this.item.status == 'open') {
          this.form = {
              type: '',
              notes: '',
              invoiceId: '',
              sendUser: false
          }
        }
        this.$modal.hide('manual-pay-modal')
      },
      saveManualSubscription() {
          this.form.invoiceId = this.item.id
          this.loading = true
          this.$http
          .post('billing/manual-payment', this.form)
          .then((data) => {
              this.loading = false
              this.cancel();
              this.showVerifyOTP = false;
              this.otp = "";
              this.$successNotif("Successfully created a new subscription");
              this.$store.commit('billing/SET_RELOAD_INVOICE', true);         
              this.$emit('reload');   
          })
          .catch((error) => {
              this.loading = false
                        this.otp = null;
        this.showVerifyOTP = false;
          })
      },
      sendOTP() {
        this.clearError();
        this.loading = true;

        let form = {
          type: 'manual-payment'
        }

        this.$http
         .post(`/mfa/send-sms-code`,form)
          .then((data) => {
          this.loading = false;
          if (data.status == 200) {
            this.$successNotif('Successfully sent SMS code')
              this.showVerifyOTP = true;
              this.countDown = 60;
              this.countDownTimer();
          }
          })
          .catch((error) => {
            if (error.response.status == 400) {
                this.loading = false;
                this.showError = true;
                this.error = "Invalid Phone Number, Please check Super Admin's Phone number at profile";
                setTimeout(() => {
                  state.showError = false
                }, 10000);
            } 
          });
      },
      verifyOTP() {
      this.loading = true;
          this.$http
          .put(`/mfa/validate-sms-code`, this.form)
          .then((data) => {
            this.showVerifyOTP = false;
              this.$successNotif('SMS has been verified')
              this.showError = false;
              this.error = "";
              this.saveManualSubscription();
          })
          .catch((error) => {
              this.loading = false
              this.resetOTPInput();
              if (error.response.status == 422){
                  this.showError = true
                  this.error = error.response.data.errors
              } else {
                  this.$errorNotif("Something went wrong")
              }

              this.$refs.form.reset()
              setTimeout(() => {
                  state.showError = false
              }, 10000);
          })
      },
      goToPaymentForm() {
    this.showVerifyOTP = false;
  },
  clearError() {
    this.showError = false;
    this.error = "";
  },
  countDownTimer() {
    if (this.countDown > 0) {
      setTimeout(() => {
        this.countDown -= 1;
        this.countDownTimer();
      }, 1000);
    }

    if (this.countDown === 0) {

    }
  },
  resendOTP() {
    this.resetOTPInput()
    this.sendOTP();
  },
  resetOTPInput() {
    this.form.sms_key = '';
    let otpInput = this.$refs.otpInput
    if (otpInput.hasOwnProperty('otp')) {
      otpInput.otp = []
    }
  }
},
}
</script>
