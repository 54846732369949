<template>
<div class="company-logo">
    <div>
        <p class="subtitle-2 my-3">
            Company Icon
        </p>
        <v-alert
            max-width="300"
            dense
            class="caption text-left"
            outlined
            v-if="isError"
            type="error"
        >
        {{ errorMsg }}
        </v-alert>
        <p class="caption">Recommended: width 300px, height 300px. 2MB max size</p>
        <v-hover>
            <template v-slot:default="{ hover }">
                <div class="d-flex justify-center">
                    <v-avatar size="150">
                        <v-img 
                            class="company-logo__img"
                            @click="openFile()"
                            :src="image_preview">  
                            <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    class="img-hover" >
                                    <p class="text-center px-2 ma-0" width="100%">
                                        <a href="javascript:void(0)" >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                v-bind="attrs"
                                                v-on="on">mdi-upload</v-icon>
                                                </template>
                                                <span>Click to upload company icon</span>
                                            </v-tooltip>
                                        </a>
                                    </p>
                                </v-overlay>
                            </v-fade-transition>
                        </v-img>
                    </v-avatar>
                </div>
            </template>
        </v-hover>
        <input 
            class="file-type" 
            ref="imageFilesIcon" 
            type="file" 
            accept="image/png, image/gif, image/jpeg" 
            @change="previewImage()" 
            id="company-icon-img-file"
        >
    </div>
</div>
</template>
<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("layout", ["companyIcon"]),
        user() {
            return this.$store.state.user.current_user
        },
    },
    props: [
        'isError'
    ],
    data() {
        return {
            image: '',
            image_preview: '',
            errorMsg: 'Only jpeg,png,gif images are allowed. Maximum allowed size is 2MB'
        };
    },
    watch: {
        companyIcon() {
            this.renderImage()
        },
    },
    mounted() {
        this.renderImage()
    },
    methods: {
        openFile() {
           $('#company-icon-img-file').trigger('click');
        },
        
        renderImage() {
            return this.image_preview = this.companyIcon
        },

        previewImage() {
            this.renderImage()
            let event = this.$refs.imageFilesIcon.files[0];
            const file = event;
            this.image = event
            this.image_preview = URL.createObjectURL(file);
            this.$emit('iconUpload', this.image)
        },
      
    },
}
</script>
