<template>
    <div>
        <v-card class="mt-5" :loading="isLoading || loading" :disabled="isLoading || loading || isDisabled">
            <v-container>
                <v-row>
                    <v-col>
                        <v-card-title>
                            Active Users
                            <v-chip class="ma-2 text-capitalize" size="small">
                                {{ addIntervalSuffix(userLimit.interval) }}
                            </v-chip>
                        </v-card-title>
                        <v-card-subtitle>Price varies per usage</v-card-subtitle>
                    </v-col>
                    <v-col>
                        <div class="text-right pa-2 pr-4">
                            <span class="price font-weight-bold">${{ userLimit.usageTotal }}</span>&nbsp;
                            <span class="unit">per month</span>
                        </div>
                    </v-col>

                    <v-col cols="12">
                        <v-card-text class="font-weight-bold">{{ userLimit.activeUserTotal }} of {{
                            userLimit.subscriptionUserTotal }} users</v-card-text>
                        <div class="px-4 pb-5">
                            <v-progress-linear :value="userLimitProgress" :height="7"></v-progress-linear>
                        </div>
                    </v-col>
                    <v-col cols="12">   
                        <v-container>
                            <v-row>
                                <v-col :cols="4" v-for="(tier, index) in filteredTiers" :key="index">
                                    <v-card>
                                        <v-container>
                                            <div class="text-center font-weight-bold mt-4">
                                                {{ tier.name }}
                                            </div>

                                            <div class="text-center" style="height: 2rem;">
                                                <v-chip class="ma-2" color="green" text-color="white"
                                                    v-if="userLimit.currentTier.id == tier.id">
                                                    Active
                                                </v-chip>
                                            </div>

                                            <v-card-subtitle>

                                                <div class="text-center">
                                                    {{ tier.up_to }}
                                                </div>

                                                <div class="text-center" v-if="tier.unit_price == 0">
                                                    Free
                                                </div>
                                                <div class="text-center" v-else>
                                                    ${{ tier.unit_price }} per user
                                                </div>

                                            </v-card-subtitle>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>



                </v-row>

            </v-container>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer />
                <v-switch class="pr-4" label="Pay as you go" v-model="payAsYouGo" @change="updatePayAsYouGo()"></v-switch>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "ActiveUsersCard",
    components: {

    },
    props: {
        userLimit: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        isDisabled: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            payAsYouGo: this.userLimit.payAsYouGo,
            loading: false,
            headers: [
                { text: "Tiers", value: "name", sortable: false },
                { text: "Max Users", value: "up_to", sortable: false },
                { text: "Current users", value: "count_user", sortable: false },
                { text: "Amount per user", value: "unit_price", sortable: false },
                { text: "Consume Amount", value: "total", sortable: false },
                { text: "", value: "action", width: "180" },
            ],
            items: []
        };
    },
    computed: {
        userLimitProgress() {
            return (this.userLimit.activeUserTotal / this.userLimit.subscriptionUserTotal) * 100
        },
        userId() {
            return this.$route.params.id || null
        },
        filteredTiers() {
            return this.userLimit.tiers.filter(tier => tier.order >= this.userLimit.currentTier.order)
        },
        computedCols() {
      // Calculate the number of columns based on the length of filteredTiers
      const numCols = 5;

      // Set the maximum number of columns to 5
      const maxCols = 5; // Maximum number of columns

      // Ensure the number of columns is within the specified range
      return Math.min(numCols, maxCols);
    },
    },
    created() {

    },
    methods: {
        suffixTierUpto(upTo) {
            if (upTo == 1) {
                return 'user';
            }
            else if (upTo == null) {

            }
            else {
                return 'users';
            }
        },
        upgradeTier(tier) {

            let msg = {
                title: `Upgrade to ${tier.name}?`,
                confirmButtonText: `Yes, Upgrade ${tier.name}!`,
            }

            this.$questionNotif(msg)
                .then((result) => {
                    if (result) {
                        this.loading = true
                        this.$http
                            .post(`billing/upgrade-tier`, {
                                userId: this.userId,
                                tierId: tier.id
                            })
                            .then((data) => {
                                this.loading = false;
                                this.$emit('fetchActiveUsers');

                            })
                            .catch((error) => {
                                this.loading = false;
                                this.$errorNotif("Something went wrong");
                            });

                    }
                })
        },
        addIntervalSuffix(interval) {
            return (interval === 'month' || interval === 'year') ? interval + 'ly' : interval;
        },
        updatePayAsYouGo() {

            let msg = {
                title: `Turn ${this.payAsYouGo ? 'On' : 'Off'} Pay As You Go?`,
                confirmButtonText: `Yes, Turn ${this.payAsYouGo ? 'On' : 'Off'} Pay As You Go!`,
            }

            this.$questionNotif(msg)
                .then((result) => {
                    if (result) {
                        this.loading = true
                        this.$http
                            .post(`billing/update-pay-as-you-go`, {
                                userId: this.userId,
                                appId: this.userLimit.id,
                                payAsYouGo: this.payAsYouGo
                            })
                            .then((data) => {
                                this.loading = false;
                                this.$emit('fetchActiveUsers');
                            })
                            .catch((error) => {
                                this.loading = false;
                                this.$errorNotif("Something went wrong");
                            });

                    }
                    else {
                        this.payAsYouGo = !this.payAsYouGo;
                    }
                })
        }

    },
};
</script>
