var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content"},[_c('billing-portal-filter',{on:{"filter":_vm.filter}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"BillingPortalTable","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.per_page,"footer-props":{
      disablePagination: _vm.paginationDisable,
      'items-per-page-options': [5, 10, 20, 50],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"item.deleted_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.deleted_at).toLocaleString()))])]}},{key:"item.is_mobile",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.is_mobile),expression:"item.is_mobile"}],staticClass:"ml-1",attrs:{"color":"primary"}},[_vm._v(" mdi-check-circle-outline ")])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(!item.is_mobile)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 primaryMainText--text",attrs:{"x-small":"","color":"primaryMain"},on:{"click":function($event){return _vm.redirectToPortal(item)}}},'v-btn',attrs,false),on),[_vm._v(" View Billing ")])]}}],null,true)},[_c('span',[_vm._v("Go to "+_vm._s(item.name)+" Billing Portal")])])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }