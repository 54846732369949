<template>
<div class="breadcrumbs-wrapper z-top">
    <div class="breadcrumbs" >
        <div class="app-back" 
            v-if="hasAppBack"
            @mouseover="showAppBackClose = true"
            @mouseleave="showAppBackClose = false">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <a 
                        v-on="on" 
                        v-bind="attrs" 
                        href="javascript:void(0)"
                        class="app-back-item"
                        @click="appBackRedirect()"
                    >
                        {{ appBack.name }}
                    </a>
                </template>
                <span>Go back to {{ appBack.name }}</span>
            </v-tooltip>
            <v-tooltip bottom >
                <template v-slot:activator="{ on, attrs }">
                    <a href="javascript:void(0)" class="mx-2">
                        <v-icon 
                            @click="clearAppBack()"
                            v-if="showAppBackClose"
                            x-small
                            v-bind="attrs"
                            v-on="on">
                            mdi-close
                        </v-icon>
                    </a>
                </template>
                <span>Remove back to App </span>
            </v-tooltip>
            <v-divider vertical/>
        </div>
        <div v-for="(link, index) in links" :key="index" class="bc-link" :class="index == Object.keys(links).length - 1 ? 'active': ''">
            <a  class="black-bold" 
                :class="index == Object.keys(links).length - 1 ? 'active': ''" 
                v-html="link.title" 
                @click="navigate(link.link, link.params)"
            >
            </a>
        </div>
    </div>
    <div>
        <v-progress-linear
        v-if="breadcrumbsLoader"
        indeterminate
        color="primary"
        bottom
        height="5"
        ></v-progress-linear>
    </div>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
 
    props: {
        links: [],
        params: {
            default: ''
        }
    },
     computed: {
        ...mapState("global", ["appBack",'hasAppBack','breadcrumbsLoader']),
    },
    data() {
        return {
            showAppBackClose: false,
        }
    },
    methods: {
        navigate(link, params) {
            if(params == undefined){                
                this.$router.push({ name: link });
            } else {
                this.$router.push({ name: link, params:{id: params.id } });
            }
        },
        clearAppBack() {
            this.$store.commit('global/SET_APP_BACK', false)            
            this.$cookies.remove('app_origin');
        },
        appBackRedirect() {
            window.open(this.appBack.redirect)
        }
    },
    components: {
    }
}
</script>

<style>
.z-top {
    z-index: 9 !important;
}
.breadcrumbs {
    box-shadow: 5px 0 7px #636763 !important;
}
</style>