<template>
    <v-form 
        ref="form" 
        @submit.prevent="submit()"  
        method="POST" 
    >
        <v-card-text class="login-card__field">
            <v-text-field
                v-model="form.password"
                prepend-inner-icon="mdi-lock-outline"
                :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="password_show ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                outlined
                @click:append="password_show = !password_show"
                :rules="errors.password"
                hint="The password must be between 8 and 30 characters, must have atleast 1 uppercase and 1 numeric "
            ></v-text-field>
             <v-text-field
                v-model="form.confirm_password"
                prepend-inner-icon="mdi-lock-outline"
                :append-icon="confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="confirm_password_show ? 'text' : 'password'"
                name="input-10-1"
                label="Confim Password"
                outlined
                :rules="errors.confirm_password"
                @click:append="confirm_password_show = !confirm_password_show"
                hint="The confirm password must be identical to the password "
            ></v-text-field>
        </v-card-text>
        <v-card-actions class="login-card__action pa-0">
          <v-spacer/>
          <v-btn
            color="primaryMain"
            class="primaryMainText--text mb-3"
            type="submit"
            :loading="loading"
          >
            Change Password
        </v-btn>
        <v-spacer/>
        </v-card-actions>
    </v-form>
</template>
<script>

export default {
  name: 'ResetPasswordContent',
  props: [
    'card_state',
    'data',
  ],
  data() {
    return {
        errors: [],
        password_show: false,
        confirm_password_show: false,
        loading: false,
        form: {
            password: '',
            confirm_password: '',
        }
    }
  },
  methods: {
    submit() {
      this.loading = true
      this.form['verify'] = this.data
      this.$http
        .post(`/auth/reset-password`, this.form)
        .then((data) => {
            this.loading = false
            this.$refs.form.reset()
            this.$successNotif('Successfully reset password')
            this.$router.replace({'query': null});
        })
        .catch((error) => {
          if (error.response.status == 422){
            this.errors = error.response.data.errors
          } else {
            this.$errorNotif("Something went wrong")
          }

          this.loading = false
        })
    },
    changeState() {
      this.$emit('state', this.card_state)
    }
  }
};
</script>