<template>
 <div class="login" v-if="showLogin">
      <login-card/>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LoginCard from '../components/login/LoginCard.vue';
import logoImport from '@/assets/images/kain-logo.png'
import carImgImport from '@/assets/images/carclean.png'


export default {
  name: 'Login',
  computed: {
    ...mapState("layout", ["companyLogo","layoutloading","admin"]),
  },
  components: {
    LoginCard
  },
  data() {
    return {
        logo: logoImport,
        carImg: carImgImport,
        switch_state: true,
        card_state: 'signin',
        showLogin: false,
        form: {
          name: '',
          email: '',
          company_id: '',
          terms: false,
        },
        email: '',
        password: '',
        company_list: [],
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.$route.query.client_id) {
        return this.postLogin()
      }

      if (this.$route.query.shared) {
        return this.preLogin()
      }

      if (this.$route.query.company_id) {
        return this.showLogin = true
      }

      if (this.$route.query.signup == 'true') {
        this.card_state = 'signup'
        return this.showLogin = true
      }

      if (this.$route.query['forgot-token'] && this.$route.query['id']) {
        this.card_state = 'reset_password'
        return this.showLogin = true
      }

      if (this.$route.query['forgot_password']) {
        this.card_state = 'forgot_password'
        return this.showLogin = true
      }

      return this.preLogin()
    },
    preLogin() {

      if (this.$route.query.nextUrl && this.$route.query.nextUrl !== '/home') {
        localStorage.setItem('nextUrl', this.$route.query.nextUrl);
      }

      this.$http
        .get('okta/pre-login/')
        .then((result) => {
          if (!result.data) {
              this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
              window.location.href = window.location.origin+'/okta/logout';
          }

          window.location.href = result.data
        })
    },
    async postLogin() {
      this.$http
        .post('okta/post-login/', {client_id: this.$route.query.client_id })
        .then(({ data }) => {
          const accessToken = `Bearer ${data.access_token.accessToken}`;
          this.$http.defaults.headers.common.Authorization = accessToken;
          this.$auth.storeManager.setUser(data.user);
          this.$auth.storeManager.setToken(data.access_token.accessToken);
          this.$cookies.set('default_auth_token', data.access_token.accessToken, '20y');
          this.$store.commit('user/SET_CURRENT_USER', data.user)
          this.$renderLayout(data.user.company_id)

          if (localStorage.getItem('nextUrl')) {
            this.$router.push(localStorage.getItem('nextUrl'));
          }
          window.location.reload()
        })
    },
    changeSwitch(val) {
      this.switch_state = true
    }
  }
};
</script>
