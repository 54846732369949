const data = {
    namespaced: true,
    state: {
        loginError: '',
        showLoginError: false,
        cardState: 'signin',
    },
    mutations: {
        SET_LOGIN_ERROR: (state, payload) => {
            state.showLoginError = true
            state.loginError = payload

            setTimeout(() => {
                state.showLoginError = false
              }, 10000);
        },
        SET_CARD_STATE: (state, payload) => {
            state.cardState = payload
        },
    },
}

export default data;
