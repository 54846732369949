var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('users-filter',{on:{"filter":_vm.filter}})],1),_c('div',{staticClass:"content-card__header d-flex justify-space-between"},[_c('div'),_c('div',[_c('v-btn',{staticClass:"mr-2 primaryMainText--text",attrs:{"color":"primaryMain"},on:{"click":function($event){return _vm.handleClickExport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-export ")]),_vm._v(" Export ")],1),_c('v-btn',{staticClass:"mr-2 primaryMainText--text",attrs:{"color":"primaryMain"},on:{"click":function($event){return _vm.goToCreate()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add New ")],1)],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","options":_vm.options,"server-items-length":_vm.pagination.total,"items-per-page":_vm.pagination.per_page,"footer-props":{
      disablePagination: _vm.paginationDisable,
      'items-per-page-options': [5, 10, 20, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.roleCondition(item) ? _vm.goToEdit(item.id) : _vm.goToView(item.id)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v("View")])])]}},{key:"item.roles",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.roles.length > 0 ? item.roles[0].friendly_name : null))]),_c('br'),(item.roles.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"small":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" see more ")])]}}],null,true)},_vm._l((item.roles),function(role,index){return _c('p',{key:index,staticClass:"ma-0"},[_vm._v(" • "+_vm._s(role.friendly_name)+" ")])}),0):_vm._e()]}},{key:"item.products",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex",on:{"click":function($event){return _vm.viewProducts(item.name, item.products)}}},_vm._l((item.products),function(product,index){return _c('span',{key:index,staticClass:"mx-1"},[(product.access)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"white","size":"25"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":product.cover_photo}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(product.name))])]):_vm._e()],1)}),0)]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":_vm.formatStatus(item.status).type,"text-color":"white"},on:{"click":function($event){return _vm.changeStatus(item)}}},[_vm._v(" "+_vm._s(_vm.formatStatus(item.status).name)+" ")])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])]}},{key:"item.last_login_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.last_login_at !== null) ? new Date(item.last_login_at).toLocaleString() : ''))])]}},{key:"item.deleted_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.deleted_at).toLocaleString()))])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.is_super_admin)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"purple"},on:{"click":function($event){return _vm.resync(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,true)},[_c('span',[_vm._v("Re-Sync")])])],1):_vm._e(),(_vm.showTrashed)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"success"},on:{"click":function($event){return _vm.restore(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,true)},[_c('span',[_vm._v("Restore")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"error"},on:{"click":function($event){return _vm.permanentDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-forever")])],1)]}}],null,true)},[_c('span',[_vm._v("Permanent Remove")])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"success"},on:{"click":function($event){return _vm.changeStatus(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve/Reject")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.resend(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("Re-send Invitation")])]),_c('span',[(_vm.roleCondition(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"warning"},on:{"click":function($event){return _vm.goToEdit(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"info"},on:{"click":function($event){return _vm.goToView(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])],1),(_vm.roleCondition(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","color":"error","icon":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])]):_vm._e()],1)])]}}])}),_c('products-modal',{attrs:{"items":_vm.selectedProducts}}),_c('status-modal',{attrs:{"item":_vm.selectedItem}}),_c('restrict-create-modal'),_c('export-dialog'),_c('import-dialog'),_c('export-users-modal',{attrs:{"filters":_vm.filters}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }