var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3 pa-4"},[(_vm.showError)?_c('v-alert',{staticClass:"caption mb-8",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_vm._m(0),_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","loading":_vm.loading,"small":"","color":"primary"},on:{"click":function($event){return _vm.openFile()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-upload")]),_c('input',{ref:"productImageFileType",staticClass:"file-type",attrs:{"id":"product-image-upload","type":"file","accept":"image/*","multiple":""},on:{"change":_vm.uploadFile}})],1)]}}])},[_c('span',[_vm._v("upload")])])],1),_c('div',{staticClass:"my-5"},[_c('v-lazy',{staticClass:"list-group-item",attrs:{"options":{
      threshold: .5
      },"min-height":"200","transition":"fade-transition"}},[_c('v-container',{staticClass:"product-gallery d-flex flex-wrap justify-center"},[_c('draggable',{staticClass:"gallery-list-group",attrs:{"disabled":!_vm.enabledDrag,"list":_vm.images,"handle":".gallery-handle"}},_vm._l((_vm.images),function(media,index){return _c('v-hover',{key:index,staticClass:"gallery-handle",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('div',[_c('v-img',{staticClass:"ma-3",attrs:{"aspect-ratio":16/9,"width":"150","src":media.gallery_icon,"lazy-src":media.gallery_icon}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{staticClass:"gallery-img-hover",attrs:{"absolute":""}},[_c('div',{staticClass:"gallery-close"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.destroy(media.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"red--text text--accent-3",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("Remove")])])],1)]),_c('div',{staticClass:"gallery-view"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.view(media.image_path)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify-expand")])]}}],null,true)},[_c('span',[_vm._v("View")])])],1)])]):_vm._e()],1)],1)],1)]}}],null,true)})}),1),_c('div',{staticClass:"text-center"},[(_vm.images.length < 1)?_c('p',{staticClass:"my-10 caption text-uppercase "},[_vm._v("No Image")]):_vm._e()])],1)],1)],1),_c('gallery-viewer',{attrs:{"path":_vm.viewerPath}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"caption"},[_vm._v("Recommended: png, jpg, 2MB max per image size, 8 total images ")])])}]

export { render, staticRenderFns }