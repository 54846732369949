<template>
    <div>
      <v-layout class="d-flex justify-end mb-3">  
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" @click="resetFilter()"
              v-bind="attrs" fab dark x-small>
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </template>
          <span>Reset Filter</span>
        </v-tooltip>
      </v-layout>
    
      <v-text-field
        v-model="search"
        outlined
        dense
        label="Search"
        clearable
      ></v-text-field>
      <v-layout row class="mt-1">
        <v-flex xs12 md6 lg6 class="px-3">
          <v-select
              v-model="filters.status"
              :items="status"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              item-value="value"
              item-text="name"
              label="Status"
              clearable
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 lg6 class="px-3" >
          <v-select
            v-model="filters.role"
            :items="roles"
            outlined
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            item-value="id"
            item-text="friendly_name"
            label="Role"
            :loading="loadRole"
            clearable
          ></v-select>
        </v-flex>
      </v-layout>
    </div>
    </template>
    
    <script>
    import {
        filter as _filter,
    } from 'lodash';
    
    export default {
      name: 'InviteUsersFilter',
      components: {
      },
      data () {
        return {
            timeout: null,
            loading: false,
            status: [
                {
                    value: 'Pending',
                    name: 'Pending',
                },
                {
                    value: 'Registered',
                    name: 'Registered',
                },
            ],
            loadRole: false,
            roles: [],
            search: '',
            filters: {
              status: null,
              role: null,
              search: null,
              trashed: false,
            },
        }
      },
      computed: {
        user() {
           return this.$store.state.user.current_user
        },
      },
      watch: {
        filters: {
          handler: function (after, before) {
            this.$emit('filter', this.filters)
          },
        deep: true,
        },
        search() {
          this.renderSearch()
        }
      },
      created() {
        this.fetchRoles()
      },
      methods: {
        renderSearch() {
          clearTimeout(this.timeout);
    
          this.timeout = setTimeout(() => {
            this.filters.search = this.search
          }, 1000);
        },
        fetchRoles() {
          this.loadRole = true
          this.$http
          .get('roles')
          .then((result) => {
            this.roles = result.data.filter(role => role.name != 'csm')
            this.loadRole = false
          })
        },
        resetFilter() {
            this.filters.status = null
            this.filters.role = null
            this.filters.search = null
            this.filters.trashed = false
            this.search = null
        }
      }
    };
    </script>
    