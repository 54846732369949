<template>
  <modal 
    class="status-modal"
    name="status-modal" 
    width="300"  
    height="auto"  
    :clickToClose="true"
    :adaptive="true" >

    <div class="ma-5" >
        <div class="d-flex justify-space-between ">
            <h2>Status</h2>
            <v-btn
                @click="cancel()"
                icon
                color="black"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <p>{{item.name}}</p>
    </div>
    <v-divider></v-divider>
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        bottom
        height="5"
        ></v-progress-linear>

    <div class="mx-5 my-8">
        <v-radio-group v-model="item.status" :loading="loading" :disabled="loading">
            <v-radio
                v-for="(status, index) in statuses"
                :key="index"
                :label="status.name"
                :value="status.value"
            ></v-radio>
        </v-radio-group>
    </div>
  </modal>
</template>
<script>

export default {
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
    },
    props: {
    item: {
      required: true,
    },
  },
  watch: {
    'item.status'(newVal, oldVal) {
      if (!this.current || !oldVal) {
            this.current = newVal;
        } else if (this.current !== newVal) {
            this.update(newVal);
        }
    },
  },
    data() {
        return {
            statuses: [
                {
                    name: 'Active',
                    value: 'ACTIVE',
                },
                {
                    name: 'Rejected',
                    value: 'REJECTED',
                },
                {
                    name: 'Under Review',
                    value: 'UNDER_REVIEW',
                },
                {
                    name: 'Inactive',
                    value: 'INACTIVE',
                }
            ],
            loading: false,
            current: ''
        };
    },
    methods: {
        cancel() {
            this.current = ''
            this.$modal.hide('status-modal')
        },
        update(newStatus) {
            const isStatusValid = this.statuses.some(status => status.value === newStatus);
            if (isStatusValid) {
                this.loading = true
                this.$http
                .put(`/user/change-status/${this.item.id}`, {item: newStatus})
                .then((data) => {
                    this.current = data.data.status
                    this.loading = false
                    this.$successNotif('Successfully changed status')
                })
                .catch(() => {
                    this.loading = false
                    this.$errorNotif("Something went wrong")
                })
            }
        }
    },
    components: {
    }
}
</script>
