<template>
  <div class="content">
    <breadcrumbs :links="breadcrumbs"/>


    <div class="content-wrapper preference-card ">
      <h1 class="font-weight-medium">{{ $route.meta.title }}</h1>

      <div class="mt-5 content-card">
          <v-alert
            dense
            type="info"
            outlined
          >
            Preference will affect all Company's users
          </v-alert>

          
        <div class="preference-card__content d-flex justify-space-around ">
          <!-- Company Logo -->
          <div class="preference-item">
            <company-logo :isError="isCompanyLogoError" @logoUpload="logoUpload" @logoWidthUpload="logoWidthUpload"></company-logo>
          </div>
          <!-- Company Logo Background Color -->
          <div class="preference-item">
            <p class="subtitle-2 my-3">Company Logo <br> Background Color
              <v-btn
                class="ml-1"
                icon
                color="gray"
                x-small
                outlined
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >  
                        <v-icon color='gray' x-small>mdi-information-variant</v-icon>
                        </span>
                    </template>
                    <div>
                      Company logo background color includes:
                      <ul>
                        <li>Sidebar logo's background color</li>
                        <li>LMS Sidebar logo's background color</li>
                        <li>login logo's background color (Flag shape)</li>
                      </ul>
                    </div>
                  </v-tooltip>
                </v-btn>
            </p>
            <photoshop-picker v-model="logoBgcolor" :disableAlpha="true"/>
          </div>
          <!-- Company Icon -->
          <div class="preference-item">
            <company-icon :isError="isCompanyIconError" @iconUpload="iconUpload"></company-icon>
          </div>
          <!-- Primary Color -->
          <div class="preference-item">
            <p class="subtitle-2 my-3">Primary Color

              <v-btn
              class="ml-1"
              icon
              color="gray"
              x-small
              outlined
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                      <span
                      v-bind="attrs"
                      v-on="on"
                      >  
                      <v-icon color='gray' x-small>mdi-information-variant</v-icon>
                      </span>
                  </template>
                  <div>
                    Primary color includes:
                    <ul>
                      <li>Components Highlight (eg. Button, Title, Links, Loading, etc.)</li>
                    </ul>
                  </div>
                </v-tooltip>
              </v-btn>
            </p>
          
            <photoshop-picker v-model="primary" :disableAlpha="true"/>
          </div>
          <!-- Secondary Color -->
          <!-- <div class="preference-item">
            <p class="subtitle-2 my-3">Secondary Color
              <v-btn
                class="ml-1"
                icon
                color="gray"
                x-small
                outlined
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >  
                        <v-icon color='gray' x-small>mdi-information-variant</v-icon>
                        </span>
                    </template>
                    <div>
                      Secondary color includes:
                      <ul>
                        <li>Login Page Secondary Color</li>
                      </ul>
                    </div>
                  </v-tooltip>
                </v-btn>
            </p>
            <photoshop-picker v-model="secondary" :disableAlpha="true"/>
          </div> -->
    </div>

    <div class="preference-card__action">
      <v-btn
        v-if="!isDefault"
        @click="reset()"
        class="mx-2"
        dark
        :loading="loading"
        elevation="2"
        >
        Reset to default 
      </v-btn>
      <v-btn
        :disabled="disableSubmit"
        @click="submit()"
        :loading="loading"
        elevation="2"
        class="primaryMainText--text"
        color="primaryMain">
        Submit
      </v-btn>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapState } from "vuex";
import CompanyLogo from '@/components/preference/CompanyLogo';
import CompanyIcon from '@/components/preference/CompanyIcon';
import { Chrome } from 'vue-color'

export default {
  name: 'Preference',
  components: {
    Breadcrumbs,
    CompanyLogo,
    CompanyIcon,
    'photoshop-picker': Chrome
  },
  data () {
    return {
       breadcrumbs: {
            0 : {
              title: 'Home',
              link: 'home',
            },
            1 : {
              title: this.$route.meta.title ,
              link: this.$route.meta.name,
            }
        }, 
        loading: false,
        primary: '',
        secondary: '',
        logoBgcolor: '',
        logo: '',
        logoWidth: '',
        icon: '',
        disableSubmit: false,
        isDefault: true,
        showError: false,
        errorMsg: '',
        isCompanyLogoError: false,
        isCompanyIconError: false
    }
  },
  computed: {
    ...mapState("layout", [
      "companyLogo", 
      "companyLogoBgcolor", 
      "primaryColor", 
      "secondaryColor",
      "type"
    ]),
    user() {
       return this.$store.state.user.current_user
    },
    primaryValue() {
      return this.primary.hasOwnProperty('hex') ? 
      this.primary.hex : this.primary
    },
    secondaryValue() {
      return this.secondary.hasOwnProperty('hex') ? 
      this.secondary.hex : this.secondary
    },
    logoBgcolorValue() {
      return this.logoBgcolor.hasOwnProperty('hex') ? 
      this.logoBgcolor.hex : this.logoBgcolor
    },
  },
  watch: {
    primary(val) {
      document.documentElement.style.setProperty('--primary', this.primaryValue);
      document.documentElement.style.setProperty('--navbar-active', this.$hexToRgba(this.primaryValue, 0.25));
      this.$vuetify.theme.themes.light.primary = this.primaryValue;
      this.$autoAdjustColor(this.primaryValue, this.secondaryValue)
      this.renderZendesk()
    },
    secondary(val) {
      document.documentElement.style.setProperty('--secondary', this.secondaryValue);
      this.$vuetify.theme.themes.light.secondary = this.secondaryValue;
    },
    logoBgcolor(val) {
      document.documentElement.style.setProperty('--logo-bgcolor', this.logoBgcolorValue);
    },
    primaryColor(val) {
      this.primary = this.primaryColor
    },
    secondaryColor(val) {
      this.secondary = this.secondaryColor
    },
    companyLogoBgcolor(val) {
      this.logoBgcolor = this.companyLogoBgcolor
    },
  },
  mounted() {
    this.renderLayout();
  },
  methods: {
    renderZendesk() {
      if (window.zE !== undefined) {
        if (this.primaryValue == this.primaryColor) {
          this.$zendesk.show();
        } else {
          this.$zendesk.hide();
        }
      } 
    },
    renderLayout() {
      this.primary = this.primaryColor
      this.secondary = this.secondaryColor
      this.logoBgcolor = this.companyLogoBgcolor
      this.isDefault = this.type == 'default' || this.type == '' ? true : false
    },
    iconUpload(val) {
      this.icon = val
    },
    logoUpload(val) {
      this.logo = val
    },
    logoWidthUpload(val){
      this.logoWidth = val
    },
    submit(){
      this.loading = true
      var form = new FormData();

      form.append('primary_color', this.primaryValue);
      form.append('secondary_color', this.secondaryValue);
      form.append('company_logo', this.logo);
      form.append('company_icon', this.icon);
      form.append('logo_width', this.logoWidth);
      form.append('logo_bgcolor', this.logoBgcolorValue);
      this.$http
        .post('preference', form)
        .then((result) => {
          this.loading = false
          if (result.data) {
            this.$successNotif('Layout has been saved')
            this.$setLayout(result.data)
            this.$cookies.set('companyId',this.user.company_id, '20y');
            this.renderLayout()
            location.reload();
          } else {
            this.$infoNotifToast('Default Layout, No Changes Occurred')
          }
        })
        .catch((error) => {
          this.loading = false

          if (this.logo.size / 1000 > 2000) {
            this.isCompanyLogoError = true
          }

          if (this.icon.size / 1000 > 2000) {
            this.isCompanyIconError = true
          }

          setTimeout(() => {
            this.isCompanyLogoError = false
            this.isCompanyIconError = false

          }, 5000);
        })
    },
    reset() {
      this.loading = true
      this.$http
        .delete(`/preference/${this.user.company_id}` )
        .then((result) => {
          this.isDefault = true
          this.loading = false
          this.$setLayout(result.data)
          this.renderLayout()
          this.$cookies.remove('companyId');
        })
        .catch((error) => {
          
        })
    }
  }
};
</script>
