<template>
  <div class="content">
    <breadcrumbs :links="breadcrumbs" />

    <div class="content-wrapper">
      <h1 class="font-weight-medium">{{ $route.meta.title }}</h1>

      <div class="mt-5 content-card pa-0">
        <v-tabs grow v-model="tab" class="pa-8 pb-0">
          <v-tab>Users</v-tab>
          <v-tab>Invite Users</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item class="pa-8 pt-5">
            <users-table></users-table>
          </v-tab-item>
          <v-tab-item class="pa-8 pt-5">
            <InviteUsersTable />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UsersTable from "@/views/users/UsersTable";
import InviteUsersTable from "@/views/invite_users/InviteUsersTable";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  name: "Users",
  components: {
    Breadcrumbs,
    UsersTable,
    InviteUsersTable,
  },
  computed: {
    ...mapState("user", [
      "current_user", 
      'is_super_admin', 
      'is_account_manager',
      'is_dealer_manager',
      'is_position_manager',
      'restrict_create'

    ]),
  },
  data() {
    return {
      tab: null,
      breadcrumbs: {
        0: {
          title: "Home",
          link: "home",
        },
        1: {
          title: this.$route.meta.title,
          link: this.$route.meta.name,
        },
      },
    };
  },
  created() {
    this.tab = parseInt(localStorage.userTab || 0);
    this.checkUserlimit();
  },
  mounted() {
    this.checkPositionManager();
  },
  methods: {
    checkUserlimit() { 
      this.$http
        .get(`billing/check-user-limit/${this.current_user.company_id}`)
        .then(({data}) => {
            if (data.status == 'restrict_create') {
              this.$store.commit('user/SET_RESTRICT_CREATE', data);
            }
        })
        .catch((error) => {
            this.loading = false
            this.$errorNotif("Something went wrong")
        })
    },
    checkPositionManager() {
        this.$http
        .get('user/check-position-manager', {
            params: {
                user_id: this.current_user.id,
            },
        })
        .then(({data}) => {
            this.$store.commit('user/SET_CURRENT_USER_POSITION_MANAGER', data)
        })
        .catch((error) => {
        });
    },
  },
};
</script>
