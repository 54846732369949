import Vue from 'vue'
import Vuex from 'vuex'
import company from './modules/company'
import user from './modules/user'
import global from './modules/global'
import login from './modules/login'
import layout from './modules/layout'
import apps from './modules/apps'
import users from './modules/users'
import product from './modules/product'
import billing from './modules/billing'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
      global,
      company,
      user,
      login,
      layout,
      apps,
      users,
      product,
      billing
    }
})
