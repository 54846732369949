<template>
  <div class="content">
    <breadcrumbs :links="breadcrumbs"/>


    <div class="content-wrapper">
      <h1 class="font-weight-medium">{{ $route.meta.title }}</h1>

      <div class="mt-5 content-card">
        <company-table></company-table>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import CompanyTable from '@/views/companies/CompanyTable';

export default {
  name: 'Companies',
  components: {
    Breadcrumbs,
    CompanyTable
  },
  data () {
    return {
       breadcrumbs: {
            0 : {
              title: 'Home',
              link: 'home',
            },
            1 : {
              title: this.$route.meta.title ,
              link: this.$route.meta.name,
            }
        }, 
    }
  },
  computed: {
 
  },
  created() {

  },
  methods: {
  }
};
</script>
