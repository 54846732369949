<template>
  <div class="qr-widget">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mx-2 primaryMainText--text"
            fab
            small
            color="primaryMain"
            v-bind="attrs"
            v-on="on"
            @click="$modal.show('qr-modal')"
            >
            <v-icon dark>
               mdi-link
            </v-icon>
        </v-btn>
      </template>
      <span>Share Link</span>
    </v-tooltip>
    <qr-modal/>
  </div>
</template>
<script>
import QrModal from './QrModal.vue';

export default {
name: 'QrWidget',
components: {
    QrModal
},
data() {
    return {
    }
},
mounted() {
},
computed: {
    
},
watch: {
},
methods: {
  
}
};
</script>