<template>
  <modal 
    class="edit-security-question"
    name="edit-security-question-modal" 
    width="600"  
    height="auto"  
    :clickToClose="false"
    :resizable="true"
    :adaptive="true" >
        <h2 class="title pa-5">Edit Security Questions</h2>
        <v-divider/>
        <v-card-text class="login-card__field px-5 py-8">
            <div class="mb-8" v-for="(item, index) in security_questions" :key="index">
                <h3 class="primary--text mb-3">Question {{++index}}</h3>
                <v-autocomplete
                    v-model="item.question"
                    :items="questions"
                    outlined
                    dense
                    label="Question"
                ></v-autocomplete>
                <v-text-field
                    v-model="item.answer"
                    outlined
                    dense
                    label="Answer"
                />
                <p class="caption red--text" v-if="errorId.includes(item.id)">Answer Field is Required</p>
                <div class="text-right">
                    <v-btn
                        @click="submit(item)"
                        small
                        outlined
                        :loading="loading"
                        color="primary"
                        type="submit"
                        >Update</v-btn>
                </div>
            </div>
        </v-card-text>
        <v-card-actions class="px-5 py-3">
            <v-btn 
                text 
                color="primary"
                @click="cancel()">Cancel</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
  </modal>
</template>
<script>

export default {
    data() {
        return {
            showError: false,
            errorId: [],
            loading: false,
            items: [
                { text: "What is your mother's maiden name?", disabled: false },
                { text: "In what city were you born?", disabled: false },
                { text: "What is the name of your first school?", disabled: false },
                { text:  "What high school did you attend?", disabled: false },
                { text: "In what city or town was your first job?", disabled: false },
                { text: "What is the name of your favorite pet?", disabled: false },
                { text: "What was the make of your first car?", disabled: false },
                { text: "What was your favorite food as a child?", disabled: false },
                { text: "What was your childhood nickname?", disabled: false },
            ],
        };
    },
    props: [
        'security_questions',
    ],
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
        questions() {
            Object.entries(this.items).forEach(
                value => value[1].disabled = false);

            Object.entries(this.items).forEach(([key, value]) => {
                Object.entries(this.security_questions).forEach(([key1, value1]) => {
                    value.text == value1.question ?
                    value.disabled = !value.disabled :
                    ''     
                });
            });
            return this.items
        }
    },
    methods: {
        cancel() {
            this.$modal.hide('edit-security-question-modal')
        },
        submit(item) {
            this.$http
            .put(`/security-question/${item.id}`, item)
            .then((data) => {
                this.$successNotif('Successfully updated')
                this.errorId = this.errorId.filter(val => val !== item.id)
            })
            .catch((error) => {
                if (error.response.status == 422){
                    this.errorId.push(item.id) 
                }
            })
        }
    },
}
</script>
