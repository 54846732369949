<template>
    <v-card class="product-card ma-3">
        <div>
           
            <v-img
                class="white--text align-end"
                height="300px"
                :src="image"
            >
            </v-img>
            <div class="chip-active" v-if="product.active">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-on="on" 
                            v-bind="attrs" 
                            class="mr-2"
                            color="black"
                            text-color="white"
                            small
                            >
                                Active
                        </v-chip>
                    </template>
                    <span>You have an active subscription to this product</span>
                </v-tooltip>
            </div>
            <v-card-title class="pt-1">
                {{ product.name }}
            </v-card-title>
            <v-card-subtitle class="pb-0 ">
                {{$formatProductType(product.type)}}
            </v-card-subtitle>
            <v-card-subtitle class="py-0 primary--text title ">
                {{ price }}
            </v-card-subtitle>
            
            <v-card-text class="text--primary">
                <p>{{ product.description.substring(0, 100)}} <span>{{ product.description.length > 100 ? '...' : ''  }}</span></p>
            </v-card-text>
    
        </div>
        <label v-if="product.canceled" class="item-title-cancel caption d-flex justify-space-between">
            Plan will be canceled on {{ product.canceled.canceled_at | moment("MMMM DD, YYYY") }}
            <v-btn :loading="loadingRenew" x-small class="mx-1 ml-3" outlined dark v-if="product.canceled.hasRenewAccess" @click="renew(product.canceled)">
            Renew
            </v-btn>
        </label>
        <div v-if="product.invoice">
            <v-divider/>
            <div class="ma-4 mb-1">
                <p class="caption">
                   <span class="font-weight-bold">Unpaid Manual Subscription</span>
                    You have manual subscription requested by Admin. Pay here to proceed. <br>
                    If you are already paid, Please email             
                    <a class="font-weight-bold" :href="'mailto:' + admin.email">
                        {{ admin.email }}
                    </a>, to verified your subscription
                </p>
            </div>
        </div >
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="primary" v-if="showCheckout" @click="checkout()">
                Checkout
            </v-btn>
            <v-btn small color="primary" v-if="product.invoice" @click="manualPay()">
                Pay Here
            </v-btn>
            <v-btn small color="primary" v-if="showAdds" @click="viewAddson()">
                Add ons
            </v-btn>
        </v-card-actions>
        <checkout-slide
            :product="product"
            title="Checkout"
            :show="slideOver.show"
            @close="toggleSlideOver"
        >
      </checkout-slide>
      <adds-on-modal :item="product"></adds-on-modal>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment'
import {
    find as _find,
} from 'lodash';
import imageDefault from '@/assets/images/revdojo_default.jpg'
import CheckoutSlide from './CheckoutSlide.vue';
import AddsOnModal from "@/components/manage_products/AddsOnModal.vue";

export default {
    name: 'ProductCard',
    components: {
        CheckoutSlide,
        AddsOnModal
    },
    props: [
        'product',
    ],
    data () {
        return {
            imageDefault: imageDefault,
            image: imageDefault,
            loadingRenew: false,
            slideOver: {
                show: false,
            },
        }
    },
    computed: {
        ...mapState("layout", [
            "defaultImage","companyIcon",'admin'
        ]),
        ...mapState("user", [
            "current_user", 
        ]),
        showAdds() {
            if (this.product.addson == undefined) {
                return false
            }
            return ((this.product.addson.length > 0) && !this.showCheckout)
        },
        showCheckout() {
            return !this.product.active && !this.product.invoice
        },
        isSingleUser() {
            return this.current_user.company.is_single_user
        },
        canAccountSwitch() {
            return this.product.type == 'ENTERPRISE' && this.isSingleUser 
        },
        price() {

            if (this.product.price) {
                return this.product.price + '.00'
            }

            let price = _find(this.product.pricings, (price) => {
                return price.interval == 'month' || price.interval == 'one_time' ;
            });

            return '$' + price.unit_price / 100 + '.00'
        },
        
    },
    created() {
        this.renderImage();
    },
    methods: {
        renderImage() {
            this.image = this.product.galleries.length > 0 
                ? this.product.galleries[0].image_path
                : this.defaultImage
        },
        checkout() {
            this.toggleSlideOver()
        },
        toggleSlideOver() {
            this.slideOver.show = !this.slideOver.show;
        },
        renew(productCancel) {
            if (this.loadingRenew) {
                return false
            }
            let text = "This plan will no longer be canceled. It will renew on " + moment(productCancel.canceled_at).format("MMMM DD, YYYY") + '.'
            let msg = {
                title: "Renew "+productCancel.name+" for "+ productCancel.price +" per month?",
                text: text, 
                confirmButtonText: "Renew Plan!",
            }
            this.$questionNotif(msg)
            .then((result) => {
                if (result) {
                    this.loadingRenew = true
                    this.$http
                    .post(`billing/renew-subscribe`, productCancel)
                    .then((data) => {
                        this.$emit('reload')
                        this.loadingRenew = false
                        this.$successNotif('Successfully Renew '+ productCancel.name)
                    })
                }
            })
        },
        manualPay() {
            window.open(this.product.invoice.invoice_link)
        },
        viewAddson() {
            this.$modal.show('addson-modal')
        }
    }
};
</script>