<template>
  <modal
    name="activity-logs-modal"
    width="1500"
    height="auto"
    :adaptive="true" >
         <div class="pa-5 align-center">
          <div class="d-flex justify-space-between ">
            <h2>Activity Logs</h2>
            <v-btn
                @click="cancel()"
                icon
                color="black"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        </div>
        <v-divider/>
        <div class="ma-5">
          <v-data-table
              :headers="headers"
              :items="logs"
              class="elevation-1"
              :loading="loading"
              loading-text="Loading... Please wait"
              :options.sync="options"
              :server-items-length="pagination.total"
              :items-per-page="pagination.per_page"
              :footer-props="{ disablePagination: paginationDisable }"
          >
              <template v-slot:item.batch_id="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <p v-on="on">
                          {{item.batch_id.substring(0, 16) + " ..." }}
                      </p>
                    </template>
                    {{ item.batch_id }}
                  </v-tooltip>
              </template>

              <template v-slot:item.change_field="{ item }">
                <p class="ma-0" v-for="(field, index) in item.change_field" :key='index'>
                  {{field}}
                </p>
              </template>

              <template v-slot:item.created_at="{ item }">
                  {{ item.created_at | moment("MMMM DD, YYYY") }}
              </template>

          </v-data-table>
        </div>
  </modal>
</template>

<script>
export default {
  name: 'ActivityLogsModal',
  data () {
    return {
       loading: false,
      headers: [
        { text: 'Unique Identifier', value: 'batch_id', width: 200 },
        { text: 'Name', value: 'name' },
        { text: 'Initiated By', value: 'causer' },
        { text: 'Model Changed', value: 'target_type' },
        { text: 'Fields Changed', value: 'change_field', width: 350 },
        { text: 'Date', value: 'created_at'},
      ],
      search: '',
      logs: [],
      pagination: {
        total: 1,
        per_page: 10,
        current_page: 1,
        search: '',
        sortBy: '',
        sortDesc: false,
      },
      options: {},
      paginationDisable: false,
      timeout: null, 
    }
  },
  props: [
    'user'
  ],
  watch: {
    options: {
      handler() {
        this.changeTable();
      },
      deep: true,
    },
    user() {
      this.fetch()
    }
  },
  mounted() {
  },
  methods: {
    cancel() {
      this.$modal.hide('activity-logs-modal')
    },
    fetch() {
      if (!this.user.hasOwnProperty('id')) {
        return false;
      }

      this.loading = true;
      this.paginationDisable = true;
      this.logs = [];

      this.$http
          .get(`/activity-logs/${this.user.id}`, {
            params: {
              per_page: this.options.itemsPerPage,
              current_page: this.pagination.current_page,
              sortBy: this.pagination.sortBy,
              sortDesc: this.pagination.sortDesc,
              search: this.search,
            },
          })
          .then((data) => {
              this.loading = false;
              this.logs = data.data.data
              this.pagination.total = data.data.total;
              this.pagination.current_page = data.data.current_page;
              this.pagination.per_page = data.data.per_page
              this.paginationDisable = false;
          })
          .catch((error) => {
              this.$errorNotif("Something went wrong")
          })
    },
    changeTable() {
      this.pagination.current_page = this.options.page;

      this.pagination.sortBy =
        this.options.sortBy !== undefined ? this.options.sortBy[0] : '';
      this.pagination.sortDesc =
        this.options.sortDesc !== undefined ? this.options.sortDesc[0] : '';

      this.fetch();
    },
  }
};
</script>
