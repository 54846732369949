<template>
  <div>
    <v-layout class="d-flex justify-space-between mb-3">
      <v-switch v-model="filters.trashed" class="ma-0" hide-details label="show removed users"></v-switch>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" @click="resetFilter()" v-bind="attrs" fab dark x-small>
            <v-icon>mdi-filter-remove</v-icon>
          </v-btn>
        </template>
        <span>Reset Filter</span>
      </v-tooltip>
    </v-layout>

    <v-layout row class="mt-5">
      <v-flex xs12 md6 lg6 class="px-3">
        <v-autocomplete autocomplete="off" v-model="selectedFilters" :items="filterItems" outlined
          :menu-props="{ bottom: true, offsetY: true }" dense item-value="value" item-text="name" label="Filters"
          clearable multiple></v-autocomplete>
      </v-flex>

      <v-flex xs12 md6 lg6 class="px-3">
        <v-text-field v-model="search" outlined dense label="Search" clearable></v-text-field>
      </v-flex>

      <template v-if="is_super_admin && selectedFilters.includes('Companies')">
        <v-flex xs12 md6 lg6 class="px-3">
          <v-autocomplete autocomplete="off" v-model="filters.companies" :items="companies" outlined
            :loading="companyLoading" :menu-props="{ bottom: true, offsetY: true }" dense item-value="id" item-text="name"
            label="Companies" clearable multiple></v-autocomplete>
        </v-flex>
      </template>

      <template v-if="(is_super_admin || is_account_manager) && selectedFilters.includes('Stores')">
        <v-flex xs12 md6 lg6 class="px-3">
          <v-autocomplete autocomplete="off" v-model="filters.dealers" :items="filteredDealers" outlined
            :loading="dealerLoading" :menu-props="{ bottom: true, offsetY: true }" dense item-value="id" item-text="name"
            :label="dealersLabel" clearable multiple></v-autocomplete>
        </v-flex>
      </template>

      <v-flex xs12 md6 lg6 class="px-3" v-if="selectedFilters.includes('Role')">
        <v-autocomplete autocomplete="off" v-model="filters.roles" :items="roles" outlined :loading="roleLoading"
          :menu-props="{ bottom: true, offsetY: true }" dense item-value="id" item-text="friendly_name" label="Role"
          clearable multiple></v-autocomplete>
      </v-flex>

      <v-flex xs12 md6 lg6 class="px-3" v-if="selectedFilters.includes('Status')">
        <v-autocomplete autocomplete="off" v-model="filters.statuses" :items="statuses" outlined
          :menu-props="{ bottom: true, offsetY: true }" dense item-value="value" item-text="name" label="Status" clearable
          multiple></v-autocomplete>
      </v-flex>

      <v-flex xs12 md6 lg6 class="px-3" v-if="selectedFilters.includes('Positions')">
        <v-autocomplete autocomplete="off" v-model="filters.positions" :items="positions" outlined
          :menu-props="{ bottom: true, offsetY: true }" dense item-value="id" item-text="friendly_name" label="Positions"
          clearable multiple></v-autocomplete>
      </v-flex>

      <v-flex xs12 md6 lg6 class="px-3" v-if="selectedFilters.includes('Team Leader')">
        <v-select v-model="filters.leader_id" :items="leaders" outlined :menu-props="{ bottom: true, offsetY: true }"
          dense item-value="id" item-text="name" label="Team Leader" clearable>
        </v-select>
      </v-flex>

    </v-layout>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  filter as _filter,
} from 'lodash';
import {
  isEmpty as _isEmpty,
  forEach as _forEach,
  flatten as _flatten,
  map as _map,
} from 'lodash-es';
import importExportUsers from '@/mixins/importExportUsers'

export default {
  name: 'UsersFilter',
  mixins: [importExportUsers],
  data() {
    return {
      timeout: null,
      loading: false,
      status: [
        {
          value: 'ACTIVE',
          name: 'Active',
        },
        {
          value: 'REJECTED',
          name: 'Rejected',
        },
        {
          value: 'UNDER_REVIEW',
          name: 'Under Review',
        },
        {
          value: 'INACTIVE',
          name: 'Inactive',
        },
        {
          value: 'NO_STATUS',
          name: 'No Status',
        }
      ],
      userManagingViewList: [
        {
          value: 'all',
          name: 'All',
        },
        {
          value: 'position',
          name: 'Position Manager View',
        },
      ],
      loadRole: false,
      loadPosition: false,
      loadLeaders: false,
      leaders: [],
      search: '',
      filters: {
        status: null,
        role: null,
        position: null,
        search: null,
        trashed: false,
        managing_view: null,
        managing_view_all: [],
        managing_position: null,
        leader_id: null,
        companies: [],
        dealers: [],
        statuses: [],
        roles: [],
        positions: [],
      },
      selectedFilters: []
    }
  },
  computed: {
    ...mapState("layout", ["labels"]),
    ...mapState("user", [
      "current_user",
      'is_super_admin',
      'is_position_manager',
      'is_account_manager',
      'is_dealer_manager',
      'managing_positions',
      'only_position_manager'
    ]),
    filteredPosition() {
      if (this.filters.role) {
        return _filter(this.positions, (position) => {
          return position.role_id == this.filters.role
        });
      }

      return this.positions
    },
    hidePositionField() {
      return this.filters.managing_view == 'all'
    },
    dealersLabel() {
      if (this.labels.dealers === undefined) {
        return '';
      }
      return this.labels.dealers.charAt(0).toUpperCase() + this.labels.dealers.slice(1);
    },
    filteredDealers() {
      if (!_isEmpty(this.filters.companies)) {
        let dealers = [];

        _forEach(this.filters.companies, (companyId) => {
          dealers.push(_flatten(_filter(this.dealers, { company_id: companyId })));
        });

        dealers = _flatten(dealers);

        return dealers;
      }

      return this.dealers;
    },
    filterItems() {

      let items = [];

      if (this.is_super_admin) {
        items.push('Companies');
      }

      if (this.is_super_admin || this.is_account_manager) {
        items.push('Stores');
      }

      items.push('Role');
      items.push('Status');

      if (this.is_super_admin || this.is_account_manager || (this.is_position_manager && !this.hidePositionField)) {
        items.push('Positions');
      }

      items.push('Team Leader');

      return items;
    }
  },
  watch: {
    filters: {
      handler: function (after, before) {
        this.$emit('filter', this.filters)
      },
      deep: true,
    },
    selectedFilters: {
      handler: function (after, before) {

        if (!after.includes('Companies')) {
          this.filters.companies = null;
        }

        if (!after.includes('Stores')) {
          this.filters.dealers = null;
        }

        if (!after.includes('Role')) {
          this.filters.roles = null;
        }

        if (!after.includes('Status')) {
          this.filters.statuses = null;
        }

        if (!after.includes('Positions')) {
          this.filters.positions = null;
        }

        if (!after.includes('Team Leader')) {
          this.filters.leader_id = null;
        }

      }
    },
    search() {
      this.renderSearch()
    },
    is_position_manager() {
      if (this.is_position_manager) {
        this.renderManagerView()
      }
    },
    only_position_manager() {
      this.resetFilter();
    }
  },
  created() {
    this.$emit('filter', this.filters)
    this.fetchLeaders()
    if (this.is_position_manager) {
      this.renderManagerView()
    }
  },
  methods: {
    renderManagerView() {
      let dealerView = {
        value: 'dealer',
        name: 'Dealer Manager View',
      }

      let companyView = {
        value: 'company',
        name: 'Company Admin View',
      }

      if (this.is_account_manager) {
        this.filters.managing_view_all.push('company')
        this.userManagingViewList.push(companyView)
      }

      if (this.is_dealer_manager) {
        this.filters.managing_view_all.push('dealer')
        this.userManagingViewList.push(dealerView)
      }

      if (this.only_position_manager) {
        this.filters.managing_view = 'position'
      }
    },
    renderSearch() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.filters.search = this.search
      }, 1000);
    },
    fetchRoles() {
      this.loadRole = true
      this.$http
        .get('roles')
        .then((result) => {
          this.roles = result.data
          this.roles.push({ id: 'no-role', friendly_name: 'No Role' })
          this.loadRole = false
        })
    },
    fetchLeaders() {
      this.loadLeaders = true;

      this.$http
        .get('leaders')
        .then((result) => {
          this.leaders = result.data
          this.loadLeaders = false
        })
    },
    fetchPosition() {
      this.loadPosition = true
      this.$http
        .get('positions', {
          params: {
            company_id: this.current_user.company_id,
          },
        })
        .then((result) => {
          this.positions = result.data.data
          this.loadPosition = false
        })
    },
    resetFilter() {
      this.filters.status = null
      this.filters.role = null
      this.filters.position = null
      this.filters.search = null
      this.filters.trashed = false
      this.search = null
      this.filters.managing_view = null
      this.filters.managing_view_all = []
      this.filters.managing_position = null
      if (this.only_position_manager && this.is_position_manager) {
        this.filters.managing_view = 'position'
      }

      this.filters.companies = [];
      this.filters.dealers = [];
      this.filters.statuses = [];
      this.filters.roles = [];
      this.filters.positions = [];
    }
  }
};
</script>
