<template>
  <modal 
    name="email-verify-modal" 
    width="400"  
    height="auto"  
    draggable
    :clickToClose="false"
    :adaptive="true" >
        <div class="pa-5 d-flex justify-space-between align-center">
            <h2 class="title ">Email Verification</h2>
            <v-btn
                class="ml-"
                icon
                color="gray"
                x-small
                outlined
                >
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                        v-bind="attrs"
                        v-on="on"
                        >  
                        <v-icon color='gray' small>mdi-information-variant</v-icon>
                        </span>
                    </template>

                    <div>
                        <h3 class="subtitle-2">How to verify?</h3>
                        <span class="caption">1. Send 6 digit code to your email address by clicking <b>Get Code</b></span><br>
                        <span class="caption">2. Enter the 6 digit code on <b>Email Code</b></span><br>
                        <span class="caption">3. Click <b>Submit</b> to verify the code </span><br>
                    </div>
                </v-tooltip>
            </v-btn>
        </div>
        <v-divider/>
        
         <v-alert
            v-if="showError"
            dense
            outlined
            type="error"
            class="caption text-xs ma-5"
            >
            {{error}}
        </v-alert>
        <v-form 
            ref="form" 
            @submit.prevent="update()"  
            method="PUT" 
        > 
            <v-card-text class="login-card__field px-5 py-8">
                <div class="code">
                    <v-text-field
                        type="number"
                        maxlength = "6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        v-model="form.email_key"
                        outlined
                        label="Email Code"
                        class="text-code"
                    >
                    <template class=" ma-0" v-slot:append>
                        
                    </template>
                    </v-text-field>
                    <v-btn
                        :loading="emailLoading"
                        text
                        color="primary"
                        class="code__get-code ma-0"
                        @click="getEmailCode">
                        Get Code
                    </v-btn>
                    <p class="caption ml-2">Enter the 6 digit code received by {{user.email}}.</p>
                </div>
            </v-card-text>
            <v-card-actions class="px-5 py-3">
                <v-btn 
                    text 
                    color="primary"
                    @click="cancel()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                    class="primaryMainText--text"
                    :loading="loading"
                    color="primaryMain"
                    type="submit"
                    >Submit</v-btn>
            </v-card-actions>
        </v-form>
  </modal>
</template>
<script>

export default {
    computed: {
        user() {
            return this.$store.state.user.current_user
        },
    },
    data() {
        return {
            showError: false,
            error: '',
            loading: false,
            emailLoading: false,
            form: {
                email_key: '',
            }
        };
    },
    methods: {
        cancel() {
            this.$modal.hide('email-verify-modal')
        },
        getEmailCode() {
            this.emailLoading = true
            
            this.$http
            .post(`/mfa/send-email-code`)
            .then((data) => {
                this.$successNotif('Successfully sent Email code')
                this.emailLoading = false
            })
            .catch((error) => {
                this.emailLoading = false
            })
        },
        update() {
            this.loading = true
            this.$http
            .put(`/mfa/validate-email-code`, this.form)
            .then((data) => {
                this.$meFetch()
                this.$successNotif('Email has been verified')
                this.$store.commit('user/SET_CURRENT_USER', data.data)
                this.loading = false
                this.$modal.hide('email-verify-modal')
            })
            .catch((error) => {
                this.loading = false
                if (error.response.status == 422){
                    this.showError = true
                    this.error = error.response.data.errors
                } else {
                    this.$errorNotif("Something went wrong")
                }

                this.$refs.form.reset()
                setTimeout(() => {
                    state.showError = false
                }, 10000);
            })
        }
    },
}
</script>
