<template>
  <div>
    <div>
      <invite-users-filter @filter="filter"/>
    </div>
    <div class="content-card__header d-flex justify-space-between">
      <div></div>
      <div>
        <v-btn color="primaryMain" @click="goToCreate()" class="mr-2 primaryMainText--text">
          <v-icon left>
            mdi-plus
          </v-icon>
          Invite
        </v-btn>
      </div>
    </div>
    <v-data-table :headers="headers" :items="items" class="elevation-1" :loading="loading"
      loading-text="Loading... Please wait" :options.sync="options" :server-items-length="pagination.total"
      :items-per-page="pagination.per_page" :footer-props="{
        disablePagination: paginationDisable,
        'items-per-page-options': [5, 10, 20, 50]
      }">

      <template v-slot:item.email="{ item }">
        <span>{{ item.email }}</span>
      </template>

      <template v-slot:item.roles="{ item }">
        <span>{{ item.roles.length > 0 ? item.roles[0].friendly_name : null }}</span><br>
        <v-tooltip bottom v-if="item.roles.length > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="pa-0" v-bind="attrs" v-on="on" text color="primary">
              see more
            </v-btn>
          </template>
          <p class="ma-0" v-for="(role, index) in item.roles" :key="index">
            • {{ role.friendly_name }}
          </p>
        </v-tooltip>
      </template>


      <template v-slot:item.company="{ item }">
        <span>{{ item.company.name }}</span>
      </template>

      <template v-slot:item.specificDealer="{ item }">
        <span>{{ item.specificDealer.name }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <span>{{ item.status }}</span>
      </template>

      <template v-slot:item.invited_by="{ item }">
        <span>{{ item.invited_by.name }}</span>
      </template>

      <template v-slot:item.created_at="{ item }">
        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="d-flex" v-if="item.status == 'Pending'">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="copyUrl(item)" x-small icon class="mx-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Copy Link</span>
          </v-tooltip>

          <div v-if="is_super_admin">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="resync(item)" x-small icon color="purple" class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              <span>Re-Sync</span>
            </v-tooltip>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="resend(item)" x-small icon class="mx-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Re-send Invitation</span>
          </v-tooltip>

          <span>
            <v-tooltip bottom v-if="roleCondition(item)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="goToEdit(item)" x-small icon color="warning" class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="goToView(item.id)" x-small icon color="info" class="mx-1" v-bind="attrs" v-on="on">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>View</span>
            </v-tooltip>
          </span>

          <v-tooltip bottom v-if="roleCondition(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="remove(item)" x-small color="error" icon class="mx-1" v-bind="attrs" v-on="on">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Remove</span>
          </v-tooltip>

        </div>
      </template>
    </v-data-table>

    <restrict-create-modal/>
    <invite-users-modal @save="fetch" />
    <edit-invite-users-modal @save="fetch" :inviteUser="selectedInvite" />
  </div>
</template>
  
<script>
import InviteUsersFilter from '@/components/invite_users/InviteUsersFilter';
import Vue from 'vue';
import InviteUsersModal from '@/components/invite_users/InviteUsersModal';
import EditInviteUsersModal from '@/components/invite_users/EditInviteUsersModal';
import RestrictCreateModal from '@/components/users/RestrictCreateModal';

import { mapState } from "vuex";
import {
  some as _some,
  find as _find,
} from 'lodash';

export default {
  name: 'UsersTable',
  components: {
    InviteUsersModal,
    EditInviteUsersModal,
    InviteUsersFilter,
    RestrictCreateModal
  },
  data() {
    return {
      loading: false,
      items: [],
      filters: null,
      pagination: {
        total: 1,
        per_page: 10,
        current_page: 1,
        search: '',
        sortBy: '',
        sortDesc: false,
      },
      options: {},
      paginationDisable: false,
      timeout: null,
      selectedProducts: [],
      selectedItem: [],
      selectedInvite: {
        email: '',
        role_id: ''
      },
    }
  },
  computed: {
    ...mapState("layout", ["labels"]),
    ...mapState("user", [
      "current_user",
      'is_super_admin',
      'is_account_manager',
      'restrict_create'
    ]),
    showTrashed() {
      return this.filters ? this.filters.trashed : false
    },
    superAdmin() {
      return this.findRole('super-administrator')
    },
    accountManager() {
      return this.findRole('account-manager')
    },
    dealerManager() {
      return this.findRole('specific-dealer-manager')
    },
    roles() {
      return this.$auth.user().roles;
    },
    headers() {
      return [
        { text: 'Email', value: 'email' },
        { text: 'Roles', value: 'role.friendly_name', sortable: false, width: '100' },
        { text: 'Company', value: 'company.name', sortable: false },
        { text: this.dealerTitle, value: 'specific_dealer.name', sortable: false },
        { text: 'Status', value: 'status' },
        { text: 'Invited By', value: 'invited_by.name', sortable: false },
        { text: 'Date Created', value: 'created_at' },
        { text: '', value: 'action', width: '190' },
      ]
    },
    dealerTitle() {
      return this.labels !== undefined && this.labels.dealer !== undefined ? this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1) : '';
    }
  },
  watch: {
    options: {
      handler() {
        this.changeTable();
      },
      deep: true,
    },
  },
  created() {
    localStorage.userTab = 1;
    this.fetch()
  },
  methods: {
    hideDealerCol() {
      let dealerHeader = _find(this.headers, {
        value: "specific_dealer",
      });

      return dealerHeader.align = this.current_user.company.is_automotive ? '' : ' d-none'
    },
    fetch() {
      this.loading = true;
      this.paginationDisable = true;
      this.items = [];
      this.$http
        .get(`/revdojo-api/v1/invite-users`, {
          params: {
            per_page: this.pagination.per_page,
            current_page: this.pagination.current_page,
            sortBy: this.pagination.sortBy,
            sortDesc: this.pagination.sortDesc,
            filter: this.filters,
          },
        })
        .then((data) => {
          this.loading = false
          this.items = data.data.data
          this.pagination.total = data.data.total;
          this.pagination.current_page = data.data.current_page;
          this.pagination.per_page = data.data.per_page
          this.paginationDisable = false;
        })
        .catch((error) => {
          this.loading = false
          this.$errorNotif("Something went wrong")
        })
    },
    changeTable() {
      this.pagination.current_page = this.options.page;
      this.pagination.per_page = this.options.itemsPerPage;

      this.pagination.sortBy =
        this.options.sortBy !== undefined ? this.options.sortBy[0] : '';
      this.pagination.sortDesc =
        this.options.sortDesc !== undefined ? this.options.sortDesc[0] : '';

      this.fetch();
    },
    resend(item) {
      let msg = {
        title: "Resend Invitation?",
        confirmButtonText: "Yes, Resend!",
      }

      this.$questionNotif(msg)
        .then((result) => {
          if (result) {
            this.loading = true
            this.$http
              .put(`/revdojo-api/v1/invite-users/resend/${item.id}`)
              .then((data) => {
                this.loading = false
                this.$successNotif('Successfully sent invitation')
              })
          }
        })
    },
    remove(item) {
      let msg = {
        title: "Remove " + item.email + "?",
        confirmButtonText: "Yes, Remove!",
      }

      this.$questionNotif(msg)
        .then((result) => {
          if (result) {
            this.loading = true
            this.$http
              .delete(`/revdojo-api/v1/invite-users/delete/${item.id}`)
              .then((data) => {
                this.loading = false
                this.$successNotif('Successfully deleted user')
                this.fetch()
              })
              .catch((error) => {
                this.loading = false
                if (error.response.status == 422) {
                  this.$errorNotif(error.response.data.errors)
                }
              })

          }
        })
    },
    goToCreate() {
      if (this.restrict_create) {
        this.$modal.show('restrict-create-modal')
        return
      } 

      this.$modal.show('invite-users-modal')
    },
    goToEdit(invite) {
      this.selectedInvite = invite;
      this.$modal.show('edit-invite-users-modal')
    },
    goToView(id) {
      this.$router.push({ name: this.$route.meta.type + ".view", params: { id: id } });
    },
    roleCondition(item) {
      if (item.deleted_at) {
        return false;
      }

      if (this.is_super_admin) {
        return true
      }

      return !_some(item.roles, { name: 'super-administrator' })
    },
    resync(item) {
      this.loading = true
      this.$http
        .get(`/revdojo-api/v1/invite-users/resync/${item.id}`)
        .then((data) => {
          this.loading = false;
          this.$successNotif("Successfully re-synced")
        })
        .catch((error) => {
          this.loading = false;
          this.$errorNotif("Something went wrong")
        })
    },
    findRole(role) {
      return _find(this.roles, {
        name: role,
      });
    },
    copyUrl(item) {
      navigator.clipboard.writeText(item.invite_link);
      this.$successNotif('Copied URL')
    },
    filter(filters) {
      this.filters = filters
      this.fetch()
      
      const items = _find(this.headers, { value: 'deleted_at' });
      this.showTrashed 
        ? items.align = '' 
        : items.align = ' d-none' 
    },
  }
};
</script>