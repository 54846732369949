<template>
    <modal
      name="user-limit-modal"
      width="500"
      height="auto"
      :clickToClose="false"
      :adaptive="true" >
           <div class="pa-5 align-center">
            <div class="d-flex justify-space-between ">
              <h2>Tiers Details</h2>
              <v-btn
                  @click="cancel()"
                  icon
                  color="black"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </div>
          </div>
          <v-divider/>
          <div class="ma-5">
            <v-layout row>
                <v-flex xs12 lg12 class="px-4 content-image" >
                    <form @submit.prevent="submit()"  method="PUT">
                        <v-container class="my-5 content-container">
                            <tier-prices :error="errors.tiers" :product="product" @tiers="handleTiers"></tier-prices>

                            <v-checkbox
                                v-model="form.active_status"
                                class="mt-0  mb-5"
                                label="Active"
                                color="primary"
                                hide-details
                            ></v-checkbox>
                        </v-container>
                        <v-spacer/>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                            color="primaryMain"
                            dark
                            :loading="loading"
                            class="mt-5 primaryMainText--text"
                            type="submit" > Submit </v-btn>
                        </v-card-actions>
                    </form>
                </v-flex>
            </v-layout>   
          </div>
    </modal>
</template>

<script>
import Vue from 'vue'
import { mapState } from "vuex";
import TierPrices from "@/views/products/TierPrices";

Vue.use(require('vue-moment'));

export default { 
    computed: {
        ...mapState("layout", ["labels"]),
        ...mapState("user", [
          'is_super_admin', 
          'current_user', 
        ]),
    },
    props: [
        'load'
    ],
    data() {
        return {
            errors: [],
            loading:false,
            form: {
                active_status: true
            },
            product: [],
            productUsageListLoading: false,
        };
    },
    watch: {
        load() {
            this.fetchUsage();
        }
    },  
    created() {
        this.fetchUsage();
    },
    methods: {
        handleTiers(tiers) {
            this.form.tiers = tiers
        },
        fetchUsage() {
            this.product = []
            this.productUsageListLoading = true
            this.$http
            .get(`/billing/get-usage-product`)
            .then((result) => {
                this.product = result.data;
                this.form.active_status = this.product ? this.product.active_status : true
            })
            .catch((error) => {
                this.productUsageListLoading = false
                this.$errorNotif("Something went wrong!");
            });
        },

        submit() {
            this.loading = true;
            this.errors = [];

            this.form.name = 'User Licenses'
            this.form.description = 'User Licenses'
            this.form.billing_type = 'RECURRING'
            this.form.type = 'USAGE_BASED'

            let link = '/billing/product/';
            if (this.product) {
                this.form.id = this.product.id
                link = `/billing/product/${this.product.id}`
            }

            let method = this.product ? 'put' : 'post'
            this.$http[method](link, this.form)
            .then((result) => {
                this.loading = false;
                this.$successNotif("Successfully set user limit setting");
                this.$emit("reloadUsageProduct");
            })
            .catch((error) => {
                if (error.response.status == 422) {
                this.errors = error.response.data.errors;
                this.$errorNotif("Invalid inputs, Please check the details");
                if (this.errors.tiers) {
                    setTimeout(() => {
                        this.errors = []
                    }, 5000);
                }
                }
                this.loading = false;
            });
        },
        cancel() {
            this.$modal.hide('user-limit-modal')
        },
    },
    components: {
        TierPrices
    },
}
</script>