<template>
  <modal 
    class="restore-message-modal"
    name="restore-message-modal" 
    width="600"  
    height="auto"  
    :clickToClose="true"
    :adaptive="true" >

    <div class="ma-5 mb-2" >
        <div class="d-flex justify-space-between ">
            <div>
                <h3 class="red--text">Existing Deleted User</h3>
                <p class="subtitle-2">User's email has existing deleted user. Email must not be duplicated</p>
            </div>
            <v-btn
                @click="cancel()"
                icon
                color="black"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
    <v-alert
        dense
        text
        type="warning"
    >
    <span class="subtitle-2">
        <strong> Please Read </strong> the conditions before selecting an option
    </span>
    </v-alert>
    <div class="mx-5 my-8">
         <v-layout row class="mt-1">
            <v-flex xs12 :class="isUserCompanyDeleted ? 'md12 lg12' : 'md6 lg6' " class="px-3">
                <div class="d-flex justify-center">
                    <v-btn
                    outlined
                    class="ma-2"
                    large
                    :loading="loading"
                    @click="createUser()"
                    >
                    Create New User
                    </v-btn>
                </div>
                <ul>
                    <li class="caption">You will create a new user with the existing email and other filled-up fields.</li>
                    <li class="caption">The previously deleted user will be <span class="red--text"> permanently removed </span>, and <span class="red--text">you won't be able to revert it back.</span></li>
                </ul>
            </v-flex>
            <v-divider vertical v-if="!isUserCompanyDeleted"></v-divider>
            <v-flex xs12 :class="isUserCompanyDeleted ? 'md12 lg12' : 'md6 lg6' " class="px-3" v-if="!isUserCompanyDeleted">
                <div class="d-flex justify-center">
                    <v-btn
                    color="primaryMain"
                    dark
                    :loading="loading"
                    large
                    class="ma-2 primaryMainText--text"
                    @click="restore()"
                    >
                        Restore User
                    </v-btn>
                </div>
                 <ul>
                    <li class="caption">The previously deleted user, along with their previous services and data, will be restored. </li>
                    <li class="caption">The new information you filled up in the necessary fields will be disregarded.</li>
                </ul>
            </v-flex>
         </v-layout>

         <div class="my-10">
            <h5>Deleted User's Information</h5>
            
            <div class="mt-5">
                <v-layout row class="mt-1">
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Name"
                            outlined
                            dense
                            disabled
                            v-model="user.name"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Email"
                            outlined
                            dense
                            disabled
                            v-model="user.email"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                 <v-layout row class="mt-1">
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Job Title"
                            outlined
                            dense
                            disabled
                            v-model="user.job_title"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Phone Number"
                            outlined
                            dense
                            disabled
                            v-model="phoneNumber"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                 <v-layout row class="mt-1">
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Status"
                            outlined
                            dense
                            disabled
                            v-model="user.status"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Roles"
                            outlined
                            dense
                            disabled
                            v-model="roles"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row class="mt-1">
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Company"
                            outlined
                            dense
                            disabled
                            v-model="company"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6 lg6 class="px-3">
                        <v-text-field
                            label="Dealer"
                            outlined
                            dense
                            disabled
                            v-model="dealer"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </div>

            
         </div>
    </div>
  </modal>
</template>
<script>
import {
    map as _map,
} from 'lodash';
import Vue from 'vue';

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: [
        'user',
    ],
    computed: {
        phoneNumber() {
            return this.user.phone_code + this.user.phone_number
        },
        roles() {
            let roles = _map(this.user.roles, 'friendly_name')
            return roles.toString()
        },
        company(){
            return this.user.company ? this.user.company.name : '' 
        },
        dealer(){
            return this.user.specific_dealer ? this.user.specific_dealer.name : '' 
        },
        isUserCompanyDeleted(){
            return this.user.company && this.user.company.deleted_at !== null
        }
    },
    created(){
    },
    methods: {
        cancel() {
            this.$modal.hide('restore-message-modal')
        },
        restore(){
            this.loading = true
            this.$http
            .put(`/user/restore/${this.user.id}`)
            .then((data) => {
                this.loading = false
                this.$successNotif('Successfully restored user')
                this.$router.push({ name: "users.edit", params:{id: this.user.id } });
            })
        },
        createUser(){
            this.loading = true
            this.$http
            .put(`/user/permanent-remove/${this.user.id}`)
            .then((data) => {
                Vue.prototype.$echo
                .channel(`user-permanent-deleted-${data.data}`)
                .listen('UserPermanentDeleted', (e) => {
                    this.$emit('createUser', this.user)
                    this.loading = false
                    this.$modal.hide('restore-message-modal')
                });
            })
        }
    },
    components: {
    }
}
</script>