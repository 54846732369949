<template>
<div>
  <v-layout class="d-flex justify-space-between mb-3">
    <v-switch
      v-model="filters.trashed"
      class="ma-0"
      hide-details
      v-if="is_super_admin"
      label="show removed companies"
    ></v-switch>  
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" @click="resetFilter()"
          v-bind="attrs" fab dark x-small>
          <v-icon>mdi-filter-remove</v-icon>
        </v-btn>
      </template>
      <span>Reset Filter</span>
    </v-tooltip>
  </v-layout>
  <v-text-field
    v-model="search"
    outlined
    dense
    clearable
    label="Search"
  ></v-text-field>
  <v-layout class="mt-1" v-show="is_super_admin">
    <v-flex xs12 md6 lg4>
      <v-select
          v-model="filters.status"
          :items="status"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          dense
          item-value="value"
          item-text="name"
          label="Status"
          clearable
      ></v-select>
    </v-flex>
    <v-flex xs12 md6 lg4 class="pl-3" >
      <v-select
          v-model="filters.automotive"
          :items="automotive"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          dense
          item-value="value"
          item-text="name"
          label="Automotive"
          clearable
      ></v-select>
    </v-flex>
    <v-flex xs12 md6 lg4 class="pl-3" >
      <v-select
          v-model="filters.singleUser"
          :items="singleUser"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          dense
          item-value="value"
          item-text="name"
          label="Single User"
          clearable
      ></v-select>
    </v-flex>
  </v-layout>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'UsersFilter',
  components: {
  },
  data () {
    return {
        timeout: null,
        loading: false,
        status: [
            {
                value: 'false',
                name: 'Inactive',
            },
            {
                value: 'true',
                name: 'Active',
            },
        ],
        automotive: [
            {
                value: 'true',
                name: 'Automotive',
            },
            {
                value: 'false',
                name: 'Non Automotive',
            },
        ],
        singleUser: [
            {
                value: 'true',
                name: 'Single User',
            },
            {
                value: 'false',
                name: 'Company / Non Single User',
            },
        ],
        service: [
            {
                value: 'all',
                name: 'All Services',
            },
            {
                value: 'lms_service',
                name: 'LMS Service',
            },
            {
                value: 'secretshop_service',
                name: 'Secret Shop Service',
            },
            {
                value: 'none',
                name: 'No Service',
            },
        ],
        search: '',
        filters: {
          status: null,
          service: null,
          search: null,
          automotive: null,
          singleUser: null,
          trashed: false,
        },
    }
  },
  computed: {
     ...mapState("user", [
          'is_super_admin', 
      ]),
  },
  watch: {
    filters: {
      handler: function (after, before) {
        this.$emit('filter', this.filters)
      },
    deep: true,
    },
    search() {
      this.renderSearch()
    }
  },
  created() {
  },
  methods: {
    renderSearch() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.filters.search = this.search
      }, 1000);
    },
    resetFilter() {
      this.filters.status = null
      this.filters.service = null
      this.filters.search = null
      this.filters.automotive = null
      this.filters.singleUser = null
      this.filters.trashed = false
      this.search = null
    }
  }
};
</script>
