<template>
    <div class="content">
        <breadcrumbs :links="breadcrumbs"/>

        <div class="content-wrapper">
            <h1 class="font-weight-medium">{{ $route.meta.title }}
                <span v-if="isView" class="title font-weight-thin">View</span>
                <span v-if="!isProfile && !isView" class="title font-weight-thin">Edit</span>
            </h1>

            <v-layout row justify-center class="mt-5">
                <v-flex xs12 md5 lg4 class="px-4 content-image-section">
                    <v-card class="mb-5 pa-5 content-image-section__card">
                        <v-alert
                            dense
                            class="caption text-left"
                            outlined
                            v-if="showError"
                            type="error"
                        >
                        {{ errorMsg }}
                        </v-alert>
                       <v-img
                        contain
                        class="content-image"
                        :src="image"
                        :lazy-src="defaultImage"
                        max-width="500"
                        max-height="300"
                        >
                        <template v-slot:placeholder>
                            <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                            >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                            </v-row>
                        </template>
                        </v-img>
                        <div class="img-option plain-btn-group"  v-if="!isView">
                            <v-btn
                                block
                                elevation="2"
                                depressed
                                color="primaryMain"
                                dark
                                class="mt-5 primaryMainText--text"
                                @click="openFile()" > Select Avatar </v-btn>
                            <input class="file-type" ref="filetype" type="file" @change="previewImage">
                            <v-btn
                                v-if="isUpload"
                                block
                                elevation="2"
                                depressed
                                dark
                                :loading="loading"
                                @click="submit()"
                                class="mt-5">
                                Upload Avatar
                            </v-btn>
                        </div>
                    </v-card>
                    <v-card class="my-5 text-left"  v-if="!isView">
                        <v-list>
                            <v-list-item-group color="primary">
                                <v-list-item @click="showChangePassword()">
                                    <v-list-item-icon>
                                        <v-icon>mdi-key</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Change Password</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <change-password-modal :user_id="user.id"/>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                    <v-card class="my-5 text-left">
                        <v-list>
                            <v-list-item-group color="primary">
                                <v-list-item @click="showActivityLogs()">
                                    <v-list-item-icon>
                                        <v-icon>mdi-clipboard-text</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Activity Logs</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <activity-logs-modal :user="user"/>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                    <v-card class="my-5 text-left" v-if="is_super_admin">
                        <v-list>
                            <v-list-item-group color="primary">
                                <v-list-item @click="showSyncLogs()">
                                    <v-list-item-icon>
                                        <v-icon>mdi-clipboard-flow</v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title>Sync Logs</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <sync-logs-modal :data="user" :model="'App\\Models\\User'"/>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-flex>
                <v-flex xs12 md7 lg8 class="px-4" >
                    <v-form method="POST" :readonly="isView">
                        <v-card class="pa-4 d-flex flex-column" min-height="800">
                            <h2 class="title">Information</h2>
                            <v-divider/>
                            <v-container class="my-5">
                                <v-text-field
                                    class="field-hide"
                                    label="Id"
                                    placeholder="ID"
                                    outlined
                                    required
                                    readonly
                                    dense
                                    v-if="is_super_admin"
                                    v-model="user.id"
                                />
                                <v-text-field
                                    class="field-hide"
                                    label="Central Id"
                                    placeholder="ID"
                                    outlined
                                    required
                                    readonly
                                    v-if="is_super_admin"
                                    dense
                                    v-model="user.central_id"
                                />
                                <v-text-field
                                    class="field-hide"
                                    label="Okta Id"
                                    placeholder="ID"
                                    outlined
                                    required
                                    readonly
                                    v-if="is_super_admin"
                                    dense
                                    v-model="user.okta_id"
                                />
                                <v-text-field
                                    class="field-hide"
                                    label="Stripe id"
                                    placeholder="ID"
                                    outlined
                                    required
                                    readonly
                                    v-if="is_super_admin && (user.stripe_id && user.billing_default)"
                                    dense
                                    v-model="user.stripe_id"
                                />
                                <v-text-field
                                    label="Name"
                                    placeholder="Name"
                                    outlined
                                    required
                                    dense
                                    v-model="user.name"
                                    :error-messages="errors.name"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Name
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    label="Email"
                                    placeholder="Email"
                                    outlined
                                    required
                                    dense
                                    v-model="user.email"
                                    :error-messages="errors.email"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Email
                                    </template>
                                </v-text-field>
                                <vue-phone-number-input
                                    :disabled="isView"
                                    v-model="user.phone_number"
                                    class="mb-3"
                                    no-example
                                    :only-countries="['US', 'PH']"
                                    :color="$vuetify.theme.themes.light.primary"
                                    valid-color="#004df9"
                                    :default-country-code="user.phone_country"
                                    @update="formatPhoneNo($event)"
                                />
                                 <p class="phone-number-error caption red--text ml-4 mb-2">
                                    <span v-if="isPhoneNoError || errors.phone_number">Phone number is invalid, please input correct phone number</span>
                                    <span v-else class="white--text">Phone Number</span>
                                </p>
                                <v-select
                                    :disabled="disableNonAccess"
                                    v-model="user.status"
                                    :items="statusItems"
                                    label="Status"
                                    item-text="name"
                                    item-value="value"
                                    outlined
                                    dense
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :rules="errors.status"
                                ></v-select>
                                <v-autocomplete
                                    :disabled="disableCompanyField"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    label="Company"
                                    required
                                    persistent-hint
                                    :hint="user.stripe_id && user.billing_default ? 'this user is the default admin of this company'  : ''"
                                    dense
                                    v-model="user.company_id"
                                    :items="company_list"
                                    autocomplete="off"
                                    :rules="errors.company_id"
                                    @change="changeCompanyField()"
                                    @blur="outFocusCompanyField()"
                                >
                                    <template #label>
                                        <span class="red--text"><strong>* </strong></span>Company
                                    </template>
                                    <template v-slot:item="data">
                                        <div class="dropdown-subvalue">
                                            <span>
                                            {{ data.item.name }}
                                            </span>
                                            <span v-if="data.item.is_automotive">
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="subvalue" v-bind="attrs"
                                                        v-on="on">mdi-car-convertible</v-icon>

                                                    </template>
                                                    <span>Automotive Company</span>
                                                </v-tooltip>
                                            </span>
                                        </div>
                                    </template>
                                </v-autocomplete>

                                <span class="d-flex" v-if="hideDealerField">
                                    <v-autocomplete
                                        :disabled="disableDealerField"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        :label="dealerTitle"
                                        dense
                                        v-model="user.specific_dealer_id"
                                        :items="specific_dealers"
                                        autocomplete="off"
                                        clearable
                                        :loading="loadDealer"
                                        :rules="errors.specific_dealer_id"
                                        @change="changeDealerField()"
                                        :no-data-text="loadDealer ? 'Loading' : 'No data available'"
                                    >
                                        <template v-slot:append-item>
                                            <div v-if="!loadedAllSpecificDealer" v-intersect="onIntersectSpecificDealer" class="pa-4 primary--text">
                                                Loading more items ...
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom v-if="!disableDealerField">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="showCreateDealerModal()"
                                                color='primary'
                                                icon
                                                v-bind="attrs"
                                                v-on="on">
                                                <v-icon>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add {{ dealerTitle }}</span>
                                    </v-tooltip>
                                </span>

                                <span class="d-flex">
                                    <v-autocomplete
                                        :disabled="disablePositionField"
                                        item-text="friendly_name"
                                        item-value="id"
                                        outlined
                                        dense
                                        label="Position"
                                        v-model="user.position_id"
                                        :items="positions"
                                        autocomplete="off"
                                        clearable
                                        :loading="loadPosition"
                                        :rules="errors.position_id"
                                        :search-input.sync="positionSearch"
                                        :required="is_position_manager && only_position_manager"
                                        :no-data-text="loadPosition ? 'Loading' : 'No data available'"
                                    >
                                        <template v-slot:append-item>
                                            <div v-if="!loadedAllPosition" v-intersect="onIntersectPosition" class="pa-4 primary--text">
                                                Loading more items ...
                                            </div>
                                        </template>
                                        <template v-slot:item="data">
                                        <div class="dropdown-subvalue">
                                            <span>
                                            {{ data.item.friendly_name }}
                                            </span>
                                            <span v-if="data.item.specific_dealer">
                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon class="subvalue" v-bind="attrs"
                                                        v-on="on">mdi-handshake</v-icon>

                                                    </template>
                                                    <span>{{ data.item.specific_dealer.name }}</span>
                                                </v-tooltip>
                                            </span>
                                        </div>
                                    </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom v-if="!disablePositionField" >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="showCreatePositionModal()"
                                                color='primary'
                                                icon
                                                v-bind="attrs"
                                                v-on="on">
                                                <v-icon>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add Position</span>
                                    </v-tooltip>
                                </span>
                                <span v-if="isOnlyLearner" class="d-flex">
                                  <v-autocomplete
                                      :disabled="disableNonAccess"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      label="Team Leader"
                                      v-model="user.team_leader_id"
                                      :items="managers"
                                      autocomplete="off"
                                      clearable
                                      :loading="loadManager"
                                      :no-data-text="loadManager ? 'Loading' : 'No data available'"
                                  >
                                  </v-autocomplete>
                                </span>
                                <span class="d-flex">
                                    <v-autocomplete
                                        item-text="name"
                                        item-value="id"
                                        multiple
                                        outlined
                                        dense
                                        label="Assign to Group"
                                        v-model="user.groups"
                                        :items="groups"
                                        autocomplete="off"
                                        clearable
                                        :loading="loadGroup"
                                        :rules="errors.groups"
                                        :search-input.sync="groupSearch"
                                        :no-data-text="loadGroup ? 'Loading' : 'No data available'"
                                    >
                                        <template v-slot:append-item>
                                            <div v-if="!loadedAllGroup" v-intersect="onIntersectGroup" class="pa-4 primary--text">
                                                Loading more items ...
                                            </div>
                                        </template>
                                    </v-autocomplete>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                @click="showCreateGroupModal()"
                                                color='primary'
                                                icon
                                                v-bind="attrs"
                                                v-on="on">
                                                <v-icon>
                                                    mdi-plus-circle-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add Group</span>
                                    </v-tooltip>
                                </span>
                                <h4 class="subtitle-2">Roles</h4>
                                <div class="role-loader" v-show="loadRole">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary"
                                    ></v-progress-circular>
                                </div>
                                <div v-for="(role, index) in roles" :key="index" v-show="!loadRole">
                                    <v-checkbox
                                        :label="role.friendly_name"
                                        v-model="userRoles"
                                        :value="role.id"
                                        color="primary"
                                        hide-details
                                        v-show="role.value"
                                        :disabled="disableNonAccess"
                                    >
                                        <template #label>
                                             <v-tooltip bottom :disabled="!role.description">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-on="on" v-bind="attrs">{{ role.friendly_name }}</span>
                                                </template>
                                                <span>{{ role.description }}</span>
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-container>
                            <v-spacer/>
                            <v-container>
                              <h4 class="subtitle-2">Notification Settings</h4>
                              <v-checkbox
                                  :label="emailOptedOut.label"
                                  v-model="user.email_opted_out"
                                  :value="emailOptedOut.value"
                                  color="primary"
                                  hide-details
                              >
                                  <template #label>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on, attrs }">
                                              <span v-on="on" v-bind="attrs">{{ emailOptedOut.label }}</span>
                                          </template>
                                          <span>{{ emailOptedOut.label }}</span>
                                      </v-tooltip>
                                  </template>
                              </v-checkbox>
                            </v-container>
                            <v-card-actions v-if="!isView">
                                <v-btn
                                color="error"
                                dark
                                v-if="!isProfile"
                                @click="remove()"
                                class="mt-5"
                                :loading="loading"
                                text
                                >
                                Remove
                                </v-btn>
                                <v-spacer/>
                                <v-btn
                                color="primaryMain"
                                dark
                                @click="submit()"
                                class="mt-5 primaryMainText--text"
                                :loading="loading"
                                > Submit </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-flex>
            </v-layout>
        </div>
        <create-dealer-modal :companyId="user.company_id" @refreshDealer="dealerFieldRefresh()"></create-dealer-modal>
        <create-position-modal :companyId="user.company_id" @refreshPosition="positionFieldRefresh()"></create-position-modal>
        <create-group-modal :companyId="user.company_id" :dealerId="user.specific_dealer_id" @refreshGroup="groupFieldRefresh()"></create-group-modal>
        <restore-message-modal :user="deletedUser" @createUser="submit()"/>
    </div>
</template>

<script>
import Vue from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs';
import ChangePasswordModal from '@/components/users/ChangePasswordModal';
import ActivityLogsModal from '@/components/users/ActivityLogsModal';
import SyncLogsModal from '@/components/SyncLogsModal';
import imageImport from '@/assets/images/empty-img.png'
import RestoreMessageModal from '@/components/users/RestoreMessageModal';
import CreateDealerModal from '@/components/users/CreateDealerModal';
import CreatePositionModal from '@/components/users/CreatePositionModal';
import CreateGroupModal from '@/components/users/CreateGroupModal';

import {
    find as _find,
    some as _some,
    includes as _includes,
    filter as _filter,
} from 'lodash';
import { mapState } from "vuex";

Vue.use(require('vue-moment'));

export default {
    components: {
        Breadcrumbs,
        ChangePasswordModal,
        ActivityLogsModal,
        RestoreMessageModal,
        SyncLogsModal,
        CreateDealerModal,
        CreatePositionModal,
        CreateGroupModal
    },
    computed: {
        ...mapState("layout", ["labels"]),
        ...mapState("user", [
            "current_user",
            'is_super_admin',
            'is_account_manager',
            'is_dealer_manager',
            'only_position_manager',
            'managing_positions',
            'is_position_manager'
        ]),
        company_list() {
            return this.$store.state.company.company_list;
        },

        loadedAllSpecificDealer() {
            return this.specific_dealers.length >= this.specificDealerSetting.total
        },
        loadedAllPosition() {
            return this.positions.length >= this.positionSetting.total
        },
        loadedAllGroup() {
            return this.groups.length >= this.groupsSettings.total
        },
        selectedCompany() {
            return this.user.company_id;
        },
        selectedDealer() {
            return this.user.specific_dealer_id;
        },
        selectedCompanyDetails() {
            if (!this.selectedCompany) {
                return false
            }
            return _find(this.company_list, {
                id: this.selectedCompany,
            });
        },

        isProfile() {
            return this.$route.name == 'profile'
        },

        isView() {
            return this.$route.name == this.$route.meta.type +'.view'
        },
        isSuperAdminSelected() {
            if (this.superAdmin == undefined) {
                return false
            }
            return _includes(this.userRoles, this.superAdmin.id);
        },
        disableCompanyField() {
            let stripeId = Boolean(this.user.stripe_id)
            let isSuperAdmin = Boolean(this.is_super_admin)
            let billingDefault = Boolean(this.user.billing_default)
            return !isSuperAdmin || (billingDefault && stripeId)
        },
        hideDealerField() {
            if (this.isSuperAdminSelected) {
                return false
            }
            return !this.hideSpecificDealer && (this.user.specific_dealer_id || !this.disableNonAccess)
        },
        disableDealerField() {
            return !this.is_super_admin && !this.is_account_manager
        },
        disableNonAccess() {
            return !this.is_super_admin && !this.is_account_manager && !this.is_dealer_manager
        },
        disablePositionField() {
            return !(this.is_super_admin || this.is_account_manager || this.is_dealer_manager) || (this.is_position_manager && this.only_position_manager);
        },
        renderRoleCompany() {
            return [this.roles, this.selectedCompanyDetails]
        },

        //Roles
        superAdmin() {
            return this.findRole('super-administrator', this.roles)
        },
        accountManager() {
            return this.findRole('account-manager',this.roles)
        },
        secretShopper() {
            return this.findRole('secret-shopper',this.roles)
        },
        dealerManager() {
            return this.findRole('specific-dealer-manager',this.roles)
        },
        salesperson() {
            return this.findRole('salesperson',this.roles)
        },
        csm() {
            return this.findRole('csm',this.roles)
        },
        dealerTitle() {
          return this.labels !== undefined && this.labels.dealer !== undefined ? this.labels.dealer.charAt(0).toUpperCase() + this.labels.dealer.slice(1) : '';
        },
        isOnlyLearner() {
            if(!this.userRoles.length || this.userRoles.length > 1){
              return false
            }

            const learnerRole = this.roles.find((role) => role.name === "salesperson")

            return this.userRoles[0] === learnerRole.id
        },
    },
    data() {
        return {
            trueCarCentralId: '16deade7-d7d9-49bb-a3d1-ff3eba8cc153',
            password_show: false,
            loading: false,
            loadRole: false,
            loadDealer: false,
            loadPosition: false,
            loadGroup: false,
            groupSearch: '',
            breadcrumbs: {
                '0': {
                    title: 'Home',
                    link: 'home',
                },
                '1': {
                    title: this.$route.meta.title,
                    link: this.$route.meta.type,
                },
                '2': {
                    title: 'Edit',
                    link: this.$route.meta.type+'.edit',
                }
            },
            defaultImage: imageImport,
            image: '',
            groups: [],
            groupsSettings: {
                page: 1,
                per_page: 20,
                total: ''
            },
            user: {},
            errors: [],
            statusItems: [
                {
                    name: 'Active',
                    value: 'ACTIVE',
                },
                {
                    name: 'Under Review',
                    value: 'UNDER_REVIEW',
                },
                {
                    name: 'Inactive',
                    value: 'INACTIVE',
                },
                {
                    name: 'Rejected',
                    value: 'REJECTED',
                }
            ],
            companies: [],
            companySetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            specific_dealers: [],
            specificDealerSetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            positionSearch: '',
            renderPosition: false,
            positionSetting: {
                page: 1,
                per_page: 20,
                total: ''
            },
            hideSpecificDealer: false,
            hidePosition: false,
            isPhoneNoError: false,
            nationalNumber: '',
            roles: [],
            positions: [],
            userRoles: [],
            isUpload: false,
            deletedUser: [],
            currentCompanyId: '',
            showError: false,
            emailOptedOut: {
              label: 'Opt-out from email notification',
              value: 1
            },
            managers: [],
            loadManager: false
        };
    },
    watch: {
        groupSearch() {
            if (this.groupSearch){
                this.groupFieldRefresh();
            }
        },
        positionSearch() {
            this.positionFieldRefresh();
        },
        renderRoleCompany() {
            if (this.roles.length > 0 && this.selectedCompanyDetails) {
                this.rolesCondition()
            }
        },
        selectedCompany() {
            this.specificDealerSetting.page = 1;
            this.specific_dealers = []
            this.rolesCondition()
            this.checkAutomotiveCompany()

            this.fetchSpecificDealer()
            this.positions = [];
        },
        user(){
            if (!this.user.id) {
                return false
            }
            this.renderInitialData()
        },
        userRoles() {
            if (this.userRoles == 0) {
                this.userRoles.push(this.salesperson.id)
            }
        },
        managing_positions(){
            this.renderInitialPosition()
        },
        selectedDealer() {
            this.fetchManagers()
        },
    },
    created() {
        this.$store.dispatch('company/getList')
        this.fetchRoles()
        this.fetchManagers()
        this.fetchUser()
        this.renderPage()
        this.isUpload = false
        this.loadPosition = true;
        this.loadDealer = true;
        this.loadGroup = true;
    },
    mounted() {
        this.isChangePasswordShow()
    },
    methods: {
        checkPositionManager() {
            this.$http
            .get('user/check-position-manager', {
                params: {
                    user_id: this.current_user.id,
                },
            })
            .then(({data}) => {
                this.$store.commit('user/SET_CURRENT_USER_POSITION_MANAGER', data)
                this.renderPosition = true
                this.fetchPosition()
                this.renderInitialPosition()
            })
            .catch((error) => {
            });
        },

        renderInitialPosition() {
            if (this.managing_positions.length == 1 && this.form) {
                this.form.position_id = this.managing_positions[0].id
            }
        },
        isChangePasswordShow() {
            if (this.$route.query.change_password) {
                this.$modal.show('change-password-modal')
            }
        },
        checkAutomotiveCompany() {
            if (!this.selectedCompanyDetails) {
                return false
            }

            if (!this.selectedCompanyDetails.is_automotive) {
                this.hideSpecificDealer = true
                this.user.specific_dealer_id = null
            } else {
                this.hideSpecificDealer = false
            }
        },
        renderPage() {
            if (this.isProfile) {
                delete this.breadcrumbs[2]
            }

            if (this.isView) {
                this.breadcrumbs[2].title = 'View'
                this.breadcrumbs[2].link = this.$route.meta.type+'.view'
            }
        },
        pageValidation() {

            let isItemSuperAdmin = typeof this.findRole('super-administrator',this.user.roles) !== "undefined";
            if (isItemSuperAdmin && !this.is_super_admin && !this.isView) {
                this.$router.push({ name: this.$route.meta.type+".view", params:{id: this.user.id } });
            }

            if (this.user.deleted_at && !this.isView){
                this.$router.push({ name: this.$route.meta.type+".view", params:{id: this.user.id } });
            }

            if (this.is_super_admin) {
                return true
            }
        },
        renderInitialData() {
            if (this.user.roles !== undefined) {
                this.image = this.user.cover_photo
                this.user.roles.map(role => {
                    this.userRoles.push(role.id)
                })
            }
        },
        rolesCondition() {

            if (!this.selectedCompanyDetails || this.roles.length < 1) {
                return false
            }

            if (this.is_super_admin) {
                this.superAdmin.value = true
                this.secretShopper.value = true
            } else {
                this.superAdmin.value = false
                this.secretShopper.value = false
            }

            if (this.secretShopper !== undefined) {
                this.secretShopper.value = this.is_super_admin ? true : false
            }

            this.accountManager.value = this.is_account_manager || this.is_super_admin  ? true : false

            //only show csm if truecar
            if (this.csm !== undefined) {
                this.csm.value = this.selectedCompanyDetails.central_id != this.trueCarCentralId ? false : true
            }

            //hide dealer manager for non automotive
            this.dealerManager.value = Boolean(this.selectedCompanyDetails.is_automotive) ? true : false

            //hide dealer manager if dealer field is empty
            if (this.user.specific_dealer_id == null || this.user.specific_dealer_id == '') {
                this.dealerManager.value = false
                this.removeManagerOnRole()
            } else {
                this.dealerManager.value = true
            }
            this.loadRole = false
        },
        findRole(role,roles){
            return _find(roles, {
                name: role,
            });
        },
        fetchRoles() {
            this.loadRole = true
            this.$http
            .get('roles', {
                params: {
                    role_exclude: true,
                },
            })
            .then((result) => {
                this.roles = result.data
                this.rolesCondition()
            })
        },

        fetchUser() {
            this.$store.commit('global/SET_BREADCRUMBS_LOADER', true)
            let user_id = this.$route.params.id
            if (this.isProfile) {
                user_id = this.current_user.id
            }

            this.$http
                .get('user/'+ user_id)
                .then((result) => {
                    this.user = result.data
                    this.currentCompanyId = this.user.company_id
                    this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)
                    this.pageValidation()
                    this.checkPositionManager()
                    this.fetchGroups()
                })
                 .catch((error) => {
                     if (error.response.status == 403){
                        this.$errorNotif(error.response.data.msg)
                        this.$router.push({ name: this.$route.meta.type });
                    }
                    this.$store.commit('global/SET_BREADCRUMBS_LOADER', false)
                    this.loading = false
                })
        },

        // Specific Dealer Setup
        fetchSpecificDealer() {
            this.loadDealer = true
            this.$http
            .get('specific-dealer', {
                params: {
                    per_page: this.specificDealerSetting.per_page,
                    current_page: this.specificDealerSetting.page,
                    company_id: this.selectedCompany,
                    initial: this.user.specific_dealer_id,
                },
            })
            .then((result) => {
                this.specific_dealers = [
                    ...this.specific_dealers,
                    ...result.data.data
                ]
                this.specificDealerSetting.total = result.data.total
                this.loadDealer = false
            })
        },

        onIntersectSpecificDealer() {
            this.specificDealerSetting.page += 1
            this.fetchSpecificDealer()
        },

        dealerFieldRefresh() {
            this.specificDealerSetting.page = 1
            this.specificDealerSetting.total = ''
            this.fetchSpecificDealer()
        },


        //fetch position
        fetchPosition() {
            let filter = {
                search: this.positionSearch
            }
            this.loadPosition = true
            this.$http
            .get('positions', {
                params: {
                    per_page: this.positionSetting.per_page,
                    current_page: this.positionSetting.page,
                    company_id: this.selectedCompany,
                    specific_dealer_id: this.selectedDealer,
                    company_only: false,
                    initial: this.user.position_id,
                    filter: filter,
                    only_position_manager: this.only_position_manager,
                    managing_positions: this.only_position_manager ? this.managing_positions : [],
                },
            })
            .then((result) => {
                this.positions = [
                    ...this.positions,
                    ...result.data.data
                ]
                this.loadPosition = false
                this.positionSetting.total = result.data.total
            })
        },
        fetchGroups() {
            let filter = {
                search: this.groupSearch
            }
            this.loadGroup = true
            this.$http
            .get('groups', {
                params: {
                    per_page: this.groupsSettings.per_page,
                    current_page: this.groupsSettings.page,
                    company_id: this.selectedCompany,
                    specific_dealer_id: this.selectedDealer,
                    filter: filter,
                    userGroups: this.user.groups
                },
            })
            .then((result) => {
                this.groups = [
                    ...this.groups,
                    ...result.data.data
                ]
                this.loadGroup = false
                this.groupsSettings.total = result.data.total
            })
        },
        onIntersectGroup() {
            this.groupsSettings.page += 1
            this.fetchGroups()
        },
        groupFieldRefresh() {
            this.groupsSettings.page = 1
            this.groupsSettings.total = ''
            this.fetchGroups()
        },
        showCreateGroupModal() {
            this.$modal.show('create-group-modal')
        },
        fetchManagers() {
            this.loadManager = true
            this.$http
            .get('user/get-admins', {
              params: {
                  company_id: this.selectedCompany,
                  specific_dealer_id: this.selectedDealer,
                  role: this.selectedDealer ? 'specific-dealer-manager' : 'account-manager',
              },
            })
            .then((result) => {
                this.managers = result.data
                this.loadManager = false
            })
        },

        onIntersectPosition() {
            this.positionSetting.page += 1
            this.fetchPosition()
        },

        positionFieldRefresh() {
            this.positionSetting.page = 1
            this.positionSetting.total = ''
            this.positionSearch = ''

            if (this.renderPosition) {
                this.fetchPosition()
            }
        },

        formatPhoneNo(event) {
            this.isPhoneNoError = false

            if (event.phoneNumber == undefined) {
                return this.isPhoneNoError;
            }

            if (event.phoneNumber && !event.isValid) {
                return this.isPhoneNoError = true
            }

            this.user.phone_country = event.countryCode
            this.user.phone_code = '+' + event.countryCallingCode
            this.nationalNumber = event.nationalNumber
            this.user.phone_number = this.nationalNumber
        },

        previewImage(e) {
            const file = e.target.files[0];
            this.user.images = e.target.files[0]
            this.image = URL.createObjectURL(file);
            this.isUpload = true
        },
        openFile() {
           $('.file-type').trigger('click');
        },
        submit() {
            if (this.is_position_manager && this.only_position_manager && this.form && !this.form.position_id) {
                this.$errorNotif('Position field is required')
                return false
            }

            if (this.isPhoneNoError) {
                this.$errorNotif('Invalid inputs, Please check the details')
                return false
            }
            this.loading = true

            if (!this.hideDealerField) {
                this.removeManagerOnRole()
                this.user.specific_dealer_id = null
            }

            this.errors = []
            let data = new FormData();





            data.append('_method', 'PUT');
            data.append('name', this.user.name);
            data.append('email', this.user.email);
            data.append('position_id', this.user.position_id ? this.user.position_id : '');
            data.append('company_id', this.user.company_id);
            data.append('specific_dealer_id', this.user.specific_dealer_id ? this.user.specific_dealer_id : '')
            data.append('status', this.user.status);
            data.append('roles', this.userRoles);
            data.append('id', this.user.id);
            data.append('email_opted_out',this.user.email_opted_out ? this.user.email_opted_out : 0)
            data.append('team_leader_id',this.user.team_leader_id && this.isOnlyLearner ? this.user.team_leader_id : '')
            data.append('groups',JSON.stringify(this.user.groups))

            if (this.user.phone_number) {
                data.append('phone_number', this.user.phone_number);
                data.append('phone_code', this.user.phone_code);
                data.append('phone_country', this.user.phone_country);
            }

            if (this.user.images instanceof File) {
                data.append('image', this.user.images);
            }


            this.$http
            .post(`/user/${this.user.id}`, data)
            .then((result) => {
                this.loading = false
                this.isUpload = false

                if (this.isProfile || result.data.id == this.current_user.id) {
                    this.$meFetch()
                    this.$successNotif('User information has been saved')
                    this.$store.commit('user/SET_CURRENT_USER', result.data)
                    window.location.reload()
                } else {
                    this.$successNotif('Successfully updated the user')
                }
            })
            .catch((error) => {
                if (error.response.status == 422){
                    this.errors = error.response.data.errors
                    this.$errorNotif('Invalid inputs, Please check the details')
                }

                if ((error.response.status == 422  && error.response.data.errors.image) || error.response.status == 413){
                    this.errorMsg = "Only jpeg,png,gif images are allowed. Maximum allowed size is 2MB"
                    this.showError = true
                    setTimeout(() => {
                        this.showError = false
                    }, 5000);
                }

                if (error.response.status == 403 && error.response.data.existing_deleted_user){
                    this.deletedUser = error.response.data.deleted_user
                    this.$modal.show('restore-message-modal')
                }

                this.loading = false
            })
        },
        remove() {
            let msg = {
                title: "Remove "+this.user.name+"?",
                confirmButtonText: "Yes, Remove!",
            }

            this.$questionNotif(msg)
            .then((result) => {
                if (result) {
                    this.loading = true
                    this.$http
                    .delete(`/user/${this.user.id}`)
                    .then((data) => {
                        this.loading = false
                        this.$successNotif('Successfully deleted user')
                        this.$router.push({ name: this.$route.meta.type });
                    })
                    .catch((error) => {
                        this.loading = false
                        if (error.response.status == 422){
                            this.$errorNotif(error.response.data.errors)
                        }
                    })
                }
            })
        },
        showChangePassword() {
            this.$modal.show('change-password-modal')
        },
        showActivityLogs() {
            if (this.user) {
                this.$modal.show('activity-logs-modal')
            }
        },
        showSyncLogs() {
            if (this.user) {
                this.$modal.show('sync-logs-modal')
            }
        },
        gotoSecurity() {
            this.$router.push({ name: "security" });
        },
        changeCompanyField() {
            this.user.specific_dealer_id = ''
            this.user.position_id = ''
            this.specific_dealers = []
            this.position = []
            this.specificDealerSetting.page = 1
            this.specificDealerSetting.total = ''
            this.positionSetting.page = 1
            this.positionSetting.total = ''
            this.fetchSpecificDealer()

            if (!this.selectedCompanyDetails.is_automotive) {
                this.removeManagerOnRole()
            }
        },
        changeDealerField() {
            this.rolesCondition()
            this.removeManagerOnRole()
            this.positions = []
            this.user.position_id = ''
            this.positionFieldRefresh()
        },
        removeManagerOnRole() {
            if (_includes(this.userRoles, this.dealerManager.id)) {
                this.userRoles = this.userRoles.filter(role => {
                    return role != this.dealerManager.id;
                })
            }
        },
        showCreateDealerModal() {
            this.$modal.show('create-dealer-modal')
        },
        showCreatePositionModal() {
            this.$modal.show('create-position-modal')
        },
        outFocusCompanyField() {
           if (this.user.company_id == null) {
                this.user.company_id = this.currentCompanyId
            }
        }
    },

}
</script>
