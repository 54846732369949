<template>
  <div>
    <edit-users/>
  </div>
  </template>
  
  <script>
  import EditUsers from '@/views/users/EditUsers';
  
  export default {
    name: 'Profile',
    components: {
      EditUsers
    },
    data () {
     return {
  
     }
    },
    computed: {
      user() {
         return this.$store.state.user.current_user
      },
    },
    created() {
    },
    methods: {
    }
  };
  </script>