<template>
  <modal 
    name="qr-modal" 
    width="400"  
    height="auto"  
    draggable
    :clickToClose="true"
    :adaptive="true" >
    <div class="qr-modal__header">
        <v-btn
            icon
            small
            color="black"
            @click="cancel()"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </div>
    <div class="ma-3 mt-12">
        <p class="mx-5 my-0 body-1 font-weight-medium">Go to Mobile</p>
        <span class="mx-5 caption">Scan this code to redirect on this page</span>
        <div class="pa-5 pt-6 d-flex justify-center align-center">
            <div>
                <qr-code :text="urlLink.toString()"></qr-code>
            </div>
        </div>
        <div class="mx-5 mt-5">
            <span class="caption">
                <a href="javascript:void(0)" @click="copyUrl">
                    Copy Link
                </a>
            </span>
            <v-text-field
                v-model="urlLink"
                append-icon="mdi-content-copy"
                label="url"
                type="text"
                solo
                @click:append="copyUrl"
            ></v-text-field>
        </div>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            url: '',
        };
    },
    computed: {
        ...mapState("layout", [
            "type",
            "currentType"
        ]),
        user() {
            return this.$store.state.user.current_user
        },
        companyId(){
            let companyId
            if (this.user) {
                companyId = this.user.company_id
            } else {
                companyId = this.$cookies.get('companyId')
            }

            if (this.$route.query.company_id) {
                companyId = this.$route.query.company_id
            }
            return companyId
        },
        urlLink() {
            let url = window.location.href
            let urlObject = new URL(window.location.href);
            if (this.type == 'modified' || this.currentType == 'modified') {
                urlObject.searchParams.append('company_id', this.companyId);
                urlObject.searchParams.append('shared', true);
            } 
            return urlObject
        }        
    },
    watch:{
        $route (to, from){
            this.urlLink
        },
        type() {
            this.urlLink
        },
        currentType() {
            this.urlLink
        }
    },
    mounted() {
        this.urlLink
    },
    methods: {
        cancel() {
            this.$modal.hide('qr-modal')
        },
        copyUrl() {
            this.$successNotif('Copied URL')
            navigator.clipboard.writeText(this.urlLink);
        },
    },
}
</script>
