<template>
<div>
  <div>
    <specific-dealer-filter @filter="filter"/>
  </div>
    <div class="content-card__header">
      <v-btn color="primaryMain" class="primaryMainText--text" @click="goToCreate()" v-if="roleCondition()">
        <v-icon left>
          mdi-plus
        </v-icon>
        Add New
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      :options.sync="options"
      :server-items-length="pagination.total"
      :items-per-page="pagination.per_page"
      :footer-props="{ 
        disablePagination: paginationDisable,
        'items-per-page-options': [5, 10, 20, 50 ]
      }"
    >

        <template v-slot:item.name="{ item }">
        <v-tooltip bottom> 
            <template v-slot:activator="{ on, attrs }">
            <a href=""
                v-bind="attrs"
                v-on="on"
                @click="roleCondition(item) ? goToEdit(item.id) : goToView(item.id) "
            >
                {{ item.name }}
            </a>
            </template>
            <span>View</span>
        </v-tooltip>
        </template>
        <template v-slot:item.created_at="{ item }">
        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
        </template>
        <template v-slot:item.deleted_at="{ item }">
        <span>{{ new Date(item.deleted_at).toLocaleString() }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <div v-if="is_super_admin">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      @click="resync(item)"
                      x-small 
                      icon 
                      color="purple"
                      class="mx-1" 
                      v-bind="attrs"
                      v-on="on">
                        <v-icon>mdi-sync</v-icon>
                    </v-btn>
                </template>
                <span>Re-Sync</span>
              </v-tooltip>
            </div>
            <div v-if="showTrashed">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      @click="restore(item)"
                      x-small 
                      icon 
                      color="success"
                      class="mx-1" 
                      v-bind="attrs"
                      v-on="on">
                        <v-icon>mdi-backup-restore</v-icon>
                    </v-btn>
                </template>
                <span>Restore</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      @click="permanentDelete(item)"
                      x-small 
                      icon 
                      color="error"
                      class="mx-1" 
                      v-bind="attrs"
                      v-on="on">
                        <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                </template>
                <span>Permanent Remove</span>
              </v-tooltip>
            </div>
            <div v-else>
                <span>
                  <v-tooltip bottom v-if="roleCondition()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          @click="goToEdit(item.id)"
                          x-small 
                          icon 
                          color="warning"
                          class="mx-1" 
                          v-bind="attrs"
                          v-on="on">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                          @click="goToView(item.id)"
                          x-small 
                          icon 
                          color="info"
                          class="mx-1" 
                          v-bind="attrs"
                          v-on="on">
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                </span>

                <v-tooltip bottom  v-if="roleCondition()">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        @click="remove(item)"
                        x-small 
                        color="error"
                        icon 
                        class="mx-1" 
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>Remove</span>
                </v-tooltip>
            </div>
          </div>
        </template>
    </v-data-table>
</div>
</template>

<script>
import SpecificDealerFilter from './SpecificDealerFilter.vue';
import { mapState } from "vuex";
import {
    find as _find,
} from 'lodash';

export default {
  name: 'SpecificDealerTable',
  components: {
    SpecificDealerFilter
  },
  data () {
    return {
        loading: false,
        headers: [
          { text: 'Name', value: 'name'},
          { text: 'Company', value: 'company' },      
          { text: 'Date Created', value: 'created_at' },
          { text: 'Date Removed', value: 'deleted_at', align: ' d-none' },
          { text: '', value: 'action', width: '180'},
        ],
        items: [],
        search: '',
        pagination: {
          total: 1,
          per_page: 10,
          current_page: 1,
          search: '',
          sortBy: '',
          sortDesc: false,
        },
        options: {},
        paginationDisable: false,
        timeout: null,
        filters: null,
    }
  },
  computed: {
    ...mapState("layout", ["labels"]),
    ...mapState("user", [
          "current_user", 
          'is_super_admin', 
          'is_account_manager'
      ]),
      showTrashed(){
        return this.filters ? this.filters.trashed : false 
      }
  },
  watch: {
    options: {
      handler() {
        this.changeTable();
      },
      deep: true,
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true;
      this.paginationDisable = true;
      this.items = [];

      this.$http
        .get(`specific-dealer`, {
            params: {
              per_page: this.options.itemsPerPage,
              current_page: this.pagination.current_page,
              sortBy: this.pagination.sortBy,
              sortDesc: this.pagination.sortDesc,
              filter: this.filters,
            },
        })
        .then((data) => {
            this.loading = false
            this.items = data.data.data
            this.pagination.total = data.data.total;
            this.pagination.current_page = data.data.current_page;
            this.pagination.per_page = data.data.per_page
            this.paginationDisable = false;
        })
        .catch((error) => {
            this.loading = false
            this.$errorNotif("Something went wrong")
        })
    },
    changeTable() {
      this.pagination.current_page = this.options.page;
      this.pagination.per_page = this.options.itemsPerPage;

      this.pagination.sortBy =
        this.options.sortBy !== undefined ? this.options.sortBy[0] : '';
      this.pagination.sortDesc =
        this.options.sortDesc !== undefined ? this.options.sortDesc[0] : '';

      this.fetch();
    },
    changeStatus(item) {
      this.selectedItem = item
      this.$modal.show('status-modal')
    },
    goToCreate() {
      this.$router.push({ name: this.$route.meta.type+".create" });
    },
    goToEdit(id) {
      this.$router.push({ name: this.$route.meta.type+".edit", params:{id: id } });
    },
    goToView(id) {
      this.$router.push({ name: this.$route.meta.type+".view", params:{id: id } });
    },
    remove(item) {
      let msg = {
          title: "Remove "+item.name+"?",
          confirmButtonText: "Yes, Remove!",
      }

      this.$questionNotif(msg)
      .then((result) => {
        if (result) {
          this.loading = true
          this.$http
          .delete(`/specific-dealer/${item.id}`)
          .then((data) => {
              this.loading = false
              this.$successNotif('Successfully deleted '+this.labels.dealer)
              this.fetch()
          })
          .catch((error) => {
            this.loading = false
            this.$errorNotif(error.response.data.msg)
        })
        }
      })
    },

    restore(item) {
      let msg = {
          title: "Restore "+item.name+"?",
          confirmButtonText: "Yes, Restore!",
      }

      this.$questionNotif(msg)
      .then((result) => {
        if (result) {
          this.loading = true
          this.$http
          .put(`/specific-dealer/restore/${item.id}`)
          .then((data) => {
              this.loading = false
              this.$successNotif('Successfully restored '+this.labels.dealer)
              this.fetch()
          })
        }
      })
    },

    permanentDelete(item) {
      let msg = {
          title: "Permanent Remove "+item.name+"?",
          confirmButtonText: "Yes, Remove!",
      }

      this.$questionNotif(msg)
      .then((result) => {
        if (result) {
          this.loading = true
          this.$http
          .put(`/specific-dealer/permanent-remove/${item.id}`)
          .then((data) => {
              this.loading = false
              this.$successNotif('Successfully permanent removed the '+this.labels.dealer)
              this.fetch()
          })
        }
      })
    },

    filter(filters) {
      this.filters = filters
      this.fetch()
  
      const items = _find(this.headers, { value: 'deleted_at' });
      this.showTrashed 
        ? items.align = '' 
        : items.align = ' d-none' 
    },
    roleCondition(item = false) {
      if(item && item.deleted_at) {
        return false
      }

      if (this.is_super_admin) {
        return true
      }

      if (this.is_account_manager) {
        return true
      }

      return false
    },
    resync(item) {
      this.loading = true
      this.$http
        .get(`/resync/${item.id}`, {
          params: {
            model: 'App\\Models\\SpecificDealer',
          },
        })
        .then((data) => {
            this.loading = false;
            this.$successNotif("successfully re-synced")
        })
        .catch((error) => {
            this.loading = false;
            this.$errorNotif("Something went wrong")
        })
    }
  }
};
</script>
