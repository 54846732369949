<template>
<div class="company-logo">
    <div>
        <p class="subtitle-2 my-3">
            Company Logo
        </p>
        <v-alert
            max-width="300"
            dense
            class="caption text-left"
            outlined
            v-if="isError"
            type="error"
        >
        {{ errorMsg }}
        </v-alert>
        <p class="caption">Recommended: <br> png, jpg, 2MB max size, width: 280px, height: 77px</p>
        <v-hover>
            <template v-slot:default="{ hover }">
                <div class="company-logo__section">
                    <v-img 
                        class="company-logo__img"
                        max-height="150"
                        max-width="250" 
                        @click="openFile()"
                        :src="image_preview">  
                        <v-fade-transition>
                            <v-overlay
                                v-if="hover"
                                absolute
                                class="img-hover" >
                                <p class="text-center px-2 ma-0" width="100%">
                                    <a href="javascript:void(0)" >
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                            v-bind="attrs"
                                            v-on="on">mdi-upload</v-icon>
                                            </template>
                                            <span>Click to upload company logo</span>
                                        </v-tooltip>
                                    </a>
                                </p>
                            </v-overlay>
                        </v-fade-transition>
                    </v-img>
                </div>
            </template>
        </v-hover>
        <input 
            class="file-type" 
            ref="imageFiles" 
            type="file" 
            accept="image/png, image/gif, image/jpeg" 
            @change="previewImage()" 
            id="company-logo-img-file"
        >
    </div>

    <div class="mt-10">
        <p class="body-2 mt-3 mb-1 font-weight-medium">Company Logo width</p>
        <p class="caption">
            <span>min: 50px |</span>
            <span> max: 250px</span>
        </p>
         <v-slider
            v-model="width"
            class="align-center"
            max="250"
            min="50"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="width"
                class="mt-0 pt-0"
                single-line
                type="number"
                style="width: 48px"
                suffix="px"
                :rules="[rules.maxCounter, rules.minCounter]"
              ></v-text-field>
            </template>
          </v-slider>
        
    </div>
</div>
</template>
<script>
import { mapState } from "vuex";
import emptyImgImport from '@/assets/images/revdojo_logo.png'

export default {
    computed: {
        ...mapState("layout", ["companyLogo", "companyLogoWidth"]),
        user() {
            return this.$store.state.user.current_user
        },
    },
    props: [
        'isError'
    ],
    data() {
        return {
            image: '',
            emptyImg: emptyImgImport,
            image_preview: '',
            width: '',
            rules: {
                maxCounter: value => value <= 250 || 'max 250px',
                minCounter: value => value >= 50 || 'min 50px',
            },
            errorMsg: 'Only jpeg,png,gif images are allowed. Maximum allowed size is 2MB'
        };
    },
    watch: {
        companyLogo() {
            this.renderImage()
        },
        companyLogoWidth() {
            this.renderWidth()
        },
        width() {
            this.previewWidth()
        }
    },
    mounted() {
        this.renderWidth()
        this.renderImage()
    },
    methods: {
        openFile() {
           $('#company-logo-img-file').trigger('click');
        },
        
        renderImage() {
            if (!this.companyLogo) {
                return this.image_preview = this.emptyImg
            }
            return this.image_preview = this.companyLogo
        },

        renderWidth() {
            this.width = this.companyLogoWidth
        },

        previewImage() {
            let event = this.$refs.imageFiles.files[0];
            const file = event;
            this.image = event
            this.image_preview = URL.createObjectURL(file);
            this.$store.commit('layout/SET_LOGO', this.image_preview)    
            this.$emit('logoUpload', this.image)
        },
        
        previewWidth() {
            this.$store.commit('layout/SET_LOGO_WIDTH', this.width)   
            this.$emit('logoWidthUpload', this.width)
        },
      
    },
}
</script>
